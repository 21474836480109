import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import CustomerPriceReport from './CustomerPriceReport';
import CustomerOrderReport from './CustomerOrderReport';
import Utils from '../Utils';

class CustomersReport extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { report } = Utils.sanitizeQuery(['reporte'], props.location.search);
    const { id } = props.match.params;
    this.state = {
      customerId: id,
      reportKey: report,
    };
  }

  render() {
    const { customerId, reportKey } = this.state;
    return (
      <div>
        <Row className="d-flex align-items-center page-title">
          <Col md={6}>
            <h1>Reportes de clientes</h1>
          </Col>
        </Row>
        <Tabs defaultActiveKey="pedidos" activeKey={reportKey} id="uncontrolled-tab-example">
          <Tab eventKey="pedidos" title="Pedidos">
            <CustomerOrderReport customerId={customerId} />
          </Tab>
          <Tab eventKey="precios" title="Precios y descuentos">
            <CustomerPriceReport customerId={customerId} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default withToastManager(CustomersReport);
