import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormInputField, FormSwitch } from '../../components';

import APIClient from '../../services/APIClient';

class ProveedorEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: null,
      levels: [],
    };
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;

    const proveedoresRes = await APIClient.get(`/proveedores/${id}`);

    const entity = proveedoresRes.data.data;

    this.setState({ entity });
    return entity;
  };

  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/proveedores', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/proveedores/${id}`, entityToSave);
    }

    toastManager.add(
      `Proveedor #${saveResponse.data.data.description} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/proveedores'),
    );
    return saveResponse.data.data;
  };



  render() {
    const { entity, isAdding, } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Proveedor nuevo' : `Proveedor #${entity && entity.description}`}</h1>
        <EntityEditForm
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}>
            <Row>
            <Col md={4}>
                <FormInputField id="code" label="Codigo" type="text" defaultValue={entity && entity.code} required />
              </Col>
              <Col md={4}>
                <FormInputField id="description" label="Descripción" type="text" defaultValue={entity && entity.description} required />
              </Col>
            </Row>
            <Row>
                  <Col md={8}>
                    <FormSwitch
                      id="active"
                      label={'Activo'}
                      checked={entity ? entity.active : true}
                      labelField="active"
                    />
                  </Col>
                </Row>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ProveedorEdit);
