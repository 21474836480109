import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import {
  EntityEditForm, FormAddressInput, FormInputField, FormCheckField, FormSelectField,
} from '../../components';
import APIClient from '../../services/APIClient';

class PuntoEntregaEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };


  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      clientes: [],
      entity: {},
      expresos: [],
      id,
      isAdding: typeof id === 'undefined',
      provincias: [],
    };
  }

  onLoadForm = async () => {
    // get provincias
    const provinciasRes = await APIClient.get('/provincias');

    // get clientes
    const clientesRes = await APIClient.get('/clientes');

    // get expresos
    const expresosRes = await APIClient.get('/expresos');
    this.setState({
      clientes: clientesRes.data.data,
      expresos: expresosRes.data.data,
      provincias: provinciasRes.data.data,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get puntoEntrega
    const puntoEntregaRes = await APIClient.get(`/puntos-entrega/${id}`);
    this.setState({
      entity: puntoEntregaRes.data.data,
    });

    return puntoEntregaRes.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/puntos-entrega', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/puntos-entrega/${id}`, entityToSave);
    }

    history.push('/puntos-entrega');
    toastManager.add(`Punto de Entrega ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  render() {
    const {
      clientes, entity, expresos, id, isAdding, provincias,
    } = this.state;

    return (
      <div>
        <h1 className="page-title">
          {isAdding ? 'Punto de Entrega nuevo' : `Punto de Entrega #${id}`}
        </h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
        >
          <>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="descripcion"
                  label="Descripción"
                  type="text"
                  defaultValue={entity.descripcion}
                />
              </Col>
            </Row>

            <FormAddressInput addMode={isAdding} entity={entity} provincias={provincias} />

            <Row>
              <Col md={6}>
                <FormInputField id="email" label="Email" type="text" defaultValue={entity.email} />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="telefono"
                  label="Teléfono"
                  type="text"
                  defaultValue={entity.telefono}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelectField
                  id="clienteId"
                  label="Cliente"
                  type="text"
                  choices={clientes}
                  choiceIdField="id"
                  choiceLabelField="razonSocial"
                  defaultValue={entity.clienteId}
                  placeholder="(Sin Cliente asociado)"
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="expresoId"
                  label="Expreso"
                  type="text"
                  choices={expresos}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                  defaultValue={entity.expresoId}
                  placeholder="(Sin Expreso)"
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <FormCheckField id="isEliminado" label="Deshabilitado" defaultChecked={entity.isEliminado} />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(PuntoEntregaEdit);
