import React, { useEffect, useState } from 'react';
import { Alert, Card, Image, Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Loading } from '../../components';
import apiClient from '../../services/APIClient';
import Security from '../../services/Security';
import Utils from '../Utils';

interface LogoInterface {
  full_url: string;
}

interface PermissionDbInterface {
  codigo: string;
  created_at: string;
  updated_at: string;
  descripcion: string;
  id: number;
}

interface PermissionInterface {
  rol: string;
  isSuperAdmin: boolean;
  pathView: string;
  actionsView: Array<string>;
}

const HomePortal = (): JSX.Element => {
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [logo, setlogo] = useState<LogoInterface>();
  const [isSuperAdmin, setisSuperAdmin] = useState(false);
  const [permissions, setPermissions] = useState<PermissionInterface[]>([]);
  const [permissionsDb, setPermissionsDb] = useState<PermissionDbInterface[]>([]);
  useEffect(() => {
    loadInitialDate();
    getLogo();
    getPermissions();
  }, []);

  const loadInitialDate = async () => {
    try {
      const permissionsDb = (await apiClient.get('/permissions')).data.data;
      setPermissionsDb(permissionsDb);
    } catch (error) {
      console.error(error);
    }
  };

  const getPermissions = async () => {
    const userPermissions = await Utils.viewsByPermission();
    const isSuperAdminUser = userPermissions.every((permission: any) => permission.isSuperAdmin === true);
    setPermissions(userPermissions);
    setisSuperAdmin(isSuperAdminUser);
    setIsDataLoading(false);
  };

  const getLogo = async () => {
    try {
      const logo = await apiClient.get('/cms/logo');
      setlogo(logo.data.data);
    } catch (error) {
      console.error(`No se pudo obtener el logo: `, error);
    }
  };
  return (
    <>
      {isDataLoading === false ? (
        <div className="page-title">
          <div>
            {' '}
            <Image className="mw-100 mt-4" src={logo?.full_url} alt="Logo Mareno" />
          </div>
          <h1 className="mt-4">{`Bienvenido ${Security.getSession().user.username}`}</h1>
          {isSuperAdmin ? (
            <div>
              <Alert className="my-4" variant={'success'}>
                Eres <span className="font-weight-bold">SUPER USUARIO</span> tienes acceso a todos los modulos y ademas puedes Gestionar
                Usuarios y Roles
              </Alert>
            </div>
          ) : (
            <>
              {' '}
              <div className="page-title">
                <Alert className="my-4" variant={'success'}>
                  POSEE LOS SIGUIENTES PERMISOS.
                </Alert>
              </div>
              <div className="d-flex row ">
                {permissions.map((permission) => {
                  return (
                    <>
                      <div className=" mb-4  col-4">
                        {permission.pathView.length > 0 ? (
                          <>
                            <Card bg={'primary'} key={permission.pathView} text={'white'}>
                              <Card.Header>PERMISO</Card.Header>
                              <Card.Body>
                                <Card.Title>
                                  <NavItem>
                                    <LinkContainer to={`/${permission.pathView}`}>
                                      <Nav.Link>
                                        <span className="font-weight-bold text-white px-0">{permission.pathView.toUpperCase()}</span>
                                      </Nav.Link>
                                    </LinkContainer>
                                  </NavItem>
                                </Card.Title>
                                {permission.actionsView.map((action) => {
                                  const permissionDb = permissionsDb.find((per) => per.codigo === `${permission.pathView}_${action}`);
                                  return (
                                    <Card.Text>
                                      <li>{permissionDb?.descripcion}</li>
                                    </Card.Text>
                                  );
                                })}
                              </Card.Body>
                            </Card>
                          </>
                        ) : (
                          <>
                            <span className="font-weight-bold ">NO POSEE NINGUN PERMISO</span>
                          </>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default HomePortal;
