export const SET_INGRESO = 'SET_INGRESO';
export const DELETE_INGRESO = 'DELETE_INGRESO';
export const CLEAR_STORE = 'CLEAR_STORE';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';

export const setPermissionsUser = value =>  ({type: SET_PERMISSIONS, value});

export const setIngreso = value =>  ({type: SET_INGRESO, value});

export const deleteItem = value =>  ({type: DELETE_INGRESO, value});

export const clearStore = value =>  ({type: CLEAR_STORE, value});