import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import APIClient from '../../services/APIClient';
import { DataTable, FormSelectField } from '../../components';

class CustomerPriceReport extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      customerId: props.customerId,
      customers: [],
      details: [],
      isDataLoading: true,
      totalSize: 0,
      selectedCustomerId: '',
    };
  }

  async componentDidMount() {
    const { customerId } = this.state;
    await this.loadCustomers();
    if (customerId) {
      this.setState(prevState => ({
        ...prevState,
        selectedCustomerId: customerId,
      }));
      this.onLoadData();
    }
  }

  // load all customers for customers select
  loadCustomers = async () => {
    const { toastManager } = this.props;
    try {
      const response = await APIClient.get('/clientes?&sortField=razonSocial&sortDir=asc');

      this.setState(prevState => ({
        ...prevState,
        customers: response.data.data,
        isDataLoading: false,
      }));
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  handleFormChange = (e) => {
    const { value, name } = e.target;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  onLoadData = async () => {
    const { toastManager } = this.props;
    const { selectedCustomerId } = this.state;
    try {
      this.setState(prevState => ({
        ...prevState,
        isDataLoading: true,
      }));

      const response = await APIClient.get(`/customer-report/${selectedCustomerId}/prices`);

      this.setState(prevState => ({
        ...prevState,
        details: response.data.data,
        totalSize: response.data.data.length,
        isDataLoading: false,
      }));
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  render() {
    const {
      customers, details, selectedCustomerId, isDataLoading, totalSize,
    } = this.state;

    const columns = [
      {
        dataField: 'codigoEan13',
        text: 'Código',
        sort: true,
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'lista_precio',
        text: 'Lista de precio',
        sort: true,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
      {
        dataField: 'modificador_precio',
        text: 'Descuento',
        sort: true,
      },
      {
        dataField: 'porcentaje',
        text: 'Porcentaje',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
    ];

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title className="mb-4">Reporte de precios y descuentos</Card.Title>
            <Row className="d-flex justify-content-between">
              <Col md={6}>
                <FormSelectField
                  id="selectedCustomerId"
                  label="Selecciona un cliente"
                  value={selectedCustomerId}
                  onChange={this.handleFormChange}
                  choices={customers}
                  choiceIdField="id"
                  choiceLabelField="razonSocial"
                />
              </Col>
              <Col md={1}>
                <Button variant="primary" size="md" className="mt-4" onClick={this.onLoadData}>
                  Buscar
                </Button>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={details || []}
              keyField="articuloId"
              isDataLoading={isDataLoading}
              totalSize={totalSize}
              showExport={false}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withToastManager(CustomerPriceReport);
