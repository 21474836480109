import React from 'react';
import { Route } from 'react-router-dom';
import ProductionOrderList from './ProductionOrderList';
import ProductionOrderAdvance from './ProductionOrderAdvance';
import ProductionOrderNew from './ProductionOrderNew';

const routes = () =>
  [
    <Route path="/production-orders/new" exact component={ProductionOrderNew} key="/production-orders/new" />,
    <Route path="/production-orders/:id" component={ProductionOrderAdvance} key="/production-orders/:id" />,
    <Route path="/production-orders" exact component={ProductionOrderList} key="/production-orders" />,
  ].map((route) => route);

export default routes;
