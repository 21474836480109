import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FormInputField, FormSelectField } from '../../../components';
import { withToastManager } from 'react-toast-notifications';
import APIClient from '../../../services/APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

class RowMovement extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      removeMovement: props.removeMovement,
      handleRowData: props.handleRowData,
      handleIdentificators: props.handleIdentificators,
      handleUniqueIdentificator: props.handleUniqueIdentificator,
      handleEditChangesInState: props.handleEditChangesInState,
      index: props.index,
      productData: props.productData,
      warehouses: props.aditionalData.warehouses,
      sections: props.aditionalData.sections,
      locations: props.aditionalData.locations,
      productStatuses: props.aditionalData.productStatuses,
      ubications: [],
      selectedArticle: {},
      availableWarehouses: [],
      itemCurrentValue: [],
      availableSections: [],
      availableLocations: [],

      unitsInStockLoading: true,

      availableStates: [],
      availableDetails: [],
      selectedFifoDate: [],
      selectedState: '',
      provisionalData: {},
      unitsInStock: 0,
      sectionsForDestination: [],
      locationsForDestination: [],
      descriptionOfMove: '',
      units: 0,
      warehouseCurrentValue: 0,
      sectionCurrentValue: undefined,
      locationCurrentValue: undefined,

      validateUnits: props.validateUnits,
      displayNone: false,
      resetMovementInState: props.resetMovementInState,
      showConfirmModal: false,
      indexRowDelete: undefined,
    };
  }

  handleArticles = async (e) => {
    const { toastManager } = this.props;
    const { productData, warehouses, sections, locations, handleRowData, index, handleIdentificators, resetMovementInState } = this.state;
    const value = e[0].id;
    const data = productData.type.description === 'comercializable' ? { articuloId: value } : { insumoId: value };
    this.setState({ unitsInStockLoading: true, unitsInStock: 0, units: 0 });

    handleRowData(index, data);
    const selectedProductType = productData.type.description;
    const warehousesInArticle = [];
    const sectionsInArticle = [];
    const locationsInArticle = [];
    const detailsInArticle = [];
    try {
      const saveResponse = await APIClient.get(`/monitor-inventario/${value}/${selectedProductType}`);
      let ubications;
      ubications = saveResponse.data.data;
      ubications.map((ubication) => {
        const warehouse = warehouses.find((item) => item.id === ubication.warehouse.id);
        if (!warehousesInArticle.find((item) => item.id === warehouse.id)) {
          warehousesInArticle.push(warehouse);
        }

        const section = sections.find((item) => item.id === ubication.section.id);

        if (!sectionsInArticle.find((item) => item.id === section.id)) {
          sectionsInArticle.push(section);
        }
        const location = locations.find((item) => item.id === ubication.location.id);

        if (!locationsInArticle.find((item) => item.id === location.id)) {
          locationsInArticle.push(location);
        }

        detailsInArticle.push(...ubication.details);
      });

      this.setState(
        (prevState) => ({
          ...prevState,
          ubications,
          unitsInStockLoading: true,
          units: 0,
          availableWarehouses: warehousesInArticle,
          itemCurrentValue: value,
          availableSections: [],
          availableLocations: [],
          selectedFifoDate: [],
          unitsInStock: '',
          selectedState: '',
          warehouseCurrentValue: 0,
          provisionalData: {
            availableSections: sectionsInArticle,
            availableLocations: locationsInArticle,
            availableDetails: detailsInArticle,
          },
        }),
        () => {
          handleIdentificators(index, ubications);
          resetMovementInState(index);
        },
      );
    } catch (err) {
      toastManager.add(`error buscando ubicaciones: ${err}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  handleWarehouses = (e) => {
    const { value } = e.target;
    const { provisionalData, handleRowData, index, handleEditChangesInState, ubications, handleIdentificators } = this.state;
    handleRowData(index, { warehouseId: Number(value) });
    this.setState({ unitsInStockLoading: true, unitsInStock: 0, units: 0 });

    const newSectors = provisionalData.availableSections.filter((sector) => sector?.warehouseId === Number(value));
    this.setState(
      (prevState) => ({
        ...prevState,
        unitsInStock: '',
        availableSections: newSectors,
        availableLocations: [],
        availableStates: [],
        selectedFifoDate: [],
        warehouseCurrentValue: Number(value),
        units: 0,
      }),
      () => {
        handleIdentificators(index, ubications);
        handleEditChangesInState(index, 'warehouseId');
      },
    );
  };

  handleSections = (e) => {
    const { value } = e.target;
    const { provisionalData, handleRowData, index, handleEditChangesInState, ubications, handleIdentificators } = this.state;
    handleRowData(index, { sectionId: Number(value) });
    this.setState({ unitsInStockLoading: true, unitsInStock: 0, units: 0 });

    const newLocations = provisionalData.availableLocations.filter((location) => location?.sectionId === Number(value));
    this.setState(
      (prevState) => ({
        ...prevState,
        sectionCurrentValue: Number(value),
        unitsInStock: '',
        availableLocations: newLocations,
        unitsInStockLoading: true,
        availableStates: [],
        selectedFifoDate: [],
        units: 0,
        locationsLoading: false,
        statusLoading: true,
        fifoDateLoading: true,
        unitsInStockLoading: true,
      }),
      () => {
        handleIdentificators(index, ubications);
        handleEditChangesInState(index, 'sectionId');
      },
    );
  };

  handleLocations = (e) => {
    const { value } = e.target;
    const { provisionalData, handleRowData, index, handleEditChangesInState } = this.state;
    this.setState({ unitsInStockLoading: true, unitsInStock: 0, units: 0 });

    handleRowData(index, { locationId: Number(value) });
    const newStates = [];
    provisionalData.availableDetails.map(async (detail) => {
      if (detail.locationId === Number(value) && !newStates.find((item) => item.id === detail?.locationId)) {
        const existState = newStates.some((elem) => elem.id === detail.productStatus.id);

        if (existState === false) {
          newStates.push({
            ...detail.productStatus,
            locationId: detail.locationId,
          });
        }
      }
    });

    this.setState(
      (prevState) => ({
        ...prevState,
        locationCurrentValue: Number(value),
        unitsInStock: '',
        availableStates: newStates,
        unitsInStockLoading: true,
        selectedState: '',
        selectedFifoDate: [],
        units: 0,
      }),
      () => {
        handleEditChangesInState(index, 'locationId');
      },
    );
  };

  handleStatuses = async (e) => {
    const { value } = e.target;
    this.setState({ unitsInStockLoading: true, unitsInStock: 0, units: 0 });
    const {
      index,
      productData,
      handleRowData,
      handleEditChangesInState,
      itemCurrentValue,
      warehouseCurrentValue,
      sectionCurrentValue,
      locationCurrentValue,
    } = this.state;
    if (value) {
      const typeItem = productData.type.description;
      const queryParams = `statusId=${value}&typeItem=${typeItem}&articleId=${itemCurrentValue}&insumoId=${itemCurrentValue}&warehouseId=${warehouseCurrentValue}&sectionId=${sectionCurrentValue}&locationId=${locationCurrentValue}`;
      const unistStockRes = await APIClient.get(`/stocklist/units-available?${queryParams}`);
      handleRowData(index, { productStatusId: Number(value) });

      const unitsInStock = unistStockRes.data.data[0].resta;
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedState: value,
          unitsInStock,
          unitsInStockLoading: false,
          units: 0,
        }),
        () => {
          handleEditChangesInState(index, 'productStatusId');
        },
      );
    } else {
      this.setState({ selectedState: '', unitsInStock: 0, units: 0 });
    }
  };

  unitsToMove = async (e) => {
    const { toastManager } = this.props;
    const { value } = e.target;
    const { handleRowData, index, validateUnits, unitsInStock } = this.state;

    let units = value;

    const validation = await validateUnits(index, { unitsToMove: value });

    if (validation) {
      if (Number(value) > unitsInStock) {
        toastManager.add('Stock insuficiente', {
          appearance: 'warning',
          autoDismiss: true,
        });
        units = '0'
        
      }
        handleRowData(index, { unitsToMove: units });
        this.setState({ units });
    }
  };

  handleWarehousesForDestination = (e) => {
    const { value } = e.target;
    const { sections, handleRowData, index } = this.state;
    const newSections = sections.filter((section) => section.warehouseId === Number(value));
    handleRowData(index, { warehouseIdDestination: Number(value) });
    this.setState((prevState) => ({
      ...prevState,
      sectionsForDestination: newSections,
      locationsForDestination: [],
    }));
  };

  handleSectionsForDestination = (e) => {
    const { value } = e.target;
    const { locations, handleRowData, index } = this.state;
    const newLocations = locations.filter((location) => location.sectionId === Number(value));
    handleRowData(index, { sectionIdDestination: Number(value) });
    this.setState((prevState) => ({
      ...prevState,
      locationsForDestination: newLocations,
    }));
  };

  handleLocationsForDestination = (e) => {
    const { value } = e.target;
    const { handleRowData, index } = this.state;
    handleRowData(index, { locationIdDestination: Number(value) });
  };

  handleStatusForDestination = (e) => {
    const { value } = e.target;
    const { handleRowData, index } = this.state;
    handleRowData(index, { productStatusIdDestination: Number(value) });
  };

  handleDescriptionOfMove = (e) => {
    const { value } = e.target;
    const { handleRowData, index } = this.state;
    handleRowData(index, { descriptionOfMove: value });

    this.setState((prevState) => ({
      ...prevState,
      descriptionOfMove: value,
    }));
  };

  showDeletemModal = (index) => {
    this.setState({
      showConfirmModal: true,
      indexRowDelete: index,
    });
  };

  closeDeleteConfirmModal = (row) => {
    this.setState({
      indexRowDelete: undefined,
      showConfirmModal: false,
    });
  };

  renderDeleteModal = () => {
    const { showConfirmModal, indexRowDelete, removeMovement } = this.state;
    return (
      <Modal size="md" show={showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Transaccion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar y deshacer esta transacción?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => removeMovement(indexRowDelete)}>
            Eliminar
          </Button>
          <Button variant="secondary" onClick={this.closeDeleteConfirmModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const {
      index,
      productData,
      availableWarehouses,
      availableSections,
      availableLocations,
      availableStates,
      unitsInStock,
      warehouses,
      sectionsForDestination,
      locationsForDestination,
      selectedState,
      productStatuses,
      descriptionOfMove,
      units,
      warehouseCurrentValue,
      unitsInStockLoading,
    } = this.state;
    const displayNone_ = this.props.displayNone;
    const redBorder = this.props.indexesErrors.indexOf(this.props.index) >= 0;
    return (
      <div key={index}>
        {this.renderDeleteModal()}

        <Row
          style={{ flexWrap: 'nowrap', width: '160%' }}
          className={`align-items-center ${displayNone_ ? 'd-none' : ''} ${redBorder ? 'border border-danger' : ''}`}>
          <Button className="mt-3" size="sm" variant="outline-danger" title="Eliminar" onClick={() => this.showDeletemModal(index)}>
            <FontAwesomeIcon icon={faTrashAlt} fixedWidth size="xs" />
          </Button>
          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '17%', flex: '0 0 17%' }}>
            <Typeahead
              defaultSelected={[]}
              labelKey={(option) => {
                const label =
                  productData.type.description === 'comercializable'
                    ? `${option ? option.erpCodigo : ''} - ${option ? option.descripcion : ''}`
                    : `${option ? option.erpCode : ''} - ${option ? option.description : ''}`;
                return label;
              }}
              id={'articulos'}
              label="Typeahead"
              key={'articulos'}
              style={{ paddingTop: '1rem' }}
              onChange={(selected) => {
                if (selected.length) {
                  this.handleArticles(selected);
                }
              }}
              options={productData.type.description === 'comercializable' ? productData.comercializables : productData.insumos}
              placeholder="Ingrese erp"
              filterBy={productData.type.description === 'comercializable' ? ['descripcion', 'erpCodigo'] : ['description', 'erpCode']}
            />
          </Col>

          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '7.2%', flex: '0 0 7.2%' }}>
            <FormSelectField
              id="warehouseId"
              label="Depósito"
              choices={availableWarehouses}
              value={warehouseCurrentValue}
              onChange={this.handleWarehouses}
              choiceIdField="id"
              choiceLabelField="description"
              size="sm"
            />
          </Col>

          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '7.2%', flex: '0 0 7.2%' }}>
            <FormSelectField
              id="sectionId"
              label="Sector"
              choices={availableSections}
              onChange={this.handleSections}
              choiceIdField="id"
              choiceLabelField="description"
              size="sm"
            />
          </Col>

          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '7.2%', flex: '0 0 7.2%' }}>
            <FormSelectField
              id="locationId"
              label="Posición"
              choices={availableLocations}
              onChange={this.handleLocations}
              choiceIdField="id"
              choiceLabelField="description"
              size="sm"
            />
          </Col>

          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '7.2%', flex: '0 0 7.2%' }}>
            <FormSelectField
              id="productStatusId"
              label="Estado"
              value={selectedState}
              choices={availableStates}
              choiceIdField="id"
              choiceLabelField="description"
              onChange={this.handleStatuses}
              size="sm"
            />
          </Col>

          {!unitsInStockLoading && (
            <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '6%', flex: '0 0 6%' }}>
              <FormInputField disabled id="unitsInStock" label="U. en stock" type="text" defaultValue={unitsInStock} required />
            </Col>
          )}

          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '7.2%', flex: '0 0 7.2%' }}>
            <FormInputField
              id="unitsToMove"
              label="U. a mover"
              type="number"
              value={units}
              min={0}
              max={unitsInStock}
              disabled={!unitsInStock}
              onChange={this.unitsToMove}
              required
            />
          </Col>
          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '7.2%', flex: '0 0 7.2%' }}>
            <FormSelectField
              id="warehouseIdDestination"
              label="Depósito"
              choices={warehouses}
              onChange={this.handleWarehousesForDestination}
              choiceIdField="id"
              choiceLabelField="description"
              size="sm"
            />
          </Col>
          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '7.2%', flex: '0 0 7.2%' }}>
            <FormSelectField
              id="sectionIdDestination"
              label="Sector"
              choices={sectionsForDestination}
              onChange={this.handleSectionsForDestination}
              choiceIdField="id"
              choiceLabelField="description"
              size="sm"
            />
          </Col>
          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '7.2%', flex: '0 0 7.2%' }}>
            <FormSelectField
              id="locationIdDestination"
              label="Posición"
              choices={locationsForDestination}
              onChange={this.handleLocationsForDestination}
              choiceIdField="id"
              choiceLabelField="description"
              size="sm"
            />
          </Col>
          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '7.2%', flex: '0 0 7.2%' }}>
            <FormSelectField
              id="productStatusIdDestination"
              label="Estado"
              choices={productStatuses}
              choiceIdField="id"
              choiceLabelField="description"
              onChange={this.handleStatusForDestination}
              size="sm"
            />
          </Col>
          <Col style={{ paddingLeft: '5px', paddingRight: '5px', maxWidth: '12%', flex: '0 0 12%' }}>
            <FormInputField
              id="descriptionOfMove"
              label="Descripción *"
              type="text"
              onChange={this.handleDescriptionOfMove}
              value={descriptionOfMove}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withToastManager(RowMovement);
