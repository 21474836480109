import { faCircleNotch, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withToastManager } from 'react-toast-notifications';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import { pendingsMapper } from './Utils';
import { connect } from 'react-redux'

class MonitorPendientes extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      articulosList: [],
      colTotals: {},
      generatedColors: [],
      isDataLoading: true,
      lineas: [],
      monitorPendientes: [],
      selectedLinea: {},
      selectedClienteId: 0,
      selectedRow: {},
      showCancel: false,
      showModal: false,
      showArticulosModal: false,
      windowHeight: 0,
    };


  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }

  componentDidMount() {
    this.loadData();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  handleRedirect = async () => {
    const { toastManager, history } = this.props;
    const { selectedClienteId } = this.state;
    try {
      const entregaAbierta = await APIClient.get(`/entregas?filter[clienteId][eq]=${selectedClienteId}&filter[estadoEntrega][eq]=EA`);
      if (entregaAbierta.data.data.length === 0) {
        history.push(`/entregas/nuevo/${selectedClienteId}`);
      } else {
        history.push(`/entregas/${entregaAbierta.data.data[0].id}`);
      }
    } catch (err) {
      toastManager.add('Ocurrió un error al intentar obtener la entrega', {
        appearance: 'error',
      });
    }
  };

  /**
   * Get /monitor-pendientes data and calculate totals.
   * Generate random colors for rows.
   * Save monitorPendientesData, columnTotals & generatedColors to state.
   */
  loadData = async () => {
    const { toastManager } = this.props;
    let monitorPendientesData = [];
    let lineas = [];
    try {
      const monitorPendientesRes = await APIClient.get('/monitor-pendientes');
      monitorPendientesData = monitorPendientesRes.data.data;
      lineas = await APIClient.get('/lineas?filter[eliminadoFlag][eq]=0');
      if (monitorPendientesData.length > 0) {
        const colTotals = {};
        monitorPendientesData.forEach((row, index) => {
          const keys = Object.keys(row);
          for (let i = 0; i < keys.length; i++) {
            const curVal = parseInt(row[keys[i]]);
            const key = keys[i];
            if (index === 0 && !isNaN(curVal)) {
              colTotals[key] = curVal;
            } else if (index === 0) {
              colTotals[key] = 0;
            } else if (curVal) {
              colTotals[key] += curVal;
            }
          }
          row.total = this.getRowTotals(row);
        });
        colTotals.total = this.getRowTotals(colTotals);

        // Get columns and generate random colors.
        const tableColumns = Object.keys(monitorPendientesData[0]);
        const randomColors = UIUtils.generateRandomColors(tableColumns.length);
        const bgColors = randomColors.map((color, index) => {
          // set grey for Razon Social and Totals columns.
          if (index === 1 || index === randomColors.length - 1) {
            return { backgroundColor: 'grey' };
          }
          return { backgroundColor: color };
        });
        this.setState({
          monitorPendientes: monitorPendientesData,
          colTotals,
          lineas: lineas.data.data,
          generatedColors: bgColors,
        });
      }
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  };

  calculateColumnWidth = (value) => {
    if (value.length * 18 < 50) {
      return 100;
    }
    return value.length * 10 + 50;
  };

  /** Receives column values string array,
   * generates column objects and returns an column object array.
   * @param  {string[]} columnValues - Column string array.
   * @return {Object[]} - column object array.
   */
  generateColumns = (columnValues) => {
    const { colTotals, generatedColors } = this.state;
    const columns = columnValues.map((colVal, index) => {
      const newCol = {
        accessor: colVal,
        sortable: true,
        sortMethod(a, b, descending) {
          // sort values while ignoring null
          a = a === null || a === undefined ? '' : a;
          b = b === null || b === undefined ? '' : b;
          // force any string values to lowercase
          a = typeof a === 'string' ? a.toLowerCase() : a;
          b = typeof b === 'string' ? b.toLowerCase() : b;

          // Return either 1 or -1 to indicate a sort priority
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          // returning 0, undefined or any falsey value will use subsequent sorts or
          // the index as a tiebreaker
          return 0;
        },
        filterable: colVal === 'Razon Social' ? true : false,
      };

      // hide clienteId column
      if (colVal === 'clienteId') {
        newCol.show = false;
      }

      // return null when value is 0
      newCol.Cell = (cell) => {
        if (Number(cell.value) === 0) {
          return null;
        }
        if (colVal === 'Razon Social') {
          return <div title={cell.value}>{cell.value}</div>;
        }
        if (colVal === 'Fecha Entrega') {
          const curDate = moment.utc(cell.value);
          return (
            <div
              className={curDate < moment() ? 'text-center font-weight-bold text-danger' : 'text-center'}
              title={moment(cell.value).format('YYYY-MM-DD')}>
              {moment.utc(cell.value).format('YYYY-MM-DD')}
            </div>
          );
        }
        // apply classes for lineas and total columns
        return <div className={newCol.accessor === 'total' ? 'text-center' : 'text-right'}>{Number(cell.value)}</div>;
      };

      // set column headers
      newCol.Header = (dataObj) => {
        const { column } = dataObj;
        if (!column.id.match(/(Razon Social|total|Fecha Entrega)/)) {
          // set onClick event for text in linea columns, retrieve and set column totals and percentage.
          return (
            <span className="flex-column rt-th">
              <p className="border-bottom pb-2 text-center align-self-center text-uppercase text-nowrap">
                <button
                  type="submit"
                  className="link-button text-white font-weight-bold"
                  onClick={(e) => this.getArticulosAndShowModal(column.id, e)}>
                  {colVal} <FontAwesomeIcon icon={faInfoCircle} />
                </button>
              </p>
              <p className="mb-0">
                {colTotals[column.id]} ({((colTotals[column.id] * 100) / colTotals.total).toFixed(2)}
                %)
              </p>
            </span>
          );
        }
        if (column.id.match(/clienteId/)) return true;

        if (column.id.match(/total/)) {
          // for total column, set classes to center values and show as table header.
          // Also, remove bottom margin from total number.
          return (
            <span className="rt-th">
              <p className="text-nowrap border-bottom pb-2 text-center align-self-center">{column.id}</p>
              <p className="mb-0">{colTotals.total}</p>
            </span>
          );
        }
        return (
          <span className="rt-th">
            <p className="text-center align-self-center">{column.id}</p>
          </span>
        );
      };
      // set generated color for header.
      const bgColor = generatedColors[index].backgroundColor;
      newCol.headerStyle = {
        backgroundColor: bgColor,
        color: 'white',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        whiteSpace: 'no-wrap',
      };

      // set minWidth value according to accessor.length
      newCol.minWidth = this.calculateColumnWidth(newCol.accessor);
      return newCol;
    });
    return columns;
  };

  handleLimpiarBtnClick = async () => {
    // Check for open entregas for clienteId, show limpiar pendientes button if there are none.
    const { toastManager } = this.props;
    const { selectedClienteId } = this.state;
    this.setState({ isDataLoading: true });
    try {
      const entregaResponse = await APIClient.get(`/entregas?filter[clienteId][eq]=${selectedClienteId}&filter[estadoEntrega][eq]=EA`);
      const entregaInProgress = entregaResponse.data.data;
      if (entregaInProgress.length === 0) {
        this.setState({ showCancel: true });
      } else {
        toastManager.add('No se pueden limpiar los pendientes, hay una entrega en curso.', { appearance: 'warning', autoDismiss: true });
        this.setState({ showModal: false });
      }
    } catch (err) {
      console.error(err);
      toastManager.add(`Ocurrió un error: ${err.message}`, { appearance: 'error' });
      this.setState({ showModal: false });
    } finally {
      this.setState({ isDataLoading: false });
    }
  };

  limpiarPendientes = async () => {
    const { toastManager } = this.props;
    const { selectedClienteId } = this.state;
    try {
      await APIClient.delete(`/monitor-pendientes/${selectedClienteId}`);
      this.setState((prevState) => {
        const monitorCopy = [...prevState.monitorPendientes];
        const newMonitorPendientes = monitorCopy.filter((row) => row.clienteId !== selectedClienteId);
        return {
          ...prevState,
          monitorPendientes: newMonitorPendientes,
          selectedRow: [],
        };
      });
      toastManager.add('Se limpiaron los pendientes correctamente.', { appearance: 'success' });
    } catch (err) {
      toastManager.add(`Ocurrió un error: ${err.message}`, { appearance: 'error' });
      console.error(err);
    } finally {
      this.setState({ showCancel: false, showModal: false, isDataLoading: false });
    }
  };

  /**
   * Receives a string and an event object, then retrieves linea info by text,
   * and then gets articulos by lineaId.
   * @param  {string} text - column header to search for linea.
   * @param  {Object} e - event to stop sorting when clicking text.
   */
  getArticulosAndShowModal = async (text, e) => {
    const { toastManager } = this.props;
    e.stopPropagation();
    e.preventDefault();
    try {
      const lineaRes = await APIClient.get(`/lineas?filter[descripcion][like]=${text}`);
      const linea = lineaRes.data.data[0];
      const articulosRes = await APIClient.get(`/articulos?filter[lineaId][eq]=${linea.id}`);
      this.setState({
        showArticulosModal: true,
        selectedLinea: linea,
        articulosList: articulosRes.data.data,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        showArticulosModal: false,
      });
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
    }
  };

  /**
   * Receives row object, isSelect boolean, rowIndex integer and event object.
   * Retrieves solicitudes with estado_solicitud_codigo = SP for client,
   * then loops through the solicitud and its detalles searching
   * for undelivered detalles, pushing them into undeliveredDetalles array.
   * Then, saves undeliveredDetalles into state and shows modal.
   * @param  {Object} row - Row object.
   */
  getRowDataAndShowModal = async (row) => {
    const { toastManager } = this.props;
    const { showModal } = this.state;
    let rowDataRes = [];

    try {
      rowDataRes = await APIClient.get(`/monitor-pendientes/${row.clienteId}`);

      const undeliveredDetalles = pendingsMapper(rowDataRes.data.data);
      this.setState({ selectedClienteId: row.clienteId, selectedRow: undeliveredDetalles, showModal: !showModal });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
      return false;
    }
  };

  /** Receives row data & returns onClick event for row, passing rowInfo to getRowDataAndShowModal function.
   * @param  {Object} state - Table object.
   * @param  {Object} rowInfo - Row data object.
   * @return {Object} onClick event property for rows.
   */
  getRowProps = (state, rowInfo) => ({
    onClick: () => this.getRowDataAndShowModal(rowInfo.original),
  });

  /** Receives row object, loops through its values and returns a total value.
   * @param  {Object} row - Row object.
   * @return  {number} - Row total.
   */
  getRowTotals = (row) => {
    const keys = Object.keys(row);
    let total = 0;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let value = row[key];
      if (!key.match(/Razon Social|Fecha Entrega|clienteId/) && value !== null) {
        value = Number(value);
        total += value;
      }
    }
    return total;
  };

  renderEntregasModal = () => {
    const { isDataLoading, lineas, selectedRow, showModal, showCancel, isSuperAdmin, editPermission } = this.state;

    const columns = [
      {
        dataField: 'articuloId',
        text: 'articuloId',
        hidden: true,
      },
      {
        dataField: 'erpCodigo',
        text: 'Código ERP',
      },
      {
        dataField: 'lineaId',
        text: 'Linea',
        formatter: (cell, row, data, extra) => {
          const linea = extra.find((elem) => elem.id === row.lineaId);
          return linea.descripcion;
        },
        formatExtraData: lineas,
      },
      {
        dataField: 'descripcion',
        text: 'Descripcion',
      },
      {
        dataField: 'noEntregado',
        text: 'Pendientes de Entrega',
        formatter: (cellContent) => <div className="text-right">{cellContent}</div>,
      },
    ];

    return (
      <Modal size="lg" show={showModal} onHide={() => this.setState({ showModal: false, showCancel: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Pendientes de Entrega</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BootstrapTable columns={columns} hover data={selectedRow} keyField="articuloId" />
        </Modal.Body>

        <Modal.Footer>
          {showCancel ? (
            <>
              <strong className="text-danger">¡Advertencia! Este proceso no se puede deshacer. </strong>
              <Button
                disabled={isDataLoading}
                variant="danger"
                onClick={() => {
                  this.setState({ isDataLoading: true });
                  this.limpiarPendientes();
                }}>
                {isDataLoading ? <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" /> : 'Confirmar'}
              </Button>
            </>
          ) : (
            <>
              <Button variant="link" onClick={() => window.open(`/monitor-pendientes/${this.state.selectedClienteId}/imprimir`)}>
                Imprimir pendientes
              </Button>
              {isSuperAdmin || editPermission ? (
                <>
                  <Button variant="danger" onClick={this.handleLimpiarBtnClick}>
                    Limpiar Pendientes
                  </Button>
                  <Button variant="primary" onClick={() => this.handleRedirect()}>
                    Generar Entrega
                  </Button>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          <Button variant="secondary" onClick={() => this.setState({ showModal: false, showCancel: false })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderArticulosModal = () => {
    const { selectedLinea, showArticulosModal, articulosList } = this.state;
    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        hidden: true,
      },
      {
        dataField: 'erpCodigo',
        text: 'Codigo ERP',
      },
      {
        dataField: 'descripcion',
        text: 'Descripcion',
      },
    ];
    return (
      <Modal show={showArticulosModal} onHide={() => this.setState({ showArticulosModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedLinea.descripcion} - Articulos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BootstrapTable columns={columns} hover data={articulosList} keyField="id" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showArticulosModal: false })}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  updateWindowDimensions = () => {
    this.setState({ windowHeight: window.innerHeight });
  };

  render() {
    const { isDataLoading, monitorPendientes, windowHeight } = this.state;

    let columns = [];

    if (monitorPendientes.length > 0) {
      // generate columns
      const colValues = Object.keys(monitorPendientes[0]);
      columns = this.generateColumns(colValues);
    }

    return (
      <div>
        {this.renderEntregasModal()}
        {this.renderArticulosModal()}
        <h1 className="page-title">Monitor de Pendientes</h1>
        {monitorPendientes.length > 0 && !isDataLoading ? (
          <div>
            <ReactTable
              data={monitorPendientes}
              columns={columns}
              defaultPageSize={monitorPendientes.length}
              showPagination={false}
              getTrProps={this.getRowProps}
              className="react-bootstrap-table table-bordered table -highlight"
              style={{
                cursor: 'pointer',
                borderSpacing: 0,
                height: parseInt(`${windowHeight * 0.78}`, 10),
                display: 'flex',
              }}
            />
          </div>
        ) : (
          <div>¡No hay entregas pendientes!</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(MonitorPendientes));