import React from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withToastManager } from 'react-toast-notifications';
import { Button, ButtonGroup, ButtonToolbar, Card, Col, Row, Badge, Modal } from 'react-bootstrap';
import { FormSelectField, DataTable, FormInputField, Loading } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faUser } from '@fortawesome/free-solid-svg-icons';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import { connect } from 'react-redux'

class StocklistList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.apiParams = '';
    this.state = {
      stocklist: [],
      articulos: [],
      sections: [],
      insumos: [],
      warehouses: [],
      isDataLoading: true,
      totalSize: 0,
      curFromDate: '',
      curToDate: '',
      selectedArticulosId: [],
      filterSelectedArticulos: '',
      selectedInsumosId: [],
      selectedMotives: [],
      filterSelectedMotive: '',
      selectedFifoDate: [],
      filterSelectedFifoDate: '',
      filterSelectedInsumos: '',
      selectedTipoMovimiento: [],
      filterSelectedMovimiento: '',
      selectedTipoArticulo: [],
      filterSelectedTipoArticulo: '',
      selectedDeposito: [],
      filterSelectedDeposito: '',
      selectedSector: [],
      filterSelectedSector: '',
      showUserModal: false,
      userIdToView: '',
      usuarios: [],
      stocklistProductTypes: [],
      movementTypes: [],
      locations: [],
      unidadesList: [],
      motives: [],
    };



    this.loadStocklist = this.loadStocklist.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.getFilterData = this.getFilterData.bind(this);
  }

  getPermissions() {
    const { isSuperAdmin, editPermission, deletePermission, isPermissionsLoading } = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }


  componentDidMount() {
    this.loadStocklist();
    this.loadUsuarios();
    this.getFilterData();
    this.loadUnidadeslist();
  }

  /**
   * @param  {string} type - change type
   * @param  {number} page - page number
   * @param  {number} sizePerPage - current page size
   * receives table changes, makes custom API calls and saves results to state.
   */
  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    let {
      curFromDate,
      curToDate,
      filterSelectedArticulos,
      filterSelectedInsumos,
      filterSelectedMovimiento,
      filterSelectedTipoArticulo,
      filterSelectedDeposito,
      filterSelectedSector,
      filterSelectedFifoDate,
      filterSelectedMotive,
    } = this.state;
    this.setState({ isDataLoading: true });
    try {
      const { direction } = sorting;
      let { field } = sorting;
      const filterQuery = `${filterSelectedMotive}&${filterSelectedFifoDate}&fromDate=${curFromDate}&toDate=${curToDate}&${filterSelectedArticulos}&${filterSelectedInsumos}
      &${filterSelectedMovimiento}&${filterSelectedTipoArticulo}&${filterSelectedDeposito}&${filterSelectedSector}
      `;
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || ''}&sortDir=${direction || ''
        }&excludeAssocFields=imagenes&${filterQuery}`;
      const stocklistRes = await APIClient.get(`/stocklist?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}`);
      const stocklist = stocklistRes.data.data;

      this.setState({
        stocklist,
        totalSize: stocklistRes.data.meta.total,
        isDataLoading: false,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  async loadStocklist() {
    const { toastManager } = this.props;
    try {
      const stocklistRes = await APIClient.get('/stocklist?limit=10&offset=0&sortField=date&sortDir=asc');
      this.setState({
        stocklist: stocklistRes.data.data,
        isDataLoading: false,
        totalSize: stocklistRes.data.meta.total,
      });
    } catch (error) {
      console.error('Cargando movimientos.', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  async loadUnidadeslist() {
    const { toastManager } = this.props;
    try {
      const unidadesListRes = await APIClient.get('/unidades-medida');
      this.setState({
        unidadesList: unidadesListRes.data.data,
      });
    } catch (error) {
      console.error('Cargando UDM.', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  async loadUsuarios() {
    const { toastManager } = this.props;

    await APIClient.get('/usuarios')
      .then((res) => {
        this.setState({
          isDataLoading: false,
          usuarios: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  getFilterData = async () => {
    const { toastManager } = this.props;
    try {
      // get tipos
      const articulosRes = await APIClient.get('/articulos');
      const insumosRes = await APIClient.get('/insumos?filter[active][eq]=1');
      const warehousesRes = await APIClient.get('/warehouses?filter[disabled][eq]=0');
      const sectionsRes = await APIClient.get('/sections?filter[disabled][eq]=0');
      const locationsRes = await APIClient.get('/locations?filter[disabled][eq]=0');
      const stocklistProductTypesRes = await APIClient.get('/stocklist-product-types');
      const movementTypesRes = await APIClient.get('/stocklist-movement-types');
      const motivesRes = await APIClient.get('/stocklist-movement-motives');
      this.setState({
        articulos: articulosRes.data.data,
        insumos: insumosRes.data.data,
        warehouses: warehousesRes.data.data,
        sections: sectionsRes.data.data,
        locations: locationsRes.data.data,
        stocklistProductTypes: stocklistProductTypesRes.data.data,
        movementTypes: movementTypesRes.data.data,
        motives: motivesRes.data.data,
      });
    } catch (err) {
      console.error('Error al obtener la información de los filtros: ', err);
      toastManager.add(`No se pudo obtener la información de los filtros.`, {
        appearance: 'error',
      });
    }
  };

  handleDataChange = (e) => {
    const proto = Object.getPrototypeOf(e);
    const isArray = proto.hasOwnProperty('map');
    const argument = !isArray ? e.target : this.parseDataFilterSelect(e);
    const { id, value, options, multiple } = argument;
    let filterName = '';
    let fieldName = '';
    switch (id) {
      case 'selectedArticulosId':
        fieldName = 'articuloId';
        filterName = 'filterSelectedArticulos';
        break;
      case 'selectedInsumosId':
        fieldName = 'insumoId';
        filterName = 'filterSelectedInsumos';
        break;
      case 'selectedTipoMovimiento':
        fieldName = 'typeId';
        filterName = 'filterSelectedMovimiento';
        break;
      case 'selectedTipoArticulo':
        fieldName = 'productTypeId';
        filterName = 'filterSelectedTipoArticulo';
        break;
      case 'selectedDeposito':
        fieldName = 'warehouseId';
        filterName = 'filterSelectedDeposito';
        break;
      case 'selectedSector':
        fieldName = 'sectionId';
        filterName = 'filterSelectedSector';
        break;
      case 'selectedFifoDate':
        fieldName = 'fifoDate';
        filterName = 'filterSelectedFifoDate';
        break;
      case 'selectedMotive':
        fieldName = 'motiveId';
        filterName = 'filterSelectedMotive';
        break;
      default:
        break;
    }
    if (multiple) {
      const values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);
      this.setState((prevState) => ({
        ...prevState,
        [id]: values,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', values),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [id]: value,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', [value]),
      }));
    }
  };

  parseDataFilterSelect = (array) => {
    const response = {};
    response.id = array[0].hasOwnProperty('competidorArticulo') ? 'selectedArticulosId' : 'selectedInsumosId';
    response.value = array[0].id;
    response.multiple = false;
    response.options = array;
    return response;
  };

  createSelectAllButtons = (entityName) => (
    <p className="m-0">
      {entityName.substr(0, 1).toUpperCase()}
      {entityName.substr(1)}(
      <button
        id={`select-all-${entityName}`}
        type="submit"
        className="link-button text-primary"
        onClick={() => this.handleSelectAll(entityName)}>
        Limpiar filtro
      </button>
      )
    </p>
  );

  handleSelectAll = (entityName) => {
    this.setState((prevState) => {
      let {
        selectedArticulosId,
        filterSelectedArticulos,
        selectedInsumosId,
        filterSelectedInsumos,
        selectedTipoMovimiento,
        filterSelectedMovimiento,
        selectedTipoArticulo,
        filterSelectedTipoArticulo,
        selectedDeposito,
        filterSelectedDeposito,
        selectedSector,
        filterSelectedSector,
        selectedFifoDate,
        filterSelectedFifoDate,
        selectedMotive,
        filterSelectedMotive,
      } = prevState;
      // map ids from entities into "selected" variables
      switch (entityName) {
        case 'Articulos':
          selectedArticulosId = [];
          filterSelectedArticulos = '';
          break;
        case 'Insumos':
          selectedInsumosId = [];
          filterSelectedInsumos = '';
          break;
        case 'Tipo de Movimiento':
          selectedTipoMovimiento = [];
          filterSelectedMovimiento = '';
          break;
        case 'Tipo de Articulo':
          selectedTipoArticulo = [];
          filterSelectedTipoArticulo = '';
          break;
        case 'Deposito':
          selectedDeposito = [];
          filterSelectedDeposito = '';
          break;
        case 'Sector':
          selectedSector = [];
          filterSelectedSector = '';
          break;
        case 'N°FIFO':
          selectedFifoDate = [];
          filterSelectedFifoDate = '';
          break;
        case 'Motivos':
          selectedMotive = [];
          filterSelectedMotive = '';
          break;
        default:
          break;
      }

      return {
        ...prevState,
        selectedArticulosId,
        filterSelectedArticulos,
        selectedInsumosId,
        filterSelectedInsumos,
        selectedTipoMovimiento,
        filterSelectedMovimiento,
        selectedTipoArticulo,
        filterSelectedTipoArticulo,
        selectedDeposito,
        filterSelectedDeposito,
        selectedSector,
        filterSelectedSector,
        selectedFifoDate,
        filterSelectedFifoDate,
        selectedMotive,
        filterSelectedMotive,
      };
    });
  };

  makeListOfSectors = (completeList) => {
    const newList = [];
    completeList.map((item) => {
      if (item.section && newList.findIndex((section) => section.id === item.section.id) === -1) {
        newList.push(item.section);
      }
    });
    return newList;
  };

  renderSelectedArticles = () => {
    const { articulos } = this.state;
    const { selectedArticulosId } = this.state;
    // if(selectedInsumosId.length > 0){
    //  return <Badge variant="warning" className="ml-1">Tenga en cuenta que puede buscar solamente por insumos o por articulos</Badge>
    // }

    const selected = articulos.filter((selected) => selectedArticulosId === selected.id);
    return selected.length > 0
      ? selected.map((selected, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {selected.descripcion}
        </Badge>
      ))
      : '';
  };

  renderSelectedSupplies = () => {
    const { selectedInsumosId, insumos } = this.state;

    const selected = insumos.filter((selected) => selectedInsumosId === selected.id);
    return selected.length > 0
      ? selected.map((selected, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {selected.description}
        </Badge>
      ))
      : '';
  };

  renderSelectMovementType = () => {
    const { selectedTipoMovimiento, movementTypes } = this.state;
    const selectedMovementsToNumber = selectedTipoMovimiento.map((selected) => parseInt(selected));
    const selected = movementTypes.filter((selected) => selectedMovementsToNumber.includes(selected.id));
    return selected.length > 0
      ? selected.map((selected, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {selected.description}
        </Badge>
      ))
      : '';
  };

  renderSelectArticleType = () => {
    const { selectedTipoArticulo, stocklistProductTypes } = this.state;
    const selectedTypeArticlesToNumber = selectedTipoArticulo.map((selected) => parseInt(selected));
    const selected = stocklistProductTypes.filter((selected) => selectedTypeArticlesToNumber.includes(selected.id));
    return selected.length > 0
      ? selected.map((selected, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {selected.description}
        </Badge>
      ))
      : '';
  };

  renderSelectDeposito = () => {
    const { selectedDeposito, warehouses } = this.state;

    const selectedDepositoToNumber = selectedDeposito.map((selected) => parseInt(selected));
    const selected = warehouses.filter((selected) => selectedDepositoToNumber.includes(selected.id));
    return selected.length > 0
      ? selected.map((selected, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {selected.description}
        </Badge>
      ))
      : '';
  };

  renderSelectMotive = () => {
    const { selectedMotives, motives } = this.state;

    const selectedMotiveToNumber = selectedMotives.map((selected) => parseInt(selected));
    const selected = motives.filter((selected) => selectedMotiveToNumber.includes(selected.id));
    return selected.length > 0
      ? selected.map((selected, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {selected.description}
        </Badge>
      ))
      : '';
  };

  renderSelectSector = () => {
    const { selectedSector, sections } = this.state;
    const selectedSectorToNumber = selectedSector.map((selected) => parseInt(selected));
    const selected = sections.filter((selected) => selectedSectorToNumber.includes(selected.id));
    return selected.length > 0
      ? selected.map((selected, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {selected.description}
        </Badge>
      ))
      : '';
  };

  showUserModal = () => {
    const { showUserModal, userIdToView, usuarios } = this.state;
    const userIndex = usuarios.findIndex((item) => item.id === userIdToView);
    const user = usuarios[userIndex];
    return (
      <Modal size="md" show={showUserModal} onHide={() => this.setState({ showUserModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Informacion del Movimiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userIdToView && user !== -1 ? (
            <div>
              <p>El usuario que realizo el movimiento fue {`${user.firstName} ${user.lastName}`}</p>

              <p>Para mas información contactese con el via: {user.emailAddress}</p>
            </div>
          ) : (
            <div>El movimiento no tiene ningun usuario asignado</div>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  render() {
    const {
      stocklist,
      articulos,
      insumos,
      warehouses,
      sections,
      isDataLoading,
      totalSize,
      curFromDate,
      curToDate,
      selectedTipoMovimiento,
      selectedTipoArticulo,
      selectedDeposito,
      selectedSector,
      stocklistProductTypes,
      movementTypes,
      selectedFifoDate,
      motives,
      isPermissionsLoading,
    } = this.state;

    const articulosRef = React.createRef();
    const insumosRef = React.createRef();

    const columns = [
      {
        dataField: 'id',
        text: 'ID movimiento',
        sort: true,
      },
      {
        dataField: 'articulo.erpCodigo',
        text: 'Codigo ERP',
        sort: true,
        formatter: (cellContent, rowData) => {
          const erpCode = rowData.articulo ? rowData.articulo.erpCodigo : rowData.insumo.erpCode;
          return erpCode;
        },
      },
      {
        dataField: 'productType.description',
        text: 'Tipo de producto',
        sort: false,
      },
      {
        dataField: 'productStatus.description',
        text: 'Estado',
        sort: false,
      },
      {
        dataField: 'units',
        text: 'Unidades',
        sort: true,
      },
      {
        dataField: 'udm.description',
        text: 'UDM',
        sort: true,
      },
      {
        dataField: 'articulo.descripcion',
        text: 'Producto',
        sort: true,
        formatter: (cellContent, row) => {
          const view = row.articuloId !== null ? row.articulo.descripcion : row.insumo.description;
          return view;
        },
      },
      {
        dataField: 'date',
        text: 'Fecha',
        sort: true,
        formatter: (cellContent, row) => {
          const fecha = new Date(cellContent).toLocaleDateString('en-GB');
          return fecha;
        },
      },
      /*   TODO: COLUMNA FIFO
        {
          dataField: 'fifoDate',
          text: 'N° FIFO',
          sort: false,
        }, */

      {
        dataField: 'type.description',
        text: 'Tipo de movimiento',
        sort: false,
      },

      {
        dataField: 'motive.description',
        text: 'Motivo',
        sort: false,
      },
      {
        dataField: 'description',
        text: 'Descripcion',
        sort: true,
      },
      {
        dataField: 'client.razonSocial',
        text: 'Cliente',
        sort: false,
      },
      {
        dataField: 'provider.description',
        text: 'Proveedor',
        sort: false,
      },

      {
        dataField: 'warehouse.description',
        text: 'Depósito',
        sort: true,
      },
      {
        dataField: 'section.description',
        text: 'Sector',
        sort: true,
      },
      {
        dataField: 'location.description',
        text: 'Ubicación',
        sort: true,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                size="sm"
                variant="outline-primary"
                title="View User"
                onClick={() => {
                  this.setState({ showUserModal: true, userIdToView: row.userId });
                }}>
                <FontAwesomeIcon icon={faUser} fixedWidth size="xs" />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        {this.showUserModal()}
        <h1 className="page-title">Movimientos de inventario</h1>
        {isPermissionsLoading === false ? (
          <>
            <Card>
              <Card.Header>Filtros de búsqueda</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <FormInputField
                      id="curFromDate"
                      type="date"
                      label="Fecha desde:"
                      disabled={isDataLoading}
                      defaultValue={curFromDate !== '' ? curFromDate : null}
                      onChange={this.handleDataChange}
                    />
                  </Col>
                  <Col md={6}>
                    <FormInputField
                      id="curToDate"
                      type="date"
                      label="Fecha hasta:"
                      disabled={isDataLoading}
                      onChange={this.handleDataChange}
                      defaultValue={curToDate !== '' ? curToDate : null}
                      min={curFromDate !== '' ? curFromDate : null}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <p className="mb-0">
                      Articulos
                      <button
                        id="clearInput-Articulos"
                        type="submit"
                        className="link-button text-primary"
                        disabled={isDataLoading}
                        onClick={() => {
                          articulosRef.current.clear();
                          this.setState({ selectedArticulosId: [], filterSelectedArticulos: '' });
                        }}>
                        (Limpiar filtro)
                      </button>
                    </p>
                    <Typeahead
                      defaultSelected={[]}
                      labelKey={(option) => `${option ? option.erpCodigo : 'erp'} - ${option ? option.descripcion : 'descripcion'}`}
                      id={'articulos'}
                      label="Typeahead"
                      key={'articulos'}
                      disabled={isDataLoading}
                      onChange={(selected) => {
                        if (selected.length) {
                          this.handleDataChange(selected);
                        }
                      }}
                      options={articulos}
                      ref={articulosRef}
                      placeholder="Ingrese erp"
                      filterBy={['descripcion', 'erpCodigo']}
                    />

                    {this.renderSelectedArticles()}
                  </Col>
                  <Col md={6}>
                    <p className="m-0">
                      Insumos
                      <button
                        id="clearInput-Insumos"
                        type="submit"
                        className="link-button text-primary"
                        disabled={isDataLoading}
                        onClick={() => {
                          insumosRef.current.clear();
                          this.setState({ selectedInsumosId: [], filterSelectedInsumos: '' });
                        }}>
                        (Limpiar filtro)
                      </button>
                    </p>
                    <Typeahead
                      defaultSelected={[]}
                      labelKey={(option) => `${option ? option.erpCode : 'erp'} - ${option ? option.description : 'descripción'}`}
                      id={'insumos'}
                      label="Typeahead"
                      key={'insumos'}
                      disabled={isDataLoading}
                      onChange={(selected) => {
                        if (selected.length) {
                          this.handleDataChange(selected);
                        }
                      }}
                      options={insumos}
                      ref={insumosRef}
                      placeholder="Ingrese erp"
                      filterBy={['description', 'erpCode']}
                    />
                    {this.renderSelectedSupplies()}
                  </Col>
                </Row>
                <Row className="mt-4 mb-4">
                  <Col md={6}>
                    <FormSelectField
                      id="selectedTipoMovimiento"
                      label={this.createSelectAllButtons('Tipo de Movimiento')}
                      value={selectedTipoMovimiento}
                      onChange={this.handleDataChange}
                      choices={movementTypes}
                      choiceIdField="id"
                      choiceLabelField="description"
                      disabled={isDataLoading}
                      multiple
                    />
                    {this.renderSelectMovementType()}
                  </Col>
                  <Col md={6}>
                    <FormSelectField
                      id="selectedTipoArticulo"
                      label={this.createSelectAllButtons('Tipo de Articulo')}
                      value={selectedTipoArticulo}
                      onChange={this.handleDataChange}
                      choices={stocklistProductTypes}
                      disabled={isDataLoading}
                      choiceIdField="id"
                      choiceLabelField="description"
                      multiple
                    />
                    {this.renderSelectArticleType()}
                  </Col>
                </Row>
                <Row className="mt-4 mb-4">
                  <Col md={6}>
                    <FormSelectField
                      id="selectedDeposito"
                      label={this.createSelectAllButtons('Deposito')}
                      value={selectedDeposito}
                      onChange={this.handleDataChange}
                      choices={warehouses}
                      disabled={isDataLoading}
                      choiceIdField="id"
                      choiceLabelField="description"
                      multiple
                    />
                    {this.renderSelectDeposito()}
                  </Col>
                  <Col md={6}>
                    <FormSelectField
                      id="selectedSector"
                      label={this.createSelectAllButtons('Sector')}
                      value={selectedSector}
                      onChange={this.handleDataChange}
                      choices={sections}
                      choiceIdField="id"
                      disabled={isDataLoading}
                      choiceLabelField="description"
                      multiple
                    />
                    {this.renderSelectSector()}
                  </Col>
                </Row>
                <Row className="mt-4 mb-4">
                  <Col md={6} style={{ marginTop: 13 }}>
                    <FormSelectField
                      id="selectedMotive"
                      label={this.createSelectAllButtons('Motivos')}
                      onChange={this.handleDataChange}
                      choices={motives}
                      choiceIdField="id"
                      choiceLabelField="description"
                      disabled={isDataLoading}
                    />
                    {this.renderSelectMotive()}
                  </Col>

                  {/*        
          TODO: FILTRO PARA N° FIFO
             <Col md={6}>
                    <span>
                      N°FIFO
                      <button
                        id="clearInput-Insumos"
                        type="submit"
                        className="link-button text-primary "
                        onClick={() => {
                          this.setState({ selectedFifoDate: [], filterSelectedFifoDate: '' });
                        }}>
                        (Limpiar filtro)
                      </button>
                      <FormInputField
                        id="selectedFifoDate"
                        value={selectedFifoDate}
                        disabled={isDataLoading}
                        placeholder={'1632200....'}
                        onChange={this.handleDataChange}
                        label=''
                      />
                      {this.renderSelectSector()}
                    </span>
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex flex-row justify-content-center">
                      <Button
                        disabled={isDataLoading}
                        className="d-flex py-2 m-1 my-3 btn-lg"
                        variant="primary"
                        onClick={() =>
                          this.onTableUpdate({
                            freeText: '',
                            pagination: { limit: 10, offset: 0 },
                            sorting: { field: 'id', direction: 'ASC' },
                          })
                        }>
                        {!isDataLoading ? (
                          <p className="m-0">Buscar</p>
                        ) : (
                          <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <DataTable
              sort={{ dataField: 'id', order: 'desc' }}
              wrapperClasses="table-responsive"
              remote={{
                filter: true,
                pagination: true,
                sort: true,
                cellEdit: false,
              }}
              totalSize={totalSize}
              columns={columns}
              data={stocklist}
              onTableUpdate={this.onTableUpdate}
              isDataLoading={isDataLoading}
              keyField="id"
              addButton="/stocklist/nuevo"
            />
          </>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(StocklistList));


