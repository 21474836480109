import React from 'react';
import { Route } from 'react-router-dom';
import WarehouseList from './WarehouseList';
import WarehouseEdit from './WarehouseEdit';
import SectionEdit from './SectionEdit';
import LocationEdit from './LocationEdit';

const routes = () => [
 <Route path="/warehouses/nuevo" exact component={WarehouseEdit} key="/warehouses/nuevo" />,
  <Route path="/warehouses/:warehouseId/sections/:sectionId/locations/:id" exact component={LocationEdit} key="/warehouses/sections/locations" />,
  <Route path="/warehouses/:warehouseId/sections/:sectionId/locations" exact component={LocationEdit} key="/warehouses/sections/locations/nuevo" />,
  <Route path="/warehouses/:warehouseId/sections"  exact component={SectionEdit} key="/warehouses/section/nuevo" />,
  <Route path="/warehouses/:warehouseId/sections/:id" exact component={SectionEdit} key="/warehouses/sections" />,
  <Route  exact path="/warehouses/:id" component={WarehouseEdit} key="/warehouses/:id" />,
  <Route path="/warehouses"  component={WarehouseList} key="/warehouses" />,
].map(route => route);

export default routes;
