import React, {FC} from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    id:string;
    value?:string | Array<string>;
    label:string | object;
    choices:Array<Object> | Array<any>;
    disabled?:boolean;
    required?:boolean;
    placeholder?:string;
    defaultValue?:string | Array<string> | undefined;
    choiceIdField:string;
    choiceLabelField: string | Function;
    arrayvalidateindex?:Array<string>;
    multiple?:boolean;
    onChange:(e: (React.ChangeEvent<HTMLInputElement>) | any  )=>void;

}

const FormSelect:FC<Props> = ({
  id,
  label,
  choices,
  required,
  onChange,
  multiple,
  disabled,
  placeholder,
  defaultValue,
  choiceIdField,
  choiceLabelField,
  arrayvalidateindex,
}) => {

  return (
    <Form.Group controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control onChange={onChange} defaultValue={defaultValue} disabled={disabled} name={id} multiple={multiple} as="select" className={ arrayvalidateindex && arrayvalidateindex.indexOf(id) >= 0 ? 'border border-danger' : ''}>
        {!required && !multiple && <option value="">{placeholder}</option>}
        {choices.map(choice => (
          <option value={choice[choiceIdField]} key={choice[choiceIdField]}>
            {typeof choiceLabelField === 'string' ? choice[choiceLabelField] : choiceLabelField(choice)}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
            Please provide a valid zip.
          </Form.Control.Feedback>
    </Form.Group>
  );
};

FormSelect.defaultProps = {
  required: false,
  placeholder: '(Seleccione)',
  choiceIdField: 'id',
  choiceLabelField: 'label',
  arrayvalidateindex: []
};

export default FormSelect;
