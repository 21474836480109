import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { DataTable, Loading } from '../../components';
import { withToastManager } from 'react-toast-notifications';

import APIClient from '../../services/APIClient';

import FormInput from '../../components/componentsTs/FormInput';
import FormSelect from '../../components/componentsTs/FormSelect';
import { useForm } from '../../hooks/useForm';
import {
  LineInterface,
  TypesInterface,
  ClientInterface,
  SellerInterface,
  ArticleInterface,
  DataTableInterface,
  ProvincesInterface,
  SearchTypeInterface,
  InitialFormStateInterface,
  ColumsInterface,
} from './DeliveryReportInterface';
import Utils from '../Utils';
import { connect } from 'react-redux';
import columnsMonths from './columns';

const DeliveryReport = (props: { toastManager: any; history: any; permissionsUser: any }): JSX.Element => {
  const { toastManager, permissionsUser } = props;

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isChartLoading, setIsChartLoading] = useState(false);

  const [articles, setArticles] = useState<ArticleInterface[]>([]);
  const [lines, setLines] = useState<LineInterface[]>([]);
  const [clients, setClients] = useState<ClientInterface[]>([]);
  const [sellers, setSellers] = useState<SellerInterface[]>([]);
  const [provinces, setProvinces] = useState<ProvincesInterface[]>([]);

  const [types, setTypes] = useState<TypesInterface[]>([]);
  const [searchTypes, setSearchType] = useState<SearchTypeInterface[]>([]);
  const [dataTable, setDataTable] = useState<DataTableInterface[]>([]);

  const initialFormState: InitialFormStateInterface = {
    curToDate: '',
    curFromDate: '',
    lineSelected: '',
    clientSelected: '',
    sellerSelected: '',
    articleSelected: '',
    yearSelected: '',
    provinceSelected: '',
    selectedType: 'articleType',
    selectedSearchType: 'disaggregated',
  };

  const { inputsValue, handlerInputChange, resetForm } = useForm(initialFormState);
  const [permissionsView, setPermissionsView] = useState({
    isSuperAdmin: false,
    editPermission: false,
    deletePermission: false,
    isPermissionsLoading: true,
  });

  const {
    curToDate,
    curFromDate,
    lineSelected,
    selectedType,
    sellerSelected,
    yearSelected,
    clientSelected,
    articleSelected,
    provinceSelected,
    selectedSearchType,
  } = inputsValue;

  const typesOptions = [
    { id: 'articleType', description: 'Articulo' },
    { id: 'lineType', description: 'Linea' },
    { id: 'clientType', description: 'Cliente' },
    { id: 'sellerType', description: 'Vendedor' },
    { id: 'provinceType', description: 'Provincia' },
  ];
  const searchTypesOptions = [
    { id: 'disaggregated', description: 'Desagregado' },
    { id: 'consolidated', description: 'Consolidado' },
  ];

  const validateInputs = curFromDate === '' || curToDate === '';

  const loadInitialDate = async () => {
    try {
      const articles = (await APIClient.get('/articulos?filter[eliminadoFlag][eq]=false')).data.data;
      const lines = (await APIClient.get('/lineas?filter[eliminadoFlag][eq]=false')).data.data;
      const clients = (await APIClient.get('/clientes?filter[eliminadoFlag][eq]=false')).data.data;
      const sellers = (await APIClient.get('/vendedores?filter[eliminadoFlag][eq]=false')).data.data;
      const provinces = (await APIClient.get('/provincias')).data.data;

      setLines(lines);
      setSellers(sellers);
      setClients(clients);
      setArticles(articles);
      setTypes(typesOptions);
      setIsDataLoading(false);
      setProvinces(provinces);
      setSearchType(searchTypesOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const getPermissions = () => {
    const { isSuperAdmin, editPermission, deletePermission, isPermissionsLoading } = permissionsUser;
    setPermissionsView({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    });
  };

  useEffect(() => {
    getPermissions();
  }, [permissionsUser]);

  useEffect(() => {
    loadInitialDate();
  }, []);

  useEffect(() => {
    setDataTable([]);
    let inputValueReset;
    switch (selectedType) {
      case 'lineType':
        inputValueReset = {
          ...inputsValue,
          articleSelected: '',
          clientSelected: '',
          sellerSelected: '',
          provinceSelected: '',
        };
        break;
      case 'articleType':
        inputValueReset = {
          ...inputsValue,
          lineSelected: '',
          clientSelected: '',
          sellerSelected: '',
          provinceSelected: '',
        };
        break;
      case 'clientType':
        inputValueReset = {
          ...inputsValue,
          articleSelected: '',
          lineSelected: '',
          sellerSelected: '',
          provinceSelected: '',
        };
        break;
      case 'sellerType':
        inputValueReset = {
          ...inputsValue,
          articleSelected: '',
          lineSelected: '',
          clientSelected: '',
          provinceSelected: '',
        };
        break;
      case 'provinceType':
        inputValueReset = {
          ...inputsValue,
          articleSelected: '',
          lineSelected: '',
          clientSelected: '',
          sellerSelected: '',
        };
        break;
    }
    resetForm(inputValueReset);
  }, [selectedType, selectedSearchType, curToDate]);

  const searchReports = async () => {
    setIsChartLoading(true);
    try {
      const queryParams = `selectedType=${selectedType}&yearSelected=${yearSelected}&searchType=${selectedSearchType}&vendedorId=${sellerSelected}&clienteId=${clientSelected}&articuloId=${articleSelected}&provinciaCodigo=${provinceSelected}&lineaId=${lineSelected}&curFromDate=${curFromDate}&curToDate=${curToDate}`;
      const entregasRes = await (await APIClient.get(`/delivery-report?${queryParams}`)).data.data;
      if (entregasRes.length === 0) {
        toastManager.add(`No hay entregas para la busqueda`, {
          appearance: 'warning',
          autoDismiss: true,
        });
      }
      setDataTable(entregasRes);
      //setArticlesFilter([]);
    } catch (error) {
      console.error(error);
    }
    setIsChartLoading(false);
  };
  let columnsTable = [];

  const generateColumnTypeItem = () => {
    let columnTypeItem: Array<ColumsInterface> = [];

    switch (selectedType) {
      case 'articleType':
        if (selectedSearchType !== 'consolidated' || (articleSelected !== '' && selectedSearchType === 'consolidated')) {
          columnTypeItem = [
            {
              dataField: 'articleDescription',
              text: 'Articulo',
              sort: true,
            },
          ];
        }
        break;

      case 'clientType':
        if (
          (clientSelected !== '' && selectedSearchType === 'consolidated') ||
          (articleSelected !== '' && selectedSearchType === 'consolidated')
        ) {
          columnTypeItem = [
            {
              dataField: 'clientDescription',
              text: 'Cliente',
              sort: true,
            },
          ];
        }
        break;

      case 'lineType':
        if (
          (lineSelected !== '' && selectedSearchType === 'consolidated') ||
          (articleSelected !== '' && selectedSearchType === 'consolidated')
        ) {
          columnTypeItem = [
            {
              dataField: 'lineDescription',
              text: 'Linea',
              sort: true,
            },
          ];
        }

        break;

      case 'sellerType':
        if (
          (sellerSelected !== '' && selectedSearchType === 'consolidated') ||
          (articleSelected !== '' && selectedSearchType === 'consolidated')
        ) {
          columnTypeItem = [
            {
              dataField: 'sellerDescription',
              text: 'Vendedor',
              sort: true,
            },
          ];
        }

        break;
      case 'provinceType':
        if (
          (provinceSelected && selectedSearchType === 'consolidated') ||
          (articleSelected !== '' && selectedSearchType === 'consolidated')
        ) {
          columnTypeItem = [
            {
              dataField: 'provinceDescription',
              text: 'Provincia',
              sort: true,
            },
          ];
        }

        break;
    }
    return columnTypeItem;
  };

  const columnsDisaggregated: Array<ColumsInterface> = [
    {
      dataField: 'deliveryDate',
      text: 'Fecha de Entrega',
      sort: true,
    },
    {
      dataField: 'requestCode',
      text: 'Codigo de Solicitud',
      sort: true,
      align: 'right',
    },
    {
      dataField: 'deliveried',
      text: 'Entregados',
      sort: true,
      align: 'right',
    },
    {
      dataField: 'price',
      text: 'Precio Lista',
      sort: true,
      align: 'right',
    },

    {
      dataField: 'discountPercentage',
      text: 'Descuento Aplicado',
      sort: true,
      align: 'right',
    },
    {
      dataField: 'priceWithDiscount',
      text: 'Precio con Descuento',
      sort: true,
      align: 'right',
    },
  ];

  const columnConsolidate: Array<ColumsInterface> = [
    {
      dataField: 'price',
      text: 'Total Precio Lista',
      sort: true,
      align: 'right',
    },

    {
      dataField: 'discountPercentage',
      text: 'Total Descuento Aplicado',
      sort: true,
      align: 'right',
    },
    {
      dataField: 'priceWithDiscount',
      text: 'Total con Descuento',
      sort: true,
      align: 'right',
    },
  ];

  if (selectedSearchType === 'consolidated') {
    const columnTypeItem = generateColumnTypeItem();
    columnsTable = columnTypeItem.concat(columnConsolidate, columnsMonths);
  } else {
    const columnTypeItem = generateColumnTypeItem();
    columnsTable = columnTypeItem.concat(columnsDisaggregated);
  }

  return (
    <>
      <h1 className="page-title">Reporte por Entregas</h1>
      {isDataLoading === false && permissionsView.isPermissionsLoading === false ? (
        <>
          <Card className="my-3">
            <Card.Header>
              <Button variant="warning" disabled={isChartLoading} onClick={() => resetForm(initialFormState)} className="float-right">
                Limpiar Filtros
              </Button>
              Filtros de búsqueda
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <FormSelect
                    id="selectedSearchType"
                    required
                    label="Tipo de Filtro"
                    disabled={isChartLoading}
                    choices={searchTypes}
                    value={selectedSearchType}
                    choiceIdField="id"
                    choiceLabelField="description"
                    onChange={handlerInputChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormSelect
                    id="selectedType"
                    required
                    label="Tipo de Item"
                    disabled={isChartLoading}
                    choices={types}
                    value={selectedType}
                    choiceIdField="id"
                    choiceLabelField="description"
                    onChange={handlerInputChange}
                  />
                </Col>
                {selectedType === 'articleType' ? (
                  <Col md={6}>
                    <FormSelect
                      id="articleSelected"
                      label="Articulos"
                      disabled={isChartLoading}
                      value={articleSelected}
                      choices={articles}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                      onChange={handlerInputChange}
                    />
                  </Col>
                ) : selectedType === 'lineType' ? (
                  <Col md={6}>
                    <FormSelect
                      id="lineSelected"
                      label="Lineas"
                      disabled={isChartLoading}
                      value={lineSelected}
                      choices={lines}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                      onChange={handlerInputChange}
                    />
                  </Col>
                ) : selectedType === 'provinceType' ? (
                  <Col md={6}>
                    <FormSelect
                      id="provinceSelected"
                      label="Provincias"
                      disabled={isChartLoading}
                      value={provinceSelected}
                      choices={provinces}
                      choiceIdField="codigo"
                      choiceLabelField="descripcion"
                      onChange={handlerInputChange}
                    />
                  </Col>
                ) : selectedType === 'clientType' ? (
                  <Col md={6}>
                    <FormSelect
                      id="clientSelected"
                      label="Clientes"
                      disabled={isChartLoading}
                      value={clientSelected}
                      choices={clients}
                      choiceIdField="id"
                      choiceLabelField="razonSocial"
                      onChange={handlerInputChange}
                    />
                  </Col>
                ) : selectedType === 'sellerType' ? (
                  <Col md={6}>
                    <FormSelect
                      id="sellerSelected"
                      label="Vendedor"
                      disabled={isChartLoading}
                      value={sellerSelected}
                      choices={sellers}
                      choiceIdField="id"
                      choiceLabelField="nombre"
                      onChange={handlerInputChange}
                    />
                  </Col>
                ) : (
                  <></>
                )}
              </Row>

              <hr />
              <Row className="my-3">
                <Col md={4}>
                  <FormSelect
                    id="yearSelected"
                    label="Seleccione año"
                    value={yearSelected}
                    choices={Utils.rangeYear()}
                    choiceIdField="id"
                    choiceLabelField="description"
                    disabled={isChartLoading}
                    onChange={handlerInputChange}
                  />
                </Col>

                <Col md={4}>
                  <FormInput
                    id="curFromDate"
                    type="month"
                    value={curFromDate}
                    disabled={yearSelected === '' || isChartLoading}
                    label="Fecha desde:"
                    min={yearSelected !== '' ? `${yearSelected}-01` : undefined}
                    max={yearSelected !== '' ? `${yearSelected}-12` : undefined}
                    onChange={handlerInputChange}
                  />
                </Col>
                <Col md={4}>
                  <FormInput
                    id="curToDate"
                    type="month"
                    value={curToDate}
                    disabled={curFromDate === '' || isChartLoading}
                    label="Fecha hasta:"
                    min={curFromDate !== '' ? curFromDate : undefined}
                    max={yearSelected !== '' ? `${yearSelected}-12` : undefined}
                    onChange={handlerInputChange}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button disabled={validateInputs} onClick={searchReports} className="float-right">
                {isChartLoading ? <span>Cargando...</span> : <span>Filtrar</span>}
              </Button>
            </Card.Footer>
          </Card>
          {selectedType === 'articleType' ? (
            <span className="my-4 d-flex justify-content-center font-weight-bold">
              {articleSelected === ''
                ? 'Todos Los Articulos'
                : `Articulo : ${articles.find((art) => art.id === Number(articleSelected))?.erpCodigo}`}
            </span>
          ) : selectedType === 'lineType' ? (
            <span className="my-4 d-flex justify-content-center font-weight-bold">
              {lineSelected === '' ? 'Todas las lineas' : `Linea : ${lines.find((line) => line.id === Number(lineSelected))?.descripcion}`}
            </span>
          ) : selectedType === 'provinceType' ? (
            <span className="my-4 d-flex justify-content-center font-weight-bold">
              {provinceSelected === ''
                ? 'Todas las Provincias'
                : `Provincia : ${provinces.find((province) => province.codigo === provinceSelected)?.descripcion}`}
            </span>
          ) : selectedType === 'sellerType' ? (
            <span className="my-4 d-flex justify-content-center font-weight-bold">
              {sellerSelected === ''
                ? 'Todos los Vendedores'
                : `Vendedor : ${sellers.find((seller) => seller.id === Number(sellerSelected))?.nombre}`}
            </span>
          ) : selectedType === 'clientType' ? (
            <span className="my-4 d-flex justify-content-center font-weight-bold">
              {clientSelected === ''
                ? 'Todos los Clientes'
                : `Cliente : ${clients.find((client) => client.id === Number(clientSelected))?.razonSocial}`}
            </span>
          ) : (
            <></>
          )}

          <DataTable
            wrapperClasses="table-responsive"
            defaultSorted={[{ dataField: 'deliveryDate', order: 'desc' }]}
            isDataLoading={isChartLoading}
            columns={columnsTable}
            data={dataTable}
            keyField="deliveryId"
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

const mapStateToProps = (permissionsUser: any) => permissionsUser;

export default connect(mapStateToProps)(withToastManager(DeliveryReport));
