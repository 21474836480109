import { SET_INGRESO, DELETE_INGRESO, CLEAR_STORE } from '../actions';
const ingresoStore = (state = {}, action) => {
  switch (action.type) {
    case SET_INGRESO:
      return { ...state, ingresoStore: action.value };
      case DELETE_INGRESO:
        return {
          ...state,
          ingresoStore: state.ingresoStore.filter((item,i) => item.idForDeleteInUI !== action.value)
        };
        case CLEAR_STORE:
          return {
            ...state,
            ingresoStore: []
          }
    default:
      return state;
  }
};

export default ingresoStore;