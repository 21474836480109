import React from 'react';
import PropTypes from 'prop-types';
import {Col,Row, } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityEditForm, FormCheckField, FormInputField, } from '../../components';
import { faCheck, } from '@fortawesome/free-solid-svg-icons';
import APIClient from '../../services/APIClient';

class SectionEdit extends React.Component {

 static propTypes = {
  id: PropTypes.number,
  warehouseId: PropTypes.number.isRequired,
  toggleComponent: PropTypes.func,
  actualizeSections: PropTypes.func,
 };

 constructor(props) {
  super(props);

  // const { id, warehouseId } = props.match.params;
  const { id, warehouseId, toggleComponent, actualizeSections } = props;

  this.state = {
   entity: {},
   id,
   isAdding: id === 0,
   warehouseId,
   warehouseDescription: '',
   sectionDescription: '',
   section: {},
   toggleComponent,
   actualizeSections,
   checkExistenceParam: 'section_id',
   sectionWithItemInStock: [],
  };
 }

 async componentDidMount() {
  const { warehouseId, id, checkExistenceParam } = this.state;
  const warehouseRes = await APIClient.get(`/warehouses/${warehouseId}`);
  let sectionDescription = '';
  let sectionWithItemInStock = []
  if (id) {
   const sectionDescriptionRes = await APIClient.get(`/sections/${id}`);
   sectionDescription = sectionDescriptionRes.data.data.description;
   const sectionWithItemInStockRes = await APIClient.get(`/monitor-inventario/check-existence/${checkExistenceParam}/${id}`);
   sectionWithItemInStock = sectionWithItemInStockRes
  }

  this.setState({
   warehouseDescription: warehouseRes.data.data.description,
   sectionDescription,
   sectionWithItemInStock,
  });
 }

 onRetrieveEntity = async () => {
  const { id } = this.state;

  // get section
  const sectionRes = await APIClient.get(`/sections/${id}`);
  const section = sectionRes.data.data;

  this.setState({
   entity: section,
  });

  return section;
 };

 /**
  * Save the item
  */
 onSaveEntity = async (entityToSave) => {
  const { id, isAdding, warehouseId, toggleComponent, actualizeSections } = this.state;
  let saveResponse = null;
  entityToSave.warehouseId = warehouseId;
  if (isAdding) {
   saveResponse = await APIClient.post('/sections', entityToSave);
  } else {
   saveResponse = await APIClient.patch(`/sections/${id}`, entityToSave);
  }
  actualizeSections(saveResponse.data.data.id);
  toggleComponent();
  // toastManager.add(`Sector "${saveResponse.data.data.description}" guardado con éxito`, {
  //  appearance: 'success',
  //  autoDismiss: true,
  // });
 };



 render() {
  const {
   isAdding,
   entity,
   warehouseDescription,
   sectionDescription,
   toggleComponent,
   sectionWithItemInStock,
  } = this.state;

  const ableDisable = sectionWithItemInStock.length > 0;
  return (
   <div>
    <h1 className="page-title">{isAdding ? `${warehouseDescription} / agregar sector` : `${warehouseDescription} / ${sectionDescription}`}</h1>

    <EntityEditForm
     //   onLoadForm={this.onLoadForm}
     onRetrieveEntity={this.onRetrieveEntity}
     onSaveEntity={this.onSaveEntity}
     addMode={isAdding}
     buttons={[
      {
       text: 'Guardar',
       type: 'submit',
       variant: 'primary',
       classes: 'mr-1',
       icon: <FontAwesomeIcon icon={faCheck} fixedWidth className="mr-1" />,
      },
      {
       text: 'Cancelar',
       type: 'button',
       variant: 'secondary',
       classes: 'mr-1',
       click: toggleComponent,
      },
     ]}
    //    prepareToSave={this.prepareToSave}
    >
     <>
      <Row>
       <Col md={6}>
        <FormInputField id="description" label="Descripción" type="text" defaultValue={entity.description} required />
       </Col>
      </Row>
      <Row>
       <Col md={2}>
        <FormCheckField id="disabled" label="Deshabilitado" defaultChecked={entity.disabled} disabled={ableDisable}/>
       </Col>
      </Row>
      <Row>
      {ableDisable ? <span className="bg-warning">Este sector tiene posiciones con cantidades comprometidas en el stock </span> : ''}
      </Row>
     </>
    </EntityEditForm>
   </div>
  );
 }
}

export default withToastManager(SectionEdit);
