import APIClient from '../services/APIClient';

const modosCalculo = [
  { id: 'A', nombre: 'Acumulable sobre último' },
  { id: 'B', nombre: 'Aplica a valor base' },
];

const modosCalculoMap = {};
modosCalculo.forEach((modoCalculo) => {
  modosCalculoMap[modoCalculo.id] = modoCalculo.nombre;
});

const validationToUpdatePrice = async (solicitudId) => {
  const solicitud = await APIClient.get(`/solicitudes/${solicitudId}`);
  return solicitud.data.data.toUpdatePrice && true;
};

const priceUpdatedRequest = async (requestIds) => {

  const promisesRequest = requestIds.map((reqId) => APIClient.patch(`/solicitudes/recalculate-price/${reqId}`))
  const updatedRequests = await Promise.all(promisesRequest);
  return updatedRequests;
};

export default {
  modosCalculo,
  modosCalculoMap,
  validationToUpdatePrice,
  priceUpdatedRequest,
};
