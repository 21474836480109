import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withToastManager } from 'react-toast-notifications';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { DataTable, Loading } from '../../components';

import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import { connect } from 'react-redux'

class UnidadMedidaList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      unidades: [],
      clases: [],
      isDataLoading: true,
    };

  

    this.loadUnits = this.loadUnits.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }
  

  componentDidMount() {
    this.loadUnits();
  }

  async loadUnits() {
    const { toastManager } = this.props;
    try {
      const unidadesRes = await APIClient.get('/unidades-medida');
      const clasesRes = await APIClient.get('/clases-unidades-medida/');
      const clases = clasesRes.data.data;
      this.setState({
        unidades: unidadesRes.data.data,
        clases,
        isDataLoading: false,
      });
      this.apiParams = 'sortField=id&sortDir=asc';
    } catch (err) {
      console.error('Error cargando las unidades de medida.', err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  }

  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    try {
      const { direction } = sorting;
      let { field } = sorting;
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || ''}&sortDir=${direction || ''}`;
      const unidadesRes = await APIClient.get(`/unidad-medida?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}`);
      this.setState({
        unidades: unidadesRes.data.data,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  getExportData = async () => {
    const { toastManager } = this.props;
    try {
      const apiResponse = await APIClient.get(`/unidad-medida?${this.apiParams}`);
      return apiResponse.data.data;
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
      return false;
    }
  };

  render() {
    const { unidades, isDataLoading, clases, isSuperAdmin, editPermission, isPermissionsLoading } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'id',
        hidden: true,
      },
      {
        dataField: 'udm',
        text: 'UDM',
        sort: true,
      },
      {
        dataField: 'description',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'classId',
        text: 'Clase',
        sort: true,
        formatter: (cellContent, row) => {
          const classIndex = clases.findIndex((item) => item.id === cellContent);
          return classIndex !== -1 ? clases[classIndex].description : 'No tiene una clase asignada';
        },
      },
      {
        dataField: 'active',
        text: 'Estado',
        sort: true,
        formatter: (cellContent, row) => UIUtils.getClienteEstadoBadge(cellContent ? 'Activo' : 'Inactivo'),
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <>
                  <LinkContainer to={`/unidades-medida/${row.id}`}>
                    <Button size="sm" variant="outline-primary" title="Editar">
                      <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                    </Button>
                  </LinkContainer>
                </>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Unidades de Medida</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            columns={columns}
            data={unidades}
            isDataLoading={isDataLoading}
            keyField="id"
            addButton={isSuperAdmin || editPermission ? '/unidades-medida/new' : null}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(UnidadMedidaList));

