import React from 'react';
import { Route } from 'react-router-dom';
import UsuarioList from './UsuarioList';
import UsuarioEdit from './UsuarioEdit';

const routes = () => [
  <Route path="/usuarios/nuevo" exact component={UsuarioEdit} key="/usuarios/nuevo" />,
  <Route path="/usuarios/:id" component={UsuarioEdit} key="/usuarios/:id" />,
  <Route path="/usuarios" exact component={UsuarioList} key="/usuarios/:id" />,

].map(route => route);

export default routes;
