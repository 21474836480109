/* listOutOfStockDetailsModal */
module.exports.columnsOutOfStock = [
  {
    dataField: 'insumoChild.erpCode',
    text: 'Código ERP',
    sort: true,
    editable: false,
  },

  {
    dataField: 'unitsInChild',
    text: 'Stock requerido',
    headerStyle: { backgroundColor: '#c12f2f', color: '#f8f9fa' },
    style: { textAlign: 'right' },
    sort: true,
    editable: false,
  },
  {
    dataField: 'resta',
    text: 'Stock Disponible',
    style: { textAlign: 'right' },
    headerStyle: { backgroundColor: '#28a745' },
    sort: true,
    editable: false,
  },
  {
    dataField: 'warehouse',
    text: 'Depósito asignado',
    sort: true,
    editable: false,
  },
];

/* listConsolidatedModal */
module.exports.columnsConsolidated = [
  {
    dataField: 'insumoId',
    text: 'ID',
    sort: true,
    editable: false,
  },
  {
    dataField: 'erpCode',
    text: 'Codigo ERP',
    editable: false,
  },
  {
    dataField: 'insumoDescription',
    text: 'Descripcion',
    editable: false,
  },
  {
    dataField: 'units',
    style: { textAlign: 'right' },
    text: 'Cantidad',
    editable: false,
  },
  {
    dataField: 'warehouseDescription',
    style: { textAlign: 'right' },
    text: 'Depósito',
    editable: false,
  },
  {
    dataField: 'sectioDescripction',
    style: { textAlign: 'right' },
    text: 'Sector',
    editable: false,
  },
  {
    dataField: 'locationDescripction',
    style: { textAlign: 'right' },
    text: 'Ubicacion',
    editable: false,
  },
];
/* Selectd Parameters */
module.exports.columnsParameters = [
  {
    dataField: 'erpCode',
    text: 'codigoERP',
    sort: true,
    editable: false,
  },
  {
    dataField: 'warehouse.description',
    style: { textAlign: 'right' },
    text: 'Depósito',
    editable: false,
  },
  {
    dataField: 'section.description',
    style: { textAlign: 'right' },
    text: 'Sector',
    editable: false,
  },
  {
    dataField: 'location.description',
    style: { textAlign: 'right' },
    text: 'Ubicacion',
    editable: false,
  },
];
/* listItemsModal */

module.exports.columnsItems = [
  {
    dataField: 'id',
    text: 'ID',
    sort: true,
    editable: false,
  },

  {
    dataField: 'erpCode',
    text: 'codigoERP',
    sort: true,
    editable: false,
  },
  {
    dataField: 'description',
    text: 'Descripcion',
    sort: true,
    editable: false,
  },
  {
    dataField: 'categoryDescription',
    text: 'Categoria',
    style: { textAlign: 'center' },
    sort: true,
    editable: false,
  },
  {
    dataField: 'lineaDescription',
    style: { textAlign: 'center' },
    text: 'Linea',
    sort: true,
    editable: false,
  },
];


