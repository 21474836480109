import React from 'react';
import PropTypes from 'prop-types';
import {  Col, Row, } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormCheckField, FormInputField, } from '../../components';
import APIClient from '../../services/APIClient';

class LocationEdit extends React.Component {
 
 static propTypes = {
  warehouseId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
 };

 constructor(props) {
  super(props);

  const { id, warehouseId, sectionId, closeModal, actualizeSections } = props;

  this.state = {
   entity: {},
   id,
   closeModal,
   isAdding: id === 0,
   warehouseId,
   sectionId,
   warehouseDescription: '',
   sectionDescription: '',
   locationDescription: '',
   actualizeSections,
   checkExistenceParam: 'location_id',
   locationWithItemInStock: [],
  };
 }

 async componentDidMount() {
  const { warehouseId, sectionId, id, checkExistenceParam,  } = this.state;
  const warehouseRes = await APIClient.get(`/warehouses/${warehouseId}`);
  const sectionRes = await APIClient.get(`/sections/${sectionId}`);
  let locationDescription = '';
  let locationWithItemInStock = [];


  if (id) {
   const locationDescriptionRes = await APIClient.get(`/locations/${id}`);
   locationDescription = locationDescriptionRes.data.data.description;
   const locationWithItemInStockRes = await APIClient.get(`/monitor-inventario/check-existence/${checkExistenceParam}/${id}`);
   locationWithItemInStock = locationWithItemInStockRes.data.data;
  }

  this.setState({
   warehouseDescription: warehouseRes.data.data.description,
   sectionDescription: sectionRes.data.data.description,
   locationDescription,
   locationWithItemInStock,
  });
 }
 onRetrieveEntity = async () => {
  const { id } = this.state;

  // get location
  const locationRes = await APIClient.get(`/locations/${id}`);
  const location = locationRes.data.data;

  this.setState({
   entity: location,
  });

  return location;
 };

 /**
  * Save the item
  */
 onSaveEntity = async (entityToSave) => {
  const { id, isAdding, sectionId, closeModal, actualizeSections } = this.state;
  let saveResponse = null;
  entityToSave.sectionId = sectionId;

  if (isAdding) {
   saveResponse = await APIClient.post('/locations', entityToSave);
  } else {
   saveResponse = await APIClient.patch(`/locations/${id}`, entityToSave);
  }
  // history.push('/warehouses');
  await actualizeSections(sectionId);
  closeModal();
  // toastManager.add(`Posición "${saveResponse.data.data.description}" guardada con éxito`, {
  //  appearance: 'success',
  //  autoDismiss: true,
  // });
 };



 render() {
  const {
   isAdding,
   entity,
   warehouseDescription,
   sectionDescription,
   locationDescription,
   locationWithItemInStock
  } = this.state;

  const ableDisable = locationWithItemInStock.length > 0;

  return (
   <div>
    <h1 className="page-title">{isAdding ? `${warehouseDescription} / ${sectionDescription} / agregar posición` : `${warehouseDescription} / ${sectionDescription} / ${locationDescription}`}</h1>

    <EntityEditForm
     //   onLoadForm={this.onLoadForm}
     onRetrieveEntity={this.onRetrieveEntity}
     onSaveEntity={this.onSaveEntity}
     addMode={isAdding}
    //    prepareToSave={this.prepareToSave}
    >
     <>
      <Row>
       <Col md={6}>
        <FormInputField id="description" label="Descripción" type="text" defaultValue={entity.description} required />
       </Col>
      </Row>
      <Row>
       <Col md={2}>
        <FormCheckField id="disabled" label="Deshabilitado" defaultChecked={entity.disabled} disabled={ableDisable}/>
       </Col>
      </Row>
      <Row>
      {ableDisable ? <span className="bg-warning">Esta posición tiene cantidades comprometidas en el stock </span> : ''}
      </Row>
     </>
    </EntityEditForm>
   </div>
  );
 }
}

export default withToastManager(LocationEdit);
