import { faCheck, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, EntityEditForm, FormInputField, FormSelectField } from '../../components';
import { columnsOutOfStock, columnsNotExistStock } from './columnsStaticData';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import Utils from '../Utils';

class EntregaEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { id, clienteId } = props.match.params;
    const query = Utils.sanitizeQuery(['solicitudId'], props.location.search);
    const solicitudIdURL = query.solicitudId;
    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      clienteId,
      solicitudIdURL,
      cliente: {},
      disableButtons: false,
      entity: {},
      entregaDetalles: [],
      expresos: [],
      fechasEntrega: [],
      isDataLoading: false,
      lineas: [],
      marcas: [],
      articles: [],
      stockAvailable: true,
      articlesNotAvailable: [],
      itemsNotStock: [],
      itemsStockAvailable: [],
      showOutOfStockDetailsModal: false,
      puntosEntrega: [],
      columns: {
        columnsOutOfStock,
        columnsNotExistStock,
      },
      showConfirmRecalculateModal: false,

    };
  }
  /* validatorStock */

  validateStock = async (entregaDetalle) => {
    const queryParams = `articleId=${entregaDetalle.articuloId}&lineId=${entregaDetalle.lineaId}&units=${entregaDetalle.cantidad}`;
    const validateStockRes = await APIClient.get(`/entregas/validate-stock?${queryParams}`);
    return validateStockRes.data.data;
  };

  componentDidMount() {
    const { isAdding } = this.state;
    const { history } = this.props;
    if (history.action === 'POP' && isAdding) {
      history.push('/entregas');
      return false;
    }
    return true;
  }

  afterEditingCell = (oldValue, newValue, detalle) => {
    const { articles } = this.state;
    const { toastManager } = this.props;

    this.setState(async (prevState) => {
      const { entregaDetalles } = prevState;
      const editedDetalle = entregaDetalles.find((det) => detalle.articuloId === det.articuloId);
      const consumerStock = articles.find((elem) => elem.id === detalle.articuloId).byStock === true;
      if (consumerStock) {
        const validatorStock = await this.validateStock(detalle);
        if (!validatorStock.hasAssignedParameter) {
          toastManager.add(`Completar Parametros de entrega Linea: ${validatorStock.lineDescription}`, {
            appearance: 'warning',
            autoDismiss: true,
          });
          editedDetalle.isEdited = false;
          editedDetalle.cantidad = 0;
        } else if (!validatorStock.itemsStockNotAvailble) {
          toastManager.add(
            `Sin Stock Deposito:(${validatorStock.warehouseDescription}) Sector:(${validatorStock.sectionDescription}) Restan:(${validatorStock.unitsAvailableStock}) `,
            {
              appearance: 'warning',
              autoDismiss: true,
            },
          );
          editedDetalle.isEdited = true;
          editedDetalle.cantidad = validatorStock.unitsAvailableStock;
        } else {
          editedDetalle.isEdited = true;
          editedDetalle.cantidad = parseInt(newValue, 10);
          editedDetalle.warehouseId = validatorStock.warehouseId;
          editedDetalle.sectionId = validatorStock.sectionId;
        }
      } else {
        editedDetalle.isEdited = true;
        editedDetalle.cantidad = parseInt(newValue, 10);
      }
      return {
        ...prevState,
        disableButtons: !entregaDetalles.every((entDet) => entDet.cantidad <= entDet.pendingDelivery),
        entregaDetalles: [...entregaDetalles],
      };
    });
  };

  /**
   * Retrieve and return expresoId from puntosEntrega array
   * @param  {object} cliente data from cliente
   * @param  {object[]} puntosEntrega all puntos de entrega
   * @return expresoId or null
   */
  getDefaultExpresoId = (cliente) => {
    const { puntosEntrega } = this.state;
    const puntoEntrega = puntosEntrega.find((pe) => cliente.puntoEntregaId === pe.id);
    if (puntoEntrega !== undefined) {
      return puntoEntrega.expresoId;
    }
    return null;
  };

  /**
   * Calculate and return correct fechaEntrega date
   * @param  {object} now now moment object
   * @param  {string} formattedNow current date formatted as YYYY-MM-DDTHH:mm
   * @return fechaEntrega string
   */
  getDefaultFecha = () => {
    const now = moment();
    const formattedNow = now.format('YYYY-MM-DDTHH:mm');
    const { isAdding, entity, fechasEntrega } = this.state;
    if (!isAdding) {
      if (entity) {
        return moment(entity.fechaEntrega).format('YYYY-MM-DDTHH:mm');
      }
      return formattedNow;
    }
    for (let i = 0; i < fechasEntrega.length; i++) {
      const curFecha = moment.utc(fechasEntrega[i], 'YYYY-MM-DD HH:mm');
      if (curFecha > now) {
        return curFecha.format('YYYY-MM-DDTHH:mm');
      }
    }
    return formattedNow;
  };

  /**
   * Retrieve and return correct puntoEntregaId
   */
  getDefaultPuntoEntrega = () => {
    const { isAdding, cliente, entity, entregaDetalles } = this.state;
    if (isAdding) {
      return cliente.puntoEntregaId;
    }
    if (entregaDetalles && entity) {
      return entity.puntoEntregaId;
    }
    return null;
  };

  /**
   * Generate columns for new entrega or existing entrega
   */
  generateColumns = () => {
    const { isAdding, lineas, marcas, entity } = this.state;
    const { estadoEntrega } = entity;
    if (!isAdding && estadoEntrega === 'EC') {
      return [
        {
          dataField: 'articuloId',
          hidden: true,
        },
        {
          dataField: 'articulo.erpCodigo',
          text: 'Codigo ERP',
          editable: false,
          sort: true,
        },
        {
          dataField: 'articulo.descripcion',
          text: 'Descripcion',
          editable: false,
          sort: true,
        },
        {
          dataField: 'articulo.linea',
          text: 'Línea',
          editable: false,
          sort: true,
          formatter: (cell, row, rowIndex, extraData) => {
            const matchingLinea = extraData.find((linea) => linea.id === row.articulo.lineaId);
            return matchingLinea.descripcion;
          },
          formatExtraData: lineas,
        },

        {
          dataField: 'articulo.marcaCodigo',
          text: 'Marca',
          editable: false,
          sort: true,
          formatter: (cell, row, rowIndex, extraData) => {
            const marca = extraData.find((data) => data.codigo === row.articulo.marcaCodigo);
            return marca.descripcion;
          },
          formatExtraData: marcas,
        },
        {
          dataField: 'articulo.factorBulto2',
          text: 'Metros Cúbicos',
          editable: false,
          sort: true,
        },
        {
          dataField: 'cantidad',
          text: 'Entregado',
          editable: false,
          sort: true,
        },
        {
          dataField: '',
          text: 'Consume Stock',
          editable: false,
          align: 'center',
          sort: true,
          formatter: (cell, row, rowIndex) => {
            const { articles } = this.state;
            const articleConsumer = articles.find((article) => article.id === row.articuloId);
            if (articleConsumer !== undefined) {
              const isConsumerStock = articleConsumer.byStock === true;
              if (isConsumerStock === true) {
                return UIUtils.getConsumeByStockBadge(isConsumerStock);
              }
              return UIUtils.getConsumeByStockBadge(isConsumerStock);
            }
          },
        },
      ];
    }
    return [
      {
        dataField: 'articuloId',
        hidden: true,
      },
      {
        dataField: 'erpCodigo',
        text: 'Código ERP',
        editable: false,
        sort: true,
      },
      {
        dataField: 'descripcion',
        text: 'Descripcion',
        editable: false,
        sort: true,
      },
      {
        dataField: 'articulo.linea',
        text: 'Línea',
        editable: false,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => {
          const linea = extraData.find((data) => data.id === row.lineaId);
          return linea.descripcion;
        },
        formatExtraData: lineas,
      },
      {
        dataField: 'm3',
        isDummyField: true,
        text: 'Metros Cúbicos',
        editable: false,
        sort: true,
        classes: 'text-right',
        formatter: (cell, row) => (cell = row.factorBulto2 * row.pendingDelivery),
      },
      {
        dataField: 'pendingDelivery',
        text: 'Pendiente Entrega',
        classes: 'text-right',
        editable: false,
        sort: true,
      },

      {
        dataField: 'cantidad',
        text: 'Cantidad A Entregar',
        classes: 'text-right',
        sort: false,
        validator: (newValue, row) => {
          if (parseInt(newValue, 10) > row.pendingDelivery) {
            return {
              valid: false,
              message: 'La cantidad a entregar no puede superar la cantidad pendiente de entrega.',
            };
          }

          if (isNaN(parseInt(newValue, 10))) {
            return {
              valid: false,
              message: 'Debe ingresar un número.',
            };
          }

          return true;
        },
      },
      {
        dataField: '',
        text: 'Consume Stock',
        editable: false,
        align: 'center',
        sort: true,
        formatter: (cell, row, rowIndex) => {
          const { articles } = this.state;
          const articleConsumer = articles.find((article) => article.id === row.articuloId);
          if (articleConsumer !== undefined) {
            const isConsumerStock = articleConsumer.byStock === true;
            if (isConsumerStock === true) {
              return UIUtils.getConsumeByStockBadge(isConsumerStock);
            }
            return UIUtils.getConsumeByStockBadge(isConsumerStock);
          }
        },
      },
    ];
  };

  initializeEntity = async () => {
    const { clienteId, solicitudIdURL } = this.state;
    let pendientesDetalle;
    // By pedido/solicitud
    if (solicitudIdURL) {
      pendientesDetalle = await APIClient.get(`/monitor-pendientes/${clienteId}?solId=${solicitudIdURL}`);
    } else {
      // Get detalle rows.
      pendientesDetalle = await APIClient.get(`/monitor-pendientes/${clienteId}`);
    }
    let cliente = await APIClient.get(`/clientes/${clienteId}`);
    cliente = cliente.data.data;
    // Get only solicitudesId.
    const solicitudesId = pendientesDetalle.data.data
      .map((pendiente) => pendiente.solicitudes)
      .filter((value, index, self) => self.indexOf(value) === index);
    // Fetch those solicitudes.
    const solicitudes = await APIClient.get(`/solicitudes?filter[id][in]=${solicitudesId}&limit=&offset=&includeCantidad=true&freeText=&sortField=fechaConfirmacion&sortDir=DESC&precioEntrega=&clienteId=&codigo=&curFromDate=&curToDate=`);

    const fechasEntrega = solicitudes.data.data.map((sol) => moment.utc(sol.fechaEntrega).format('YYYY-MM-DD HH:mm'));
    const entregaDetalles = [];
    // Add custom fields
    pendientesDetalle.data.data.forEach((data) => {
      if (data.solicitado > data.entregado) {
        data.cantidad = 0;
        data.pendingDelivery = data.solicitado - data.entregado;
        data.isSelected = false;
        data.isEdited = false;
        entregaDetalles.push(data);
      }
    });
    const entity = {
      clienteId: cliente.id,
      expresoId: this.getDefaultExpresoId(cliente),
      fechaEntrega: this.getDefaultFecha(),
      solicitudId: solicitudIdURL,
    };
    entity.fecha = moment().format('YYYY-MM-DDTHH:mm:ss');
    const entregas = [];
    if (entregaDetalles.length > 0) {
      entity.puntoEntregaId = entregaDetalles[0].puntosEntrega ? entregaDetalles[0].puntosEntrega.split(',')[0] : null;
    }
    if (entregaDetalles && entregaDetalles.length > 0) {
      entregaDetalles.forEach((detalle) => {
        entregas.push({
          articuloId: detalle.articuloId,
          cantidad: detalle.cantidad,
          isEdited: false,
        });
      });
    }

    entity.detalles = entregas;
    this.setState({
      entregaDetalles,
      cliente,
      fechasEntrega,
      entity,
      showConfirmRecalculateModal:solicitudes.data.data.some(elem=>elem.toUpdatePrice === true),
    });
    return entity;
  };
  /**
   * Get entrega, expresos and puntosEntrega data and save to state
   */
  onLoadForm = async () => {
    const { isAdding } = this.state;
    try {
      if (isAdding) {
        const { clienteId } = this.state;
        const puntosEntregaRes = await APIClient.get(`/clientes/${clienteId}/puntos-entrega`);
        const puntosDeEntrega = puntosEntregaRes.data.data.map((pe) => {
          pe.descripcion = `${pe.descripcion} - ${pe.calle} ${pe.numero === '00' ? '' : ' ' + pe.numero} ${!pe.piso ? '' : ' ' + pe.piso} ${
            !pe.depto ? '' : ' ' + pe.depto
          }
          ${pe.localidad} ${!pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal}
         ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`;
          return pe;
        });
        this.setState({ puntosEntrega: puntosDeEntrega });
      }
      const expresoResponse = await APIClient.get('/expresos');
      const articlesResponse = await APIClient.get('/articulos');
      const lineasResponse = await APIClient.get('/lineas');
      const marcasResponse = await APIClient.get('/marcas');

      this.setState({
        expresos: expresoResponse.data.data,
        lineas: lineasResponse.data.data,
        marcas: marcasResponse.data.data,
        articles: articlesResponse.data.data,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        isDataLoading: false,
      });
    }
  };

  onRetrieveEntity = async () => {
    const { id, solicitudIdURL } = this.state;
    // Get entrega.
    const entityResponse = await APIClient.get(`/entregas/${id}?populate=entregaDetalle.articulo.linea`);
    const entity = entityResponse.data.data;

    const { clienteId, solicitudId: solicitudIdEntity } = entityResponse.data.data;
    const cliente = await APIClient.get(`/clientes/${clienteId}`);
    const puntosEntregaRes = await APIClient.get(`/clientes/${clienteId}/puntos-entrega`);
    const puntosDeEntrega = puntosEntregaRes.data.data.map((pe) => {
      pe.descripcion = `${pe.descripcion} - ${pe.calle} ${pe.numero === '00' ? '' : ' ' + pe.numero} ${!pe.piso ? '' : ' ' + pe.piso} ${
        !pe.depto ? '' : ' ' + pe.depto
      }
      ${pe.localidad} ${!pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal}
     ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`;
      return pe;
    });
    let entregaDetalles = [];
    let originalSolicitudCode;
    if (entity.estadoEntrega === 'EA') {
      // If entrega is open, then get & add monitor pendientes data.
      //const pendientesDetalle = await APIClient.get(`/monitor-pendientes/${entity.clienteId}`);
      let pendientesDetalle;
      let originalSolicitudResponse;
      // By pedido/solicitud
      if (solicitudIdEntity) {
        pendientesDetalle = await APIClient.get(`/monitor-pendientes/${clienteId}?solId=${solicitudIdEntity}`);
        // get solicitud code
        originalSolicitudResponse = await APIClient.get(`/solicitudes/${solicitudIdEntity}`);
        originalSolicitudCode = originalSolicitudResponse ? originalSolicitudResponse.data.data.codigo : null;
      } else {
        // Get detalle rows.
        pendientesDetalle = await APIClient.get(`/monitor-pendientes/${clienteId}`);
      }
      const pendientes = pendientesDetalle.data.data.filter((det) => det.solicitado > det.entregado);
      pendientes.forEach((data) => {
        const matchingDetalle = entity.detalles.find((det) => det.articuloId === data.articuloId);
        if (matchingDetalle) {
          data.cantidad = matchingDetalle.cantidad;
        } else {
          data.cantidad = 0;
        }
        data.linea = data.lineaDescripcion ? data.lineaDescripcion : '';
        data.pendingDelivery = data.solicitado - data.entregado;
        data.isSelected = false;
        entregaDetalles.push(data);
      });

      //check if was created from a solicitud
      if (solicitudIdEntity && solicitudIdEntity !== parseInt(solicitudIdURL, 10)) {
        //toast
        const { toastManager } = this.props;
        const toastMessage = `ATENCION! Borrador de entrega generado a partir del pedido ${originalSolicitudCode}`;
        // const redirectUrl = `/entregas/${entity.id}/${entity.solicitudId}`;
        toastManager.add(toastMessage, {
          appearance: 'warning',
          autoDismiss: false,
        });
      }
    } else {
      entity.detalles.forEach((det) => {
        det.linea = det.articulo.linea ? det.articulo.linea.descripcion : '';
        det.erpCodigo = det.articulo.erpCodigo ? det.articulo.erpCodigo : '';
        const existingDetalle = entregaDetalles.find((entDet) => det.articuloId === entDet.articuloId);
        if (existingDetalle) {
          existingDetalle.cantidad += det.cantidad;
        } else {
          entregaDetalles.push(det);
        }
      });
    }

    const { detalles } = entityResponse.data.data;
    detalles.forEach((det) => {
      det.isEdited = false;
    });
    // sort entregaDetalles
    entregaDetalles = entregaDetalles
      .sort((a, b) => {
        return Utils.pickNumForString(a.erpCodigo) - Utils.pickNumForString(b.erpCodigo);
      })
      .sort((a, b) => {
        return a.linea.localeCompare(b.linea);
      });
    const showConfirmRecalculateModal = await this.verifyUpdatePrice(entregaDetalles)

    this.setState({
      isDataLoading: false,
      cliente: cliente.data.data,
      clienteId: cliente.data.data.id,
      disableButtons: !entregaDetalles.every((entDet) => entDet.cantidad <= entDet.pendingDelivery),
      entity,
      entregaDetalles,
      puntosEntrega: puntosDeEntrega,
      originalSolicitudCode,
      showConfirmRecalculateModal
    });
    
  };

  async verifyUpdatePrice(entregaDetalles){

    const solicitudesId = entregaDetalles.map(entregaDetalle=>{
      let response;
      
      if(entregaDetalle?.solicitudes?.length){
        response = [... entregaDetalle.solicitudes.split(',')]
      }else {
        response = entregaDetalle.solicitudDetalleTalleId
      }
      return response
    })
    const solicitudesIdFilter = [...new Set(solicitudesId.flat())];

    const solicitudes = await APIClient.get(`/solicitudes?filter[id][in]=${solicitudesIdFilter}&limit=&offset=&includeCantidad=true&freeText=&sortField=fechaConfirmacion&sortDir=DESC&precioEntrega=&clienteId=&codigo=&curFromDate=&curToDate=`);
    return solicitudes.data.data.some(solicitud=> solicitud.toUpdatePrice === true)
  }

  renderAlertRecalculateModal = () => {
  const { showConfirmRecalculateModal } = this.state;
  return (
    <Modal size="md" show={showConfirmRecalculateModal} onHide={() => this.setState({ showConfirmRecalculateModal: false })}>
      <Modal.Header closeButton>
        <Modal.Title>Actualizacion de precios pendiente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>La lista de precios de algún pedido fue actualizada, tienes actualizaciones de precios pendientes.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => this.setState({ showConfirmRecalculateModal: false })}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

  verifyNewItemByConsumer = () => {
    const { entregaDetalles, articles } = this.state;
    const filterItem = entregaDetalles.filter((item) => item.cantidad > 0);
    const editedDetalle = [];
    filterItem.forEach((det) => {
      const isItemByConsumer = articles.find((art) => art.id === det.articuloId && art.byStock === true && det?.isEdited === true);
      if (isItemByConsumer !== undefined) {
        editedDetalle.push(det);
      }
    });
    return editedDetalle;
  };

  /**
   * Save entity.
   */
  onSaveEntity = async (entityToSave, actionType) => {
    const { id, isAdding, solicitudIdURL } = this.state;
    const { history, toastManager } = this.props;
    let saveResponse = null;
    let toastMessage = '';
    let redirectUrl = '';
    try {
      //const itemsByConsumer = this.verifyItemByConsumer();
      const itemsByConsumer = this.verifyNewItemByConsumer();
      if (isAdding) {
        saveResponse = await APIClient.post('/entregas', entityToSave);
        if (actionType === 'submit') {
          if (itemsByConsumer.length > 0) {
            await APIClient.post(`/entregas/consumer-articles`, itemsByConsumer);
          }
          saveResponse = await APIClient.post(`/entregas/${saveResponse.data.data.id}/confirmar`);
          toastMessage = 'Entrega generada y confirmada con éxito';
          redirectUrl = solicitudIdURL ? '/solicitudes' : '/monitor-pendientes';
        } else {
          toastMessage = 'Borrador de entrega generado con éxito';
          redirectUrl = solicitudIdURL ? '/solicitudes' : `/entregas/${saveResponse.data.data.id}`;
        }
      } else {
        // Editing entrega draft
        saveResponse = await APIClient.patch(`/entregas/${id}`, entityToSave);
        const updatedEntity = await APIClient.get(`/entregas/${saveResponse.data.data.id}`);
        this.setState({ entity: updatedEntity.data.data });
        if (actionType === 'submit') {
          // Confirm entrega

          if (itemsByConsumer.length > 0) {
            await APIClient.post(`/entregas/consumer-articles`, itemsByConsumer);
          }
          saveResponse = await APIClient.post(`/entregas/${id}/confirmar`);
          toastMessage = 'Entrega guardada con éxito';
          redirectUrl = solicitudIdURL ? '/solicitudes' : '/entregas';
        } else {
          toastMessage = 'Borrador de entrega guardado con éxito';
          redirectUrl = solicitudIdURL ? '/solicitudes' : `/entregas/${saveResponse.data.data.id}`;
        }
      }
      // Show message and redirect.
      toastManager.add(
        toastMessage,
        {
          appearance: 'success',
          autoDismiss: true,
        },
        () => history.push(redirectUrl),
      );
    } catch (error) {
      toastManager.add(error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  validate = (entityToSave, actionType) => {
    const { isAdding, entity } = this.state;
    // La entidad que se guarda tiene que tener detalles con cantidad mayor a 0.
    let { detalles: detallesToValidate } = entityToSave;
    if (detallesToValidate) {
      if (!isAdding) {
        // Buscar en el array original si hay detalles modificados.
        entity.detalles.forEach((detOrig) => {
          const detalleModificado = detallesToValidate.find((det) => det.articuloId === detOrig.articuloId);
          if (!detalleModificado) {
            // Si no los hay, agregar los originales al array detallesToValidate
            detallesToValidate.push(detOrig);
          }
        });
      }
    } else {
      detallesToValidate = entity.detalles;
    }
    if (!detallesToValidate || detallesToValidate.length === 0 || detallesToValidate.every((det) => det.cantidad === 0)) {
      return 'Debe ingresar detalles con cantidad mayor a 0.';
    }
    return null;
  };

  prepareToSave = (entityToSave, actionType) => {
    const { entity, entregaDetalles, isAdding } = this.state;
    // Si estoy editando, me aseguro que el entityToSave tenga lo nuevo,
    // lo que se actualiza, y lo que hay que eliminar.
    if (isAdding) {
      // Que array tiene informacion para grabar?
      entityToSave.detalles = entregaDetalles.filter((det) => det.cantidad > 0);
    } else {
      let { detalles } = entity;
      const updatedDetalles = [];
      const assocToDelete = { detalles: [] };
      // Find detalles to update or delete in entregaDetalles.
      entregaDetalles.forEach((entDet) => {
        const existingDetalle = detalles.find((det) => {
          if (det.articuloId === entDet.articuloId && entDet.isEdited) {
            det.cantidad = parseInt(entDet.cantidad, 10);
            return det;
          }
          return null;
        });
        if (existingDetalle) {
          if (existingDetalle.cantidad > 0) {
            updatedDetalles.push(existingDetalle);
          } else {
            assocToDelete.detalles.push(existingDetalle.id);
          }
        }
      });
      // Check if detalles which had been available are now unavailable.
      detalles.forEach((det) => {
        const existingEntregaDetalle = entregaDetalles.find((entDet) => det.articuloId === entDet.articuloId);
        if (!existingEntregaDetalle) {
          assocToDelete.detalles.push(det.id);
        }
      });
      // If there are detalles to delete, add them to entityToSave.
      if (assocToDelete.detalles.length > 0) {
        entityToSave.assocToDelete = assocToDelete;
      }
      // Add new detalles to detalles array.
      detalles = entregaDetalles.filter(
        (entDet) => entDet.cantidad > 0 && entDet.isEdited && !updatedDetalles.find((det) => det.articuloId === entDet.articuloId),
      );
      detalles = [
        ...updatedDetalles,
        ...detalles.map((det) => ({ entregaId: entity.id, articuloId: det.articuloId, cantidad: parseInt(det.cantidad, 10) })),
      ];
      if (detalles.length > 0) {
        entityToSave.detalles = detalles;
      }
    }
    if (actionType === 'submit') {
      entityToSave.isSubmitting = true;
    }
    return entityToSave;
  };

  onStartEditing = (row) => {
    this.setState((prevState) => {
      const { entregaDetalles } = prevState;
      const updatedDetalle = entregaDetalles.find((ed) => ed.articuloId === row.articuloId);
      updatedDetalle.cantidad = row.pendingDelivery;

      return {
        ...prevState,
        entregaDetalles,
      };
    });
  };

  masiveSelection = ()=>{
    this.setState((prevState) => {
      const { entregaDetalles } = prevState;
      entregaDetalles.forEach(entregaDetalle =>{
        entregaDetalle.cantidad = entregaDetalle.pendingDelivery
        this.afterEditingCell(null, entregaDetalle.cantidad, entregaDetalle)
      })
    });
  };

  render() {
    const { cliente, disableButtons, entregaDetalles, entity, expresos, isAdding, isDataLoading, puntosEntrega, originalSolicitudCode } =
      this.state;
    const tableColumns = this.generateColumns();
    const formattedToday = moment.utc().startOf('day').format('YYYY-MM-DDTHH:mm');

    const rowClasses = (row) => {
      if (entity.estadoEntrega !== 'EC') {
        if (parseInt(row.cantidad, 10) !== row.pendingDelivery) {
          if (row.cantidad > 0) {
            return 'bg-warning font-weight-bold';
          }
          return '';
        }
        return 'bg-success font-weight-bold';
      }
      return null;
    };

    let buttonArray = [];
    if (isAdding || entity.estadoEntrega === 'EA') {
      buttonArray = [
        {
          text: 'Confirmar Entrega',
          type: 'submit',
          classes: 'mr-3',
          variant: 'primary',
          icon: <FontAwesomeIcon icon={faCheck} fixedWidth className="mr-1" />,
          disabled: disableButtons,
        },
        {
          text: 'Guardar Borrador',
          type: 'save',
          classes: 'mr-1',
          variant: 'secondary',
          icon: <FontAwesomeIcon icon={faSave} fixedWidth className="mr-1" />,
          disabled: disableButtons,
        },
      ];
    } else {
      buttonArray = [
        {
          text: 'Guardar',
          type: 'save',
          classes: 'mr-3',
          variant: 'primary',
          icon: <FontAwesomeIcon icon={faCheck} fixedWidth className="mr-1" />,
        },
      ];
    }

    return (
      <div>
      {this.renderAlertRecalculateModal()}

        <Row>
          <Col className="d-flex justify-content-between pr-0" md={12}>
            <h1 className="d-flex my-3">
              {isAdding ? 'Entrega nueva' : 'Entrega'} {entity && entity.id ? `#${entity.id}` : null}
              {entity.estadoEntrega && <div className="ml-3 d-flex">{UIUtils.getEntregaEstadoBadge(entity.estadoEntrega)}</div>}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between pr-0" md={12}>
            {originalSolicitudCode ? (
              <h5 className="mt-4 ">
                {' '}
                Generada a partir del pedido <span className="badge mx-2 badge-info"> {originalSolicitudCode}</span>
              </h5>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          buttons={buttonArray}
          submitButtonText={isAdding ? 'Generar Entrega' : 'Guardar'}
          initializedEntity={this.initializeEntity}
          prepareToSave={this.prepareToSave}
          validate={this.validate}>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label className="form-label">Código</label>
                <p className="form-control-plaintext">{cliente.codigoInterno}</p>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label className="form-label">CUIT</label>
                <p className="form-control-plaintext">{cliente.documentoNumero}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label className="form-label">Cliente/Razón Social</label>
                <p className="form-control-plaintext">{cliente.razonSocial}</p>
              </div>
            </Col>
          </Row>
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>Datos Adicionales</h3>
              </Card.Title>
              <Row>
                <Col md={6}>
                  <FormSelectField
                    id="puntoEntregaId"
                    label="Punto de Entrega"
                    type="text"
                    choices={puntosEntrega}
                    choiceIdField="id"
                    choiceLabelField="descripcion"
                    defaultValue={entity.puntoEntregaId}
                    placeholder="Retira en Fábrica"
                    required
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField
                    id="expresoId"
                    label="Expreso"
                    type="text"
                    choices={expresos}
                    choiceIdField="id"
                    choiceLabelField="descripcion"
                    defaultValue={entity.expresoId}
                    placeholder="(Sin Expreso)"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormInputField
                    id="fechaEntrega"
                    label="Fecha de Entrega"
                    type="datetime-local"
                    defaultValue={moment(entity.fechaEntrega).format('YYYY-MM-DDTHH:mm:ss')}
                    // min={now.hours() === 23 && now.minutes() === 59 ? tomorrow : formattedNow}
                    min={formattedToday}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInputField id="observaciones" label="Observaciones" as="textarea" type="text" defaultValue={entity.observaciones} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <DataTable
            columns={tableColumns}
            data={entregaDetalles}
            isDataLoading={isDataLoading}
            keyField="articuloId"
            cellEdit={cellEditFactory({
              mode: 'click',
              blurToSave: true,
              autoSelectText: true,
              afterSaveCell: this.afterEditingCell,
              onStartEdit: this.onStartEditing,
            })}
            showSearch={false}
            showExport={false}
            enablePagination={false}
            rowClasses={rowClasses}
            addButton={()=>this.masiveSelection()}
            buttonText={'Selección masiva'}
            addButtonIconHidden={true}
          />
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(EntregaEdit);
