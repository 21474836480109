import React from 'react';
import { Route } from 'react-router-dom';
import AutoMarcaList from './AutoMarcaList';
import AutoMarcaEdit from './AutoMarcaEdit';

const routes = () => [
  <Route path="/auto-marcas/nuevo" exact component={AutoMarcaEdit} key="/auto-marca/nuevo" />,
  <Route path="/auto-marcas/:id" exact component={AutoMarcaEdit} key="/auto-marca/:id" />,
  <Route path="/auto-marcas" exact component={AutoMarcaList} key="/auto-marca" />,
].map(route => route);

export default routes;
