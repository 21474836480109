import React from 'react';
import { Route } from 'react-router-dom';
import CustomersReport from './CustomersReport';

const routes = () => [
  <Route path="/customers-report" exact component={CustomersReport} key="/customers-report" />,
  <Route path="/customers-report/customer/:id" component={CustomersReport} key="/customers-report/:id" />,
].map(route => route);

export default routes;
