interface Dropdowninterface {
  keySubModule:string;
  subModuleName: string;
  dropdownLinks: Array<DropdownLinksInterface>;
}

interface DropdownLinksInterface {
  keyView: string;
  name: string;
  path: string;
}

interface LinksIterface {
  keyView: string;
  name?: string;
  path?: string;
  icon: string;
  dropdownSubModule?: Array<Dropdowninterface>;
}

interface ModuleInterface {
  keyModule:string;
  moduleName: string;
  views: Array<LinksIterface>;
}

export const sideMenu: Array<Array<ModuleInterface>> = [
  [
    {
      keyModule:'administracion_mod',
      moduleName: 'Administracion',
      views: [
        {
          keyView: 'articulos-pendientes',
          name: 'Pendiente de Entrega',
          path: 'articulos-pendientes',
          icon: 'tachometer-alt',
        },
        {
          keyView: 'monitor-pendientes',
          name: 'Monitor de Pendientes',
          path: 'monitor-pendientes',
          icon: 'clipboard-list',
        },
        {
          keyView: 'solicitudes',
          name: 'Solicitudes',
          path: 'solicitudes',
          icon: 'file-alt',
        },
        {
          keyView: 'delivery-parameters',
          name: 'Parametros de Entrega',
          path: 'delivery-parameters',
          icon: 'file-alt',
        },
      ],
    },
  ],
  [
    {
      keyModule:'logistica_mod',
      moduleName: 'Logística',
      views: [
        {
          keyView: 'entregas',
          name: 'Entregas',
          path: 'entregas',
          icon: 'truck',
        },
        {
          keyView: 'expresos',
          name: 'Expresos',
          path: 'expresos',
          icon: 'dolly',
        },
        {
          keyView: 'puntos-entrega',
          name: 'Puntos de Entrega',
          path: 'puntos-entrega',
          icon: 'dolly',
        },
      ],
    },
  ],
  [
    {
      keyModule:'estadisticas_mod',
      moduleName: 'Estadisticas',
      views: [
        {
          keyView: 'delivery-report',
          name: 'Reporte de Entregas',
          path: 'delivery-report',
          icon: 'clipboard-list',
        },
        {
          keyView: 'production-report',
          name: 'Reporte de Producción',
          path: 'production-report',
          icon: 'clipboard-list',
        },
        {
          keyView: 'sale-report',
          name: 'Reporte de Ventas',
          path: 'sale-report',
          icon: 'clipboard-list',
        },
        {
          keyView: 'customers-report',
          name: 'Reporte de Clientes',
          path: 'customers-report',
          icon: 'clipboard-list',
        },
      ],
    },
  ],
  [
    {
      keyModule:'produccion_mod',
      moduleName: 'Producción',
      views: [
        {
          keyView: 'materials-list',
          name: 'Lista de Materiales',
          path: 'materials-list',
          icon: 'boxes',
        },
        {
          keyView: 'resources',
          name: 'Recursos de Planta',
          path: 'resources',
          icon: 'boxes',
        },
        {
          keyView: 'operations-uptakes',
          name: 'Operaciones de Consumo',
          path: 'operations-uptakes',
          icon: 'boxes',
        },
        {
          keyView: 'production-parameters',
          name: 'Parametros de Producción',
          path: 'production-parameters',
          icon: 'boxes',
        },
        {
          keyView: 'production-orders',
          name: 'Ordenes de Producción',
          path: 'production-orders',
          icon: 'boxes',
        },
      ],
    },
  ],
  [
    {
      keyModule:'inventario_mod',
      moduleName: 'Inventario',
      views: [
        {
          keyView: 'warehouses',
          name: 'Depósitos',
          path: 'warehouses',
          icon: 'dolly',
        },
        {
          keyView: 'monitor-inventario',
          name: 'Monitor de inventario',
          path: 'monitor-inventario',
          icon: 'clipboard-list',
        },
        {
          keyView: 'movimientos',
          path: '',
          icon: 'exchange-alt',
          dropdownSubModule: [
            {
              keySubModule:'movimientos_sub_mod',
              subModuleName: 'Movimientos',
              dropdownLinks: [
                {
                  keyView: 'stocklist/ingreso',
                  name: 'Ingresar',
                  path: 'stocklist/ingreso',
                },
                {
                  keyView: 'stocklist/egreso',
                  name: 'Consumir',
                  path: 'stocklist/egreso',
                },
                {
                  keyView: 'stocklist/movimiento',
                  name: 'Mover internamente',
                  path: 'stocklist/movimiento',
                },
                {
                  keyView: 'stocklist',
                  name: 'Ver lista',
                  path: 'stocklist',
                },
              ],
            },
          ],
        },
        {
          keyView: 'supplies',
          name: 'Insumos',
          path: 'supplies',
          icon: 'boxes',
        },
        {
          keyView: 'categories',
          name: 'Categorías',
          path: 'categories',
          icon: 'boxes',
        },
        {
          keyView: 'proveedores',
          name: 'Proveedores',
          path: 'proveedores',
          icon: 'boxes',
        },
        {
          keyView: 'unidades-medida',
          name: 'Unidades de Medida',
          path: 'unidades-medida',
          icon: 'ruler-horizontal',
        },
      ],
    },
  ],
  [
    {
      keyModule:'articulos_mod',
      moduleName: 'Artículos',
      views: [
        {
          keyView: 'articulos',
          name: 'Artículos',
          path: 'articulos',
          icon: 'shopping-cart',
        },
        {
          keyView: 'marcas',
          name: 'Marcas',
          path: 'marcas',
          icon: 'list',
        },
        {
          keyView: 'lineas',
          name: 'Líneas',
          path: 'lineas',
          icon: 'list',
        },
        {
          keyView: 'colecciones',
          name: 'Colecciones',
          path: 'colecciones',
          icon: 'list',
        },
      ],
    },
  ],
  [
    {
      keyModule:'automoviles_mod',
      moduleName: 'Automóviles',
      views: [
        {
          keyView: 'auto-marcas',
          name: 'Marcas',
          path: 'auto-marcas',
          icon: 'list',
        },
        {
          keyView: 'auto-modelos',
          name: 'Modelos',
          path: 'auto-modelos',
          icon: 'list',
        },
      ],
    },
  ],
  [
    {
      keyModule:'clientes_mod',
      moduleName: 'Clientes',
      views: [
        {
          keyView: 'clientes',
          name: 'Clientes',
          path: 'clientes',
          icon: 'user-tie',
        },
        {
          keyView: 'zonas',
          name: 'Zonas',
          path: 'zonas',
          icon: 'dolly',
        },
        {
          keyView: 'vendedores',
          name: 'Vendedores',
          path: 'vendedores',
          icon: 'suitcase',
        },
        {
          keyView: 'cliente-tipos',
          name: 'Tipos',
          path: 'cliente-tipos',
          icon: 'list',
        },
        {
          keyView: 'listas-precio',
          name: 'Listas de Precio',
          path: 'listas-precio',
          icon: 'file-invoice-dollar',
        },
        {
          keyView: 'modificadores-precio',
          name: 'Modif. de Precio',
          path: 'modificadores-precio',
          icon: 'file-invoice-dollar',
        },
      ],
    },
  ],
  [
    {
      keyModule:'aesguridad_mod',
      moduleName: 'Seguridad',
      views: [
        {
          keyView: 'usuarios',
          name: 'Usuarios',
          path: 'usuarios',
          icon: 'users',
        },
        {
          keyView: 'roles',
          name: 'Roles',
          path: 'roles',
          icon: 'users',
        },
      ],
    },
  ],
];
