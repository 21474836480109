import React from 'react';
import { Route } from 'react-router-dom';
import InsumoList from './InsumoList';
import InsumoEdit from './InsumoEdit';

const routes = () => [
  <Route path="/supplies/new" component={InsumoEdit} key="/supplies/new" />,
  <Route path="/supplies/:id" component={InsumoEdit} key="/supplies/:id" />,
  <Route path="/supplies" exact component={InsumoList} key="/supplies" />,
].map(route => route);

export default routes;
