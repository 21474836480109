import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FormCheckField = (props) => {
  const { id,disabled,checked, onClick , ...otherProps } = props;

  return (
    <Form.Group controlId={id}>
      <Form.Check onClick={onClick} checked={checked} disabled={disabled} type="checkbox" name={id} {...otherProps} />
    </Form.Group>
  );
};

FormCheckField.propTypes = {
  checked:PropTypes.bool,
  disabled:PropTypes.bool,
  onClick:PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormCheckField;
