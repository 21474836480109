import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import 'react-table/react-table.css';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, FormInputField, FormSelectField, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux'

class ReporteArticulos extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      curFromDate: '',
      curToDate: '',
      isDataLoading: true,
      generatedColors: [],
      clientes: [],
      vendedores: [],
      selectedClienteIds: [],
      selectedVendedorIds: [],
      articulosPendientes: [],
      showModal: false,
      showArticulosModal: false,
      windowHeight: 0,
    };

  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }

  componentDidMount() {
    this.loadSelectData();
    this.retrievePendientes();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  createSelectAllButtons = (entityName) => {
    if (entityName === 'clientes') {
      return (
        <p className="m-0">
          {entityName.substr(0, 1).toUpperCase()}
          {entityName.substr(1)} (
          <button
            id={`select-all-${entityName}`}
            type="submit"
            className="link-button text-primary"
            onClick={(e) => this.handleSelectAll(e, 'selectedClienteIds')}>
            Seleccionar todos
          </button>
          )
        </p>
      );
    }
    if (entityName === 'vendedores') {
      return (
        <p className="m-0">
          {entityName.substr(0, 1).toUpperCase()}
          {entityName.substr(1)} (
          <button
            id={`select-all-${entityName}`}
            type="submit"
            className="link-button text-primary"
            onClick={(e) => this.handleSelectAll(e, 'selectedVendedorIds')}>
            Seleccionar todos
          </button>
          )
        </p>
      );
    }
  };

  loadSelectData = async () => {
    const { toastManager } = this.props;
    try {
      // get clientes
      const clientesRes = await APIClient.get('/clientes?sortField=razonSocial&sortDir=asc');
      // get vendedores
      const vendedoresRes = await APIClient.get('/vendedores?sortField=nombre&sortDir=asc');
      const vendedores = vendedoresRes.data.data;
      vendedores.forEach((vend) => (vend.selected = false));
      const clientes = clientesRes.data.data;
      this.setState({ vendedores: vendedores, clientes: clientes });
    } catch (err) {
      console.error('Error al intentar hacer la consulta : ', err);
      toastManager.add(`No posee algun permiso asociado de esta pagina. ${err}`, {
        appearance: 'error',
      });
    }
  };

  handleDataChange = (e) => {
    const { id, value, options, multiple } = e.target;
    if (multiple) {
      const values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);
      this.setState((prevState) => ({
        ...prevState,
        [id]: values,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  };

  handleSelectAll = (e) => {
    const { id } = e.target;
    this.setState((prevState) => {
      const { clientes, vendedores } = prevState;
      let { selectedClienteIds, selectedVendedorIds } = prevState;
      if (id === 'select-all-clientes') {
        selectedClienteIds = clientes.map((cli) => cli.id);
      }
      if (id === 'select-all-vendedores') {
        selectedVendedorIds = vendedores.map((vend) => vend.id);
      }
      // clientes.forEach(cli => cli.selected = true);
      return { ...prevState, selectedClienteIds, selectedVendedorIds };
    });
  };

  retrievePendientes = async () => {
    const { toastManager } = this.props;
    const { clientes, vendedores } = this.state;
    let { selectedClienteIds, selectedVendedorIds } = this.state;
    let { curFromDate, curToDate } = this.state;
    this.setState({ isDataLoading: true });
    try {
      let artPendientes = '';
      if (curToDate === '') {
        // toDate is empty
        curToDate = moment().add(14, 'd').format('YYYY-MM-DD');
      }
      if (curFromDate === '') {
        // fromDate is empty
        curFromDate = moment().format('YYYY-MM-DD');
      }
      if (selectedClienteIds.length === clientes.length) {
        selectedClienteIds = [];
      }
      if (selectedVendedorIds.length === vendedores.length) {
        selectedVendedorIds = [];
      }
      const queryParameters = `from_date=${curFromDate}&to_date=${curToDate}&clienteIds=${selectedClienteIds.join(
        ',',
      )}&vendedorIds=${selectedVendedorIds.join(',')}`;
      artPendientes = await APIClient.get(`/reportes/articulos-pendientes?${queryParameters}`);
      this.setState((prevState) => {
        artPendientes = artPendientes.data.data;
        return {
          ...prevState,
          articulosPendientes: artPendientes,
          curFromDate,
          curToDate,
          isDataLoading: false,
        };
      });
    } catch (error) {
      console.error('Error al obtener el listado de pendientes: ', error);
      toastManager.add(`No se pudo obtener el listado de pendientes. ${error}`, {
        appearance: 'error',
      });
    }
  };

  updateWindowDimensions = () => {
    this.setState({ windowHeight: window.innerHeight });
  };

  render() {
    const { articulosPendientes,isPermissionsLoading, curFromDate, curToDate, isDataLoading, clientes, vendedores, selectedClienteIds, selectedVendedorIds } =
      this.state;

    const columns = [
      {
        dataField: 'erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
      },
      {
        dataField: 'descripcion',
        text: 'Articulo',
        sort: true,
        editable: false,
      },
      {
        dataField: 'linea',
        text: 'Línea',
        sort: true,
        editable: false,
      },
      {
        dataField: 'pendienteEntrega',
        text: 'Cant. Pendiente',
        sort: true,
        editable: false,
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      // clickToEdit: true,
      clickToSelect: true,
      hideSelectColumn: true,
      // onSelect: this.onSelectArticulo,
    };
    return (
      <div>
        <h1 className="page-title">Articulos Pendientes de Entrega</h1>
        {isPermissionsLoading === false ? (
          <>
            <Row>
              <Col md={3}>
                <FormInputField
                  id="curFromDate"
                  type="date"
                  label="Fecha desde:"
                  defaultValue={curFromDate !== '' ? curFromDate : null}
                  onChange={this.handleDataChange}
                  required
                />
              </Col>
              <Col md={3}>
                <FormInputField
                  id="curToDate"
                  type="date"
                  label="Fecha hasta:"
                  onChange={this.handleDataChange}
                  defaultValue={curToDate !== '' ? curToDate : null}
                  min={curFromDate !== '' ? curFromDate : null}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelectField
                  id="selectedClienteIds"
                  label={this.createSelectAllButtons('clientes')}
                  value={selectedClienteIds}
                  onChange={this.handleDataChange}
                  choices={clientes}
                  choiceIdField="id"
                  choiceLabelField="razonSocial"
                  multiple
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="selectedVendedorIds"
                  label={this.createSelectAllButtons('vendedores')}
                  value={selectedVendedorIds}
                  onChange={this.handleDataChange}
                  choices={vendedores}
                  choiceIdField="id"
                  choiceLabelField="nombre"
                  multiple
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex flex-row justify-content-center">
                  <Button disabled={isDataLoading} className="d-flex py-2 m-1 my-3" variant="primary" onClick={this.retrievePendientes}>
                    {!isDataLoading ? (
                      <p className="m-0">Buscar</p>
                    ) : (
                      <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                    )}
                    {/* <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1"/> */}
                  </Button>
                </div>
              </Col>
            </Row>

            <div>
              <DataTable
                isDataLoading={isDataLoading}
                selectRow={selectRowProps}
                columns={columns}
                data={articulosPendientes}
                keyField="articuloId"
                showSearch
                showExport
                enablePagination={false}
              />
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(ReporteArticulos));

