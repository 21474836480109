import React from 'react';
import PropTypes from 'prop-types';
import { Col,Row,} from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormCheckField, FormInputField,  } from '../../components';
import APIClient from '../../services/APIClient';

class WarehouseEdit extends React.Component {
 static propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  toastManager: PropTypes.object.isRequired,
 };

 constructor(props) {
  super(props);

  const { id } = props.match.params;

  this.state = {
   entity: {},
   id,
   isAdding: typeof id === 'undefined',
   checkExistenceParam: 'warehouse_id',
   warehouseWithItemInStock: [],
  };
 }

 onRetrieveEntity = async () => {
  const { id, checkExistenceParam } = this.state;

  // get articulo
  const warehouseRes = await APIClient.get(`/warehouses/${id}`);
  const warehouse = warehouseRes.data.data;
  const warehouseWithItemInStockRes = await APIClient.get(`/monitor-inventario/check-existence/${checkExistenceParam}/${id}`);

  this.setState({
   entity: warehouse,
   warehouseWithItemInStock: warehouseWithItemInStockRes.data.data
  });

  return warehouse;
 };

 /**
  * Save the item
  */
 onSaveEntity = async (entityToSave) => {
  const { id, isAdding } = this.state;
  const { history, toastManager } = this.props;
  let saveResponse = null;
  if (isAdding) {
   saveResponse = await APIClient.post('/warehouses', entityToSave);
  } else {
   saveResponse = await APIClient.patch(`/warehouses/${id}`, entityToSave);
  }
  history.push('/warehouses');
  toastManager.add(`Depósito ${saveResponse.data.data.id} guardado con éxito`, {
   appearance: 'success',
   autoDismiss: true,
  });
 };



 render() {
  const {
   id,
   isAdding,
   entity,
   warehouseWithItemInStock
  } = this.state;
  const ableDisable = warehouseWithItemInStock.length > 0;


  return (
   <div>
    <h1 className="page-title">{isAdding ? 'Depósito nuevo' : `Deposito #${id}`}</h1>

    <EntityEditForm
     //   onLoadForm={this.onLoadForm}
     onRetrieveEntity={this.onRetrieveEntity}
     onSaveEntity={this.onSaveEntity}
     addMode={isAdding}
    //    prepareToSave={this.prepareToSave}
    >
     <>
      <Row>
       <Col md={6}>
        <FormInputField id="description" label="Descripción" type="text" defaultValue={entity.description} required />
       </Col>
       <Col md={6}>
        <FormInputField id="address" label="Ubicación" type="text" defaultValue={entity.address} required />
       </Col>
      </Row>
      <Row>
       <Col md={2}>
        <FormCheckField id="disabled" label="Deshabilitado" defaultChecked={entity.disabled} disabled={ableDisable} />
       </Col>
       <Col md={2}>
        <FormCheckField id="isFactory" label="Es fábrica" defaultChecked={entity.isFactory} />
       </Col>
      </Row>
      <Row>
      {ableDisable ? <span className="bg-warning">Este depósito tiene cantidades comprometidas en el stock </span> : ''}
      </Row>
     </>
    </EntityEditForm>
   </div>
  );
 }
}

export default withToastManager(WarehouseEdit);
