import apiClient from "../services/APIClient";
import Security from "../services/Security";
import moment from 'moment';


function sanitizeQuery(expectedFields, queryString) {
  let response = {};
  const querySections = queryString.split('&');
  if (querySections[0] === '') {
    expectedFields.forEach((field) => {
      response[field] = undefined;
    });
  } else {
    if (querySections[0].indexOf('?') >= 0) {
      querySections[0] = querySections[0].replace('?', '');
    }
    expectedFields.forEach((field) => {
      querySections.forEach((sec) => {
        if (sec.includes(field) && sec.includes('=')) {
          let querys = sec.split('=');
          if (querys[0] === field) {
            let value = querys[1];
            response[field] = value;
          } else {
            response[field] = undefined;
          }
        } else {
          response[field] = undefined;
        }
      });
    });
  }
  return response;
}
function pickNumForString(string) {
  let first, last;
  for (let i = 0; i < string.length; i++) {
    if (!isNaN(parseInt(string[i]))) {
      first = i;
      break;
    }
  }
  for (let k = first; k < string.length; k++) {
    if (isNaN(parseInt(string[k]))) {
      last = k;
      break;
    }
  }
  if (!last) last = string.length;
  return isNaN(parseInt(string.substring(first, last))) ? 0 : parseInt(string.substring(first, last));
}

function prepareQueryToFilter(queryValueField, op, ...value) {
  if (value[0].length === 0 || value[0][0] === '') {
    return undefined;
  }
  let query = '';
  value.forEach((val, j) => {
    query += val.reduce((ac, val_, k, t) => {
      if (t[k] !== '') {
        return ac + `filter[${queryValueField}][${op}]=${t[k]}&`;
      }
    }, '');
  });
  if (query) {
    return query.slice(0, query.length - 1);
  }
}

function internalMovementParseMovementsToPost(state, MovementData) {
  const {movType, movementMotivesInternalMovementId : motiveId } = MovementData;
  const bodyQuery = [];
  const typeIngreso = movType.ingreso;
  const typeEgreso = movType.egreso;
  state.forEach( (ingAndEgr) => {
      const ingreso =  makeMovement(ingAndEgr, {movementType: typeIngreso, motiveId});
      const egreso = makeMovement(ingAndEgr, {movementType: typeEgreso, motiveId});
      bodyQuery.push(egreso, ingreso);
    
  });
  return bodyQuery;
}

function makeMovement(ingAndEgr, data) {
  const {movementType, motiveId} = data;
  const isIngreso = movementType.description === 'ingreso';
  const {
    userId,
    descriptionOfMove,
    locationId,
    locationIdDestination,
    productStatusId,
    productStatusIdDestination,
    sectionId,
    sectionIdDestination,
     type,
    unitsToMove,
    warehouseId,
    warehouseIdDestination
  } = ingAndEgr;
const itemType = ingAndEgr.hasOwnProperty('articuloId') ? 'articuloId' : 'insumoId';
const idValue = ingAndEgr[itemType];

  return {
  // articuloId: ingAndEgr.hasOwnProperty('articuloId') ? ingAndEgr.articuloId : null,
  // insumoId: ingAndEgr.hasOwnProperty('insumoId') ? ingAndEgr.insumoId : null,
  [itemType]: idValue,
  productTypeId: type,
  warehouseId: isIngreso ? warehouseIdDestination : warehouseId,
  sectionId: isIngreso ? sectionIdDestination : sectionId,
  locationId: isIngreso ? locationIdDestination : locationId,
  units: unitsToMove,
  productStatusId: isIngreso ? productStatusIdDestination : productStatusId,
  motiveId,
  typeId: data.movementType.id,
  description: descriptionOfMove ? descriptionOfMove : '',
  userId,
  };
}

async function viewsByPermission() {
const userId = Security.getSession().user.id;
const viewsPermissions = await (await apiClient.get(`/usuarios/view-permissions/${userId}`)).data.data;
return viewsPermissions;
}

async function getPermissionsActions() {
  const URLactual = window.location.pathname;
  const permissionsUser = await viewsByPermission();
  const isSuperAdmin = permissionsUser.every((permission) => permission.isSuperAdmin);
  
  let viewPermission = false;
  let editPermission = false;
  let deletePermission = false;
  const isHome =  URLactual === `/`;

  permissionsUser.forEach((permissionUser) => {
    const pathPermission = URLactual.includes(`/${permissionUser.pathView}`);
    if (pathPermission) {
      viewPermission = permissionUser.actionsView.some((action) => action === `view`);
      editPermission = permissionUser.actionsView.some((action) => action === `edit`);
      deletePermission = permissionUser.actionsView.some((action) => action === `delete`);
      
    }
  });

  return {
    viewPermission,
    editPermission,
    deletePermission,
    isSuperAdmin,
    isHome
  }
}

function localDate(date, dateFormat) {
  const dateUtc = moment.utc(date);
  const localDate = dateUtc.local().format(dateFormat);
  return localDate;
}

function rangeYear () {
  const maximumDate = new Date().getFullYear()
  const yearsToSubtract = 100;
  const minimumDate = maximumDate - yearsToSubtract
  const years = []

  for (let i = maximumDate; i >= minimumDate; i--) {
    const year = {
      id: i.toString(),
      description:i
    }
      years.push(year)
  }
  return years
}

export default {
  rangeYear,
  localDate,
  sanitizeQuery,
  viewsByPermission,
  pickNumForString,
  prepareQueryToFilter,
  internalMovementParseMovementsToPost,
  getPermissionsActions,
};
