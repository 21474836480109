import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import { connect } from 'react-redux'

class MarcaList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      isDataLoading: true,
    };

    this.loadMarcas = this.loadMarcas.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }
  

  componentDidMount() {
    this.loadMarcas();
  }

  loadMarcas() {
    const { toastManager } = this.props;

    APIClient.get('/marcas')
      .then((res) => {
        this.setState({
          entities: res.data.data,
          isDataLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { entities, isDataLoading, isSuperAdmin, editPermission, isPermissionsLoading } = this.state;

    const columns = [
      {
        dataField: 'codigo',
        text: 'Código',
        sort: true,
      },
      {
        classes: 'w-75',
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
      },
      {
        classes: 'text-center',
        dataField: 'eliminadoFlag',
        text: 'Inactivo',
        headerClasses: 'text-center',
        sort: true,
        formatter: (cellContent) => {
          if (cellContent > 0) {
            return <FontAwesomeIcon icon={faCheck} fixedWidth size="xs" />;
          }
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <>
                  <LinkContainer to={`/marcas/${row.codigo}`}>
                    <Button size="sm" variant="outline-primary" title="Editar">
                      <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                    </Button>
                  </LinkContainer>
                </>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Marcas</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            columns={columns}
            data={entities}
            isDataLoading={isDataLoading}
            keyField="codigo"
            addButton={isSuperAdmin || editPermission ? '/marcas/nuevo' : null}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(MarcaList));


