import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withToastManager } from 'react-toast-notifications';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { DataTable, Loading } from '../../components';

import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import Utils from '../Utils';
import { connect } from 'react-redux';

class CategoriaList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      categorias: [],
      isDataLoading: true,
      totalSize: 0,
      selectedCategory: {},
      estados: [
        { active: true, state: 'Activo' },
        { active: false, state: 'Inactivo' },
      ],
    
    };
    this.loadCategories = this.loadCategories.bind(this);
    this.getPermissionsActions = this.getPermissionsActions.bind(this);
  }

  getPermissionsActions = async () => {
    const { toastManager, history } = this.props;
    const { isSuperAdmin, editPermission, deletePermission, viewPermission } = await Utils.getPermissionsActions();

    if (viewPermission === false && isSuperAdmin === false) {
      toastManager.add(`No tienes acceso a esa página`, {
        appearance: 'warning',
      });
      history.push('/');
    }

    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading:false
    });
  };

  componentDidMount() {
    this.getPermissionsActions();
    this.loadCategories();
  }

  async loadCategories() {
    const { toastManager } = this.props;
    try {
      const categoriasRes = await APIClient.get('/categorias?limit=10&offset=0&sortField=id&sortDir=asc');
      this.setState({
        categorias: categoriasRes.data.data,
        isDataLoading: false,
        totalSize: categoriasRes.data.meta.total,
      });
      this.apiParams = 'sortField=id&sortDir=asc&excludeAssocFields=imagenes';
    } catch (err) {
      console.error('Error cargando categorias.', err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  }

  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    try {
      const { direction, field } = sorting;
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || ''}&sortDir=${direction || ''}`;
      const categoriasRes = await APIClient.get(`/categorias?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}`);
      this.setState({
        categorias: categoriasRes.data.data,
        totalSize: categoriasRes.data.meta.total,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  ////////////////////DELETE MODAL
  onConfirmDelete = async () => {
    const { selectedCategory } = this.state;
    const { toastManager } = this.props;

    try {
      await APIClient.delete(`/categorias/${selectedCategory.id}`);
      this.setState((prevState) => {
        const categorias = [...prevState.categorias];
        const selectedIndex = categorias.findIndex((ent) => selectedCategory.id === ent.id);
        categorias.splice(selectedIndex, 1);
        return { categorias, selectedCategory: {}, showConfirmModal: false };
      });
      toastManager.add('Categoría eliminada con éxito', {
        appearance: 'success',
      });
    } catch (err) {
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
      this.setState({ selectedCategory: {}, showConfirmModal: false });
    }
  };

  renderDeleteCategoryModal = () => {
    const { showConfirmModal } = this.state;
    const selectedDetalle = {};
    return (
      <Modal size="md" show={showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Categoría</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar esta Categoría?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onConfirmDelete}>
            Eliminar
          </Button>
          <Button variant="secondary" onClick={() => this.setState({ showConfirmModal: false, selectedDetalle })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  showDeleteConfirmModal = (row) => {
    this.setState({
      selectedCategory: row,
      showConfirmModal: true,
    });
  };
  /////////////////DELETE MODAL END

  render() {
    const { categorias, isDataLoading, totalSize, estados, isSuperAdmin, editPermission, isPermissionsLoading} = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'id',
        hidden: true,
      },
      {
        dataField: 'description',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'categoryLevel.description',
        text: 'Nivel',
        sort: true,
      },
      {
        dataField: 'active',
        text: 'Estado',
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          const estadoFound = estados.find((estado) => cellContent === estado.active);
          const estado = estadoFound ? estadoFound.state : row.estado.state;
          return UIUtils.getClienteEstadoBadge(estado);
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <>
                  <LinkContainer to={`/categories/${row.id}`}>
                    <Button size="sm" variant="outline-primary" title="Editar">
                      <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                    </Button>
                  </LinkContainer>
                </>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        {this.renderDeleteCategoryModal()}
        <h1 className="page-title">Categorias</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            remote={{
              filter: true,
              pagination: true,
              sort: true,
              cellEdit: false,
            }}
            totalSize={totalSize}
            isDataLoading={isDataLoading}
            columns={columns}
            data={categorias}
            onTableUpdate={this.onTableUpdate}
            keyField="id"
            addButton={isSuperAdmin || editPermission ? '/categories/new' : null}
            defaultSorted={[{ dataField: 'description', order: 'asc' }]}
          />
          ) : (
          <Loading />
        )}
      </div>
    );
  }
}
const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(CategoriaList));
