import React, { Component, Fragment } from 'react';
import { Col, Row, Image, Modal, Button, Navbar } from 'react-bootstrap';
import config from '../config';
import APIClient from "../services/APIClient";
import './EtiquetaEnviosImprimir.css';
import QRCode from 'qrcode.react';
import { FormInputField } from '../components';
class EtiquetaEnviosImprimir extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      cliente: {},
      isAdding: typeof id === "undefined",
      detalles: [],
      entity: {},
      solicitudesIds: [],
      logo: {},
      contactData: {},
      puntoEntrega: {},
      baseUrl: config.front.url,
      showBultosModal: false,
      bultosCantidad: '',
      renderAndPrint: false,
    };
    this.getLogoAndContactData = this.getLogoAndContactData.bind(this);
  }

  async componentDidMount() {
    await this.getLogoAndContactData();
    const { id } = this.props.match.params;
    try {

      const { entity, cliente, puntoEntrega, solicitudesIds, detalles, } = await this.loadData(id);
      this.setState({ entity, cliente, puntoEntrega, solicitudesIds, detalles, id, showBultosModal: true });
      // document.title = entity.id ? `Entrega - ${entity.id}` : `Entrega - backoffice`;

      // return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  async getLogoAndContactData() {
    try {
      const logoRes = await APIClient.get('/cms/logo');
      const contactRes = await APIClient.get('/cms/site-contact');
      this.setState({ logo: logoRes.data.data, contactData: contactRes.data.data });
    }
    catch (err) {
      console.error(`No se pudo obtener el logo: `, err);
    };
  }
  // get array for solicitudes id
  getSolId = async (entity) => {
    const result = entity.detalles ? entity.detalles.reduce(function (arr, det) {
      if (arr.indexOf(det.solicitudDetalleTalle.solicitudDetalle.solicitudId) < 0) {
        arr.push(det.solicitudDetalleTalle.solicitudDetalle.solicitudId);
        return arr;
      } else { return arr };
    }, []) : [];
    return result;
  };

  loadData = async (id) => {
    const entityResponse = await APIClient.get(`/entregas/${id}?populate=entregaDetalle.articulo.linea,entregaDetalle.solicitudDetalleTalle.solicitudDetalle,cliente.puntoEntrega.provincia`);
    const entity = entityResponse.data.data;
    const cliente = entity.cliente;
    const puntoEntrega = entity.cliente.puntoEntrega ? entity.cliente.puntoEntrega : {};
    const detalles = entity.detalles;
    const solicitudesIds = await this.getSolId(entity);
    return { entity, cliente, detalles, solicitudesIds, puntoEntrega };
  };

  print = async () => {
    window.print();
    return;
  }
  onSaveSubmit = async () => {
    await this.handleClose('showBultosModal');
    this.setState({ renderAndPrint: true });
  };
  handleClose = (modal) => {
    this.setState({ [modal]: false, });
  };
  handleInputChange = (e) => {
    const value = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      bultosCantidad: value,
    }));
  }
  render() {

    const { entity, cliente, puntoEntrega, solicitudesIds, logo, contactData, id, baseUrl, showBultosModal, renderAndPrint } = this.state;
    const razonSocial = cliente.razonSocial ? cliente.razonSocial : '';
    const calle = puntoEntrega.calle ? puntoEntrega.calle : '';
    const numero = puntoEntrega.numero ? puntoEntrega.numero : '';
    const localidad = puntoEntrega.localidad ? puntoEntrega.localidad : '';
    const provincia = puntoEntrega.provincia ? puntoEntrega.provincia.descripcion : '';
    const celular = cliente.celular ? cliente.celular : '';
    const mailCliente = cliente.email ? cliente.email : '';
    const date = entity.fechaEntrega ? new Date(Date.parse(entity.fechaEntrega)).toLocaleString('es-AR') : '';
    const webUrl = contactData.main_website_url;
    const tel = contactData.phone ? contactData.phone : '';
    const address = contactData.address ? contactData.address : '';
    const renderEtiquetas = (razonSocial, calle, numero, localidad, provincia, celular, mailCliente, date, webUrl, tel, address) => {
      const { bultosCantidad } = this.state;
      const bultosCantidadNumber = parseInt(bultosCantidad, 10)
      const arrayLength = new Array(bultosCantidadNumber).fill(0);
      console.log('bultosCantidadNumber', bultosCantidadNumber);
      return arrayLength.map((b, bulto) => (
        <div className="etiqueta-container" key={bulto+1}>
          <Row>   <Image className="header-logo mw-100" src={logo.full_url} alt="" />  </Row>
          <Row className="marenoInfo">{webUrl}</Row>
          <Row className="marenoInfo">{address}</Row>
          <Row className="marenoInfo">tel: {tel}</Row>
          <br />
          <Row className="row2">{razonSocial}</Row>
          <Row>{calle} {numero}, {localidad} - {provincia}</Row>
          <Row>Tel: {celular} </Row><Row>Mail: {mailCliente}</Row>
          <br />
          <Row className="row2">Pedidos en la entrega {solicitudesIds.join(', ')}</Row>
          <Row>Fecha de entrega:  {date}</Row>
          <Row className="bultos">Bultos: {bulto + 1}/{bultosCantidad}</Row>
          <Row className="">
            <Col md={6} className="QR">
              <QRCode value={baseUrl + `/delivery/detalles/${id}`} size={280} />
            </Col>
          </Row>
          <h5></h5>
        </div>
      ))
    };
 return (
      <Fragment>
        <Modal size="sm" show={showBultosModal} onHide={() => this.handleClose('showBultosModal')}>
          <Modal.Header closeButton>
            <h4 className="ml-3">Cantidad de bultos </h4>
          </Modal.Header>
          <Modal.Body>
            <Row className="d-flex flex-row justify-content-left">
              <Col md={6}>
                <FormInputField
                  id="bultos"
                  label=""
                  type="number"
                  min="1"
                  defaultValue={0}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </Col>
              <Col md={12} className="d-flex flex-row align-items-end justify-content-end">
                <div >
                  <Button disabled={false} className="d-flex py-2 m-1 my-3" variant="primary" onClick={() => this.onSaveSubmit()} >
                    OK
              </Button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        {renderAndPrint ?  <Navbar fixed="bottom"  className="p-3 d-flex justify-content-end fixed button">
          <Col md={{offset:4}}><Button  variant="primary" className="button" onClick = {() => {this.print()}}>imprimir</Button> 
          <Button  variant="dark" className="button ml-3"  onClick={() => window.close()}>Cancelar impresion</Button> </Col> 
          
          </Navbar> : ''}
        {renderAndPrint ? renderEtiquetas(razonSocial, calle, numero, localidad, provincia, celular, mailCliente, date, webUrl, tel, address) : ''}
      </Fragment>

    );
  }
}

export default EtiquetaEnviosImprimir;
