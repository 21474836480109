import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormSelectField, FormInputField, FormSwitch } from '../../components';

import APIClient from '../../services/APIClient';

class UnidadMedidaEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: null,
      estados: [
        { active: true, state: 'Activo' },
        { active: false, state: 'Inactivo' },
      ],
      clases: [],
      arrayvalidateindex: props.arrayvalidateindex,
      existsSuppliesWithThisUDM: [],
    };
    this.requiredFields = ['udm', 'description', 'classId'];
  }
componentDidMount = async () => {
  const clasesRes = await APIClient.get('/clases-unidades-medida/');
  const clases = clasesRes.data.data;
  this.setState({  clases });
  
};

  onRetrieveEntity = async () => {
    const { id } = this.state;

    const unidadesRes = await APIClient.get(`/unidades-medida/${id}`);
    const entity = unidadesRes.data.data;

    this.setState({ entity, });
    this.loadSuppliesWithThisUDM();
    return {entity,};
  };

  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;
    await this.formValidation(entityToSave);
    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/unidades-medida', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/unidades-medida/${id}`, entityToSave);
    }

    toastManager.add(
      `Unidad de medida #${saveResponse.data.data.id} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/unidades-medida'),
    );
    return saveResponse.data.data;
  };

  loadSuppliesWithThisUDM = async () => {
    const { id, } = this.state;
    let url = `/insumos?filter[unidad_medida_id][eq]=${id}`;
    const insumosWithThisUDMRes = await APIClient.get(url);
    const existsSuppliesWithThisUDM = insumosWithThisUDMRes.data.data.length > 0;
    this.setState({ existsSuppliesWithThisUDM,  });
  };

  formValidation(postedFields) {
    const postedFieldKeys = Object.keys(postedFields);
    const arrayvalidateindex = this.requiredFields.filter((requiredField) => postedFieldKeys.indexOf(requiredField) < 0);
    this.setState({
      arrayvalidateindex,
      postedFields,
    });
    if (arrayvalidateindex.length > 0) {
      throw { message: 'faltan campos' };
    }
  }

  render() {
    const { id, entity, isAdding, clases, arrayvalidateindex, existsSuppliesWithThisUDM } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Unidad de Medida nueva' : `Unidad de Medida #${id}`}</h1>

        <EntityEditForm onRetrieveEntity={this.onRetrieveEntity} onSaveEntity={this.onSaveEntity} addMode={isAdding}>
          <>
            <Row>
              <Col md={4}>
                <FormInputField
                  id="udm"
                  label="UDM *"
                  type="text"
                  defaultValue={entity && entity.udm}
                  maxLength={3}
                  arrayvalidateindex={arrayvalidateindex}
                />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="description"
                  label="Descripción *"
                  type="text"
                  defaultValue={entity && entity.description}
                  arrayvalidateindex={arrayvalidateindex}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormSelectField
                  id="classId"
                  label="Clase *"
                  defaultValue={entity ? entity.classId : ''}
                  choices={clases}
                  choiceIdField="id"
                  choiceLabelField="description"
                  arrayvalidateindex={arrayvalidateindex}
                />
              </Col>
              <Col md={6}>
                <FormSwitch id="active" label={'Estado'} checked={entity ? entity.active : true} labelField="active" />
                {existsSuppliesWithThisUDM ? <span className="bg-warning">Existen insumos con esta Unidad de Medida asignada</span> : ''}
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(UnidadMedidaEdit);
