import { createStore } from 'redux';
import rootReducer from '../reducers/rootReducer';

const initialState = {
  ingresoStore: [],
  permissionsUser: {
    isSuperAdmin: false,
    editPermission: false,
    deletePermission: false,
    isPermissionsLoading: true,
  },
};

export const store = createStore(rootReducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
