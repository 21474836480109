import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Alert, Card } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormSelectField } from '../../components';
import APIClient from '../../services/APIClient';

class ProductionParameterEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { id } = props.match.params;
    this.state = {
      isAdding: typeof id === 'undefined',
      productionParameterList: [],
      isDataLoading: true,
      typeDescription: [],
      locationsCopy: [],
      sectionsCopy: [],
      categoriesCopy: [],
      linesCopy: [],
      warehouseId: 0,
      categories: [],
      categoryId: [],
      warehouses: [],
      disableInputMotive: true,
      entity: {},
      locations: [],
      sections: [],
      motives: [],
      types: [],
      lines: [],
      id,
      typeId: [],
      motiveId: [],
      sectionId: null,
      locationId: null,
      lineId: [],
    };
  }

  onLoadForm = async () => {
    const typesRes = await APIClient.get('/stocklist-product-types');
    const linesRes = await APIClient.get('/production-parameters/items-filter?typeItems=line');
    const categoriesRes = await APIClient.get('/production-parameters/items-filter?typeItems=category');
    const motivesRes = await APIClient.get('/stocklist-movement-motives');

    const warehousesRes = await APIClient.get('/warehouses?filter[disabled][eq]=true');
    const sectionsRes = await APIClient.get('/sections?filter[disabled][eq]=true');
    const locationsRes = await APIClient.get('/locations?filter[disabled][eq]=true');

    this.setState({
      types: typesRes.data.data,
      lines: linesRes.data.data,
      categories: categoriesRes.data.data,
      warehouses: warehousesRes.data.data,
      sections: sectionsRes.data.data,
      locations: locationsRes.data.data,
      motives: motivesRes.data.data,
    });
  };

  onRetrieveEntity = async () => {
    const { id, sections, locations, lines, categories, motives } = this.state;
    const productionParametersRes = await APIClient.get(`/production-parameters/${id}`);
    const entity = productionParametersRes.data.data;

    const linesCopySelected = entity.productType?.description === 'comercializable' ? lines : [];
    const categoriesCopySelected = entity.productType?.description === 'insumo' ? categories : [];
    const isDataLoadingSelected =
      entity.productType?.description !== 'comercializable' || (entity.productType?.description !== 'comercializable' && true);

    this.setState({
      entity,
      warehouseId: entity.warehouseId,
      sectionsCopy: entity.warehouseId === 0 ? sections : sections.filter((section) => section.warehouseId === entity.warehouseId),
      locationsCopy: entity.sectionId === 0 ? locations : locations.filter((location) => location.sectionId === entity.sectionId),
      linesCopy: linesCopySelected,
      categoriesCopy: categoriesCopySelected,
      typeId: parseInt(entity.productTypeId),
      typeDescription: entity.productType?.description,
      isDataLoading: isDataLoadingSelected,
      motiveId: entity.motiveId,
      motiveDescription: motives.find((mot) => mot.id === parseInt(entity.motiveId)).description,
    });

    return entity;
  };

  handleChangeWarehouses = (e) => {
    const { value } = e.target;
    const { sections } = this.state;
    this.setState({
      warehouseId: parseInt(value),
      sectionsCopy: parseInt(value) === 0 ? sections : sections.filter((section) => section.warehouseId === parseInt(value)),
      locationsCopy: [],
    });
  };

  handleChangeSections = (e) => {
    const { toastManager } = this.props;
    const { value } = e.target;
    const { locations } = this.state;
    const locationsCopy = locations.filter((location) => location.sectionId === parseInt(value));
    if (locationsCopy.length === 0) {
      toastManager.add('Sector sin ubicaciones disponibles', {
        appearance: 'warning',
        autoDismiss: true,
      });
    }
    this.setState({
      sectionId: parseInt(value),
      locationsCopy: parseInt(value) === 0 ? locations : locationsCopy,
    });
  };

  handleChangeLine = async (e) => {
    const { value } = e.target;
    const { typeDescription, typeId } = this.state;
    const motives = await this.handleMotives(typeDescription, typeId, value);
    this.setState({
      disableInputMotive: false,
      lineId: parseInt(value),
      categoryId: [],
      motives,
    });
  };

  handleChangeCategory = async (e) => {
    const { value } = e.target;
    const { typeDescription, typeId } = this.state;
    const motives = await this.handleMotives(typeDescription, typeId, value);
    this.setState({
      disableInputMotive: false,
      categoryId: parseInt(value),
      lineId: [],
      motives,
    });
  };

  handleMotives = async (typeDescription, typeId, value) => {
    const queryParams = `descriptionType=${typeDescription}&productTypeId=${typeId}&itemId=${value}`;
    const motivesRes = await APIClient.get(`/production-parameters/motive-filter?${queryParams}`);
    return motivesRes.data.data;
  };

  handleChangeMotive = (e) => {
    const { value } = e.target;
    const { motives } = this.state;
    this.setState({
      motiveId: parseInt(value),
      motiveDescription: motives.find((mot) => mot.id === parseInt(value)).description,
    });
  };

  handleChangeLocation = (e) => {
    const { value } = e.target;
    this.setState({
      locationId: parseInt(value),
    });
  };

  handleChangeType = (e) => {
    const { value } = e.target;
    const { types, lines, categories } = this.state;
    const type = types.find((tipe) => tipe.id === Number(value));

    const linesCopySelected = type?.description === 'comercializable' ? lines : [];
    const categoriesCopySelected = type?.description === 'insumo' ? categories : [];
    const isDataLoadingSelected = type?.description === undefined || (type?.description.length === 0 && true);

    this.setState({
      disableInputMotive: true,
      linesCopy: linesCopySelected,
      categoriesCopy: categoriesCopySelected,
      typeId: parseInt(value),
      typeDescription: type?.description,
      isDataLoading: isDataLoadingSelected,
    });
  };

  prepareToSave = (entityToSave) => {
    const { typeId, motiveId, warehouseId, sectionId, locationId } = this.state;
    entityToSave.productTypeId = typeId;
    entityToSave.motiveId = motiveId;
    entityToSave.warehouseId = warehouseId;
    entityToSave.sectionId = sectionId;
    entityToSave.locationId = locationId;
    return entityToSave;
  };

  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, types, typeId, motiveId, warehouseId, sectionId, locationId, motiveDescription } = this.state;
    const { history, toastManager } = this.props;
    let saveResponse = null;
    const tipeSelected = types.find((elem) => elem.id === parseInt(typeId));
    if (typeId.length === 0 || motiveId.length === 0 || warehouseId === 0) {
      toastManager.add('Todos lo campos son obligatorios', {
        appearance: 'warning',
        autoDismiss: true,
      });
    } else if (
      (motiveDescription === 'ingreso por producción' && sectionId === null) ||
      (motiveDescription === 'ingreso por producción' && locationId === null)
    ) {
      toastManager.add('Defina la ubicacion final por favor', {
        appearance: 'warning',
        autoDismiss: true,
      });
    } else {
      if (isAdding) {
        if (tipeSelected?.description === 'comercializable' && entityToSave?.categoryId) {
          delete entityToSave.categoryId;
        } else if (tipeSelected?.description === 'insumo' && entityToSave.lineId) {
          delete entityToSave.lineId;
        }

        saveResponse = await APIClient.post('/production-parameters', entityToSave);
      } else {
        saveResponse = await APIClient.patch(`/production-parameters/${id}`, entityToSave);
      }
      toastManager.add(
        `Parametro de Producción ${saveResponse.data.data.id} guardado con éxito`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
        () => history.push('/production-parameters'),
      );
    }
  };
  render() {
    const {
      id,
      types,
      entity,
      motives,
      isAdding,
      linesCopy,
      warehouses,
      sectionsCopy,
      isDataLoading,
      locationsCopy,
      categoriesCopy,
      typeDescription,
      motiveDescription,
      disableInputMotive,
    } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Parámetro de Producción nuevo' : `Parámetro de Producción #${id}`}</h1>
        {isAdding === false ? (
          <Alert variant="warning">
            <Alert.Heading>¿ Estas seguro de querer editar este Parámetro de Producción ?</Alert.Heading>
            <p>Recordá que los parámetros de producción están involucrados con las ordenes de producción</p>
          </Alert>
        ) : (
          <></>
        )}

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          prepareToSave={this.prepareToSave}>
          <>
            <Card>
              <Card.Header>Criterio de selección</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="productTypeId"
                      label="Tipo"
                      choices={types}
                      choiceIdField="id"
                      choiceLabelField="description"
                      disabled={isAdding === false}
                      type="text"
                      onChange={this.handleChangeType}
                      defaultValue={entity && entity.productTypeId}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSelectField
                      id="lineId"
                      label="Linea"
                      choices={linesCopy}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                      onChange={this.handleChangeLine}
                      disabled={
                        typeDescription === 'insumo' ||
                        isDataLoading ||
                        typeDescription?.length === 0 ||
                        typeDescription === undefined ||
                        isAdding === false
                      }
                      type="text"
                      defaultValue={Object.keys(entity).length !== 0 && entity.lineId}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSelectField
                      id="categoryId"
                      label="Categoria"
                      choices={categoriesCopy}
                      onChange={this.handleChangeCategory}
                      choiceIdField="id"
                      choiceLabelField="description"
                      disabled={
                        typeDescription === 'comercializable' ||
                        isDataLoading ||
                        typeDescription?.length === 0 ||
                        typeDescription === undefined ||
                        isAdding === false
                      }
                      type="text"
                      defaultValue={Object.keys(entity).length !== 0 && entity.categoryId}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormSelectField
                      id="motiveId"
                      label="Motivo"
                      choices={isAdding === false ? [entity.motive] : motives}
                      disabled={isAdding === false || disableInputMotive === true}
                      onChange={this.handleChangeMotive}
                      choiceIdField="id"
                      choiceLabelField="description"
                      type="text"
                      defaultValue={Object.keys(entity).length !== 0 && entity.motiveId}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="my-4">
              <Card.Header>Ubicación</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="warehouseId"
                      label="Depósito"
                      choices={warehouses}
                      choiceIdField="id"
                      choiceLabelField="description"
                      type="text"
                      onChange={this.handleChangeWarehouses}
                      defaultValue={entity && entity.warehouseId}
                    />
                  </Col>
                  {motiveDescription === 'ingreso por producción' ? (
                    <>
                      <Col md={4}>
                        <FormSelectField
                          id="sectionId"
                          label="Sector"
                          choices={sectionsCopy}
                          disabled={sectionsCopy.length === 0}
                          choiceIdField="id"
                          choiceLabelField="description"
                          onChange={this.handleChangeSections}
                          type="text"
                          defaultValue={entity && entity.sectionId}
                        />
                      </Col>
                      <Col md={4}>
                        <FormSelectField
                          id="locationId"
                          label="Ubicación"
                          choices={locationsCopy}
                          disabled={locationsCopy.length === 0}
                          choiceIdField="id"
                          choiceLabelField="description"
                          onChange={this.handleChangeLocation}
                          type="text"
                          defaultValue={entity && entity.locationId}
                        />
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ProductionParameterEdit);
