import { ColumsInterface } from "./DeliveryReportInterface";

const columnsMonths:Array<ColumsInterface> = [
  {
    dataField: 'january',
    text: 'Enero',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.january} Un.`;
    },
  },
  {
    dataField: 'february',
    text: 'Febrero',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.february} Un.`;
    },
  },
  {
    dataField: 'march',
    text: 'Marzo',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.march} Un.`;
    },
  },
  {
    dataField: 'april',
    text: 'Abril',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.april} Un.`;
    },
  },
  {
    dataField: 'may',
    text: 'Mayo',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.may} Un.`;
    },
  },
  {
    dataField: 'june',
    text: 'Junio',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.june} Un.`;
    },
  },
  {
    dataField: 'july',
    text: 'Julio',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.july} Un.`;
    },
  },
  {
    dataField: 'august',
    text: 'Agosto',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.august} Un.`;
    },
  },
  {
    dataField: 'september',
    text: 'Septiembre',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.september} Un.`;
    },
  },
  {
    dataField: 'october',
    text: 'Octubre',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.october} Un.`;
    },
  },
  {
    dataField: 'november',
    text: 'Noviembre',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.november} Un.`;
    },
  },
  {
    dataField: 'december',
    text: 'Diciembre',
    sort: true,
    align: 'right',
    formatter: (cellContent: any, row: any) => {
      return `${row.december} Un.`;
    },
  },
];



export default columnsMonths
;
