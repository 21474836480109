import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row, Badge } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, FormSelectField, FormInputField, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import Utils from '../Utils';
import Subtable from './Subtable';
import { connect } from 'react-redux'
import moment from 'moment';
import LoadingFull from '../../components/LoadingFull';

class MonitorInventario extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      clientes: [],
      isDataLoading: true,
      totalSize: 0,
      // FILTROS
      stock: [],
      finalStock:[],
      estados: [],
      articulos: [],
      insumos: [],
      warehouses: [],
      sections: [],
      selectedArticuloIds: [],
      selectedInsumoIds: [],
      selectedWarehouseIds: [],
      selectedFifoDate: [],
      selectedCategoryIds: [],
      selectedLineIds: [],
      selectedSectionIds: [],
      filterStringArticulo: '',
      filterStringInsumo: '',
      filterStringEstado: '',
      filterStringWarehouse: '',
      filterStringFifoDate: '',
      filterStringCategory: '',
      filterStringLine: '',
      filterStringSection: '',
      showloadingFullModal: false,
      productTypes: [],
      categories: [],
      pageNumber: 1,
      warehouseExpanded: [],
      stockDaySelected: '30',
      stockDaysOptions: [{
        id: 1,
        description: "30"
      }, {
        id: 2,
        description: "60"
      }, {
        id: 3,
        description: "180"
      }, {
        id: 4,
        description: "360"
      }]
    };

    this.loadStock = this.loadStock.bind(this);
  }

  getPermissions() {
    const { isSuperAdmin, editPermission, deletePermission, isPermissionsLoading } = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }

  async componentDidMount() {
    await this.getFilterData();
    await this.loadStock();
  }

  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    const { filterStringArticulo, filterStringInsumo, filterStringWarehouse, filterStringFifoDate, filterStringCategory, filterStringSection, filterStringLine } = this.state;

    this.setState({
      isDataLoading: true
    })

    try {
      const { direction, field } = sorting;
      const filterQuery = `${filterStringFifoDate}&${filterStringArticulo}&${filterStringInsumo}&${filterStringWarehouse}&${filterStringCategory}&${filterStringSection}&${filterStringLine}`;
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || 'id'}&sortDir=${direction || 'asc'}&excludeAssocFields=imagenes`;
      const stockRes = await APIClient.get(`/monitor-inventario/stock-list?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}&${filterQuery}`);
      window.history.pushState({ page: pagination.page }, '', `?page=${pagination.page}`);
      const stock = await this.getStockDays(stockRes.data.data)

      this.setState({
        stock,
        totalSize: stockRes.data.meta.total,
        pageNumber: pagination.page,
      });

    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }

    this.setState({
      isDataLoading: false
    })
  };

  async loadStock() {
    const { toastManager } = this.props;
    const { pageNumber } = this.state;
    const offset = (pageNumber - 1) * 10;
    try {
      const stockRes = await APIClient.get(`/monitor-inventario/stock-list?limit=10&offset=${offset}&sortField=id&sortDir=asc`);

      const stock = await this.getStockDays(stockRes.data.data)
      this.setState({
        stock,
        isDataLoading: false,
        totalSize: stockRes.data.meta.total,
      });
      this.apiParams = 'sortField=id&sortDir=asc&excludeAssocFields=imagenes';
    } catch (err) {
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  }
  /// //// %%%%%%% FILTROS %%%%%%% ///////

  getFilterData = async () => {
    const { toastManager } = this.props;
    try {
      const productTypesRes = await APIClient.get('/stocklist-product-types');
      const articulosRes = await APIClient.get('/articulos');
      const insumosRes = await APIClient.get('/insumos');
      const warehousesRes = await APIClient.get('/warehouses?filter[disabled][eq]=0');
      const categoriesRes = (await APIClient.get('/categorias?sortField=id&sortDir=asc')).data.data

      const sectionsRes = new Set(warehousesRes.data.data.map(warehouse => warehouse.sections.filter(section => !section.disabled)).flat())
      const categories1Res = categoriesRes.filter(cat => cat.categoryLevelId === 1 && cat.active);
      const categories2Res = categoriesRes.filter(cat => cat.categoryLevelId === 2 && cat.active);
      const categories3Res = categoriesRes.filter(cat => cat.categoryLevelId === 3 && cat.active);


      const linesRes = new Set(articulosRes.data.data.filter(articulo => !articulo.linea.eliminadoFlag).map(articulo => JSON.stringify(articulo.linea)))

      const categories = {
        cat1: categories1Res,
        cat2: categories2Res,
        cat3: categories3Res,
      }

      this.setState({
        productTypes: productTypesRes.data.data,
        articulos: articulosRes.data.data,
        insumos: insumosRes.data.data,
        warehouses: warehousesRes.data.data,
        categories,
        sections: [...sectionsRes],
        lines: Array.from(linesRes).map(JSON.parse),
      });
    } catch (err) {
      console.error('Error al obtener la información de los filtros: ', err);
      toastManager.add(`No se pudo obtener la información de los filtros. ${err}`, {
        appearance: 'error',
      });
    }
  };

  createSelectAllButtons = (entityName) => (
    <p className="m-0">
      {entityName.substr(0, 1).toUpperCase()}
      {entityName.substr(1)}(
      <button
        id={`select-all-${entityName}`}
        type="submit"
        className="link-button text-primary"
        onClick={() => this.handleSelectAll(entityName)}>
        Limpiar filtro
      </button>
      )
    </p>
  );

  handleDataChange = (e) => {
    const proto = Object.getPrototypeOf(e);
    const isArray = proto.hasOwnProperty('map');
    const argument = !isArray ? e.target : this.parseDataFilterSelect(e);
    const { id, value, options, multiple } = argument;

    let filterName = '';
    let fieldName = '';
    switch (id) {
      case 'selectedArticuloIds':
        fieldName = 'articuloId';
        filterName = 'filterStringArticulo';
        break;
      case 'selectedInsumoIds':
        fieldName = 'insumoId';
        filterName = 'filterStringInsumo';
        break;
      case 'selectedWarehouseIds':
        fieldName = 'warehouseId';
        filterName = 'filterStringWarehouse';
        break;
      case 'selectedFifoDate':
        fieldName = 'fifoDate';
        filterName = 'filterStringFifoDate';
        break;
      case 'selectedCategoryIds':
        fieldName = 'categoryId';
        filterName = 'filterStringCategory';
        break;
      case 'selectedSectionIds':
        fieldName = 'sectionId';
        filterName = 'filterStringSection';
        break;
      case 'selectedLineIds':
        fieldName = 'lineId';
        filterName = 'filterStringLine';
        break;
      default:
        break;
    }

    if (multiple) {
      const values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);
      this.setState((prevState) => ({
        ...prevState,
        [id]: values,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', values),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [id]: value,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', [value]),
      }));
    }
  };

  parseDataFilterSelect = (array) => {
    const response = {};
    response.id =
      array[0].hasOwnProperty('competidorArticulo') ? 'selectedArticuloIds'
        : array[0].hasOwnProperty('categoryLevel1') ? 'selectedInsumoIds'
          : array[0].hasOwnProperty('locations') ? 'selectedSectionIds'
            : array[0].hasOwnProperty('categoryLevelId') ? 'selectedCategoryIds' : 'selectedLineIds';
    response.value = array[0].id;
    response.multiple = false;
    response.options = array;
    return response;
  };

  handleSelectAll = (entityName) => {
    this.setState((prevState) => {
      let {
        selectedArticuloIds,
        selectedInsumoIds,
        filterStringArticulo,
        filterStringInsumo,
        selectedWarehouseIds,
        filterStringWarehouse,
        selectedFifoDate,
        filterStringFifoDate,
        filterStringCategory,
        selectedCategoryId,
        filterStringSection,
        selectedSectionIds,
        filterStringLine,
        selectedLineIds
      } = prevState;
      // map ids from entities into "selected" variables
      switch (entityName) {
        case 'articulos':
          selectedArticuloIds = [];
          filterStringArticulo = '';
          break;
        case 'insumos':
          selectedInsumoIds = [];
          filterStringInsumo = '';
          break;
        case 'warehouses':
          selectedWarehouseIds = [];
          filterStringWarehouse = '';
          break;
        case 'N°FIFO':
          selectedFifoDate = [];
          filterStringFifoDate = '';
          break;
        case 'categories':
          selectedCategoryId = [];
          filterStringCategory = '';
          break;
        case 'sections':
          selectedSectionIds = [];
          filterStringSection = '';
          break;
        case 'lines':
          selectedLineIds = [];
          filterStringLine = '';
          break;
        default:
          break;
      }


      return {
        ...prevState,
        selectedArticuloIds,
        selectedInsumoIds,
        filterStringArticulo,
        filterStringInsumo,
        selectedWarehouseIds,
        filterStringWarehouse,
        selectedFifoDate,
        filterStringFifoDate,
        filterStringSection,
        filterStringCategory,
        filterStringLine,
        selectedCategoryId

      };
    });
  };

  renderSelectedArts = () => {
    const { selectedArticuloIds, articulos } = this.state;
    const selectedArticuloIdsToNumber = selectedArticuloIds.map((selArt) => parseInt(selArt));
    const selected = articulos.filter((art) => selectedArticuloIdsToNumber.includes(art.id));
    return selected.length > 0
      ? selected.map((art, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {art.descripcion}
        </Badge>
      ))
      : '';
  };

  renderSelectedIns = () => {
    const { selectedInsumoIds, insumos } = this.state;
    const selectedInsumoIdsToNumber = selectedInsumoIds.map((selArt) => parseInt(selArt));
    const selected = insumos.filter((art) => selectedInsumoIdsToNumber.includes(art.id));
    return selected.length > 0
      ? selected.map((art, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {art.description}
        </Badge>
      ))
      : '';
  };

  renderSelectedWarehouse = () => {
    const { selectedWarehouseIds, warehouses } = this.state;
    const selectedWarehouseIdsToNumber = selectedWarehouseIds.map((selArt) => parseInt(selArt));
    const selected = warehouses.filter((art) => selectedWarehouseIdsToNumber.includes(art.id));
    return selected.length > 0
      ? selected.map((art, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {art.description}
        </Badge>
      ))
      : '';
  };
  /// //////// %%%%%%%%%%% FIN_FILTROS %%%%%%%%%%%  ///////////

  /// ///////%%%%%%%%%% Export %%%%%%%%%%//////////

  getExportData = async () => {
    const { toastManager } = this.props;
    const { totalSize, filterStringArticulo, filterStringFifoDate, filterStringInsumo, filterStringWarehouse } = this.state;
    this.showloadingFullModal()
    try {
      const filterQuery = `${filterStringArticulo}${filterStringFifoDate}&${filterStringInsumo}&${filterStringWarehouse}`;
      const stockRes = await APIClient.get(`/monitor-inventario/exports-stock-list?limit=${totalSize}&offset=0&sortField=id&sortDir=asc&${filterQuery}`,);
      await this.getStockDays(stockRes.data.data);
      this.setState({showloadingFullModal: false})
      return stockRes.data.data
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
      return false;
    }
    
  };

  async getStockDays(stock) {
    const { stockDaySelected } = this.state
    const stockPromises = stock.map(async (product) => {
      
        const filterQuery = `productType=${product.product_type}&stockDaySelected=${stockDaySelected}&productsStock=${product.total}&productId=${product.product_type === "comercializable" ? product.articulo_id : product.insumo_id}`;
        const stockRes = await APIClient.get(`/monitor-inventario/stock-days?${filterQuery}`);
        product.stockDays = stockRes.data.data ? stockRes.data.data : 'SIN CONSUMO' 
      
      return product;
    });
    const finalStock = await Promise.all(stockPromises)
    return finalStock
  }

  /// ///////////%%%%%%%%%%%%%%%FIN Export%%%%%%%%%%%%%%%//////////////

    /* Modal loadingFull */
    showloadingFullModal = () => {
      this.setState({
        showloadingFullModal: true,
      });
    };
  
    loadingFullModal = () => {
      const { showloadingFullModal } = this.state;
      return <LoadingFull showModal={showloadingFullModal} title={'Preparando CSV...'}/>;
    };

  render() {
    const {
      isDataLoading,
      totalSize,
      stock,
      productTypes,
      categories,
      warehouseExpanded,
      selectedWarehouseIds,
      filterStringWarehouse,
      sections,
      articulos,
      insumos,
      lines,
      warehouses,
      selectedFifoDate,
      isPermissionsLoading,
      selectedInsumoIds,
      selectedArticuloIds,
      selectedLineIds,
      selectedCategoryIds,
      filterStringFifoDate,
      filterStringCategory,
      filterStringSection,
      filterStringLine,
      stockDaysOptions,
      stockDaySelected
    } = this.state;

    const articulosRef = React.createRef();
    const insumosRef = React.createRef();
    const categoryRef = React.createRef();
    const lineRef = React.createRef();
    const sectionRef = React.createRef();

    const columns = [
      {
        dataField: 'erp_code',
        text: 'ERP',
        hidden: false,
      },
      {
        dataField: 'description',
        text: 'Descripción',
        hidden: false,
      },
      {
        dataField: 'product_type_id',
        text: 'Tipo de producto',
        formatter: (cellContent, row) => <div>{productTypes.filter((pt) => pt.id === row.product_type_id)[0].description}</div>,
        hidden: false,
      },
      {
        dataField: 'disponible',
        text: 'Disponible',
        hidden: false,
      },
      {
        dataField: 'noFinalizado',
        text: 'No finalizado',
        hidden: false,
      },
      {
        dataField: 'reservado',
        text: 'Reservado',
        hidden: false,
      },
      {
        dataField: 'reproceso',
        text: 'Reproceso',
        hidden: false,
      },
      {
        dataField: 'total',
        text: 'Total',
        hidden: false,
      },
      {
        dataField: 'stockDays',
        text: 'Dias de stock',
        hidden: false,
      },
      {
        dataField: 'category1',
        text: 'Categoría Nivel 1',
        formatter: (cellContent, row) => {
          if (row.category1) {
            const category1ArrayFilter = categories.cat1.filter((cat) => cat.id === parseInt(row.category1));
            return <div>{category1ArrayFilter.length ? category1ArrayFilter[0].description : ''}</div>;
          } else {
            return '';
          }
        },
        hidden: false,
      },
      {
        dataField: 'category2',
        text: 'Categoría Nivel 2',
        formatter: (cellContent, row) => {
          if (row.category2) {
            const category2ArrayFilter = categories.cat2.filter((cat) => cat.id === parseInt(row.category2));
            return <div>{category2ArrayFilter.length ? category2ArrayFilter[0].description : ''}</div>;
          } else {
            return '';
          }
        },
      },
      {
        dataField: 'category3',
        text: 'Categoría Nivel 3',
        formatter: (cellContent, row) => {
          if (row.category3) {
            const category3ArrayFilter = categories.cat3.filter((cat) => cat.id === parseInt(row.category3));
            return <div>{category3ArrayFilter.length ? category3ArrayFilter[0].description : ''}</div>;
          } else {
            return '';
          }
        },
        hidden: false,
      },
    ];

    const expandRow = {
      showExpandColumn: true,
      expanded: warehouseExpanded,
      renderer: (row) => {
        const filterQuery = `${filterStringWarehouse}&${filterStringSection}&${filterStringFifoDate}`
        return (
          <Subtable
            id={row.articulo_id ? row.articulo_id : row.insumo_id}
            productType={row.product_type}
            row={row}
            filterQuery={filterQuery}
          />
        );
      },
      onExpand: async (row, isExpand, rowIndex, e) => {
      },
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return (
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          );
        }
        return (
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        );
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          );
        }
        return (
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        );
      },
    };
    return (
      <div>
          {this.loadingFullModal()}
        <h1 className="page-title">Monitor de Stock</h1>
        {isPermissionsLoading === false ? (
          <>
            <Card>
              <Card.Header>Filtros de búsqueda</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <p className="m-0">
                      Articulos
                      <button
                        id="clearInput-Articulos"
                        type="submit"
                        className="link-button text-primary"
                        onClick={() => {
                          articulosRef.current.clear();
                          this.setState({ selectedArticuloIds: [], filterStringArticulo: '' });
                        }}>
                        (Limpiar filtro)
                      </button>
                    </p>
                    <Typeahead
                      defaultSelected={[]}
                      labelKey={(option) => `${option ? option.erpCodigo : 'erp'} - ${option ? option.descripcion : 'descripcion'}`}
                      id="selectedArticuloIds"
                      label="Typeahead"
                      key={'articulos'}
                      disabled={(typeof selectedInsumoIds === 'number' || typeof selectedCategoryIds === 'number') || isDataLoading}
                      style={{ paddingTop: '0.25rem' }}
                      onChange={(selected) => {
                        if (selected.length) {
                          this.handleDataChange(selected);
                        }
                      }}
                      ref={articulosRef}
                      options={articulos}
                      placeholder="Ingrese erp"
                      filterBy={['descripcion', 'erpCodigo']}
                    />
                  </Col>
                  <Col md={4}>
                    <p className="m-0">
                      Insumos
                      <button
                        id="clearInput-Insumos"
                        type="submit"
                        className="link-button text-primary"
                        onClick={() => {
                          insumosRef.current.clear();
                          this.setState({ selectedInsumoIds: [], filterStringInsumo: '' });
                        }}>
                        (Limpiar filtro)
                      </button>
                    </p>
                    <Typeahead
                      defaultSelected={[]}
                      labelKey={(option) => `${option ? option.erpCode : 'erp'} - ${option ? option.description : 'descripcion'}`}
                      id="selectedInsumoIds"
                      label="Typeahead"
                      key={'insumos'}
                      disabled={(typeof selectedArticuloIds === 'number' || typeof selectedLineIds === 'number') || isDataLoading}
                      style={{ paddingTop: '0.25rem' }}
                      onChange={(selected) => {
                        if (selected.length) {
                          this.handleDataChange(selected);
                        }
                      }}
                      options={insumos}
                      ref={insumosRef}
                      placeholder="Ingrese erp"
                      filterBy={['description', 'erpCode']}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSelectField
                      id="selectedWarehouseIds"
                      disabled={isDataLoading}
                      label={this.createSelectAllButtons('warehouses')}
                      value={selectedWarehouseIds}
                      onChange={this.handleDataChange}
                      choices={warehouses}
                      choiceIdField="id"
                      choiceLabelField="description"
                      multiple
                    />
                    {this.renderSelectedWarehouse()}
                  </Col>
                  <Col md={4}>
                    <p className="m-0">
                      Lineas
                      <button
                        id="clearInput-Linea"
                        type="submit"
                        className="link-button text-primary"
                        onClick={() => {
                          lineRef.current.clear();
                          this.setState({ selectedLineIds: [], filterStringLine: '' });
                        }}>
                        (Limpiar filtro)
                      </button>
                    </p>
                    <Typeahead
                      defaultSelected={[]}
                      labelKey={(option) => `${option ? option.descripcion : ''} `}
                      id="selectedLineIds"
                      disabled={(typeof selectedInsumoIds === 'number' || typeof selectedCategoryIds === 'number') || isDataLoading}
                      label="Typeahead"
                      key={'lines'}
                      style={{ paddingTop: '0.25rem' }}
                      onChange={(selected) => {
                        if (selected.length) {
                          this.handleDataChange(selected);
                        }
                      }}
                      ref={lineRef}
                      options={lines}
                      placeholder="Ingrese linea"
                      filterBy={['descripcion']}
                    />
                  </Col>
                  <Col md={4}>
                    <p className="m-0">
                      Categorias
                      <button
                        id="clearInput-Categoria"
                        type="submit"
                        className="link-button text-primary"
                        onClick={() => {
                          categoryRef.current.clear();
                          this.setState({ selectedCategoryIds: [], filterStringCategory: '' });
                        }}>
                        (Limpiar filtro)
                      </button>
                    </p>
                    <Typeahead
                      defaultSelected={[]}
                      labelKey={(option) => `${option ? option.description : ''} `}
                      id="selectedCategoryIds"
                      disabled={(typeof selectedArticuloIds === 'number' || typeof selectedLineIds === 'number') || isDataLoading}
                      label="Typeahead"
                      key={'categories'}
                      style={{ paddingTop: '0.25rem' }}
                      onChange={(selected) => {
                        if (selected.length) {
                          this.handleDataChange(selected);
                        }
                      }}
                      ref={categoryRef}
                      options={categories.cat2}
                      placeholder="Ingrese categoria"
                      filterBy={['descripcion', 'erpCodigo']}
                    />
                  </Col>
                  <Col md={4}>
                    <p className="m-0">
                      Sectores
                      <button
                        id="clearInput-Sectores"
                        type="submit"
                        className="link-button text-primary"
                        onClick={() => {
                          sectionRef.current.clear();
                          this.setState({ selectedSectionIds: [], filterStringSection: '' });
                        }}>
                        (Limpiar filtro)
                      </button>
                    </p>
                    <Typeahead
                      defaultSelected={[]}
                      disabled={isDataLoading}
                      labelKey={(option) => option?.description}
                      id="selectedSectionIds"
                      label="Typeahead"
                      key={'sections'}
                      style={{ paddingTop: '0.25rem' }}
                      onChange={(selected) => {
                        if (selected.length) {
                          this.handleDataChange(selected);
                        }
                      }}
                      ref={sectionRef}
                      options={sections}
                      placeholder="Ingrese sector"
                      filterBy={['descripcion']}
                    />
                  </Col>

                  <Col md={4} className="mt-5">
                    <p className="m-0">
                      N°FIFO
                      <button
                        id="clearInput-Insumos"
                        type="submit"
                        className="link-button text-primary"
                        style={{ paddingTop: '0.25rem' }}

                        onClick={() => {
                          this.setState({ selectedFifoDate: [], filterStringFifoDate: '' });
                        }}>
                        (Limpiar filtro)
                      </button>
                    </p>
                    <FormInputField disabled={isDataLoading} id="selectedFifoDate" value={selectedFifoDate} onChange={this.handleDataChange} />
                  </Col>
                  <Col md={4} className="mt-5">
                    <p className="m-0">
                      Dias de stock
                    </p>
                    <FormSelectField
                      id="selectedWarehouseIds"
                      disabled={isDataLoading}
                      value={stockDaySelected}
                      defaultValue={stockDaysOptions[0]}
                      onChange={(e) => this.setState({ stockDaySelected: e.target.value })}
                      choices={stockDaysOptions}
                      choiceIdField="description"
                      choiceLabelField="description"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex flex-row justify-content-center">
                      <Button
                        disabled={isDataLoading}
                        className="d-flex py-2 m-1 my-3"
                        variant="primary"
                        onClick={() =>
                          this.onTableUpdate({
                            freeText: '',
                            pagination: { limit: 10, offset: 0 },
                            sorting: { field: 'id', direction: 'ASC' },
                          })
                        }>
                        {!isDataLoading ? (
                          <p className="m-0">Buscar</p>
                        ) : (
                          <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                        )}
                      </Button>
                    </div>
                  </Col>

                </Row>
              </Card.Body>
            </Card>
            <DataTable
              wrapperClasses={'table-responsive'}
              remote={{
                filter: true,
                pagination: true,
                sort: true,
                cellEdit: false,
              }}
              totalSize={totalSize}
              columns={columns}
              data={stock}
              onTableUpdate={this.onTableUpdate}
              isDataLoading={isDataLoading}
              keyField="erp_code"
              getExportData={this.getExportData}
              pageNumber={1}
              expandRow={expandRow}
            />
          </>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(MonitorInventario));

