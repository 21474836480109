import { useState } from "react"

interface HandlerInputChangeInterface {
    (event: { target: HTMLInputElement }):void;
}

interface ResetFormInterface {
    (initialFormState:object):void;
}
interface ResetInputInterface {
    (id:string):void;
}


export const useForm = (inputs: any) =>{
    const [inputsValue, setInputValue] = useState(inputs)
    
    const handlerInputChange:HandlerInputChangeInterface = (event: { target: HTMLInputElement; })=>{
        setInputValue({...inputsValue,[event.target.id]:event.target.value})
    }
    const handlerInputCheckChange:HandlerInputChangeInterface = (event: { target: HTMLInputElement; })=>{
        setInputValue({...inputsValue,[event.target.id]:event.target.checked})
    }
    const resetForm:ResetFormInterface = (initialFormState:object) => setInputValue(initialFormState);

    const resetInput:ResetInputInterface = (id:string) => setInputValue({...inputsValue,[id]:''});

    return {inputsValue,handlerInputChange,resetForm,resetInput,handlerInputCheckChange};
}

