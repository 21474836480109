import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormAddressInput, FormInputField } from '../../components';
import APIClient from '../../services/APIClient';

class ExpresoEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: null,
      provincias: [],
    };
  }

  onLoadForm = async () => {
    const provinciasRes = await APIClient.get('/provincias');
    this.setState({
      provincias: provinciasRes.data.data,
    });
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get Expreso
    const expresoRes = await APIClient.get(`/expresos/${id}`);
    const entity = expresoRes.data.data;

    this.setState({ entity });
    return entity;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/expresos', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/expresos/${id}`, entityToSave);
    }

    toastManager.add(
      `Expreso ${saveResponse.data.data.id} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/expresos'),
    );
    return saveResponse.data.data;
  };

  render() {
    const {
      id, entity, isAdding, provincias,
    } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Expreso nuevo' : `Expreso #${id}`}</h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
        >
          <>
            <Row>
              <Col md={8}>
                <FormInputField
                  id="descripcion"
                  label="Descripción"
                  type="text"
                  defaultValue={entity && entity.descripcion}
                  required
                />
              </Col>
            </Row>

            <FormAddressInput addMode={isAdding} entity={entity} provincias={provincias} />

            <Row>
              <Col md={6}>
                <FormInputField
                  id="telefono"
                  label="Teléfono"
                  type="text"
                  defaultValue={entity && entity.telefono}
                />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="email"
                  label="Email"
                  type="text"
                  defaultValue={entity && entity.email}
                />
              </Col>
            </Row>


          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ExpresoEdit);
