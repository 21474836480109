const config = {
  api: {
    url: process.env.REACT_APP_API_URL || 'http://localhost',
    publicPath: process.env.REACT_APP_PUBLIC_PATH || 'http://localhost/public/images/product',
    backPublicPath: process.env.REACT_APP_API_URL || 'http://localhost:3001/uploads',
  },
  front: {
    url: process.env.REACT_APP_FRONTEND_URL
  }
};

export default config;
