import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityEditForm, FormInputField, FormCheckField, DataTable } from '../../components';
import APIClient from '../../services/APIClient';

class OperationsEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { id } = props.match.params;
    this.state = {
      resourceList: [],
      entity: {},
      id,
      isAdding: typeof id === 'undefined',
      resource: [],
      isDataLoading: typeof id !== 'undefined',
      newDetalles: [],
      selectedDetalleRowDel: [],
      selectedDetalleRowDelId: [],
      selectedArticulos: [],
      showAddModal: false,
      showDeleteModal: false,
      updatedDetalles: [],
      operationUptakeDetails: [],
      isActive: [],
      isDeletedDetailId: [],
      entity: null,
    };
  }

  afterSaveNewDetalle = (row) => {
    this.onSaveNewDetalle(row);
  };

  afterSaveUpdatedDetalle = (oldValue, newValue, row) => {
    this.onSaveUpdatedDetalle(row);
  };

  //Modal de resources
  resourcesListModal = () => {
    const { resourceList } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
        editable: false,
      },
      {
        dataField: 'description',
        text: 'Descripcion',
        sort: true,
        editable: false,
      },
      {
        dataField: 'typeResource.description',
        text: 'Tipo',
        sort: true,
        editable: false,
      },
      {
        dataField: 'availability',
        text: 'Disponibilidad(Hs)',
        sort: true,
        editable: false,
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      // clickToSelect: true,
      hideSelectAll: true,
      onSelect: this.onSelectArticulo,
    };

    return (
      <Modal size="xl" show={this.state.showAddModal} onHide={this.handleAddModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Artículos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {resourceList !== undefined && resourceList !== {} && (
            <DataTable
              isDataLoading={this.state.isDataLoading}
              selectRow={selectRowProps}
              columns={columns}
              data={resourceList || []}
              keyField="id"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.saveNewDetalles}>
            Listo
          </Button>
          <Button variant="secondary" onClick={this.handleAddModalClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // Close modals Insumo
  handleAddModalClose = () => {
    this.setState({ showAddModal: false, newDetalles: [] });
  };

  //Modal Close Delete
  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false, selectedDetalleRowDel: [] });
  };

  prepareToSave = (entityToSave) => {
    const { updatedDetalles } = this.state;
    entityToSave.operationUptakeDetails = updatedDetalles;

    return entityToSave;
  };

  saveNewDetalles = () => {
    this.setState((prevState) => ({
      operationUptakeDetails: [...prevState.newDetalles, ...prevState.operationUptakeDetails],
      newDetalles: [],
      updatedDetalles: [...prevState.newDetalles, ...prevState.operationUptakeDetails],
      showAddModal: false,
    }));
  };

  // Modal de Recursos
  showAddDetalleModal = async () => {
    const resourceListRes = await APIClient.get(`/resources?filter[isActive][eq]=1`);
    const productionOperationsTypesRes = await APIClient.get('/production-resources-types');

    const resourceList = resourceListRes.data.data;
    resourceList.forEach((resource) => {
      resource.isSelected = false;
    });

    this.setState({
      productionOperationsTypes: productionOperationsTypesRes.data.data,
      showAddModal: true,
      isDataLoading: false,
      resourceList,
    });
  };

  showDeleteConfirmModal = (row) => {
    this.setState({
      selectedDetalleRowDel: row,
      showDeleteModal: true,
    });
  };

  // Delete resources modals
  deleteDetalleModal = () => (
    <Modal size="md" show={this.state.showDeleteModal} onHide={this.handleDeleteModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Detalle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar este detalle?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.onConfirmDelete}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={this.handleDeleteModalClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  onConfirmDelete = async () => {
    const { operationUptakeDetails, selectedDetalleRowDel } = this.state;

    if (selectedDetalleRowDel.addedRow === undefined) {
      this.setState((prevState) => {
        return {
          selectedDetalleRowDelId: [...prevState.selectedDetalleRowDelId, selectedDetalleRowDel.id],
        };
      });
    }
    const operationUptakeDetailsFilter = operationUptakeDetails.filter((elem) => elem.id !== selectedDetalleRowDel.id);
    this.setState({
      operationUptakeDetails: operationUptakeDetailsFilter,
      showDeleteModal: false,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;
    try {
      const operationRes = await APIClient.get(`/operations-uptakes/${id}`);
      const operation = operationRes.data.data;

      operation.operationUptakeDetails.forEach((detalle) => (detalle.isSelected = false));
      const operationUptakeDetails = [...operation.operationUptakeDetails];
      this.setState({
        operationUptakeDetails,
        entity: operation,
        isDataLoading: false,
      });
      return operation;
    } catch (error) {
      console.error(error);
      this.setState({ isDataLoading: false });
    }
  };

  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, selectedDetalleRowDelId } = this.state;
    const { history, toastManager } = this.props;
    let saveResponse = null;

    if (selectedDetalleRowDelId.length > 0) {
      for (let i = 0; i < selectedDetalleRowDelId.length; i++) {
        await APIClient.delete(`/operations-uptakes-details/${selectedDetalleRowDelId[i]}`);
      }
    }

    entityToSave.operationUptakeDetails.forEach((elem) => delete elem.resource);

    if (isAdding) {
      saveResponse = await APIClient.post('/operations-uptakes', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/operations-uptakes/${id}`, entityToSave);
    }

    toastManager.add(
      `Operacion ${saveResponse.data.data.id} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/operations-uptakes'),
    );
    return saveResponse.data.data;
  };

  onSaveNewDetalle = async (resource) => {
    const { newDetalles } = this.state;
    if (resource.isSelected === true) {
      this.setState((prevState) => {
        const newDetalles = [...prevState.newDetalles];
        const newDetalle = {
          resource,
          resourceId: resource.id,
          description: resource.description,
          units: 0,
          addedRow: true,
        };
        newDetalles.push(newDetalle);
        return { ...prevState, newDetalles };
      });
    } else if (resource.isSelected === false) {
      this.setState({
        newDetalles: newDetalles.filter((elem) => elem.resource.id !== resource.id),
      });
    }
  };

  onSaveUpdatedDetalle = async () => {
    const { operationUptakeDetails, id } = this.state;
    operationUptakeDetails.forEach((operationUptakeDetails) => (operationUptakeDetails.operationId = id));
    this.setState({ updatedDetalles: operationUptakeDetails });
  };

  onSelectArticulo = (row, isSelect) => {
    // toggle isSelected value from resource
    const resourceList = [...this.state.resourceList];
    const resource = resourceList.find((resource) => resource.id === row.id);
    resource.isSelected = isSelect;
    this.setState({ resourceList });
    this.afterSaveNewDetalle(row);
  };

  render() {
    const { id, operationUptakeDetails, entity, isAdding, isDataLoading } = this.state;

    const columns = [
      {
        dataField: 'resource.id',
        text: 'ID',
        sort: true,
        editable: false,
      },
      {
        dataField: 'resource.description',
        text: 'Recurso',
        editable: false,
      },
      {
        dataField: 'resource.typeResource.description',
        text: 'Tipo de recurso',
        editable: false,
      },
      {
        dataField: 'resource.availability',
        text: 'Disponibilidad',
        editable: false,
      },
      {
        dataField: 'units',
        text: 'Cantidad',
        editable: true,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        editable: false,

        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar className="justify-content-center">
            <ButtonGroup>
              <Button size="sm" variant="outline-primary" title="Eliminar" onClick={() => this.showDeleteConfirmModal(row)}>
                <FontAwesomeIcon icon={faTrashAlt} fixedWidth size="xs" />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    const selectDetalleRow = {
      align: 'center',
      clickToEdit: true,
      clickToSelect: false,
      hideSelectColumn: true,
      mode: 'checkbox',
    };

    const rowClasses = (row, rowIndex) => {
      if (row.addedRow) {
        return 'bg-success text-white font-weight-bold';
      }
      if (row.deletedRow) {
        return 'bg-warning text-white font-weight-bold';
      }
    };

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Operacion de Consumo nueva' : `Operacion de Consumo #${id}`}</h1>
        {this.resourcesListModal()}
        {this.deleteDetalleModal()}
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          prepareToSave={this.prepareToSave}>
          <>
            <Row>
              <Col md={4}>
                <FormInputField
                  id="description"
                  label="Descripción"
                  placeholder="Fabricacion..."
                  type="text"
                  maxLength={20}
                  defaultValue={entity && entity.description}
                  required
                />
              </Col>

              <Col md={4}>
                <FormInputField
                  id="availability"
                  label="Disponibilidad (Hs)"
                  type="Number"
                  min={0}
                  max={99999}
                  required
                  defaultValue={entity ? entity.availability : 0}
                />
              </Col>
              <Col md={4}>
                <FormInputField
                  id="capability"
                  label="Capacidad (un/dia)"
                  type="Number"
                  min={0}
                  max={99999}
                  required
                  defaultValue={entity ? entity.capability : 0}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormCheckField id="isActive" label="Activa" defaultChecked={entity ? entity.isActive : true} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DataTable
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                    afterSaveCell: this.afterSaveUpdatedDetalle,
                  })}
                  selectRow={selectDetalleRow}
                  columns={columns}
                  data={operationUptakeDetails || []}
                  keyField="resourceId"
                  isDataLoading={isDataLoading}
                  rowClasses={rowClasses}
                  addButton={this.showAddDetalleModal}
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(OperationsEdit);
