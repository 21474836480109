import React from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';
import { Row, Col, Card, Container, Image, Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck, faCircleNotch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { withToastManager } from 'react-toast-notifications';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { FormSelectField, DataTable, FormSwitch, Loading } from '../../components';
import config from '../../config';

import APIClient from '../../services/APIClient';

class ListaMaterialesEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: null,
      estados: [
        { active: true, state: 'Activo' },
        { active: false, state: 'Inactivo' },
      ],
      isActive: true,
      productTypes: [],
      productType: {},
      comercializables: [],
      semielaborados: [],
      articleSelected: [],
      isLoadingArticle: false,
      isArticleLoaded: false,
      isLoadingData: true,
      articleData: {},
      showAddModal: false,
      isDataOfModalTableLoading: false,
      insumosDisponiblesParaAgregar: [],
      componentesHijosIntermedios: [],
      isSaving: false,
      editingRowId: '',
      operationList: [],
      selectedList: [],
      showConfirmModal: false,
      articleDataDelete: [],
      buttonSubmitDisable: false,
    };
    this.articulosRef = React.createRef();
    this.operationRef = React.createRef();
  }

  async loadPrincipalData() {
    const { toastManager } = this.props;

    const productTypesRes = await APIClient.get('/stocklist-product-types');
    const comercializablesRes = await APIClient.get('/articulos');
    const operationRes = await APIClient.get('/operations-uptakes');
    const insumosRes = await APIClient.get('/insumos?filter[active][eq]=1');

    if (operationRes.data.data.length === 0) {
      toastManager.add(`No posee Operaciones de consumo`, {
        appearance: 'warning',
        autoDismiss: true,
      });
      this.setState({
        buttonSubmitDisable: true,
      });
    }

    const productTypes = productTypesRes.data.data.map((item) => {
      if (item.description.toUpperCase() === 'INSUMO') {
        item.description = 'semielaborado';
        item.deletedRow = false;
      }
      return item;
    });

    this.setState({
      productTypes: productTypes,
      comercializables: comercializablesRes.data.data,
      semielaborados: insumosRes.data.data,
      isLoadingData: false,
      operationList: operationRes.data.data,
    });
  }

  async loadListCreated() {
    const { id, comercializables, semielaborados, isAdding, productTypes } = this.state;

    if (!isAdding) {
      this.setState({ isLoadingArticle: true, isLoadingData: true });
      const listaMaterialesRes = await APIClient.get(`/lista-materiales/${id}`);
      const entity = listaMaterialesRes.data.data;
      if (entity.insumoId) {
        const productType = productTypes.find((item) => item.description === 'semielaborado') || {};
        const selected = semielaborados.filter((item) => item.id === entity.insumoId);
        this.setState({ productType });
        this.handleArticles(selected);
      } else {
        const selected = comercializables.filter((item) => item.id === entity.articuloId);
        const productType = productTypes.find((item) => item.description === 'comercializable');
        this.setState({ productType });
        this.handleArticles(selected);
      }
      this.setState({ entity, isActive: entity.isActive, isLoadingData: false });
      return { entity };
    }
  }

  async componentDidMount() {
    await this.loadPrincipalData();
    await this.loadListCreated();
  }

  onSaveEntity = async (event) => {
    const { id, isAdding, articleData, isActive, selectedList, articleSelected, operationList } = this.state;
    const { history, toastManager } = this.props;
    event.preventDefault();
    const entityToSave = {
      isActive,
    };

    this.setState({ isSaving: true });

    if (selectedList.length > 0) {
      for (let i = 0; i < selectedList.length; i++) {
        const insumo_child_id = selectedList[i].id;
        const insumo_articulo_father_id = articleSelected[0].id;
        const typeInsumo = `${articleSelected[0].type ? articleSelected[0].type.id : 0}`;
        const queryParams = `insumo_child_id=${insumo_child_id}&insumo_articulo_father_id=${insumo_articulo_father_id}&typeInsumo=${typeInsumo}`;
        await APIClient.delete(`/materials/child-delete?${queryParams}`);
      }
    }
    const childComponentsToAdd = articleData.componentesHijos.filter((child) => child.addedRow);

    this.setState({ isSaving: true });
    entityToSave.insumoId = articleData.isInsumo ? articleData.id : null;
    entityToSave.articuloId = articleData.isInsumo ? null : articleData.id;
    const childsToPost = childComponentsToAdd.map((child) => ({
      childId: child.id,
      isInsumoFather: articleData.isInsumo,
      unitsInChild: child.unitsInChild,
      operationId: child.operationId !== '' ? child.operationId : operationList[0].id,
    }));
    await APIClient.patch(`/materials/${articleData.id}`, childsToPost);
    entityToSave.articuloId = articleData.id;
    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/lista-materiales', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/lista-materiales/${id}`, entityToSave);
    }

    toastManager.add(
      `Lista de materiales #${saveResponse.data.data.id} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/materials-list'),
    );
    this.setState({ isSaving: false });
    return saveResponse.data.data;
  };
  formValidation(postedFields) {
    const postedFieldKeys = Object.keys(postedFields);
    const arrayvalidateindex = this.requiredFields.filter((requiredField) => postedFieldKeys.indexOf(requiredField) < 0);
    this.setState({
      arrayvalidateindex,
      postedFields,
    });
    if (arrayvalidateindex.length > 0) {
      throw { message: 'faltan campos' };
    }
  }

  getProductType() {
    const { productTypes, entity } = this.state;
    if (entity.insumoId) {
      const productType = productTypes.find((item) => item.description === 'semielaborado') || {};
      return productType ? productType.id : '';
    } else {
      const productType = productTypes.find((item) => item.description === 'comercializable');
      return productType ? productType.id : '';
    }
  }

  showDeleteConfirmModal = (row) => {
    this.setState((prevState) => {
      return {
        selectedList: [...prevState.selectedList, row],
        showConfirmModal: true,
        articleDataDelete: row,
      };
    });
  };

  closeDeleteConfirmModal = (row) => {
    this.setState((prevState) => {
      const beforeState = prevState.selectedList.filter((elem) => elem.id !== prevState.articleDataDelete.id);
      return {
        selectedList: beforeState,
        showConfirmModal: false,
        articleDataDelete: [],
      };
    });
  };

  onConfirmDelete = async () => {
    const { selectedList, articleData, articleDataDelete } = this.state;
    const { toastManager } = this.props;
    selectedList.forEach((selectedList) => (selectedList.deletedRow = true));

    if (!articleDataDelete.addedRow) {
      const articleDataDeleteState = articleData.componentesHijos.filter((elem) => elem.id !== articleDataDelete.id);
      articleData.componentesHijos = articleDataDeleteState;
      this.setState({
        articleDataDelete,
        articleData,
        showConfirmModal: false,
      });
      toastManager.add(`Las modificaciones se aplicaran al guardar`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      const articleDataDeleteState = articleData.componentesHijos.filter((elem) => elem.id !== articleDataDelete.id);
      articleData.componentesHijos = articleDataDeleteState;
      this.setState({
        articleData,
        showConfirmModal: false,
      });
      toastManager.add(`Las modificaciones se aplicaran al guardar`, {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  };
  renderDeleteListaMaetrialesModal = (row) => {
    const { showConfirmModal } = this.state;
    return (
      <Modal size="md" show={showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Insumo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar y deshacer este Insumo?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onConfirmDelete}>
            Eliminar
          </Button>
          <Button variant="secondary" onClick={this.closeDeleteConfirmModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  handleArticleType = (e, articulosRef) => {
    const { productTypes } = this.state;
    const { value } = e.target;
    const productType = productTypes.find((item) => item.id === Number(value)) || {};

    this.setState({
      productType,
      isArticleLoaded: false,
      articleData: {},
      articleSelected: [],
    });
    articulosRef.current.clear();
  };

  handleArticles = async (selected) => {
    const { productType } = this.state;
    const article = selected[0];
    this.setState({ isLoadingArticle: true });
    const articleData = {
      id: article.id,
      data: article,
      imagenes: article.imagenes,
      selectedImage: article.imagenes.length ? article.imagenes[0] : {},
      isInsumo: false,
    };

    if (productType.description === 'semielaborado') {
      articleData.udm = article.unidadMedida.description;
      articleData.isInsumo = true;
    }

    const queryParams = `isInsumo=${articleData.isInsumo}`;
    const componentesHijosRes = await APIClient.get(`/materials/child-component/${articleData.id}?${queryParams}`);
    articleData.componentesHijos = componentesHijosRes.data.data;

    articleData.componentesHijos.forEach((article) => {
      const fieldToSearch = articleData.isInsumo ? 'insumoFatherId' : 'articuloFatherId';
      const child = article.insumoFatherObjects.find((item) => item[fieldToSearch] === articleData.id);
      article.unitsInChild = child ? String(child.unitsInChild) : '';
      article.unitsInChildRow = '';
      article.operationId = child.operationId;
    });

    this.setState({
      articleSelected: selected,
      articleData,
      isLoadingArticle: false,
      isArticleLoaded: true,
    });
  };

  generateImagesElem = () => {
    const { articleData, productType } = this.state;
    const { imagenes, id } = articleData;
    return (
      <Row>
        {imagenes.map((img, i) => {
          return (
            <Col key={`img_${img.id}`} md={3}>
              <Container
                style={{ height: '5rem', width: '5rem' }}
                className="d-flex justify-content-center align-items-center img-thumbnail"
                onClick={() =>
                  this.setState((prevState) => ({ ...prevState, articleData: { ...prevState.articleData, selectedImage: img } }))
                }>
                <Image
                  className="mh-100"
                  id={`img_${i}`}
                  src={
                    productType.description === 'semielaborado'
                      ? `${config.api.backPublicPath}/uploads/insumos/images/${id}/${img.filename}`
                      : `${config.api.publicPath}/${id}/${img.filename}`
                  }
                  alt={`imagen_${i}`}
                  fluid
                />
              </Container>
            </Col>
          );
        })}
      </Row>
    );
  };

  showAddChildComponent = () => {
    const { semielaborados, articleData } = this.state;
    const insumosDisponiblesParaAgregar = semielaborados.filter(
      (semielaborado) => !articleData.componentesHijos.some((hijo) => hijo.id === semielaborado.id),
    );

    insumosDisponiblesParaAgregar.forEach((insumo) => {
      insumo.unitsInChildRow = '';
      insumo.unitsInChild = 0;
      insumo.operationDescription = '';
      insumo.operationId = '';
      insumo.deletedRow = false;
    });

    this.setState({ showAddModal: true, insumosDisponiblesParaAgregar });
  };

  updateInsumoChild = (oldValue, newValue, row) => {
    this.onUpdateInsumoChild(newValue, row);
  };

  onUpdateInsumoChild = async (newValue, articulo) => {
    const { articleData } = this.state;
    const componentesHijosCopy = [...articleData.componentesHijos];

    const child = componentesHijosCopy.find((item) => item.id === articulo.id);
    child.unitsInChild = newValue;
    child.addedRow = true;
    child.operationId = articulo.operationId;
    child.operationDescription = articulo.operationDescription;
    this.setState((prevState) => ({
      articleData: {
        ...prevState.articleData,
        componentesHijos: componentesHijosCopy,
      },
    }));
  };

  insumosListModal = () => {
    const { insumosDisponiblesParaAgregar } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'id',
        hidden: true,
      },
      {
        dataField: 'type.description',
        text: 'Tipo de Articulo',
        sort: true,
        editable: false,
      },
      {
        dataField: 'erpCode',
        text: 'Código ERP',
        sort: true,
        editable: false,
      },
      {
        dataField: 'description',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'active',
        text: 'Estado',
        sort: true,
        editable: false,
        formatter: (cellContent) => {
          const estado = cellContent ? 'Activo' : 'Inactivo';
          return estado;
        },
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: false,
      clickToSelect: true,
      hideSelectAll: true,
      onSelect: this.onSelectInsumo,
    };
    const rowClasses = (row, rowIndex) => {
      if (row.addedRow) {
        return 'bg-success text-white font-weight-bold';
      }
      if (row.deletedRow) {
        return 'bg-warning text-white font-weight-bold';
      }
    };

    return (
      <Modal size="xl" show={this.state.showAddModal} onHide={() => this.setState({ showAddModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Insumos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {insumosDisponiblesParaAgregar.length > 0 && (
            <DataTable
              isDataLoading={this.state.isDataOfModalTableLoading}
              selectRow={selectRowProps}
              columns={columns}
              data={insumosDisponiblesParaAgregar || []}
              rowClasses={rowClasses}
              keyField="id"
              showExport={false}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                autoSelectText: true,
              })}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showAddModal: false, componentesHijosIntermedios: [] })}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={this.addNewChildsComponents}>
            Agregar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  onSelectInsumo = (row, isSelect) => {
    const { componentesHijosIntermedios } = this.state;
    const copyOfComponentes = [...componentesHijosIntermedios];
    if (isSelect) {
      copyOfComponentes.push({ ...row, addedRow: true });
    } else {
      const indexOfElement = copyOfComponentes.findIndex((component) => component.id === row.id);
      copyOfComponentes.splice(indexOfElement, 1);
    }
    this.setState({ componentesHijosIntermedios: copyOfComponentes });
  };

  addNewChildsComponents = () => {
    this.setState((prevState) => ({
      articleData: {
        ...prevState.articleData,
        componentesHijos: [...prevState.articleData.componentesHijos, ...prevState.componentesHijosIntermedios],
      },
      showAddModal: false,
      componentesHijosIntermedios: [],
    }));
  };

  handleSelectMoment = (e, row) => {
    const { operationList, articleData } = this.state;
    const { value } = e.target;
    const { componentesHijos } = articleData;
    const operationID = value;
    const operationDescription = operationList.find((elem) => elem.id === Number(operationID));
    const select = componentesHijos.find((elem) => elem.id === row.id);
    select.addedRow = true;
    select.operationDescription = operationDescription.description;
    select.operationId = operationDescription.id;

    this.setState({ articleData });
  };

  render() {
    const {
      entity,
      isAdding,
      productTypes,
      productType,
      comercializables,
      semielaborados,
      isArticleLoaded,
      isLoadingArticle,
      articleData,
      isSaving,
      isActive,
      isLoadingData,
      operationList,
      buttonSubmitDisable,
    } = this.state;

    const selectDetalleRow = {
      align: 'center',
      clickToEdit: true,
      clickToSelect: false,
      hideSelectColumn: true,
      mode: 'checkbox',
    };

    const rowClasses = (row, rowIndex) => {
      if (row.addedRow) {
        return 'bg-success text-white font-weight-bold';
      }
      if (row.deletedRow) {
        return 'bg-warning text-white font-weight-bold';
      }
    };

    const columns = [
      {
        dataField: 'erpCode',
        text: 'Código Componente',
        sort: true,
        editable: false,
      },
      {
        dataField: 'description',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'unitsInChildRow',
        text: 'Cantidad',
        sort: true,
        editable: true,
        formatter: (cellContent, row) => {
          return row.unitsInChild;
        },
      },
      {
        dataField: 'operationDescription',
        text: 'Momento de consumo',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => (
          <>
            {operationList.length > 0 ? (
              <FormSelectField
                id="id"
                className={`${row.addedRow !== true ? 'cellSelect' : 'cellSelectNew'}`}
                choices={operationList}
                onChange={(event) => this.handleSelectMoment(event, row)}
                defaultValue={row.operationId !== '' ? row.operationId : operationList[0].id}
                choiceIdField="id"
                choiceLabelField="description"
              />
            ) : (
              <>
                <span>NO POSEE OPERACIONES DE CONSUMO</span>
              </>
            )}
          </>
        ),
      },

      {
        dataField: 'unidadMedida.description',
        text: 'UDM',
        sort: true,
        editable: false,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        editable: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              <LinkContainer to={`/supplies/${row.id}`}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </LinkContainer>
              <Button size="sm" variant="outline-primary" title="Eliminar" onClick={() => this.showDeleteConfirmModal(row)}>
                <FontAwesomeIcon icon={faTrashAlt} fixedWidth size="xs" />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">
          {isAdding
            ? 'Lista de Materiales nueva'
            : `Lista de Materiales  ${
                articleData.data
                  ? `${articleData.isInsumo ? articleData.data.description : articleData.data.descripcion}  ${
                      articleData.isInsumo ? articleData.data.erpCode : articleData.data.erpCodigo
                    }`
                  : ''
              }`}
        </h1>
        {this.insumosListModal()}
        {this.renderDeleteListaMaetrialesModal()}
        <>
          {isLoadingData ? (
            <Loading />
          ) : (
            <Row>
              <Col md={4}>
                <FormSelectField
                  id="articleType"
                  label={isAdding ? 'Seleccione un tipo de Articulo *' : null}
                  value={productType ? productType.id : ''}
                  choices={productTypes}
                  onChange={(event) => this.handleArticleType(event, this.articulosRef)}
                  choiceIdField="id"
                  choiceLabelField="description"
                  disabled={entity}
                />
              </Col>
              <Col md={4}>
                <Typeahead
                  defaultSelected={[]}
                  labelKey={(option) => {
                    const label =
                      productType.description === 'comercializable'
                        ? `${option ? option.erpCodigo : ''} - ${option ? option.descripcion : ''}`
                        : `${option ? option.erpCode : ''} - ${option ? option.description : ''}`;
                    return label;
                  }}
                  id={'articulos'}
                  label="Typeahead"
                  key={'articulos'}
                  ref={this.articulosRef}
                  style={isAdding ? { paddingTop: '1.80rem' } : { paddingTop: '1.3rem' }}
                  onChange={(selected) => {
                    if (selected.length) {
                      this.handleArticles(selected);
                    }
                  }}
                  //selected={articleSelected}
                  disabled={!productType.id || entity}
                  options={productType.description === 'comercializable' ? comercializables : semielaborados}
                  placeholder="Ingrese erp"
                  filterBy={productType.description === 'comercializable' ? ['descripcion', 'erpCodigo'] : ['description', 'erpCode']}
                />
              </Col>
            </Row>
          )}
          {isLoadingArticle ? <Loading /> : null}
          {isArticleLoaded ? (
            <>
              <Row>
                <Col md={6}>
                  <Card>
                    <Card.Header as="h5" style={{ textAlign: 'center' }}>
                      Información de la Lista
                    </Card.Header>
                    <Card.Body>
                      <FormSwitch
                        id="active"
                        label="Estado de la Lista *"
                        checked={isActive}
                        labelField="active"
                        onClick={() => this.setState((prevState) => ({ isActive: !prevState.isActive }))}
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <Card.Header as="h5" style={{ textAlign: 'center' }}>
                      Información del Articulo
                    </Card.Header>
                    <Card.Body style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Card className="mb-2" style={{ minHeight: '350px', width: '60%' }}>
                        <Card.Body>
                          <Card.Title as="h5">Referencias</Card.Title>
                          {articleData.selectedImage && articleData.imagenes.length > 0 && (
                            <Row>
                              <Col className="d-flex align-items-center" md={9}>
                                <Container className="mb-2 border border-primary rounded d-flex justify-content-center">
                                  <Image
                                    id="img_selected"
                                    src={
                                      productType.description === 'semielaborado'
                                        ? `${config.api.backPublicPath}/uploads/insumos/images/${articleData.id}/${articleData.selectedImage.filename}`
                                        : `${config.api.publicPath}/${articleData.id}/${articleData.selectedImage.filename}`
                                    }
                                    alt={`imagen_${articleData.selectedImage.posicion}`}
                                    rounded
                                    fluid
                                  />
                                </Container>
                              </Col>
                            </Row>
                          )}
                          {articleData.imagenes && this.generateImagesElem()}
                        </Card.Body>
                      </Card>
                      <div className="form-group" style={{ marginLeft: '2rem' }}>
                        <h5 className="form-label">Unidad de Medida:</h5>
                        <p>
                          {' '}
                          {articleData.udm ? articleData.udm : 'Este articulo no tiene ninguna unidad de medida o es un comercializable'}
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row style={{ paddingTop: '2rem' }}>
                <h5>Componentes hijos</h5>
                <DataTable
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                    afterSaveCell: this.updateInsumoChild,
                  })}
                  selectRow={selectDetalleRow}
                  columns={columns}
                  data={articleData.componentesHijos || []}
                  keyField="articuloId"
                  isDataLoading={isLoadingArticle}
                  rowClasses={rowClasses}
                  showExport={false}
                  addButton={buttonSubmitDisable === false ? this.showAddChildComponent : null}
                />
              </Row>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button
                  disabled={buttonSubmitDisable}
                  className="m-1"
                  variant="primary"
                  onClick={(event) => this.onSaveEntity(event)}
                  type="submit">
                  {!isSaving ? (
                    <FontAwesomeIcon icon={faCheck} fixedWidth className="mr-1" />
                  ) : (
                    <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="mr-1" />
                  )}
                  Guardar
                </Button>
              </Row>
            </>
          ) : null}
        </>
      </div>
    );
  }
}

export default withToastManager(ListaMaterialesEdit);
