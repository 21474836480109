import React, { Component, Fragment } from 'react';
import { Col, Row, } from 'react-bootstrap';
import APIClient from "../services/APIClient";
import moment from 'moment';
import './PedidoImprimir.css';
import Utils from './Utils';
class PedidoImprimir extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      cliente: {},
      isAdding: typeof id === "undefined",
      detalles: [],
      entity: {},
    };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    let { detalles } = this.state;
    try {
      const { entity, cliente, vendedor, listaPrecio, puntoDeEntrega } = await this.loadData(id);
      const sortDetalles = entity.detalles.sort((a, b) => {
        return Utils.pickNumForString(a.articulo.erpCodigo) - Utils.pickNumForString(b.articulo.erpCodigo)
      }).sort((a, b) => {
        const lineaA = a.articulo.linea ? a.articulo.linea.descripcion : '';
        const lineaB = b.articulo.linea ? b.articulo.linea.descripcion : '';
        return lineaA.localeCompare(lineaB);
      });
      detalles = await this.makeRowsFromDetalles(sortDetalles);
      this.setState({ entity, id, cliente, vendedor, listaPrecio, detalles, puntoDeEntrega });
      document.title = entity.codigo ? `Pedido - ${entity.codigo}` : `Pedido - backoffice`;
      return window.print();
      // return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  loadData = async (id) => {
    const entregaRes = await APIClient.get(`/solicitudes/${id}?populate=vendedor,listaPrecio,puntoEntrega.provincia,solicitudDetalle.articulo.linea`);
    const entity = entregaRes.data.data;
    const cliente = entity.cliente;
    const vendedor = entity.vendedor;
    const listaPrecio = entity.listaPrecio;
    const puntoDeEntrega = entity.puntoEntrega;
    return { entity, cliente, vendedor, listaPrecio, puntoDeEntrega };
  };

  makeRowsFromDetalles = (entity) => {
    return entity.map((art) => {
      return (
        <tr key={art.id}>
          <td>{art.articulo.erpCodigo}</td>
          <td>{art.solicitudDetalleTalle.cantidad}</td>
          <td>{art.solicitudDetalleTalle.entregado}</td>
        </tr>
      );
    });
  };
  makeColumn = (detalles, i, num) => {
    let column = [];
    for (let k = i * num; k < (i + 1) * num; k++) {
      column.push(detalles[k]);
    }
    return column;
  }
  renderTable = (detalles, maxRowsPerColumn, maxColumnsPerPage, offset) => {

    const detallesForActualPage = detalles.slice(offset, detalles.length);

    let howManyColumns =
      Math.ceil(detallesForActualPage.length / maxRowsPerColumn) <= maxColumnsPerPage ?
        Math.ceil(detallesForActualPage.length / maxRowsPerColumn) :
        maxColumnsPerPage;
    const arrayLength = new Array(howManyColumns).fill(0);

    return arrayLength.map((column, i) => {
      const columnData = this.makeColumn(detallesForActualPage, i, maxRowsPerColumn);
      return (
        <Fragment key={`renderTable${i}`}>
          <Col md={3} className="colRenderTable" key={i}>
            <table className="datos_6" border="0">
              < thead >
                <tr>
                  <th>Código</th>
                  <th>Cant.</th>
                  <th>Entreg.</th>
                </tr>
              </thead >
              < tbody >
                {columnData}
              </tbody >
            </table>
          </Col>
        </Fragment>
      )
    })
  };
  renderMoreTablePages = (detalles, entity) => {
    const pages = [];
    const additionalDetails = detalles.slice(132);

    const howManyPages = Math.ceil(additionalDetails.length / 132);

    for (let k = 0; k < howManyPages; k++) {
      const startIndex = k * 132;
      const slicedDetails = additionalDetails.slice(startIndex, startIndex + 132);

      pages.push(
        <div className='remito-container'>
        <Fragment key={`renderMoreTablePages${k}`}>
          <h4 className="mt-5 d-flex">
            Pedido - {entity.codigo === null ? `#${entity.id}` : `${entity.codigo}`} -  Cliente:  <span className="ml-1"><b>{entity.cliente.razonSocial}</b></span>
          </h4>
          <Row>
            <Col className="mt-0 pt-0" md={3}>
              Fecha de Confirmacion: {entity.fechaConfirmacion ? moment(entity.fechaConfirmacion).format('DD/MM/YYYY') : '-'}
            </Col>
          </Row>
          <Row className="rowMorePages">
            {this.renderTable(slicedDetails, 33, 4, 0)} 
          </Row>
          <footer>
            <div className="page-number d-flex justify-content-end mt-4">{k + 2}</div>
          </footer>
        </Fragment>
        </div>

      );
    }

    return pages;
  };

  render() {
    const { entity, id, cliente, detalles, } = this.state;
    return (
      <Fragment>
        <div className="remito-container">
          <div>
            <h4 className=" my-3 d-flex">
              Pedido - {entity.codigo === null ? `#${id}` : entity.codigo && `${entity.codigo}`} -  Cliente:  <span className="ml-1"><b>{cliente.razonSocial && cliente.razonSocial}</b></span>
            </h4>
            {/* <header>
            <h5 className=" my-3">
              {entity.estadoSolicitudCodigo && <div className=" d-flex">{UIUtils.getSolicitudEstadoBadge(entity.estadoSolicitudCodigo, true)}</div>}
            </h5>
          </header> */}
          </div >
          {/* pag-1 */}
          <Row>
            <Col className="mt-0  pt-0" md={3}>
              Fecha de Confirmacion: {entity.fechaConfirmacion ? moment(entity.fechaConfirmacion).format('DD/MM/YYYY') : '-'}
            </Col>
          </Row>
          <Row>
            {this.renderTable(detalles, 33, 4, 0)}
          </Row>
          <footer>
            <div className="page-number d-flex justify-content-end mt-5">1</div>
          </footer>
        </div >
        {/* pags-2-n */}
          {detalles.length > 132 ? this.renderMoreTablePages(detalles, entity) : null}
      </Fragment>
    );
  }
}

export default PedidoImprimir;
