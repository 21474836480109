import React from 'react';
import { Route } from 'react-router-dom';
import ProveedorList from './ProveedorList';
import ProveedorEdit from './ProveedorEdit';

const routes = () => [
  <Route path="/proveedores/new" exact component={ProveedorEdit} key="/proveedores/new" />,
  <Route path="/proveedores/:id" component={ProveedorEdit} key="/proveedores/:id" />,
  <Route path="/proveedores" exact component={ProveedorList} key="/proveedores" />,
].map(route => route);

export default routes;
