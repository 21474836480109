import React from 'react';
import { Route } from 'react-router-dom';
import StocklistList from './StocklistList';
import StocklistEdit from './StocklistEdit';
import StocklistIngreso from './StocklistIngreso';
import StocklistEgreso from './StocklistEgreso';
import InternalMovement from './InternalMovement/InternalMovement'

const routes = () => [
 <Route path="/stocklist/ingreso" exact component={StocklistIngreso} key="/stocklist/ingreso" />,
 <Route path="/stocklist/egreso" exact component={StocklistEgreso} key="/stocklist/egreso" />,
 <Route path="/stocklist/movimiento" exact component={InternalMovement} key="/stocklist/movimiento" />,
 <Route path="/stocklist/nuevo" exact component={StocklistEdit} key="/stocklist/nuevo" />,
  <Route path="/stocklist"  component={StocklistList} key="/stocklist" />,
].map(route => route);

export default routes;