import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { EntityEditForm, FormInputField, FormCheckField, DataTable } from '../../components';
import APIClient from '../../services/APIClient';

class ListaPrecioEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      articulosList: [],
      entity: {},
      id,
      isAdding: typeof id === 'undefined',
      detalles: [],
      deletedDetalles: [],
      isDataLoading: typeof id !== 'undefined',
      newDetalles: [],
      selectedDetalleRowDel: {},
      selectedArticulos: [],
      showAddModal: false,
      showDeleteModal: false,
      updatedDetalles: [],
      toUpdatePrice: false,
      updatedItemsId: [],
    };
  }

  afterSaveNewDetalle = (oldValue, newValue, row) => {
    // check if row belongs to articulo or ListaPrecioDetalle
    this.onSaveNewDetalle(row);
  };

  afterSaveUpdatedDetalle = (oldValue, newValue, row) => {
    this.onSaveUpdatePrice(oldValue, newValue, row);
    this.onSaveUpdatedDetalle(row);
  };

  articulosListModal = () => {
    // render articulos modal
    const { articulosList } = this.state;

    const columns = [
      {
        dataField: 'erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
        formatter: (cellContent, row) => (
          <div>
            {row.erpCodigo}
            <br />
            <small title="EAN">{row.codigoEan13}</small>
          </div>
        ),
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'marca.descripcion',
        text: 'Marca',
        sort: true,
        editable: false,
      },
      {
        dataField: 'linea.descripcion',
        text: 'Línea',
        sort: true,
        editable: false,
      },
      {
        dataField: 'coleccion.descripcion',
        text: 'Colección',
        sort: true,
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: true,
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      // clickToSelect: true,
      hideSelectAll: true,
      onSelect: this.onSelectArticulo,
    };

    return (
      <Modal size="xl" show={this.state.showAddModal} onHide={this.handleAddModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Artículos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {articulosList !== undefined && articulosList !== {} && (
            <DataTable
              isDataLoading={this.state.isDataLoading}
              selectRow={selectRowProps}
              columns={columns}
              data={articulosList || []}
              keyField="id"
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                autoSelectText: true,
                afterSaveCell: this.afterSaveNewDetalle,
              })}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.saveNewDetalles}>
            Listo
          </Button>
          <Button variant="secondary" onClick={this.handleAddModalClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  deleteDetalleModal = () => (
    <Modal size="md" show={this.state.showDeleteModal} onHide={this.handleDeleteModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Detalle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar este detalle?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.onConfirmDelete}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={this.handleDeleteModalClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  handleAddModalClose = () => {
    // close modal
    this.setState({ showAddModal: false, newDetalles: [] });
  };

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false });
  };

  prepareToSave = (entityToSave) => {
    const { updatedDetalles } = this.state;
    entityToSave.detalles = updatedDetalles;
    return entityToSave;
  };

  saveNewDetalles = () => {
    // copy detalles and newDetalles from state and merge them
    const { articulosList } = this.state;
    const { toastManager } = this.props;
    const articlesPriceless = [];
    articulosList.forEach((elem) => {
      if (elem.isSelected === true && elem.precio === '') {
        articlesPriceless.push(elem);
        toastManager.add(`${elem.erpCodigo} SIN PRECIO`, {
          appearance: 'warning',
          autoDismiss: true,
        });
      }
    });
    if (articlesPriceless.length === 0) {
      this.setState((prevState) => ({
        detalles: [...prevState.newDetalles, ...prevState.detalles],
        newDetalles: [],
        updatedDetalles: [...prevState.updatedDetalles, ...prevState.newDetalles],
        showAddModal: false,
      }));
    }
  };

  showAddDetalleModal = async () => {
    // get articulos and add precio and isSelected to all objects in array. then update state
    const detalleArticuloIds = this.state.detalles.map((detalle) => detalle.articuloId);
    const articulosListRes = await APIClient.get(`/articulos?filter[id][notIn]=${detalleArticuloIds}`);
    const articulosList = articulosListRes.data.data;
    articulosList.forEach((articulo) => {
      articulo.precio = '';
      articulo.isSelected = false;
    });
    this.setState({ showAddModal: true, isDataLoading: false, articulosList });
  };

  onClickingDelete = (row, rowIndex) => {
    this.setState({ showDeleteModal: true, selectedDetalleRowDel: row });
  };

  onClickingUndo = (row) => {
    row.deletedRow = false;
    this.setState((prevState) => {
      const { detalles, deletedDetalles } = prevState;
      const newDetalles = [...detalles];
      const newDeletedDetalles = [...deletedDetalles];
      const detalleIndex = newDeletedDetalles.findIndex((det) => det.id === row.id);
      newDeletedDetalles.splice(detalleIndex, 1);
      for (let i = 0; i < newDetalles.length; i++) {
        const curDetalle = newDetalles[i];
        if (row.articuloId === curDetalle.articuloId) {
          newDetalles.splice(i, 1);
        }
        if (row.id < curDetalle.id) {
          newDetalles.splice(i, 0, row);
          break;
        }
      }
      return { detalles: [...newDetalles], deletedDetalles: [...newDeletedDetalles] };
    });
  };

  onConfirmDelete = () => {
    // delete detalle from detalles list and add to deletedDetalles
    this.setState((prevState) => {
      const detalles = [...prevState.detalles];
      const deletedDetalles = [...prevState.deletedDetalles];
      const selectedDetalleRowDel = { ...prevState.selectedDetalleRowDel };
      const updatedDetalles = [...prevState.updatedDetalles];
      if (prevState.entity.detalles) {
        const isDeletedDetalleNew = prevState.entity.detalles.find((detalle) => detalle.id === selectedDetalleRowDel.id);
        if (isDeletedDetalleNew !== undefined) {
          selectedDetalleRowDel.deletedRow = true;
          deletedDetalles.push(selectedDetalleRowDel);
        }
      }
      const selectedIndex = detalles.findIndex((detalle) => detalle.id === selectedDetalleRowDel.id);
      detalles.splice(selectedIndex, 1);
      const updatedIndex = updatedDetalles.findIndex((detalle) => detalle.id === selectedDetalleRowDel.id);
      updatedDetalles.splice(updatedIndex, 1);
      return {
        detalles: [selectedDetalleRowDel, ...detalles],
        deletedDetalles,
        selectedDetalleRowDel: {},
        showDeleteModal: false,
        updatedDetalles,
      };
    });
  };

  onLoadForm = async () => {
    // get marcas
    // const marcasRes = await APIClient.get('/marcas');
    // this.setState({
    // });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;

    try {
      // get listas precio
      const listasPrecioRes = await APIClient.get(`/listas-precio/${id}`);
      const listaPrecio = listasPrecioRes.data.data;

      listaPrecio.detalles.forEach((detalle) => (detalle.isSelected = false));

      const detalles = [...listaPrecio.detalles];

      this.setState({
        detalles,
        entity: listaPrecio,
        isDataLoading: false,
      });
      return listaPrecio;
    } catch (error) {
      console.error(error);
      this.setState({ isDataLoading: false });
    }
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, deletedDetalles, toUpdatePrice, updatedItemsId } = this.state;
    const { history, toastManager } = this.props;
    // entityToSave.detalles = updatedDetalles;

    if (deletedDetalles.length > 0) {
      const deletedDetallesIds = deletedDetalles.map((detalle) => detalle.id);
      entityToSave.assocToDelete = { detalles: [...deletedDetallesIds] };
    }
    let saveResponse = null;
    if (isAdding) {
      entityToSave.detalles.forEach((detalle) => delete detalle.articulo);
      saveResponse = await APIClient.post('/listas-precio', entityToSave);
    } else {
      if (toUpdatePrice) {
        await APIClient.patch(`/solicitudes/check-updated-price/${id}`, { toUpdatePrice, updatedItemsId });
      }
      saveResponse = await APIClient.patch(`/listas-precio/${id}`, entityToSave);
    }

    history.push('/listas-precio');
    toastManager.add(`Lista de Precio ${saveResponse.data.data.id} guardada con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  onSaveNewDetalle = (articulo) => {
    const { id } = this.state;
    if (articulo.isSelected) {
      this.setState((prevState) => {
        const newDetalles = [...prevState.newDetalles];
        const newDetalle = {
          articulo,
          articuloId: articulo.id,
          precio: articulo.precio.replace(',', '.'),
          listaPrecioId: id,
          talleNombre: 'ST',
          addedRow: true,
        };
        newDetalles.push(newDetalle);
        return { ...prevState, newDetalles };
      });
    }
  };

  onSaveUpdatePrice = async (oldValue, newValue, row) => {
    const { updatedItemsId } = this.state;
    const updatedItemId = !updatedItemsId.includes(row.articuloId) && row.articuloId;

    if ((oldValue !== newValue) & !row.addedRow) {
      this.setState({ toUpdatePrice: true });
    }

    if (updatedItemId) this.setState({ updatedItemsId: [...updatedItemsId, row.articuloId] });
  };

  onSaveUpdatedDetalle = async (articulo) => {
    // search for existing detalles and save updates
    const { updatedDetalles } = this.state;
    const newUpdatedDetalles = [...updatedDetalles];
    const newDetalle = {};
    const existingDetalle = newUpdatedDetalles.find((detalle) => detalle.id === articulo.id);
    if (existingDetalle === undefined) {
      newDetalle.id = articulo.id;
      newDetalle.articuloId = articulo.articuloId;
      newDetalle.precio = articulo.precio;
      newUpdatedDetalles.push(newDetalle);
    } else {
      existingDetalle.precio = articulo.precio;
    }
    this.setState({ updatedDetalles: newUpdatedDetalles });
  };

  onSelectArticulo = (row, isSelect) => {
    // toggle isSelected value from articulo
    const articulosList = [...this.state.articulosList];
    const articulo = articulosList.find((articulo) => articulo.id === row.id);
    articulo.isSelected = isSelect;
    this.setState({ articulosList });
  };

  render() {
    const { id, detalles, entity, isAdding, isDataLoading } = this.state;

    const columns = [
      {
        dataField: 'articulo.erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
      },
      {
        dataField: 'articulo.descripcion',
        text: 'Descripción',
        sort: true,
        editable: false,
      },
      {
        dataField: 'talleNombre',
        text: 'Talle',
        sort: true,
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        align: 'right',
        headerAlign: 'right',
        editable: true,
        formatter: (cell, row, rowIndex) => cell.replace('.', ','),
      },
      {
        dataField: 'isSelected',
        text: '',
        sort: false,
        align: 'center',
        headerAlign: 'center',
        editable: false,
        formatter: (cell, row, rowIndex) => {
          if (row.deletedRow) {
            return <FontAwesomeIcon icon={faUndo} onClick={() => this.onClickingUndo(row)} fixedWidth size="xs" />;
          }
          return <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.onClickingDelete(row)} fixedWidth size="xs" />;
        },
      },
    ];

    const selectDetalleRow = {
      align: 'center',
      clickToEdit: true,
      clickToSelect: false,
      hideSelectColumn: true,
      mode: 'checkbox',
    };

    const rowClasses = (row, rowIndex) => {
      if (row.addedRow) {
        return 'bg-success text-white font-weight-bold';
      }
      if (row.deletedRow) {
        return 'bg-warning text-white font-weight-bold';
      }
    };

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Lista de Precio nueva' : `Lista de Precio #${id}`}</h1>
        {this.articulosListModal()}
        {this.deleteDetalleModal()}
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          prepareToSave={this.prepareToSave}>
          <>
            <Row>
              <Col md={6}>
                <FormInputField id="tipo" label="Tipo" type="text" defaultValue={entity.tipo} />
              </Col>
              <Col md={6}>
                <FormInputField id="codigo" label="Código" type="text" defaultValue={entity.codigo} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInputField id="descripcion" label="Descripcion" type="text" defaultValue={entity.descripcion} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormCheckField id="eliminadoFlag" label="Inactivo" defaultChecked={entity.eliminadoFlag} />
              </Col>
            </Row>
            <Row>
              <Col>
                <DataTable
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                    afterSaveCell: this.afterSaveUpdatedDetalle,
                  })}
                  selectRow={selectDetalleRow}
                  columns={columns}
                  data={detalles || []}
                  keyField="articuloId"
                  isDataLoading={isDataLoading}
                  rowClasses={rowClasses}
                  addButton={this.showAddDetalleModal}
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ListaPrecioEdit);
