import React from 'react';
import { Route } from 'react-router-dom';
import AutoModeloList from './AutoModeloList';
import AutoModeloEdit from './AutoModeloEdit';

const routes = () => [
  <Route path="/auto-modelos/nuevo" exact component={AutoModeloEdit} key="/auto-modelos/nuevo" />,
  <Route path="/auto-modelos/:id" exact component={AutoModeloEdit} key="/auto-modelos/:id" />,
  <Route path="/auto-modelos" exact component={AutoModeloList} key="/auto-modelos" />,
].map(route => route);

export default routes;
