import React from 'react';
import PropTypes from 'prop-types';
import { Accordion as AccordionBootstrap, Card, Row, Col } from 'react-bootstrap';

const Accordion = (props) => {


  
  const { header, body, eventKey, headerClass, disabled, } = props;

  const disabledUI = () => {
    return (
      <Row className="justify-content-md-center">
        <Col md={6}>
          Sector deshabilidado. Habilitar para ver posiciones
     </Col>
      </Row>

    )
  }

  return (
    <AccordionBootstrap>
      <Card>
        <AccordionBootstrap.Toggle as={Card.Header} className={headerClass} eventKey={eventKey} >
          {header}
        </AccordionBootstrap.Toggle>
        <AccordionBootstrap.Collapse eventKey={eventKey}>
          <Card.Body>{ disabled ? disabledUI() : body }</Card.Body>
        </AccordionBootstrap.Collapse>
      </Card>
    </AccordionBootstrap>
  );
};

// FormInputField.propTypes = {
//   id: PropTypes.string.isRequired,
//   label: PropTypes.string.isRequired,
// };

export default Accordion;



Accordion.propTypes = {
  header: PropTypes.object,
  body: PropTypes.object,
  data: PropTypes.object,
  eventKey: PropTypes.number,
  headerClass: PropTypes.string,
  disabled: PropTypes.bool,
};
Accordion.defaultProps = {
  header:{},
  body: {},
  data:{},
  eventKey: 0,
  headerClass: '',
  disabled: false,

};