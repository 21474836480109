import React from 'react';
import { Route } from 'react-router-dom';
import DeliveryParameterList from './DeliveryParameterList';
import DeliveryParameterEdit from './DeliveryParameterEdit';

const routes = () =>
  [
    <Route path="/delivery-parameters/new" component={DeliveryParameterEdit} key="/delivery-parameters/new" />,
    <Route path="/delivery-parameters/:id" component={DeliveryParameterEdit} key="/delivery-parameters/:id" />,
    <Route path="/delivery-parameters" exact component={DeliveryParameterList} key="/delivery-parameters" />,
  ].map((route) => route);

export default routes;
