import React from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';

import APIClient from '../../services/APIClient';

class Subtable extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
    filterQuery: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      rowId: props.id,
      productType: props.productType,
      row: props.row,
      ubications: [],
      isDataLoading: true,
      filterQuery: props.filterQuery
    };

    this.loadLineas = this.loadLineas.bind(this);
  }

  componentDidMount() {
    this.loadLineas();
  }

  loadLineas() {
    const { toastManager } = this.props;
    const { rowId, productType, filterQuery } = this.state;

    APIClient.get(`/monitor-inventario/${rowId}/${productType}?${filterQuery}`)
      .then((res) => {
        console.log('HOALLALA', res)
        this.setState({
          isDataLoading: false,
          ubications: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { isDataLoading, ubications } = this.state;
    return !isDataLoading ?
    
    ( <div >
    <table className="w-100">
      <thead className="font-weight-bold">
        <tr>
          <th>Depósito</th>
          <th>Sector</th>
          <th>Ubicación</th>
          <th>Unidades</th>
          <th>N°FIFO</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
     {  ubications.map((ubication, ind) => (
              ubication.details.map((det, index) => (
                <tr key={`${det.id}_${index}`}>
              <td>{ubication.warehouse.description}</td>
              <td>{ubication.section.description}</td>
              <td>{ubication.location.description}</td>
                  <td>{det.resta}</td>
                  <td>{det.fifoDate}</td>
                  <td>{det.productStatus.description}</td>
                </tr>
              ))))}
            </tbody>
          </table>
        </div>
      )
     : (
      <span>Cargando...</span>
    );
  }
}

export default withToastManager(Subtable);

Subtable.propTypes = {
  filterQuery: PropTypes.string,
};
Subtable.defaultProps = {
  filterQuery: '',
};