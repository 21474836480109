import { faTrashAlt, faEdit, faEnvelope, faFileInvoiceDollar, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Moment from 'react-moment';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import { connect } from 'react-redux'

class EntregaList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      entregas: [],
      isDataLoading: true,
      selectedEntrega: {},
      isSendingEmail: false,
      showConfirmModal: false,
      showSendMailModal: false,
    };

    this.loadEntregas = this.loadEntregas.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }
  

  componentDidMount() {
    this.loadEntregas();
  }

  getExportData = async () => {
    const { toastManager } = this.props;
    try {
      const apiResponse = await APIClient.get(`/entregas?${this.apiParams}`);
      return apiResponse.data.data;
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
      return false;
    }
  };

  onConfirmDelete = async () => {
    const { selectedEntrega } = this.state;
    const { toastManager } = this.props;

    try {
      await APIClient.delete(`/entregas/${selectedEntrega.id}`);
      this.setState((prevState) => {
        const entregas = [...prevState.entregas];
        const selectedIndex = entregas.findIndex((ent) => selectedEntrega.id === ent.id);
        entregas.splice(selectedIndex, 1);
        return { entregas, selectedEntrega: {}, showConfirmModal: false };
      });
      toastManager.add('Entrega eliminada con éxito', {
        appearance: 'success',
      });
    } catch (err) {
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
      this.setState({ selectedEntrega: {}, showConfirmModal: false });
    }
  };

  onConfirmSendMail = () => {
    const { selectedEntrega } = this.state;
    const { toastManager } = this.props;

    this.setState({ isSendingEmail: true });

    try {
      APIClient.post(`/entregas/${selectedEntrega.id}/enviar-facturacion`);
      toastManager.add('Email enviado con éxito', {
        appearance: 'success',
      });
    } catch (err) {
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isSendingEmail: false, selectedEntrega: {}, showSendMailModal: false });
    }
  };

  renderDeleteEntregaModal = () => {
    const { showConfirmModal } = this.state;
    const selectedDetalle = {};
    return (
      <Modal size="md" show={showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Entrega</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar y deshacer esta entrega?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onConfirmDelete}>
            Eliminar
          </Button>
          <Button variant="secondary" onClick={() => this.setState({ showConfirmModal: false, selectedDetalle })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    try {
      const { direction } = sorting;
      let { field } = sorting;
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || 'fecha'}&sortDir=${
        direction || 'desc'
      }&excludeAssocFields=detalles`;
      const entregasRes = await APIClient.get(`/entregas?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}`);
      const entregas = entregasRes.data.data;
      this.setState({
        entregas,
        totalSize: entregasRes.data.meta.total,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  renderSendMailModal = () => {
    const { showSendMailModal, isSendingEmail } = this.state;
    const selectedDetalle = {};
    return (
      <Modal size="md" show={showSendMailModal} onHide={() => this.setState({ showSendMailModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar datos por mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSendingEmail ? <p>Enviando mail...</p> : <p>¿Está seguro de que desea reenviar los datos de la entrega por mail?</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={isSendingEmail} onClick={this.onConfirmSendMail}>
            Confirmar
          </Button>
          <Button variant="secondary" onClick={() => this.setState({ showSendMailModal: false, selectedDetalle })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  showDeleteConfirmModal = (row) => {
    this.setState({
      selectedEntrega: row,
      showConfirmModal: true,
    });
  };

  showSendMailModal = (row) => {
    this.setState({
      selectedEntrega: row,
      showSendMailModal: true,
    });
  };
  async loadEntregas() {
    const { toastManager } = this.props;
    try {
      // get paginated list of entregas
      const entregasRes = await APIClient.get('/entregas?limit=10&offset=0&excludeAssocFields=detalles&sortField=fecha&sortDir=desc');
      this.setState({
        entregas: entregasRes.data.data,
        totalSize: entregasRes.data.meta.total,
      });
    } catch (error) {
      console.error('Cargando articulos.', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  render() {
    const { entregas, isDataLoading, totalSize, isSuperAdmin, editPermission, deletePermission, isPermissionsLoading } = this.state;

    const columns = [
      {
        dataField: 'id',
        sort: false,
        hidden: true,
      },
      {
        dataField: 'cliente.razonSocial',
        text: 'Razón Social',
        sort: true,
      },
      {
        dataField: 'fecha',
        text: 'Fecha',
        sort: true,
        formatter: (cellContent) => cellContent && <Moment format="YYYY-MM-DD HH:mm">{cellContent}</Moment>,
      },
      {
        dataField: 'fechaEntrega',
        text: 'Fecha de Entrega',
        sort: true,
        formatter: (cellContent) => cellContent && <Moment format="YYYY-MM-DD HH:mm">{cellContent}</Moment>,
      },
      {
        dataField: 'numeroExterno',
        text: 'Número Externo',
        sort: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
        },
      },
      {
        dataField: 'observaciones',
        text: 'Observaciones',
        sort: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
        },
      },
      {
        dataField: 'estadoEntrega',
        text: 'Estado',
        sort: true,
        csvFormatter: (cellContent) => {
          let nombre = '';
          switch (cellContent) {
            case 'EA':
              nombre = 'En Carga';
              break;
            case 'EC':
              nombre = 'Confirmada';
              break;
            default:
              break;
          }
          return nombre;
        },
        formatter: (cellContent, row) => UIUtils.getEntregaEstadoBadge(cellContent),
      },
      {
        dataField: 'expreso.descripcion',
        text: 'Expreso',
        sort: true,
        formatter: (cellContent) => {
          if (!cellContent) {
            const newText = '(Sin Expreso)';
            return newText;
          }
          return cellContent;
        },
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            const newText = '(Sin Expreso)';
            return newText;
          }
          return cellContent;
        },
      },
      {
        dataField: 'solicitud.codigo',
        text: 'Pedido',
        sort: false,
        hidden: false,
        formatter: (cellContent) => {
          return cellContent ? cellContent : '(Sin pedido asociado)';
        },
      },
      {
        dataField: 'isSelected',
        isDummyField: true,
        text: '',
        sort: false,
        align: 'center',
        headerAlign: 'center',
        csvExport: false,
        formatter: (cell, row) => (
          <span className="d-flex align-items-center justify-content-center">
            <ButtonToolbar>
              <ButtonGroup>
                <LinkContainer to={`/entregas/${row.id}`}>
                  {/* <LinkContainer to={row.estadoEntrega === 'EA' ? `/entregas/${row.id}/confirmar` : `/entregas/${row.id}`}> */}

                  {isSuperAdmin || editPermission ? (
                    <Button size="sm" variant="outline-primary" title="Editar">
                      <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                    </Button>
                  ) : (
                    <></>
                  )}
                </LinkContainer>
                <Button
                  size="sm"
                  disabled={row.estadoEntrega === 'EA'}
                  variant="outline-primary"
                  title={row.estadoEntrega === 'EA' ? 'No se puede facturar, aún está en carga.' : 'Enviar para facturación'}
                  onClick={() => this.showSendMailModal(row)}>
                  <FontAwesomeIcon icon={faEnvelope} fixedWidth size="xs" />
                </Button>
                <Button
                  disabled={row.estadoEntrega === 'EA'}
                  size="sm"
                  variant="outline-primary"
                  onClick={() => window.open(`/remitos/${row.id}`)}
                  title="Imprimir Remito">
                  <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth size="xs" />
                </Button>
                <Button
                  disabled={row.estadoEntrega !== 'EC'}
                  size="sm"
                  variant="outline-primary"
                  onClick={() => window.open(`/etiqueta-envios/${row.id}/imprimir`)}
                  title="Imprimir Etiqueta">
                  <FontAwesomeIcon icon={faTag} fixedWidth size="xs" />
                </Button>
                {isSuperAdmin || deletePermission ? (
                  <Button size="sm" variant="outline-primary" title="Eliminar" onClick={() => this.showDeleteConfirmModal(row)}>
                    <FontAwesomeIcon icon={faTrashAlt} fixedWidth size="xs" />
                  </Button>
                ) : (
                  <></>
                )}
              </ButtonGroup>
            </ButtonToolbar>
          </span>
        ),
      },
    ];

    return (
      <div>
        {this.renderDeleteEntregaModal()}
        {this.renderSendMailModal()}
        <h1 className="page-title">Entregas</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            columns={columns}
            isDataLoading={isDataLoading}
            wrapperClasses="table-responsive"
            data={entregas}
            keyField="id"
            defaultSorted={[{ dataField: 'fecha', order: 'desc' }]}
            remote={{
              filter: true,
              pagination: true,
              sort: true,
              cellEdit: false,
            }}
            totalSize={totalSize}
            onTableUpdate={this.onTableUpdate}
            getExportData={this.getExportData}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}
const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(EntregaList));

