import { faFileCsv, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import APIClient from '../services/APIClient';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { LinkContainer } from 'react-router-bootstrap';
import './DataTable.css';
import DataTableSearchField from './DataTableSearchField';
import Loading from './Loading';
import { saveAs } from 'file-saver';

const DataTableExportCSVButton = (props) => {
  const { getExportData, url } = props;
  const handleClick = async () => {
    if (url) {
      const response = await APIClient.get(url);
      const blobObject = new Blob([`${response.data}`], { type: 'text/csv; charset=utf-8' });
      const filename = response.headers['content-disposition'].split(';')[1].substr(10);
      saveAs(blobObject, filename);
    } else {
      if (getExportData) {
        const exportData = await getExportData();
        props.onExport(exportData);
      } else {
        props.onExport();
      }
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={handleClick}>
        <FontAwesomeIcon icon={faFileCsv} fixedWidth />
        Export to CSV
      </Button>
    </div>
  );
};

class CustomBootstrapTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortField: '',
      sortOrder: '',
      page: 1,
      sizePerPage: 10,
    };
  }

  /**
   * @param  {string} type - change type
   * @param  {number} page - page number
   * @param  {number} sizePerPage - current page size
   * @param  {string} searchText - current text in search bar
   * @param  {string} sortField - current field to sort by
   * @param  {string} sortOrder - current order to sort by
   * receives table changes, generates parameters for query, saves changes to state and calls onTableUpdate function.
   */
  handleTableChange = async (type, { sortField, sortOrder, page, sizePerPage, searchText }) => {
    const { sortField: stateSortField, sortOrder: stateSortOrder } = this.state;
    const { onTableUpdate } = this.props;
    const queryParameters = {};
    const sorting = {};
    queryParameters.pagination = {
      limit: sizePerPage,
      offset: type === 'search' ? '0' : (page - 1) * sizePerPage,
    };
    if (searchText && searchText.length > 0) {
      queryParameters.freeText = searchText;
    } else {
      queryParameters.freeText = '';
    }
    if (sortField && sortField.length > 0 && sortOrder && sortOrder.length > 0) {
      sorting.field = sortField;
      sorting.direction = sortOrder.toUpperCase();
    }
    queryParameters.sorting = sorting;

    this.setState({
      page: type === 'search' ? 1 : page,
      sizePerPage,
      sortField: !sortField ? stateSortField : sortField,
      sortOrder: !sortOrder ? stateSortOrder : sortOrder,
    });
    await onTableUpdate(queryParameters);
  };

  render() {
    const {
      addButtonIconDisabled,
      addButtonIconHidden,
      addButton,
      legendButton,
      buttonText,
      defaultSorted,
      wrapperClasses,
      onTableRef,
      isDataLoading,
      showSearch,
      showExport,
      propsTP,
      paginationProps,
      paginationTableProps,
      ...otherProps
    } = this.props;

    const { page, sizePerPage } = this.state;
    if (paginationProps && otherProps.remote) {
      paginationProps.page = page;
      paginationProps.sizePerPage = sizePerPage;
    }

    return (
      <Card style={{ width: '100%' }}>
        <Card.Header>
          <Row>
            <Col md={6}>
              {
              addButton && typeof addButton === 'string' ? 
              (
                <LinkContainer to={addButton}>
                  <Button title={legendButton} variant="primary" size="sm" disabled={ addButtonIconDisabled }>
                  { !addButtonIconHidden  && <FontAwesomeIcon icon={faPlus} fixedWidth />}
                    { buttonText }
                  </Button>
                </LinkContainer>
              ) : (
                addButton && (
                  <Button title={legendButton} variant="primary" onClick={addButton} size="sm" disabled={ addButtonIconDisabled }>
                    { !addButtonIconHidden  && <FontAwesomeIcon icon={faPlus} fixedWidth />}
                    { buttonText }
                  </Button>
                )
              )}
            </Col>
            {!showSearch ? null : (
              <Col md={6}>
                <DataTableSearchField {...propsTP.searchProps} />
              </Col>
            )}
          </Row>
        </Card.Header>

        <BootstrapTable
          ref={(n) => onTableRef(n)}
          bootstrap4
          striped
          hover
          condensed
          responsive
          remote={otherProps.remote ? { ...otherProps.remote } : false}
          defaultSorted={defaultSorted}
          wrapperClasses={wrapperClasses}
          onTableChange={this.handleTableChange}
          selectRow={otherProps.selectRow}
          {...paginationTableProps}
          noDataIndication={() => {
            if (isDataLoading) {
              return <Loading />;
            }
            return <p>No hay información</p>;
          }}
          {...propsTP.baseProps}
          {...otherProps}
        />

        <Card.Footer>
          {otherProps.total && otherProps.total > 0 ? (
            <Row className="mb-2">
              <Col md={{ span: 4, offset: 7 }} className="text-right">
                Total:{' '}
              </Col>
              <Col>{otherProps.total.toFixed(2)}</Col>
            </Row>
          ) : null}
          <Row>
            <Col md={6}>
              {paginationProps && (
                <>
                  <SizePerPageDropdownStandalone {...paginationProps} />
                  <PaginationListStandalone {...paginationProps} />
                </>
              )}
            </Col>
            {showExport ? (
              <Col md={6} className="text-right">
                <DataTableExportCSVButton getExportData={otherProps.getExportData} url={otherProps.exportURL} {...propsTP.csvProps} />
              </Col>
            ) : null}
          </Row>
        </Card.Footer>
      </Card>
    );
  }
}

const DataTable = (props) => {
  const {
    addButtonIconDisabled,
    addButtonIconHidden,
    cellEdit,
    addButton,
    legendButton,
    buttonText,
    keyField,
    data: tableData,
    selectRow,
    columns,
    defaultSorted,
    wrapperClasses,
    isDataLoading,
    showSearch,
    showExport,
    enablePagination,
    ...otherProps
  } = props;

  let data = [];
  if (tableData && tableData.length > 0) {
    data = tableData;
  }

  const paginationOptions = {
    custom: true,
    totalSize: otherProps.totalSize ? otherProps.totalSize : data.length,
  };

  const curViewURLArr = window.location.href.split('/');
  let curViewName = `${curViewURLArr[curViewURLArr.length - 1]}`;
  if (curViewURLArr.length > 4) {
    curViewName = `${curViewURLArr[curViewURLArr.length - 2]}_${curViewURLArr[curViewURLArr.length - 1]}`;
  }

  const exportCSV = { fileName: `listado_${curViewName}_${moment().format('YYYYMMDDHHmmss')}.csv` };

  if (!enablePagination) {
    return (
      <ToolkitProvider bootstrap4  keyField={keyField} data={data} columns={columns} exportCSV={exportCSV} search>
        {(propsTP) => (
          <CustomBootstrapTable
            cellEdit={cellEdit}
            addButton={addButton}
            legendButton={legendButton}
            addButtonIconHidden={addButtonIconHidden}
            addButtonIconDisabled= {addButtonIconDisabled}
            buttonText={buttonText}
            wrapperClasses={wrapperClasses}
            defaultSorted={defaultSorted}
            isDataLoading={isDataLoading}
            propsTP={propsTP}
            showSearch={showSearch}
            showExport={showExport}
            {...otherProps}
          />
        )}
      </ToolkitProvider>
    );
  }
  return (
    <ToolkitProvider
      bootstrap4
      keyField={keyField}
      data={data}
      columns={columns}
      exportCSV={Object.keys(exportCSV).length > 0 ? exportCSV : null}
      search>
      {(propsTP) => (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <CustomBootstrapTable
              cellEdit={cellEdit}
              addButton={addButton}
              legendButton={legendButton}
              addButtonIconHidden={addButtonIconHidden}
              addButtonIconDisabled={addButtonIconDisabled}
              buttonText={buttonText}
              defaultSorted={defaultSorted}
              wrapperClasses={wrapperClasses}
              selectRow={selectRow}
              paginationProps={paginationProps}
              paginationTableProps={paginationTableProps}
              isDataLoading={isDataLoading}
              propsTP={propsTP}
              showExport={showExport}
              showSearch={showSearch}
              {...otherProps}
            />
          )}
        </PaginationProvider>
      )}
    </ToolkitProvider>
  );
};

DataTable.propTypes = {
  keyField: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  wrapperClasses: PropTypes.string,
  defaultSorted: PropTypes.arrayOf(PropTypes.object),
  addButton: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  legendButton: PropTypes.string,
  addButtonIconHidden: PropTypes.bool,
  addButtonIconDisabled: PropTypes.bool,
  buttonText: PropTypes.string,
  isDataLoading: PropTypes.bool.isRequired,
  showExport: PropTypes.bool,
  showSearch: PropTypes.bool,
  selectRow:PropTypes.object,
  cellEdit:PropTypes.func,
  enablePagination: PropTypes.bool,
  rowClasses:PropTypes.func,
};
DataTable.defaultProps = {
  defaultSorted: [],
  wrapperClasses: 'wrapperClasses',
  addButton: null,
  legendButton:undefined,
  addButtonIconHidden: false,
  addButtonIconDisabled: false,
  buttonText: 'Agregar',
  showExport: true,
  showSearch: true,
  enablePagination: true,
};

CustomBootstrapTable.propTypes = {
  propsTP: PropTypes.object.isRequired,
  paginationProps: PropTypes.object,
  paginationTableProps: PropTypes.object,
  addButton: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  legendButton:PropTypes.string,
  addButtonIconHidden: PropTypes.bool,
  addButtonIconDisabled: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  defaultSorted: PropTypes.arrayOf(PropTypes.object),
  wrapperClasses: PropTypes.string,
  isDataLoading: PropTypes.bool.isRequired,
  // data: PropTypes.arrayOf(PropTypes.object).isRequired,
  showExport: PropTypes.bool,
  showSearch: PropTypes.bool,
  onTableRef: PropTypes.func,
  onTableUpdate: PropTypes.func,
};

CustomBootstrapTable.defaultProps = {
  defaultSorted: [],
  wrapperClasses: 'table-responsive',
  addButton: null,
  legendButton:undefined,
  addButtonIconHidden: true,
  addButtonIconDisabled : false,
  showExport: true,
  showSearch: true,
  onTableRef: () => {},
  onTableUpdate: () => {},
  paginationTableProps: {},
  paginationProps: null,
};

export default DataTable;
