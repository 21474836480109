import React, { Component } from 'react';
import moment from 'moment';
import APIClient from '../services/APIClient';
import './Remito.css';

class EntregaRemito extends Component {
  // static propTypes = {
  //   toastManager: PropTypes.object.isRequired,
  // };

  constructor(props) {
    super(props);
    this.state = {
      detalles: [],
      entity: {},
    };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    try {
      const entregaRes = await APIClient.get(`/entregas/${id}/remito`);
      const entity = entregaRes.data.data;
      const detalles = [];
      // calculate totals for each linea
      for (let i = 0; i < entity.remito.length; i++) {
        const curRemitoDetalle = entity.remito[i];
        if (detalles.length === 0) {
          curRemitoDetalle.precio *= curRemitoDetalle.cantidad;
          detalles.push(curRemitoDetalle);
        } else {
          const existingDetalle = detalles.find(det => curRemitoDetalle.lineaId === det.lineaId);
          if (existingDetalle === undefined) {
            curRemitoDetalle.precio *= curRemitoDetalle.cantidad;
            detalles.push(curRemitoDetalle);
          } else {
            existingDetalle.precio += (curRemitoDetalle.precio * curRemitoDetalle.cantidad);
            existingDetalle.cantidad += curRemitoDetalle.cantidad;
          }
        }
      }
      this.setState({ detalles, entity });
      return window.print();
      // return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  render() {
    const { detalles, entity } = this.state;
    // const total = 0;
    let cantTotal = 0;
    const detallesElems = detalles.map((ent) => {
      // const precio = parseFloat(ent.precio) * ent.cantidad;
      // total += precio;
      cantTotal += ent.cantidad;
      return (
        <tr key={ent.id}>
          <td>{`${ent.lineaDescripcion}`}</td>
          <td className="text-right">{ent.cantidad}</td>
        </tr>
      );
    });
    const totalRow = (
      <tr>
        <td className="font-weight-bold border-top border-dark pt-3">Total</td>
        <td className="text-right border-top border-dark pt-3">{cantTotal}</td>
      </tr>
    );
    detallesElems.push(totalRow);
    return (
      <div className="remito-container">
        <div
          style={{ margin: '3% 0px', width: '80%' }}
          className="cabecera-fecha d-flex flex-row justify-content-around"
        >
          <div />
          <div>
            <p>{entity.cliente && moment(entity.fechaEntrega).format('DD/MM/YYYY')}</p>
          </div>
        </div>
        <div className="datos-cabecera">
          <div>
            <p className="d-inline-block font-weight-bold m-0 mr-2">Razón Social:</p>
            <p className="d-inline-block m-0">{entity.cliente && entity.cliente.razonSocial}</p>

          </div>
          <div>
            <p className="d-inline-block font-weight-bold m-0 mr-2">CUIT: </p>
            <p className="d-inline-block m-0">
              {entity.cliente && entity.cliente.documentoNumero}
            </p>
          </div>
          <br />
          <div>
            <p className="d-inline-block font-weight-bold m-0 mr-2">Punto de Entrega: </p>
            {entity.puntoEntrega && `${entity.puntoEntrega.descripcion} - ${entity.puntoEntrega.calle} ${entity.puntoEntrega.numero} ${!entity.puntoEntrega.piso && ''}${!entity.puntoEntrega.depto && ''} ${entity.puntoEntrega.codigoPostal === null ? '' : entity.puntoEntrega.codigoPostal}, ${entity.puntoEntrega.localidad}, ${entity.puntoEntrega.provincia && entity.puntoEntrega.provincia.descripcion}`}
          </div>
          <br />
          <div>
            <p className="d-inline-block font-weight-bold m-0 mr-2">Expreso:</p>
            <p className="d-inline-block m-0">
              {entity.expreso ? `${entity.expreso.descripcion} - ${entity.expreso.calle} ${entity.expreso.numero} ${!entity.expreso.piso && ''}${!entity.expreso.depto && ''} ${entity.expreso.codigoPostal}, ${entity.expreso.localidad}` : 'Retira en fábrica' }
            </p>
          </div>
        </div>
        <table className="remito" border="0">
          <thead>
            <tr>
              <th title="descripcion">Detalle</th>
              <th className="text-right" width="150px" title="cantidad">
                Cant.
              </th>
              {/* <th className="text-right" width="150px" title="precio">
                Precio
              </th> */}
            </tr>
          </thead>
          <tbody>
            {detallesElems}
            <br />
            <br />
            <tr>
              <td>
                <p className="font-weight-bold mr-0">OBSERVACIONES:</p>
                {entity.observaciones && entity.observaciones.length === 0 ? 'N/A' : entity.observaciones}
              </td>
              <td />
            </tr>

          </tbody>
          <br />
          <tfoot />
        </table>
      </div>
    );
  }
}

export default EntregaRemito;
