import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import { connect } from 'react-redux'

class OperationList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDataLoading: true,
      operation: [],
      selectedOperation: [],
      estados: [
        { active: true, state: 'Activo' },
        { active: false, state: 'Inactivo' },
      ],
      selectedOperationsDetailsDelete: [],
    };

    

    this.loadOperation = this.loadOperation.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }
  

  componentDidMount() {
    this.loadOperation();
  }

  showDeleteConfirmModal = (row) => {
    this.setState((prevState) => {
      const detailsId = row.operationUptakeDetails.map((elem) => elem.id);
      return {
        showConfirmModal: true,
        selectedOperation: row,
        selectedOperationsDetailsDelete: detailsId,
      };
    });
  };

  renderDeleteOperationModal = () => {
    const { showConfirmModal } = this.state;
    const selectedDetalle = {};
    return (
      <Modal size="md" show={showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Operacion de Consumo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar y deshacer esta Operacion de Consumo? Eliminar una operacion traera conflictos si , dicha operacion esta involucrada en una lista de materiales</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onConfirmDelete}>
            Eliminar
          </Button>
          <Button variant="secondary" onClick={() => this.setState({ showConfirmModal: false, selectedDetalle })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  onConfirmDelete = async () => {
    const { selectedOperation, selectedOperationsDetailsDelete } = this.state;
    const { toastManager } = this.props;

    if (selectedOperationsDetailsDelete.length > 0) {
      for (let i = 0; i < selectedOperationsDetailsDelete.length; i++) {
        await APIClient.delete(`/operations-uptakes-details/${selectedOperationsDetailsDelete[i]}`);
      }
    }
    try {
      await APIClient.delete(`/operations-uptakes/${selectedOperation.id}`);
      this.setState((prevState) => {
        const operation = [...prevState.operation];
        const selectedIndex = operation.findIndex((ent) => selectedOperation.id === ent.id);
        operation.splice(selectedIndex, 1);
        return { operation, selectedOperation: {}, showConfirmModal: false };
      });
      toastManager.add('Operacion de Consumo eliminada con éxito', {
        appearance: 'success',
      });
    } catch (err) {
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
      this.setState({ selectedOperation: {}, showConfirmModal: false });
    }
  };

  onTableRef = (props) => {
    this.tableRef = props;
  };

  onLoadForm = async () => {
    const operationRes = await APIClient.get('/operations-uptakes');
    this.setState({ operation: operationRes.data.data });
  };

  async loadOperation() {
    const { toastManager } = this.props;

    try {
      const operationRes = await APIClient.get('/operations-uptakes');
      this.setState({
        operation: operationRes.data.data,
      });
    } catch (err) {
      this.setState({ isDataLoading: false });
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  render() {
    const { operation, isDataLoading, estados, isSuperAdmin, editPermission, isPermissionsLoading } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
      },
      {
        dataField: 'description',
        text: 'Operacion',
        sort: true,
      },
      {
        dataField: 'availability',
        text: 'Disponibilidad (Hs)',
        sort: true,
      },
      {
        dataField: 'capability',
        text: 'Capacidad (un/dia)',
        sort: true,
      },
      {
        dataField: 'isActive',
        text: 'Estado',
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          const estadoFound = estados.find((estado) => cellContent === estado.active);
          const estado = estadoFound ? estadoFound.state : row.estado.state;
          return UIUtils.getClienteEstadoBadge(estado);
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar className="justify-content-center">
            {isSuperAdmin || editPermission ? (
              <ButtonGroup>
                <LinkContainer to={`/operations/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Editar">
                    <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
              </ButtonGroup>
            ) : (
              <></>
            )}
          {isSuperAdmin ? (
              <ButtonGroup>
                <Button size="sm" variant="outline-primary" title="Eliminar" onClick={() => this.showDeleteConfirmModal(row)}>
                  <FontAwesomeIcon icon={faTrashAlt} fixedWidth size="xs" />
                </Button>
              </ButtonGroup>
            ) : (
              <></>
            )} 
          </ButtonToolbar>
        ),
      },
    ];
    return (
      <div>
        {this.renderDeleteOperationModal()}
        <h1 className="page-title">Operaciones de Consumo</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            isDataLoading={isDataLoading}
            columns={columns}
            data={operation}
            keyField="id"
            addButton={isSuperAdmin || editPermission ? '/operations-uptakes/new' : null}
            showExport={false}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(OperationList));