import React from 'react';
import { Route } from 'react-router-dom';
import UnidadDeMedidaList from './UnidadDeMedidaList';
import UnidadDeMedidaEdit from './/UnidadDeMedidaEdit';

const routes = () => [
  <Route path="/unidades-medida/new" component={UnidadDeMedidaEdit} key="/unidades-medida/new" />,
  <Route path="/unidades-medida/:id" component={UnidadDeMedidaEdit} key="/unidades-medida/:id" />,
  <Route path="/unidades-medida" exact component={UnidadDeMedidaList} key="/unidades-medida" />,
].map(route => route);

export default routes;
