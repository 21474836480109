import React from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import DataTable from './DataTable';
import PropTypes from 'prop-types';

const CustomModal = (props) => {
  const {
    isDataLoading,
    alert,
    title,
    columns,
    data,
    showModal,
    closeModal,
    textClose,
    textSave,
    save,
    keyFieldDatable,
    selectRowProps,
    showExport,
    showSearch,
    customBody,
  } = props;

  const styleButton = `${alert ? 'd-flex justify-content-between' : 'd-flex justify-content-end'}`;
  return (
    <Modal size="xl" show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {customBody ? (
          customBody
        ) : (
          <DataTable
            isDataLoading={isDataLoading}
            selectRow={selectRowProps}
            columns={columns}
            data={data || []}
            keyField={keyFieldDatable}
            showSearch={showSearch}
            showExport={showExport}
          />
        )}
      </Modal.Body>

      <Modal.Footer className={styleButton}>
        {alert && (
          <>
            <Alert className="m-2" variant="primary">
              <p className="font-weight-bold text-sm-rigth m-0" style={{ fontSize: '0.7rem' }}>
                {alert}
              </p>
            </Alert>
          </>
        )}
        <div>
          {save && (
            <Button variant="primary" className="mx-1" onClick={save}>
              {textSave}
            </Button>
          )}
          {closeModal && (
            <Button variant="secondary" onClick={closeModal}>
              {textClose}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

CustomModal.propTypes = {
  isDataLoading: PropTypes.bool,
  title: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  showModal: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  save: PropTypes.func,
  keyFieldDatable: PropTypes.string.isRequired,
  selectRowProps: PropTypes.func,
  showExport: PropTypes.bool,
  showSearch: PropTypes.bool,
  customBody: PropTypes.node,
};

CustomModal.defaultProps = {
  isDataLoading: true,
  columns: [],
  data: [],
  keyFieldDatable: 'id',
  showModal: false,
  closeModal: false,
  showExport: true,
  showSearch: true,
  textClose: 'Cerrar',
  textSave: 'Agregar',
};

export default CustomModal;
