import React from 'react';
import { Container, Dropdown, DropdownButton, Image, Nav, Navbar, NavbarBrand, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faPowerOff } from '@fortawesome/free-solid-svg-icons';

import Security from '../services/Security';
import SideMenu from './SideMenu';
import APIClient from '../services/APIClient';

// import Dashboard from './Dashboard/Dashboard';
import HomePortal from './HomePortal/HomePortal';
import ArticulosPendientes from './Reportes/ArticulosPendientes';
import MonitorPendientes from './MonitorPendientes/MonitorPendientes';
import NotFound from './NotFound';
import PasswordChange from './PasswordChange';
// routes
import solicitudRoutes from './Solicitud/solicitudRoutes';
import articuloRoutes from './Articulo/ArticuloRoutes';
import marcaRoutes from './Marca/marcaRoutes';
import lineaRoutes from './Linea/lineaRoutes';
import coleccionRoutes from './Coleccion/coleccionRoutes';
import clienteRoutes from './Cliente/clienteRoutes';
import clienteTipoRoutes from './ClienteTipo/clienteTipoRoutes';
import usuarioRoutes from './Usuario/usuarioRoutes';
import vendedorRoutes from './Vendedor/VendedorRoutes';
import listaPrecioRoutes from './ListaPrecio/ListaPrecioRoutes';
import precioModificadorRoutes from './PrecioModificador/PrecioModificadorRoutes';
import puntoEntregaRoutes from './PuntoEntrega/PuntoEntregaRoutes';
import warehouseRoutes from './Warehouse/WarehouseRoutes';
import autoMarcaRoutes from './AutoMarca/AutoMarcaRoutes';
import stocklistRoutes from './Stocklist/StocklistRoutes';
import autoModeloRoutes from './AutoModelo/AutoModeloRoutes';
import entregaRoutes from './Entrega/EntregaRoutes';
import expresoRoutes from './Expreso/ExpresoRoutes';
import zonaRoutes from './Zona/ZonaRoutes';
import insumoRoutes from './Insumo/insumoRoutes';
import categoriaRoutes from './Categoria/categoriaRoutes';
import proveedorRoutes from './Proveedor/proveedorRoutes';
import unidadDeMedidaRoutes from './UnidadDeMedida/unidadDeMedidaRoutes';
import MonitorInventario from './MonitorInventario/MonitorInventario';
import ListaMateriales from './ListaMateriales/listaMaterialesRoutes';
import ResourceRouter from './Resource/resurceRoutes';
import operationsRouter from './Operations/operationsRoutes';
import productionOrderRouter from './ProductionOrder/productionOrderRouter';
import productionParameterRoutes from './ProductionParamters/productionParameterRoutes';
import deliveryParameterRoutes from './DeliveryParameters/deliveryParameterRoutes';
import productionReportRoutes from './ProductionReport/productionReportRoutes';
import deliveryReportRoutes from './DeliveryReport/deliveryReportRoutes';
import rolesRoutes from './Roles/RolesRoutes';
import saleReportRoutes from './SaleReport/SaleReportRoutes';
import customersReportRoutes from './CustomersReport/CustomersReportRoutes';


class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      session: Security.getSession(),
      logo: {},
    };

    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.getLogo = this.getLogo.bind(this);
    this.changePasswordRedirect = this.changePasswordRedirect.bind(this);
  }

  toggle() {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
    });
  }

  componentDidMount() {
    this.getLogo();
  }
  
  getLogo() {
    APIClient.get('/cms/logo')
      .then((res) => {
        this.setState({ logo: res.data.data });
      })
      .catch((err) => {
        console.error(`No se pudo obtener el logo: `, err);
      });
  }

  changePasswordRedirect(event) {
    event.preventDefault(event);
    const { history } = this.props;
    history.push('/cambiar-password');
  }

  logout(event) {
    event.preventDefault(event);

    const { history } = this.props;
    Security.clearSession();
    history.push('/login');
  }

  render() {
    const { logo, session } = this.state;

    return (
      <div>
        <Navbar bg="dark" fixed="top" variant="dark" className="flex-md-nowrap p-0 shadow">
          <NavbarBrand href="/" className="col-sm-3 col-md-2 mr-0">
            <Container className="ml-0 p-0">
              <Image className="header-logo mw-100" src={logo.full_url} alt="" />
              {/* Portal Comercial */}
            </Container>
          </NavbarBrand>
          <Nav className="ml-4 mr-auto header-title">Portal Comercial</Nav>
          <Nav className="px-3" navbar>
            <DropdownButton
              focusFirstItemOnShow={false}
              alignRight={true}
              className="pl-3"
              variant="dark"
              id="dropdown-item-button"
              title={`${session.user.username}`}>
              <Dropdown.Item href="/cambiar-password" onClick={this.changePasswordRedirect} as="button">
                <FontAwesomeIcon icon={faKey} fixedWidth className="mr-1" />
                Cambiar Password
              </Dropdown.Item>
              <Dropdown.Item href="/logout" onClick={this.logout} as="button">
                <FontAwesomeIcon icon={faPowerOff} fixedWidth className="mr-1" />
                Salir
              </Dropdown.Item>
            </DropdownButton>
          </Nav>
        </Navbar>
        <Container fluid>
          <Row>
            <SideMenu layoutProps={this.props}/>
            <main id="main-content" className="col-md-9 ml-sm-auto col-lg-10 px-4 pb-4" role="main">
              <Switch>
                {/* Administracion */}
                <Route exact path='/' component={HomePortal}/>,
                <Route exact path="/articulos-pendientes" component={ArticulosPendientes} />
                <Route exact path="/monitor-pendientes" component={MonitorPendientes} />
                <Route exact path="/cambiar-password" component={PasswordChange} />
                {solicitudRoutes()}
                {/* Ventas */}
                {deliveryParameterRoutes()}
                {/* Logística */}
                {entregaRoutes()}
                {expresoRoutes()}
                {puntoEntregaRoutes()}
                {/* Statistics */}
                {deliveryReportRoutes()}
                {productionReportRoutes()}
                {saleReportRoutes()}
                {customersReportRoutes()}
                {/* Producción */}
                {ListaMateriales()}
                {ResourceRouter()}
                {operationsRouter()}
                {productionOrderRouter()}
                {productionParameterRoutes()}
                {/* Inventario */}
                {stocklistRoutes()}
                {warehouseRoutes()}
                {categoriaRoutes()}
                {insumoRoutes()}
                {proveedorRoutes()}
                {unidadDeMedidaRoutes()}
                <Route exact path="/monitor-inventario" component={MonitorInventario} />
                {/* Articulos */}
                {articuloRoutes()}
                {marcaRoutes()}
                {lineaRoutes()}
                {coleccionRoutes()}
                {/* Automóviles */}
                {autoMarcaRoutes()}
                {autoModeloRoutes()}
                {/* Clientes */}
                {clienteRoutes()}
                {zonaRoutes()}
                {listaPrecioRoutes()}
                {precioModificadorRoutes()}
                {clienteTipoRoutes()}
                {vendedorRoutes()}
                {/* Administracion */}
                {usuarioRoutes()}
                {rolesRoutes()}

                <Route component={NotFound} />
              </Switch>
            </main>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Layout;
