import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Row, Col, Button, Modal,
} from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  EntityEditForm, FormInputField, FormSelectField, FormCheckField,
} from '../../components';
import APIClient from '../../services/APIClient';
import PrecioService from '../../services/Precio';

class PrecioModificadorEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: null,
      reglas: [],
      // eliminacion de reglas
      reglasRemovedUUIDs: [],
      showRemoveReglaModal: false,
      reglaToRemoveIndex: 0,
      // listados auxiliares para selects
      listasPrecio: [],
      clientes: [],
      articulos: [],
      lineas: [],
    };
  }

  onLoadForm = async () => {
    // obtengo listados para dropdowns
    const listasPrecioRes = await APIClient.get('/listas-precio');
    const clientesRes = await APIClient.get('/clientes');
    const articulosRes = await APIClient.get('/articulos');
    const lineasRes = await APIClient.get('/lineas');
    const marcasRes = await APIClient.get('/marcas');
    const coleccionesRes = await APIClient.get('/colecciones');

    this.setState({
      listasPrecio: listasPrecioRes.data.data,
      clientes: clientesRes.data.data,
      colecciones: coleccionesRes.data.data,
      articulos: articulosRes.data.data,
      lineas: lineasRes.data.data,
      marcas: marcasRes.data.data,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get precioModificador
    const precioModificadorRes = await APIClient.get(`/modificadores-precio/${id}`);
    const entity = precioModificadorRes.data.data;
    // use the id as UUID
    const detallesUI = entity.detalles.map(detalle => ({
      ...detalle,
      uuid: detalle.id.toString(),
    }));

    this.setState({ entity, reglas: detallesUI });
    return entity;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, reglas } = this.state;
    const { history, toastManager } = this.props;

    const entity = entityToSave;
    // deleted detalles

    if (entity.vigenciaDesde) {
      entity.vigenciaDesde = moment.utc(entityToSave.vigenciaDesde).format("YYYY-MM-DD HH:mm");
    } 
    if (entity.vigenciaHasta) {
      entity.vigenciaHasta = moment.utc(entityToSave.vigenciaHasta).format("YYYY-MM-DD HH:mm");
    } 

    // new/updated detalles
    entity.detalles = [];
    if (entityToSave.detallesTemp) {
      reglas.forEach((regla) => {
        const detalleToSave = entity.detallesTemp[regla.uuid];
        if (detalleToSave !== undefined) {
          if (!isAdding) {
            detalleToSave.id = regla.id;
            detalleToSave.modificadorId = parseInt(id, 10);
          }
          entity.detalles.push(detalleToSave);
        }
      });
    }

    // remove temp property for detalles
    delete entity.detallesTemp;

    let saveResponse = null;
    if (isAdding) {
      if(!entity.modoCalculo){
        entity.modoCalculo = 'A';
      }
      saveResponse = await APIClient.post('/modificadores-precio', entity);
    } else {
      saveResponse = await APIClient.patch(`/modificadores-precio/${id}`, entity);
    }

    toastManager.add(
      `Modificador de Precio ${saveResponse.data.data.id} guardado con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/modificadores-precio'),
    );
    return saveResponse.data.data;
  };

  validateForm = (entityToSave, actionType) => {
    if (entityToSave.detallesTemp) {
      const reglasToSave = Object.keys(entityToSave.detallesTemp);
      let negativePercentage = false;
      for (let i = 0; i < reglasToSave.length; i++) {
        const reglaToSaveKey = reglasToSave[i];
        const regla = entityToSave.detallesTemp[reglaToSaveKey];
        if (regla.porcentaje) {
          if(parseFloat(regla.porcentaje) < 0){
            negativePercentage = true;
          }
        }
      }
      if (negativePercentage) {
        return "El porcentaje de todas las no puede ser negativo";
      }
    }
    return null;
  };

  onAddNewRegla = () => {
    const newRegla = {
      id: null,
      listaPrecioId: null,
      clienteId: null,
      articuloId: null,
      lineaId: null,
      cantidadMinima: null,
      porcentaje: 0,
      // temporary for React uniquness
      uuid: `N${Math.random()
        .toString(36)
        .substring(2, 15)}`,
    };

    this.setState(prevState => ({
      reglas: [...prevState.reglas, newRegla],
    }));
  };

  onRemoveReglaClick = (event) => {
    const { target } = event;
    const reglaIndex = parseInt(target.getAttribute('data-index'), 10);

    this.setState({
      showRemoveReglaModal: true,
      reglaToRemoveIndex: reglaIndex,
    });
  };

  prepareToSave = (entityToSave, actionType) => {
    const { reglasRemovedUUIDs } = this.state;
    const assocToDelete = [];
    reglasRemovedUUIDs.forEach((reglaRemovedUUID) => {
      // if starts with "N" then was a just added one
      if (!reglaRemovedUUID.startsWith('N')) {
        assocToDelete.push(parseInt(reglaRemovedUUID, 10));
      } 
    });
    entityToSave.assocToDelete = {};
    entityToSave.assocToDelete.detalles = assocToDelete;
    return entityToSave;
  }

  removeRegla = () => {
    const { reglaToRemoveIndex, reglasRemovedUUIDs } = this.state;

    this.setState((prevState) => {
      const deletedUUID = prevState.reglas[reglaToRemoveIndex].uuid;
      const newReglas = prevState.reglas;
      newReglas.splice(reglaToRemoveIndex, 1);

      return {
        showRemoveReglaModal: false,
        reglaToRemoveIndex: null,
        reglas: newReglas,
        reglasRemovedUUIDs: [...reglasRemovedUUIDs, deletedUUID],
      };
    });
  };

  onCloseRemoveReglaModal = () => {
    this.setState({ showRemoveReglaModal: false });
  };

  renderRemoveReglaModal = () => {
    const { showRemoveReglaModal } = this.state;

    if (showRemoveReglaModal) {
      return (
        <Modal size="md" show onHide={this.onCloseRemoveReglaModal}>
          <Modal.Header closeButton>
            <Modal.Title>Eliminar Detalle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>¿Está seguro de que desea eliminar esta regla?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.removeRegla}>
              Eliminar
            </Button>
            <Button variant="secondary" onClick={this.onCloseRemoveReglaModal}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return null;
  };

  renderReglaItem = (item, index) => {
    const {
      listasPrecio, clientes, colecciones, articulos, lineas, marcas,
    } = this.state;

    return (
      <Card key={`regla_${item.uuid}`} className="mt-2">
        <Card.Body>
          <Card.Title className="d-flex">
            <span className="flex-fill">{`#${index + 1}`}</span>
            <Button variant="link" data-index={index} onClick={this.onRemoveReglaClick}>
              <FontAwesomeIcon icon={faTrash} className="mr-1" />
              Eliminar regla
            </Button>
          </Card.Title>
          <Row>
            <Col md={6} lg={4}>
              <FormSelectField
                id={`detallesTemp.${item.uuid}.listaPrecioId`}
                label="Lista de Precio"
                defaultValue={item.listaPrecioId}
                choices={listasPrecio}
                choiceIdField="id"
                choiceLabelField="descripcion"
                placeholder="(todos)"
                size="sm"
              />
            </Col>
            <Col md={6} lg={4}>
              <FormSelectField
                id={`detallesTemp.${item.uuid}.clienteId`}
                label="Cliente"
                defaultValue={item.clienteId}
                choices={clientes}
                choiceIdField="id"
                choiceLabelField="razonSocial"
                placeholder="(todos)"
                size="sm"
              />
            </Col>
            <Col md={6} lg={4}>
              <FormSelectField
                id={`detallesTemp.${item.uuid}.articuloId`}
                label="Artículo"
                defaultValue={item.articuloId}
                choices={articulos}
                choiceIdField="id"
                choiceLabelField={choiceItem => `${choiceItem.erpCodigo} - ${choiceItem.descripcion}`}
                placeholder="(todos)"
                size="sm"
              />
            </Col>
            <Col md={6} lg={4}>
              <FormSelectField
                id={`detallesTemp.${item.uuid}.lineaId`}
                label="Linea"
                defaultValue={item.lineaId}
                choices={lineas}
                choiceIdField="id"
                choiceLabelField="descripcion"
                placeholder="(todos)"
                size="sm"
              />
            </Col>
            <Col md={6} lg={4}>
              <FormSelectField
                id={`detallesTemp.${item.uuid}.marcaCodigo`}
                label="Marca"
                defaultValue={item.marcaCodigo}
                choices={marcas}
                choiceIdField="codigo"
                choiceLabelField="descripcion"
                placeholder="(todos)"
                size="sm"
              />
            </Col>
            <Col md={6} lg={4}>
              <FormSelectField
                id={`detallesTemp.${item.uuid}.coleccionCodigo`}
                label="Colección"
                defaultValue={item.coleccionCodigo}
                choices={colecciones}
                choiceIdField="codigo"
                choiceLabelField="descripcion"
                placeholder="(todos)"
                size="sm"
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInputField
                id={`detallesTemp.${item.uuid}.cantidadMinima`}
                label="Cantidad mínima"
                type="number"
                min={0}
                defaultValue={item.cantidadMinima}
                size="sm"
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInputField
                id={`detallesTemp.${item.uuid}.porcentaje`}
                label="Porcentaje"
                type="number"
                step="any"
                defaultValue={item.porcentaje}
                size="sm"
                required
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  render() {
    const {
      id, entity, isAdding, reglas,
    } = this.state;

    return (
      <div>
        {this.renderRemoveReglaModal()}

        <h1 className="page-title">{isAdding ? 'Modificador de Precio nuevo' : `Modificador de Precio #${id}`}</h1>

        <EntityEditForm
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          onLoadForm={this.onLoadForm}
          validate={this.validateForm}
          addMode={isAdding}
          prepareToSave={this.prepareToSave}
        >
          <>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="nombre"
                  label="Nombre"
                  type="text"
                  defaultValue={entity && entity.nombre}
                  required
                />
              </Col>
              <Col md={6}>
                <FormInputField id="orden" label="Orden" type="number" defaultValue={entity && entity.orden} required />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="vigenciaDesde"
                  label="Vigencia Desde"
                  type="date"
                  defaultValue={entity && moment.utc(entity.vigenciaDesde).format('YYYY-MM-DD')}
                />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="vigenciaHasta"
                  label="Vigencia Hasta"
                  type="date"
                  defaultValue={entity && moment.utc(entity.vigenciaHasta).format('YYYY-MM-DD')}
                />
              </Col>
              <Col md={6}>
                <FormCheckField
                  id="stopEjecucion"
                  label="Frena ejecución"
                  defaultChecked={entity && entity.stopEjecucion}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelectField
                  id="modoCalculo"
                  label="Modo de cálculo"
                  defaultValue={entity && entity.modoCalculo}
                  choices={PrecioService.modosCalculo}
                  choiceIdField="id"
                  choiceLabelField="nombre"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-3" md={6}>
                <Button variant="primary" onClick={this.onAddNewRegla} size="sm">
                  <FontAwesomeIcon icon={faPlus} fixedWidth />
                Agregar regla
                </Button>
              </Col>
            </Row>
            {reglas.map((element, index) => this.renderReglaItem(element, index))}
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(PrecioModificadorEdit);
