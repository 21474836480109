import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Alert, Card } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormSelectField, Loading } from '../../components';
import APIClient from '../../services/APIClient';

class DeliveryParameterEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { id } = props.match.params;
    this.state = {
      isDataLoading: true,
      isAdding: typeof id === 'undefined',
      deliveryParameterList: [],
      warehouses: [],
      warehouseId:'',
      sectionId:'',
      sections:[],
      sectionsFilter:[],
      entity: {},
      motives: [],
      lines: [],
      id,
    };
  }

  onLoadForm = async () => {
    const typesRes = await APIClient.get('/stocklist-product-types');
    const motivesRes = await APIClient.get('/stocklist-movement-motives');

    const linesRes = await APIClient.get('/entregas/lines-filter');
    const warehousesRes = await APIClient.get('/warehouses?filter[disabled][eq]=true');
    const sectionsRes = await APIClient.get('/sections?filter[disabled][eq]=true');

    this.setState({
      types: typesRes.data.data,
      lines: linesRes.data.data,
      warehouses: warehousesRes.data.data,
      motives: motivesRes.data.data,
      sections: sectionsRes.data.data,
    });
  };

  onRetrieveEntity = async () => {
    const { id, sections } = this.state;
    const deliveryParametersRes = await APIClient.get(`/delivery-parameters/${id}`);
    const entity = deliveryParametersRes.data.data;

    this.setState({
      entity,
      sectionId:entity?.section?.id,
      warehouseId:entity.warehouse.id,
      sectionsFilter: parseInt(entity.warehouse.id) === 0 ? sections : sections.filter((section) => section.warehouseId === parseInt(entity.warehouse.id)),
    });

    return entity;
  };

  handleChangeWarehouses = (e) => {
    const { value, id } = e.target;
    const { sections } = this.state;
    this.setState({
      [id]: value,
      sectionsFilter: parseInt(value) === 0 ? sections : sections.filter((section) => section.warehouseId === parseInt(value)),

    });
  };

  handleChangeSections = (e) => {
    const { value, id } = e.target;
    this.setState({
      [id]: value,
    });
  };


  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, types, motives, sectionId, warehouseId } = this.state;
    const { history, toastManager } = this.props;
    
    if(sectionId === "" || warehouseId === ""){
      toastManager.add('Necesita seleccionar Deposito y sector',{
        appearance: 'warning',
          autoDismiss: false,
      })
      
    }else{
      let saveResponse = null;
      const tipeArticle = types.find((elem) => elem.description === 'comercializable').id;
      const motiveExitByDelivery = motives.find((elem) => elem.description === 'egreso por venta').id;
      entityToSave.productTypeId = tipeArticle;
      entityToSave.motiveId = motiveExitByDelivery;
      if (isAdding) {
        saveResponse = await APIClient.post('/delivery-parameters', entityToSave);
      } else {
        saveResponse = await APIClient.patch(`/delivery-parameters/${id}`, entityToSave);
      }
      toastManager.add(
        `Parametro de consumo por Entrga  ${saveResponse.data.data.id} guardado con éxito`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
        () => history.push('/delivery-parameters'),
      );
      return saveResponse.data.data;
    }

  };
  render() {
    const { id, entity, isAdding, lines, warehouses, sectionsFilter, warehouseId, sectionId } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Parámetro de consumo por Entrega nuevo' : `Parámetro de consumo por Entrega #${id}`}</h1>
            {isAdding === false ? (
              <Alert variant="warning">
                <Alert.Heading>¿ Estas seguro de querer editar este Parámetro de consumo por Entrega ?</Alert.Heading>
                <p>Recordá que los Parámetro de consumo por Entrega están involucrados con las entregas de Articulos</p>
              </Alert>
            ) : (
              <></>
            )}
            <EntityEditForm
              onLoadForm={this.onLoadForm}
              onRetrieveEntity={this.onRetrieveEntity}
              onSaveEntity={this.onSaveEntity}
              addMode={isAdding}>
              <>
                <Card>
                  <Card.Header>Asignacion de Linea</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md={4}>
                        {
                        isAdding ? (
                          <FormSelectField
                            id="lineId"
                            label="Linea"
                            choices={lines}
                            choiceIdField="id"
                            disabled={isAdding === false}
                            choiceLabelField="descripcion"
                            onChange={this.handleChangeLine}
                            type="text"
                            defaultValue={Object.keys(entity).length !== 0 && entity.lineId}
                          />


                        ) : (
                          <span>{entity?.line?.descripcion}</span>
                        )

                        }
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="my-4">
                  <Card.Header>Ubicación</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <FormSelectField
                          id="warehouseId"
                          label="Depósito"
                          value={warehouseId}
                          choices={warehouses}
                          choiceIdField="id"
                          choiceLabelField="description"
                          type="text"
                          onChange={this.handleChangeWarehouses}
                        />
                      </Col>
                      <Col md={6}>
                        <FormSelectField
                          id="sectionId"
                          label="Sectores"
                          choices={sectionsFilter}
                          choiceIdField="id"
                          value={sectionId}
                          choiceLabelField="description"
                          disabled={warehouseId === ""}
                          type="text"
                          onChange={this.handleChangeSections}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </>
            </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(DeliveryParameterEdit);
