import React from 'react';
import { Route } from 'react-router-dom';
import RolesEdit from './RolesEdit';
import RolesList from './RolesList';

const routes = () => [
  <Route path="/roles" exact component={RolesList} key="/roles" />,
  <Route path="/roles/new" exact component={RolesEdit} key="/roles/new" />,
  <Route path="/roles/:id" exact component={RolesEdit} key="/roles/:id" />,
].map(route => route);

export default routes;
