import React from 'react';
import { faCheck, faCheckCircle, faPause, faPlay, faSync, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Devuelve el nombre de un estado dado el codigo
 * @param {string} codigo Codigo de estado
 */

function getMonths(numberMonth){
  const months = [
    {monthDescription:"Enero" , color:"rgba(219, 31, 31)",},
    {monthDescription:"Febrero", color:"rgba(219, 202, 31 )",},
    {monthDescription:"Marzo", color: "rgba(113, 219, 31)",},
    {monthDescription:"Abril", color:"rgba(31, 219, 80 )",},
    {monthDescription:"Mayo", color:"rgba(31, 219, 175)",},
    {monthDescription:"Junio", color:"rgba(31, 156, 219)",},
    {monthDescription:"Julio", color:"rgba(31, 92, 219)",},
    {monthDescription:"Agosto", color:"rgba(67, 31, 219)",}, 
    {monthDescription:"Septiembre", color:"rgba(138, 31, 219)",}, 
    {monthDescription:"Octubre", color:"rgba(205, 31, 219)",}, 
    {monthDescription:"Noviembre", color:"rgba(219, 31, 113)",}, 
    {monthDescription:"Diciembre", color:"rgba(219, 31, 113)",}, 
  ]
  const monthDate = months[numberMonth]
 return monthDate;
}

function getSolicitudEstadoBadge(codigo, withoutBadge = undefined) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case 'NE':
      nombre = 'No Entregado';
      variant = 'secondary';
      break;
    case 'SA':
      nombre = 'En Carga';
      variant = 'warning';
      break;
    case 'SB':
      nombre = 'Cancelado';
      variant = 'danger';
      break;
    case 'SC':
      nombre = 'Cerrado';
      variant = 'secondary';
      break;
    case 'SE':
      nombre = 'Envío Completo';
      variant = 'success';
      break;
    case 'SP':
      nombre = 'Confirmado';
      variant = 'info';
      break;
    case 'SF':
      nombre = 'Facturado';
      variant = 'primary';
      break;
    default:
      break;
  }

  if (withoutBadge) {
    return nombre;
  }
  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}

function getEntregaEstadoBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case 'EA':
      nombre = 'En Carga';
      variant = 'warning';
      break;
    case 'EC':
      nombre = 'Confirmada';
      variant = 'success';
      break;
    default:
      break;
  }
  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}


function generateHours() {
  const newHours = [];
  for (let i = 0; i < 24; i++) {
    let hour = i.toString();
    if (i < 10) {
      hour = hour.padStart(2, '0');
    }
    newHours.push({ hour: `${hour}:00` });
    newHours.push({ hour: `${hour}:30` });
  }
  return newHours;
}

function generateRandomColors(n) {
  const randomColors = [];
  for (let i = 0; i < n; i++) {
    let randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    if (randomColor.length < 7) {
      const newColor = generateRandomColors(1)[0];
      randomColor = newColor;
    }
    randomColors.push(randomColor);
  }
  return randomColors;
}

function getClienteEstadoBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case 'Activo':
      nombre = codigo;
      variant = 'success';
      break;
    case 'Inactivo':
      nombre = codigo;
      variant = 'danger';
      break;
    case 'Prospecto':
      nombre = codigo;
      variant = 'warning';
      break;
    default:
      break;
  }
  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}
function getConsumeByStockBadge(codigo) {
  let nombre;
  let variant;

  if (codigo === true) {
    nombre = 'SI';
    variant = 'success';
  } else {
    nombre = 'NO';
    variant = 'warning';
  }
  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}

function getItemStatusBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  let icono = '-';
  let color = '-';

  if (codigo === true) {
    nombre = 'Finalizado';
    variant = 'secondary';
    icono = faCheckCircle;
    color = 'Gray';  
  } else {
    nombre = 'Sin Finalizar';
    variant = 'success';
    icono = faPlay;
    color = 'green';
  }
  return (
    <div className="d-flex justifi-content-beetween">
      <span className={`badge badge-${variant} mx-2`}>{nombre}</span>
      <FontAwesomeIcon icon={icono} fixedWidth size="x2" color={`${color}`} />
    </div>
  );
}
function getSolicitudToUpdatePrice(codigo){
  let nombre = 'Actualizados';
  let variant = 'success';
  let icono = faCheck;
  let color = 'green';
  switch (codigo) {
    case true:
      nombre = 'Desactualizados';
      variant = 'danger';
      icono = faSync;
      color = 'red';
      break;
  }
  return (
    <div className="d-flex justifi-content-beetween">
      <span className={`badge badge-${variant} mx-2`}>{nombre} </span>
      <FontAwesomeIcon icon={icono} fixedWidth size="x2" color={`${color}`} />
    </div>
  );
}

function getOrderStatusBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  let icono = '-';
  let color = '-';
  switch (codigo) {
    case 'Available':
      nombre = 'Activa';
      variant = 'success';
      icono = faPlay;
      color = 'green';
      break;
    case 'Stopped':
      nombre = 'Pausada';
      variant = 'warning';
      icono = faPause;
      color = '#dca500';

      break;
    case 'Canceled':
      nombre = 'Cancelada';
      variant = 'danger';
      icono = faTimes;
      color = 'red';

      break;
    case 'Finished':
      nombre = 'Finalizada';
      variant = 'secondary';
      icono = faCheckCircle;
      color = 'Gray';

      break;
    default:
      break;
  }
  return (
    <div className="d-flex justifi-content-beetween">
      <span className={`badge badge-${variant} mx-2`}>{nombre}</span>
      <FontAwesomeIcon icon={icono} fixedWidth size="x2" color={`${color}`} />
    </div>
  );
}

export default {
  getSolicitudToUpdatePrice,
  getSolicitudEstadoBadge,
  generateHours,
  generateRandomColors,
  getEntregaEstadoBadge,
  getClienteEstadoBadge,
  getOrderStatusBadge,
  getConsumeByStockBadge,
  getItemStatusBadge,
  getMonths,
};
