import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FormInputField, FormSelectField } from '../../components';
import { withToastManager } from 'react-toast-notifications';
import APIClient from '../../services/APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

class RowEgreso extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      displayNone: this.props.displayNone,
      indexesErrors: this.props.indexesErrors,
      motiveDescription: this.props.motiveDescription,
      providers: [],
      clients: [],
      removeMovement: props.removeMovement,
      handleRowData: props.handleRowData,
      handleIdentificators: props.handleIdentificators,
      handleUniqueIdentificator: props.handleUniqueIdentificator,
      handleEditChangesInState: props.handleEditChangesInState,
      index: props.index,
      productData: props.productData,
      warehouses: props.aditionalData.warehouses,
      sections: props.aditionalData.sections,
      locations: props.aditionalData.locations,
      productStatuses: props.aditionalData.productStatuses,
      ubications: [],
      selectedArticle: {},
      availableWarehouses: [],
      availableSections: [],
      availableLocations: [],
      availableStates: [],
      availableDetails: [],
      availableClients: [],
      availableProvider: [],
      selectedState: '',
      itemCurrentValue: [],
      provisionalData: {},
      unitsInStock: '',
      sectionsForDestination: [],
      locationsForDestination: [],
      description: '',
      units: 0,
      warehouseCurrentValue: 0,
      clientCurrentValue: 0,
      providerCurrentValue: 0,
      validateUnits: props.validateUnits,
      displayNone: false,
      resetMovementInState: props.resetMovementInState,
      showConfirmModal: false,
      indexRowDelete: undefined,
      availableWarehousesCopy: [],
    };
  }

  handleArticles = async (e) => {
    const { toastManager, clients, providers } = this.props;
    const {
      motiveDescription,
      productData,
      warehouses,
      sections,
      locations,
      handleRowData,
      index,
      handleIdentificators,
      resetMovementInState,
    } = this.state;
    const value = e[0].id;
    const data = productData.type.description === 'comercializable' ? { articuloId: value } : { insumoId: value };
    handleRowData(index, data);
    const selectedProductType = productData.type.description;
    const warehousesInArticle = [];
    const sectionsInArticle = [];
    const locationsInArticle = [];
    const detailsInArticle = [];
    try {
      const saveResponse = await APIClient.get(`/monitor-inventario/${value}/${selectedProductType}`);
      let ubications;
      ubications = saveResponse.data.data;
      ubications.map((ubication) => {
        const warehouse = warehouses.find((item) => item.id === ubication.warehouse.id);
        if (!warehousesInArticle.find((item) => item.id === warehouse.id)) {
          warehousesInArticle.push(warehouse);
        }
        const section = sections.find((item) => item.id === ubication.section.id);
        if (!sectionsInArticle.find((item) => item.id === section.id)) {
          sectionsInArticle.push(section);
        }
        const location = locations.find((item) => item.id === ubication.location.id);
        if (!locationsInArticle.find((item) => item.id === location.id)) {
          locationsInArticle.push(location);
        }
        detailsInArticle.push(...ubication.details);
      });

      this.setState(
        (prevState) => ({
          ...prevState,
          ubications,
          availableStates: [],
          availableWarehouses: warehousesInArticle,
          itemCurrentValue: value,
          availableSections: [],
          availableLocations: [],
          selectedState: '',
          warehouseCurrentValue: 0,
          sectionCurrentValue: 0,
          locationCurrentValue: 0,
          clientCurrentValue: 0,
          providerCurrentValue: 0,
          units: 0,
          unitsInStockLoading: true,
          unitsInStock: 0,
          provisionalData: {
            availableSections: sectionsInArticle,
            availableLocations: locationsInArticle,
            availableDetails: detailsInArticle,
          },
          availableClients: clients,
          availableProvider: providers,
          availableWarehousesCopy:
            motiveDescription === 'egreso por venta'
              ? []
              : motiveDescription === 'egreso por devolución a proveedor'
              ? []
              : warehousesInArticle,
        }),
        () => {
          handleIdentificators(index, ubications);
          resetMovementInState(index);
        },
      );
    } catch (err) {
      toastManager.add(`error buscando ubicaciones: ${err}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  handleClient = (e) => {
    const { value } = e.target;
    const { availableWarehouses, handleRowData, index, handleEditChangesInState, ubications, handleIdentificators } = this.state;
    handleRowData(index, { clientId: Number(value) });
    this.setState(
      (prevState) => ({
        ...prevState,
        clientCurrentValue: Number(value),
        warehouseCurrentValue: 0,
        sectionCurrentValue: 0,
        locationCurrentValue: 0,
        providerCurrentValue: 0,
        availableWarehousesCopy: availableWarehouses,
        availableSections: [],
        availableLocations: [],
        availableStates: [],
        units: 0,
        unitsInStockLoading: true,
        unitsInStock: 0,
      }),
      () => {
        handleIdentificators(index, ubications);
        handleEditChangesInState(index, 'clientId');
      },
    );
  };

  handleProvider = (e) => {
    const { value } = e.target;
    const { availableWarehouses, handleRowData, index, handleEditChangesInState, ubications, handleIdentificators } = this.state;

    handleRowData(index, { providerId: Number(value) });
    this.setState(
      (prevState) => ({
        ...prevState,
        providerCurrentValue: Number(value),
        warehouseCurrentValue: 0,
        sectionCurrentValue: 0,
        locationCurrentValue: 0,
        clientCurrentValue: 0,
        availableWarehousesCopy: availableWarehouses,
        availableSections: [],
        availableLocations: [],
        availableStates: [],
        units: 0,
        unitsInStockLoading: true,
        unitsInStock: 0,
      }),
      () => {
        handleIdentificators(index, ubications);
        handleEditChangesInState(index, 'providerId');
      },
    );
  };

  handleWarehouses = (e) => {
    const { value } = e.target;
    const { provisionalData, handleRowData, index, handleEditChangesInState, ubications, handleIdentificators } = this.state;
    handleRowData(index, { warehouseId: Number(value) });
    const newSectors = provisionalData.availableSections.filter((sector) => sector.warehouseId === Number(value));
    this.setState(
      (prevState) => ({
        ...prevState,
        availableSections: newSectors,
        availableLocations: [],
        availableStates: [],
        warehouseCurrentValue: Number(value),
        units: 0,
        unitsInStockLoading: true,
        unitsInStock: 0,
      }),
      () => {
        handleIdentificators(index, ubications);
        handleEditChangesInState(index, 'warehouseId');
      },
    );
  };

  handleSections = (e) => {
    const { value } = e.target;
    const { provisionalData, handleRowData, index, handleEditChangesInState, ubications, handleIdentificators } = this.state;
    handleRowData(index, { sectionId: Number(value) });
    const newLocations = provisionalData.availableLocations.filter((location) => location.sectionId === Number(value));
    this.setState(
      (prevState) => ({
        ...prevState,
        sectionCurrentValue: Number(value),
        availableLocations: newLocations,
        availableStates: [],
        units: 0,
        unitsInStockLoading: true,
        unitsInStock: 0,
      }),
      () => {
        handleIdentificators(index, ubications);
        handleEditChangesInState(index, 'sectionId');
      },
    );
  };

  handleLocations = (e) => {
    const { value } = e.target;
    const { provisionalData, handleRowData, index, handleEditChangesInState } = this.state;
    handleRowData(index, { locationId: Number(value) });
    const newStates = [];
    provisionalData.availableDetails.map((detail) => {
      if (detail.locationId === Number(value) && !newStates.find((item) => item.id === detail.locationId)) {
        const existState = newStates.some((elem) => elem.id === detail.productStatus.id);

        if (existState === false) {
          newStates.push({
            ...detail.productStatus,
            locationId: detail.locationId,
          });
        }
      }
    });

    this.setState(
      (prevState) => ({
        ...prevState,
        locationCurrentValue: Number(value),
        unitsInStockLoading: true,
        availableStates: newStates,
        selectedState: '',
        units: 0,
        unitsInStockLoading: true,
        unitsInStock: 0,
      }),
      () => {
        handleEditChangesInState(index, 'locationId');
      },
    );
  };

  handleStatuses = async (e) => {
    const { value } = e.target;
    const {
      handleUniqueIdentificator,
      index,
      productData,
      handleRowData,
      handleEditChangesInState,
      itemCurrentValue,
      warehouseCurrentValue,
      sectionCurrentValue,
      locationCurrentValue,
    } = this.state;
    this.setState({ unitsInStockLoading: true, unitsInStock: 0, units: 0 });

    if (value) {
      const typeItem = productData.type.description;
      const queryParams = `statusId=${value}&typeItem=${typeItem}&articleId=${itemCurrentValue}&insumoId=${itemCurrentValue}&warehouseId=${warehouseCurrentValue}&sectionId=${sectionCurrentValue}&locationId=${locationCurrentValue}`;
      const unistStockRes = await APIClient.get(`/stocklist/units-available?${queryParams}`);

      handleRowData(index, { productStatusId: Number(value) });
      const unitsInStock = unistStockRes.data.data[0].resta;

      this.setState(
        (prevState) => ({
          ...prevState,
          selectedState: value,
          unitsInStock,
          units: 0,
          unitsInStockLoading: false,
        }),
        () => {
          handleUniqueIdentificator(index);
          handleEditChangesInState(index, 'productStatusId');
        },
      );
    } else {
      this.setState({ selectedState: '', unitsInStock: 0, units: 0 });
    }
  };

  unitsToMove = async (e) => {
    const { toastManager } = this.props;
    const { value } = e.target;
    const { handleRowData, index, validateUnits, unitsInStock } = this.state;
    let units = value;

    const validation = await validateUnits(index, { units: value });

    if (validation) {
      if (Number(value) > unitsInStock) {
        toastManager.add('Stock insuficiente', {
          appearance: 'warning',
          autoDismiss: true,
        });
        units = '0'
      }
      handleRowData(index, { units });
      this.setState({ units });
    }
  };

  handleDescriptionOfMove = (e) => {
    const { value } = e.target;
    const { handleRowData, index } = this.state;
    handleRowData(index, { description: value });

    this.setState((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  showDeletemModal = (index) => {
    this.setState({
      showConfirmModal: true,
      indexRowDelete: index,
    });
  };

  closeDeleteConfirmModal = (row) => {
    this.setState({
      indexRowDelete: undefined,
      showConfirmModal: false,
    });
  };

  renderDeleteModal = () => {
    const { showConfirmModal, indexRowDelete, removeMovement } = this.state;
    return (
      <Modal size="md" show={showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Transaccion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar y deshacer esta transacción?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => removeMovement(indexRowDelete)}>
            Eliminar
          </Button>
          <Button variant="secondary" onClick={this.closeDeleteConfirmModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const {
      index,
      productData,
      availableWarehousesCopy,
      availableSections,
      availableLocations,
      availableStates,
      availableClients,
      availableProvider,
      unitsInStock,
      selectedState,
      description,
      units,
      warehouseCurrentValue,
      motiveDescription,
      clientCurrentValue,
      providerCurrentValue,
      displayNone,
      indexesErrors,
    } = this.state;
    const redBorder = this.props.indexesErrors.indexOf(index) >= 0;
    return (
      <div key={index}>
        {this.renderDeleteModal()}
        <Row
          className={`align-items-center ${displayNone ? 'd-none' : ''} ${redBorder ? 'border border-danger' : ''}`}
          style={{ width: '120%' }}>
          <Button className="mt-1" size="sm" variant="outline-danger" title="Eliminar" onClick={() => this.showDeletemModal(index)}>
            <FontAwesomeIcon icon={faTrashAlt} fixedWidth size="xs" />
          </Button>

          <Col xs={2}>
            <Typeahead
              defaultSelected={[]}
              labelKey={(option) => {
                const label =
                  productData.type.description === 'comercializable'
                    ? `${option ? option.erpCodigo : ''} - ${option ? option.descripcion : ''}`
                    : `${option ? option.erpCode : ''} - ${option ? option.description : ''}`;
                return label;
              }}
              id={`articulos-${index}`}
              label="Typeahead"
              key={'articulos'}
              style={{ paddingTop: '0.25rem' }}
              onChange={(selected) => {
                if (selected.length) {
                  this.handleArticles(selected);
                }
              }}
              options={productData.type.description === 'comercializable' ? productData.comercializables : productData.insumos}
              placeholder="Ingrese erp"
              filterBy={productData.type.description === 'comercializable' ? ['descripcion', 'erpCodigo'] : ['description', 'erpCode']}
            />
          </Col>
          <Col md={1}>
            <FormSelectField
              id={`clientId-${index}`}
              choices={availableClients}
              value={clientCurrentValue}
              onChange={this.handleClient}
              choiceIdField="id"
              disabled={motiveDescription !== 'egreso por venta'}
              choiceLabelField="razonSocial"
              // arrayvalidateindex={arrayvalidateindex}
              size="sm"
            />
          </Col>
          <Col md={1}>
            <FormSelectField
              id={`providerId-${index}`}
              choices={availableProvider}
              value={providerCurrentValue}
              onChange={this.handleProvider}
              choiceIdField="id"
              disabled={motiveDescription !== 'egreso por devolución a proveedor'}
              choiceLabelField="description"
              // arrayvalidateindex={arrayvalidateindex}
              size="sm"
            />
          </Col>

          <Col md={1}>
            <FormSelectField
              id={`warehouseId-${index}`}
              choices={availableWarehousesCopy}
              value={warehouseCurrentValue}
              onChange={this.handleWarehouses}
              choiceIdField="id"
              choiceLabelField="description"
              // arrayvalidateindex={arrayvalidateindex}
              size="sm"
            />
          </Col>
          <Col>
            <FormSelectField
              id={`sectionId-${index}`}
              choices={availableSections}
              onChange={this.handleSections}
              choiceIdField="id"
              choiceLabelField="description"
              // arrayvalidateindex={arrayvalidateindex}
              size="sm"
            />
          </Col>
          <Col>
            <FormSelectField
              id={`locationId-${index}`}
              choices={availableLocations}
              onChange={this.handleLocations}
              choiceIdField="id"
              choiceLabelField="description"
              // arrayvalidateindex={arrayvalidateindex}
              size="sm"
            />
          </Col>
          <Col>
            <FormSelectField
              id={`productStatusId-${index}`}
              value={selectedState}
              choices={availableStates}
              choiceIdField="id"
              choiceLabelField="description"
              onChange={this.handleStatuses}
              // arrayvalidateindex={arrayvalidateindex}
              size="sm"
            />
          </Col>

          <Col>
            <FormInputField disabled id={`unitsInStock-${index}`} label="" type="text" defaultValue={unitsInStock} required />
          </Col>

          <Col>
            <FormInputField
              disabled
              id={`units-${index}`}
              label=""
              type="number"
              // defaultValue="0"
              value={units}
              min={0}
              max={unitsInStock}
              disabled={!unitsInStock}
              onChange={this.unitsToMove}
              required
            />
          </Col>
          <Col>
            <FormInputField
              id={`description-${index}`}
              label=""
              type="text"
              onChange={this.handleDescriptionOfMove}
              value={description}
              // arrayvalidateindex={arrayvalidateindex}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withToastManager(RowEgreso);
