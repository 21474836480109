import React, { useEffect, useState } from 'react';

import { Row, Col, Button, Modal, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import FormInput from '../../components/componentsTs/FormInput';
import { DataTable } from '../../components';
import { useForm } from '../../hooks/useForm';

import APIClient from '../../services/APIClient';

import { DeletePermissionInterface, PermissionInterface, RoleEntityInterface } from './RolesInterface';

const RolesEdit = (props: { toastManager: any; history: any; match: any }): JSX.Element => {
  const initialFormState = {
    description: '',
    code: '',
  };

  const initialRolEntity = {
    role: {
      description: '',
      code: '',
    },
    newPermissions: [],
    deletePermissions: [],
  };

  const rowClasses = (row: PermissionInterface): string => {
    let response = '';
    if (row.addedRow) {
      response = 'bg-success text-white font-weight-bold';
    }
    return response;
  };

  const { toastManager, history } = props;
  const { id } = props.match.params;
  const [isAdding, setIsAdding] = useState(id === undefined);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);

  const [showAddPermissionsModal, setShowAddPermissionsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [permissions, setPermissions] = useState<Array<PermissionInterface>>([]);

  const [entityRole, setEntityRole] = useState<RoleEntityInterface>(initialRolEntity);

  const [newPermissions, setNewPermissions] = useState<Array<PermissionInterface>>([]);
  const [deletePermission, setDeletePermission] = useState<DeletePermissionInterface | undefined>(undefined);
  const [deletePermissions, setDeletePermissions] = useState<Array<PermissionInterface>>([]);

  const [permissionsSelected, setPermissionsSelected] = useState<Array<PermissionInterface>>([]);

  const { inputsValue, handlerInputChange } = useForm(initialFormState);
  const { description, code } = inputsValue;

  const loadInitialData = async () => {
    try {
      const permissionsRes = await (await APIClient.get('/permissions')).data.data;

      permissionsRes.forEach((permission: PermissionInterface): void => {
        permission.addedRow = false;
      });

      setPermissions(permissionsRes);

      if (!isAdding) {
        await onRetrieveEntity();
      }

      setIsDataLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onRetrieveEntity = async () => {
    const { codigo, descripcion, permisos } = await (await APIClient.get(`/roles/${id}`)).data.data;
    setPermissionsSelected(permisos);
    setEntityRole({
      role: {
        code: codigo,
        description: descripcion,
      },
      newPermissions: permisos,
      deletePermissions: [],
    });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const permissionsAddModal = () => {
    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: false,
      clickToSelect: true,
      hideSelectAll: true,
      onSelect: selectPermission,
    };

    return (
      <Modal size="xl" show={showAddPermissionsModal} onHide={() => closeAddPermissionsModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Permisos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            isDataLoading={isDataLoading}
            selectRow={selectRowProps}
            columns={columns}
            data={permissions}
            rowClasses={rowClasses}
            keyField="id"
            showExport={false}
            cellEdit={cellEditFactory({
              mode: 'click',
              blurToSave: true,
              autoSelectText: true,
            })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeAddPermissionsModal}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={saveNewPermissions}>
            Agregar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const showDeletePermissionModal = (row: PermissionInterface) => {
    setDeletePermission(row);
    setShowDeleteModal(true);
  };

  const closeDeletePermissionModal = () => {
    setDeletePermission(undefined);
    setShowDeleteModal(false);
  };

  const confirmDeletePermissionModal = () => {
    const permissionStateDelete = permissionsSelected.filter((permission) => {
      if (deletePermission !== undefined && permission.id !== deletePermission.id) {
        return permission;
      }
    });
    if (!deletePermission?.addedRow) {
      setDeletePermissions((prevState: any) => {
        return [...prevState, deletePermission];
      });
    }
    setPermissionsSelected(permissionStateDelete);
    setShowDeleteModal(false);
    setDeletePermission(undefined);
  };

  const permissionsDeleteModal = () => {
    return (
      <Modal size="sm" show={showDeleteModal} onHide={() => closeDeletePermissionModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Permiso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar y deshacer este Permiso?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={confirmDeletePermissionModal}>
            Eliminar
          </Button>
          <Button variant="secondary" onClick={closeDeletePermissionModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const closeAddPermissionsModal = () => {
    setNewPermissions([]);
    setShowAddPermissionsModal(false);
  };

  const showModalAddPermissions = () => {
    const permissionsUnselected = permissions.filter((permission) => {
      const isUnselected = permissionsSelected.find((permissionSelected) => permissionSelected.id === permission.id);
      if (isUnselected === undefined) return permission;
    });
    setPermissions(permissionsUnselected);
    setShowAddPermissionsModal(true);
    return false
  };

  const saveNewPermissions = () => {
    setPermissionsSelected((prevState) => {
      return [...prevState, ...newPermissions];
    });
    setShowAddPermissionsModal(false);
    setNewPermissions([]);
  };

  const selectPermission = (row: any): void => {
    const permissionSelected = permissions.find((permission) => permission.id === row.id);

    if (permissionSelected !== undefined && !permissionSelected.addedRow) {
      permissionSelected.addedRow = true;
    } else if (permissionSelected !== undefined && permissionSelected.addedRow) {
      permissionSelected.addedRow = false;
    }

    const permissionUpdated = permissions.filter((permission) => permission.addedRow === true);
    setNewPermissions(permissionUpdated);
  };

  const prepareToSave = () => {
    const entityRole = {
      role: {
        code,
        description,
      },
      newPermissions: permissionsSelected,
      deletePermissions: deletePermissions,
    };

    setEntityRole(entityRole);
    createRole(entityRole);
  };

  const validateNewRole = () => {
    if ((isAdding && code === '') || isAdding && description === ''  || isAdding && permissionsSelected.length === 0 ) {
      toastManager.add('Todos los campos son obligatorios', {
        appearance: 'success',
        autoDismiss: true,
      });
      return false;
    }
    return true;
  };

  const createRole = async (entityRole: RoleEntityInterface) => {
    if (validateNewRole()) {
      setIsSubmit(true);
      try {
        let response;
        let message;
        if (isAdding) {
          response = await APIClient.post('/usuarios/create-roles', entityRole);
          message = `Rol ID#${response.data.data.id} Creado con exito`;
        } else {
          response = await APIClient.patch(`/usuarios/update-roles/${id}`, entityRole);
          message = `Rol ID#${response.data.data} Actualizado con exito`;
        }
        toastManager.add(message, {
          appearance: 'success',
          autoDismiss: true,
        });
        history.push('/roles');
      } catch (error) {
        console.error(error);
        setIsSubmit(false);
      }
    }
  };

  const columns = [
    {
      dataField: 'codigo',
      text: 'Nombre del Permiso',
      sort: true,
      editable: false,
    },
    {
      dataField: 'descripcion',
      text: 'Descripción',
      sort: true,
      editable: false,
    },
    {
      dataField: 'isSelected',
      text: '',
      align: 'center',
      hidden:showAddPermissionsModal,
      headerAlign: 'center',
      editable: false,
      formatter: (cell: any, row: PermissionInterface) => {
        return (
          <span className="d-flex align-items-center justify-content-center">
            <ButtonToolbar>
              <ButtonGroup>
                <Button size="sm" variant={`${row.addedRow ? 'outline-light' : 'outline-primary'}`} title="Editar">
                  <FontAwesomeIcon icon={faTrashAlt} onClick={() => showDeletePermissionModal(row)} fixedWidth size="xs" />
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </span>
        );
      },
    },
  ];

  const selectDetalleRow = {
    align: 'center',
    clickToEdit: true,
    clickToSelect: false,
    hideSelectColumn: true,
    mode: 'checkbox',
  };

  return (
    <div>
      {permissionsAddModal()}
      {permissionsDeleteModal()}

      <h1 className="page-title ">{isAdding ? 'Rol nuevo' : `Rol #${id}`}</h1>

      {isDataLoading ? (
        <span>Cargando....</span>
      ) : (
        <>
          <Row>
            <Col md={6}>
              <FormInput
                onChange={handlerInputChange}
                disabled={isSubmit}
                id="code"
                value={!code ? entityRole.role.code : code}
                label="Nombre de Rol"
                placeholder="Encargado de Depósito.."
                type="text"
              />
            </Col>
            <Col md={6}>
              <FormInput
                id="description"
                disabled={isSubmit}
                onChange={handlerInputChange}
                value={!description ? entityRole.role.description : description}
                placeholder="Modulo de Logística..."
                label="Descripcion"
                type="text"
              />
            </Col>
            <Col md={12}>
              <h5 className="mt-4 mb-2">Permisos Aplicados</h5>

              <DataTable
                cellEdit={cellEditFactory({
                  mode: 'click',
                  blurToSave: true,
                  autoSelectText: true,
                  afterSaveCell: selectPermission,
                })}
                selectRow={selectDetalleRow}
                columns={columns}
                data={permissionsSelected}
                keyField="articuloId"
                isDataLoading={isDataLoading}
                rowClasses={rowClasses}
                showExport={false}
                addButton={() => showModalAddPermissions()}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button disabled={isSubmit} className="btn btn-primary my-4 " onClick={prepareToSave}>
              {isAdding ? 'Crear Rol' : 'Actualizar Rol'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default withToastManager(RolesEdit);
