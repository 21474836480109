import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FormSwitch = (props) => {
 const { id, switchLabel, checked, label, labelField, disabled, ...otherProps } = props;
 return (
  <Form.Group controlId={id}>
   <Form.Label>{label}</Form.Label>
   <Form.Check
    type="switch"
    id={id}
    label={switchLabel}
    defaultChecked={checked}
    name={labelField}
    disabled={disabled}
    {...otherProps}
   />
  </Form.Group>
 );
};

FormSwitch.propTypes = {
 id: PropTypes.string.isRequired,
 label: PropTypes.string,
 switchLabel: PropTypes.string,
 checked: PropTypes.bool,
 onChange: PropTypes.func,
 disabled: PropTypes.bool,
};

FormSwitch.defaultProps = {
 checked: false,
 onChange: () => { },
 label: '',
 switchLabel: '',
 disabled: false

};
export default FormSwitch;
