import React, { Fragment } from 'react';
import { withToastManager } from 'react-toast-notifications';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'react-bootstrap';
import APIClient from '../../../services/APIClient';
import { Loading, FormSelectField } from '../../../components';
import Utils from '../../Utils';
import localStorage from '../../../services/Security';

import RowMovement from './RowMovement';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class InternalMovement extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      id: '',
      isAdding: typeof id === 'undefined',
      entity: {},
      movementMotives: [],
      movementTypes: [],
      productStatuses: [],
      movementsToPost: [{ idForDeleteInUI: '0' }],
      movementsToView: [{ id: '0' }],
      productTypes: [],
      selectedPT: { id: 0, description: 'elegir tipo' },
      warehouses: [],
      sections: [],
      locations: [],
      products: [],
      entityToSaveFather: {},
      arrayvalidateindex: [],
      ubications: [],
      renderUb: false,
      loaded: false,
      waitingQuery: false,
      movementList: [],
      productTypeId: '',
      identificators: {},
      displayNoneIndexesList: [],
      indexesErrors: [],
      userId: localStorage.getSession().user.id,
      waitingQuery: false,
    };
  }

  async componentDidMount() {
    await this.onLoadForm();
  }

  onLoadForm = async () => {
    const { toastManager } = this.props;
    try {
      const movementMotiveListIngresoQuery = '?filter[description][eq]=transacción entre depósitos/sectores';
      // get movementMotives
      const movementMotivesRes = await APIClient.get(`/stocklist-movement-motives${movementMotiveListIngresoQuery}`);
      // get movementTypes
      const movementTypesRes = await APIClient.get('/stocklist-movement-types');
      // get productStatuses
      const productStatusesRes = await APIClient.get('/stocklist-product-statuses');
      // get productTypes
      const productTypesRes = await APIClient.get('/stocklist-product-types');
      // get warehouses
      const warehousesRes = await APIClient.get('/warehouses?filter[disabled][eq]=0');
      // get sections
      const sectionsRes = await APIClient.get('/sections?filter[disabled][eq]=0');
      // get locations
      const locationsRes = await APIClient.get('/locations?filter[disabled][eq]=0');
      //get articulos
      const comercializablesRes = await APIClient.get(`/stocklist/items-filter?productType=comercializable`);
      // get insumos
      const insumosRes = await APIClient.get(`/stocklist/items-filter?productType=insumo`);
      // load default products
      this.setState({
        movementMotives: movementMotivesRes.data.data,
        movementTypes: movementTypesRes.data.data,
        productStatuses: productStatusesRes.data.data,
        productStatusesCopy: productStatusesRes.data.data,
        productTypes: productTypesRes.data.data,
        warehouses: warehousesRes.data.data,
        sections: sectionsRes.data.data,
        sectionsCopy: sectionsRes.data.data,
        locations: locationsRes.data.data,
        locationsCopy: locationsRes.data.data,
        comercializables: comercializablesRes.data.data,
        insumos: insumosRes.data.data,
      });
    } catch (err) {
      console.error('Cargando datos para movimiento de stock', err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        loaded: true,
      });
    }
  };

  addNewMovementRow = async () => {
    this.setState((prevState) => {
      const copyMovements = [...prevState.movementList];
      copyMovements.push({
        type: prevState.productTypeId,
        isDelete: false,
      });
      return { ...prevState, movementList: copyMovements };
    });
  };

  validateFieldExistence = (movements) => {
    const { toastManager } = this.props;

    const fieldList = [
      'locationId',
      'locationIdDestination',
      'productStatusId',
      'productStatusIdDestination',
      'sectionId',
      'sectionIdDestination',
      'type',
      'unitsToMove',
      'warehouseId',
      'warehouseIdDestination',
    ];
    const indexesWithErrors = [];
    movements.forEach((mv, index) => {
      if (mv.hasOwnProperty('type') && mv.type !== 'delete' && !mv.isDelete) {
        const fieldsInMov = Object.keys(mv);
        for (let y = 0; y < fieldList.length; y++) {
          if (fieldsInMov.indexOf(fieldList[y]) < 0) {
            indexesWithErrors.push(index);
            continue;
          }
        }
      }
    });
    if (indexesWithErrors.length) {
      this.setState({ indexesErrors: indexesWithErrors });
      toastManager.add('Faltan completar campos', {
        appearance: 'error',
      });
      throw 'error';
    }
  };

  validateDuplicate = (movements) => {
    const { toastManager } = this.props;
    let exitMovements = [];
    let exitMov;
    movements.forEach((mov) => {
      if (mov.insumoId) {
        const { insumoId, isDelete, locationId, productStatusId, sectionId, type, warehouseId } = mov;
        exitMov = {
          insumoId,
          locationId,
          productStatusId,
          sectionId,
          type,
          warehouseId,
          isDelete,
        };
      } else {
        const { articuloId, isDelete, locationId, productStatusId, sectionId, type, warehouseId } = mov;
        exitMov = {
          articuloId,
          locationId,
          productStatusId,
          sectionId,
          type,
          warehouseId,
          isDelete,
        };
      }

      if (exitMovements.includes(JSON.stringify(exitMov))) {
        toastManager.add('Transaccion duplicada', {
          appearance: 'error',
        });
        exitMovements = [];
        throw 'Transaccion duplicada';
      }
      if (!mov.isDelete) {
        exitMovements.push(JSON.stringify(exitMov));
      }
    });
    return false;
  };

  checkUnist(movements) {
    const { toastManager } = this.props;

    const invalidUnist = movements.some((mov) => mov.units <= 0 || mov.units === '0');
    if (invalidUnist) {
      toastManager.add('Solo unidades positivas', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return false;
    }
    return true;
  }

  makeMovements = async () => {
    const { toastManager, history } = this.props;
    const { movementList, movementMotives, movementTypes, userId } = this.state;
    this.validateFieldExistence(movementList);
    //this.checkUnist(movementList)

    const isDuplicate = this.validateDuplicate(movementList);
    if (!isDuplicate) {
      const movements = movementList.filter((mv) => mv.type !== 'delete');
      const movementFilterDeleted = movements.filter((mv) => !mv.isDelete);

      movementFilterDeleted.forEach((mv) => (mv.userId = userId));
      const ids = {
        movType: {
          ingreso: movementTypes.find((movtype) => movtype.description === 'ingreso'),
          egreso: movementTypes.find((movtype) => movtype.description === 'egreso'),
        },
        movementMotivesInternalMovementId: movementMotives[0].id,
      };
      try {
        const bodyToPost = await Utils.internalMovementParseMovementsToPost(movementFilterDeleted, ids);
        this.setState({
          waitingQuery: true,
        });
        if (this.checkUnist(bodyToPost)) {
          await APIClient.post('/stocklist', bodyToPost);
          toastManager.add(
            `Movimiento realizado con éxito`,
            {
              appearance: 'success',
            },
            () => history.push('/stocklist'),
          );
        }
      } catch (err) {
        console.error('Cargando datos', err);
        toastManager.add('Error transaciones canceladas', {
          appearance: 'error',
        });
      } finally {
        this.setState((prevState) => ({
          waitingQuery: false,
        }));
      }
    }
  };

  editMovementRow = (index, data) => {
    this.setState((prevState) => {
      const copyMovements = [...prevState.movementList];
      let articles = [...prevState.comercializables];
      let insumos = [...prevState.insumos];
      copyMovements[index].isDelete = false;
      copyMovements[index] = {
        ...copyMovements[index],
        ...data,
      };

      return { ...prevState, movementList: copyMovements, articles: articles, insumos: insumos };
    });
  };

  resetMovementInState = (index) => {
    this.setState((prevState) => {
      const copyMovements = [...prevState.movementList];
      const itemId = copyMovements[index].hasOwnProperty('articuloId') ? 'articuloId' : 'insumoId';
      copyMovements[index] = {
        type: copyMovements[index].type,
        [itemId]: copyMovements[index][itemId],
        isDelete: copyMovements[index].isDelete,
      };
      return { ...prevState, movementList: copyMovements };
    });
  };

  handleEditChangesInState = (index, handledField) => {
    const fieldsInOrder = ['warehouseId', 'sectionId', 'locationId', 'productStatusId', 'unitsToMove'];
    const indexToCut = fieldsInOrder.indexOf(handledField);
    const fieldsToDeleteFromMovement = fieldsInOrder.slice(indexToCut + 1, fieldsInOrder.length);
    this.setState((prevState) => {
      const copyMovements = [...prevState.movementList];
      const current = copyMovements[index];
      fieldsToDeleteFromMovement.forEach((fTD) => {
        if (current.hasOwnProperty(fTD)) {
          delete current[fTD];
        }
      });
      return { ...prevState, movementList: copyMovements };
    });
  };
  validateUnits = (index, data) => {
    const { identificators, movementList, displayNoneIndexesList } = this.state;
    const currentEditedMovement = movementList[index];
    const itemId = currentEditedMovement.hasOwnProperty('articuloId') ? currentEditedMovement.articuloId : currentEditedMovement.insumoId;
    const currentIdentificator = `${currentEditedMovement.type}-${itemId}-${currentEditedMovement.warehouseId}-${currentEditedMovement.sectionId}-${currentEditedMovement.locationId}-${currentEditedMovement.productStatusId}-${currentEditedMovement.fifoDate}`;
    const identificatorsKeys = Object.keys(identificators);
    identificatorsKeys.forEach((idKey) => {
      if (idKey.includes(currentIdentificator)) {
        if (Number(data.unitsToMove) > identificators[idKey].resta) {
          alert(`${data.unitsToMove} cantidad no disponible`);
          throw false;
        }
        // dont't allow two lines with the same identificator
        const idKeyFile = idKey.substr(idKey.length - 1, 1);
        if (Number(idKeyFile) !== index) {
          alert(`existe una línea en la pantalla actual para este artículo - posición física - estado`);
          this.setState((prevState) => {
            const copyMovements = [...prevState.movementList];
            const copyIdentificators = prevState.identificators;
            //reset row
            identificatorsKeys.forEach((idKey2) => {
              if (Number(idKey2.substr(idKey2.length - 1, 1)) === index) {
                delete copyIdentificators[idKey2];
              }
            });
            copyMovements[index] = { type: 'delete' };
            displayNoneIndexesList.push(index);
            return { ...prevState, movementList: copyMovements, displayNoneIndexesList, identificators: copyIdentificators };
          });
          throw false;
        }
      }
    });
    return true;
  };

  handleIdentificators = (index, ubications) => {
    this.setState(
      (prevState) => {
        const copyIdentificators = prevState.identificators;
        const identificatorsKeys = Object.keys(copyIdentificators);
        identificatorsKeys.forEach((idKey) => {
          if (Number(idKey.substr(idKey.length - 1, 1)) === index) {
            delete copyIdentificators[idKey];
          }
        });
        return { ...prevState, identificators: copyIdentificators };
      },
      () => {
        const { identificators } = this.state;
        ubications.forEach((ub) => {
          ub.details.forEach((detail) => {
            const data = detail;
            const identificator = detail.ingresoId + `-${index}`;
            identificators[identificator] = data;
          });
        });
        this.setState({ identificators });
      },
    );
  };

  handleUniqueIdentificator = (index) => {
    this.setState((prevState) => {
      const copyOfMovements = [...prevState.movementList];
      const currentEditedMovement = copyOfMovements[index];
      const itemId = currentEditedMovement.hasOwnProperty('articuloId') ? currentEditedMovement.articuloId : currentEditedMovement.insumoId;
      const currentIdentificator = `${currentEditedMovement.type}-${itemId}-${currentEditedMovement.warehouseId}-${currentEditedMovement.sectionId}-${currentEditedMovement.locationId}-${currentEditedMovement.productStatusId}-${currentEditedMovement.fifoDate}`;
      const copyIdentificators = prevState.identificators;
      const identificatorsKeys = Object.keys(copyIdentificators);
      identificatorsKeys.forEach((idKey) => {
        if (Number(idKey.substr(idKey.length - 1, 1)) === index && !idKey.includes(currentIdentificator)) {
          delete copyIdentificators[idKey];
        }
      });
      return { ...prevState, identificators: copyIdentificators };
    });
  };
  handleDataChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  removeMovement = (index) => {
    this.setState((prevState) => {
      const copyMovementList = [...prevState.movementList];
      copyMovementList[index] = {
        ...copyMovementList[index],
        ...{ isDelete: true },
      };
      return { ...prevState, movementList: copyMovementList };
    });
  };

  render() {
    const {
      isAdding,
      productTypes,
      productStatuses,
      warehouses,
      sections,
      locations,
      loaded,
      comercializables,
      insumos,
      movementList,
      displayNoneIndexesList,
      indexesErrors,
      waitingQuery,
    } = this.state;

    return loaded ? (
      <Fragment>
        <div>
          <h1 className="page-title">{isAdding ? 'Movimiento interno de stock' : ''}</h1>
          <Row className=" align-items-center fixed">
            <Col md={3}>
              <FormSelectField
                id="productTypeId"
                label="Tipo"
                choices={productTypes}
                onChange={this.handleDataChange}
                choiceIdField="id"
                choiceLabelField="description"
                // arrayvalidateindex={arrayvalidateindex}
              />
            </Col>
            <Col md={3} className="mt-2 d-flex align-items-center">
              <Button onClick={() => this.addNewMovementRow()}>Agregar nueva fila</Button>
            </Col>
          </Row>
        </div>
        <div>
          {movementList.map((movement, index) => {
            if (!movement.isDelete) {
              const productType = productTypes.find((type) => type.id === Number(movement.type));
              const productData = {
                type: productType,
                insumos,
                comercializables,
                movement,
              };
              const aditionalData = {
                productStatuses,
                warehouses,
                sections,
                locations,
              };

              return (
                <div key={index}>
                  <RowMovement
                    key={index}
                    index={index}
                    handleRowData={this.editMovementRow}
                    handleIdentificators={this.handleIdentificators}
                    handleUniqueIdentificator={this.handleUniqueIdentificator}
                    validateUnits={this.validateUnits}
                    productData={productData}
                    aditionalData={aditionalData}
                    displayNone={displayNoneIndexesList.indexOf(index) >= 0}
                    resetMovementInState={this.resetMovementInState}
                    indexesErrors={indexesErrors}
                    handleEditChangesInState={this.handleEditChangesInState}
                    removeMovement={this.removeMovement}
                  />
                  {/* <hr style={{ width: '100%', marginTop: '.2rem', marginBottom: '.2rem' }} /> */}
                </div>
              );
            }
          })}
        </div>
        {movementList.filter((mov) => mov?.isDelete === false).length >= 1 && (
          <Row className="justify-content-start align-items-center">
            <Col md={3} className="d-flex align-items-center">
              <Button onClick={() => this.makeMovements()}>
                {waitingQuery ? <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" /> : 'Guardar'}
              </Button>
            </Col>
          </Row>
        )}
      </Fragment>
    ) : (
      <Loading />
    );
  }
}

export default withToastManager(InternalMovement);
