import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar, Card, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, FormInputField, FormSelectField, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import Utils from '../Utils'
import { connect } from 'react-redux'

class ProductionOrderList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: true,
      isDataLoadingOrders: false,
      selectedRange: '',
      selectedStatusId: '',
      orders: [],
      status: [],
      rangeType: [
        { description: 'Fecha de Alta', id: 'createdAt' },
        { description: 'Fecha de Finalizacion', id: 'endDate' },
      ],
      curToDate: '',
      curFromDate: '',
    };


    this.loadOrdes = this.loadOrdes.bind(this);
  }
  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }
  


  componentDidMount() {
    this.loadOrdes();
  }

  handleChangeStatus = (e) => {
    const { value, id } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleChangeValue = (e) => {
    const { value, id } = e.target;
    if (value === '') {
      this.cleanFilters();
    } else {
      this.setState({
        [id]: value,
      });
    }
  };

  cleanFilters = () => {
    this.setState({
      curToDate: '',
      curFromDate: '',
      selectedRange: '',
      selectedStatusId: '',
    });
  };

  updateList = async () => {
    const { selectedRange, curFromDate, curToDate, selectedStatusId } = this.state;
    const { toastManager } = this.props;

    let ordersResFilter = [];
    const validatedDate = (selectedRange !== '' && curToDate === '') || (selectedRange !== '' && curFromDate === '');
    if (validatedDate === true) {
      toastManager.add('Complete el rango de fecha Correctamente', {
        appearance: 'warning',
      });
    } else {
      this.setState({ isDataLoadingOrders: true });
      const queryParams = `selectedRange=${selectedRange}&createdAt=${curFromDate}&endDate=${curToDate}&statusId=${selectedStatusId}`;
      const ordersResFilterRes = await APIClient.get(`/production-orders/orders-filter?${queryParams}`);
      ordersResFilter = ordersResFilterRes.data.data;

      this.setState({
        orders: ordersResFilter,
        isDataLoadingOrders: false,
      });
    }
  };

  getExportData = async () => {
    const { orders } = this.state;
    const { toastManager } = this.props;
    try {
      const queryParams = `orders=${JSON.stringify(orders)}`;
      const apiResponse = await APIClient.get(`/production-orders/export-csv?${queryParams}`);
      return apiResponse.data.data;
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
      return false;
    }
  };

  async loadOrdes() {
    const { toastManager } = this.props;

    try {
      const ordersRes = await APIClient.get('/production-orders?excludeAssocFields=productionOrderDetails');
      const statusRes = await APIClient.get('/production-orders-detail-status');
      statusRes.data.data.forEach((statu) => {
        const statuOrder = statu;
        if (statuOrder.description === 'Available') {
          statuOrder.description = 'Activa';
        }
        if (statuOrder.description === 'Stopped') {
          statuOrder.description = 'Pausada';
        }
        if (statuOrder.description === 'Canceled') {
          statuOrder.description = 'Cancelada';
        }
        if (statuOrder.description === 'Finished') {
          statuOrder.description = 'Finalizada';
        }
      });
      this.setState({
        orders: ordersRes.data.data,
        status: statusRes.data.data,
        isDataLoading: false,
      });
    } catch (err) {
      this.setState({ isDataLoading: false });
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  render() {
    const {
      orders,
      isDataLoadingOrders,
      selectedRange,
      selectedStatusId,
      isDataLoading,
      status,
      curFromDate,
      curToDate,
      rangeType,
      editPermission,
      isSuperAdmin,
      isPermissionsLoading,
    } = this.state;
    const columns = [
      {
        dataField: 'id',
        text: 'OP Nro.',
        sort: true,
      },

      {
        dataField: 'status.description',
        text: 'Estado',
        align: 'center',
        sort: true,
        formatter: (cellContent, row) => {
          const statusDescription = row.status?.description;
          return UIUtils.getOrderStatusBadge(statusDescription);
        },
      },
      {
        dataField: 'createdAt',
        text: 'Fecha de Alta',
        sort: true,
        formatter: (cellContent, row) => {
          const dateFormat = 'DD/MM/YYYY HH:mm:ss';
          const date = row.createdAt;
          return Utils.localDate(date, dateFormat);
        },
      },
      {
        dataField: 'endDate',
        text: 'Fecha de finalizacion',
        sort: true,
        formatter: (cellContent, row) => {
          const dateFormat = 'DD/MM/YYYY HH:mm:ss';
          const date = row.createdAt;
          const orderEndDate =
            row.status?.description === 'Available' || row.status?.description === 'Stopped'
              ? ''
              : Utils.localDate(date, dateFormat);
          return orderEndDate;
        },
      },
      {
        dataField: 'action',
        text: '',
        isDummyField: true,
        formatter: (cellContent, row) => (
          <ButtonToolbar className="justify-content-center">
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <LinkContainer to={`/production-orders/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Avance de orden">
                    <FontAwesomeIcon icon={faAngleDoubleRight} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Ordenes de Producción</h1>
        {isPermissionsLoading === false ? (
          <>
            <Card className="my-3">
              <Card.Header>
                <Button variant="warning" onClick={this.cleanFilters} className="float-right">
                  Limpiar Filtros
                </Button>
                Filtros de búsqueda
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="selectedRange"
                      label="Rango de fecha"
                      onChange={this.handleChangeValue}
                      value={selectedRange}
                      choices={rangeType}
                      choiceIdField="id"
                      choiceLabelField="description"
                    />
                  </Col>
                  <Col md={3}>
                    <FormInputField
                      id="curFromDate"
                      type="month"
                      disabled={selectedRange === ''}
                      label="Fecha desde:"
                      value={curFromDate}
                      defaultValue={curFromDate !== '' ? curFromDate : null}
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                  <Col md={3}>
                    <FormInputField
                      id="curToDate"
                      type="date"
                      label="Fecha hasta:"
                      disabled={curFromDate === ''}
                      value={curToDate}
                      onChange={this.handleChangeValue}
                      defaultValue={curToDate !== '' ? curToDate : null}
                      min={curFromDate !== '' ? curFromDate : null}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="selectedStatusId"
                      label="Estados"
                      onChange={this.handleChangeStatus}
                      value={selectedStatusId}
                      choices={status}
                      choiceIdField="id"
                      choiceLabelField="description"
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Button onClick={this.updateList} className="float-right">
                  {isDataLoadingOrders === false ? <span>Filtrar ordenes</span> : <span>Cargando...</span>}
                </Button>
              </Card.Footer>
            </Card>
            <DataTable
              wrapperClasses="table-responsive"
              sort={{ dataField: 'id', order: 'desc' }}
              isDataLoading={isDataLoading}
              columns={columns}
              data={orders}
              keyField="id"
              addButton={isSuperAdmin || editPermission ? '/production-orders/new' : null}
              getExportData={this.getExportData}
            />
          </>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}
const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(ProductionOrderList));
