import React from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, ButtonGroup, ButtonToolbar, Card, Col, Row, Badge, Modal } from 'react-bootstrap';
import { FormSelectField, Loading, DataTable } from '../../components';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withToastManager } from 'react-toast-notifications';
import { faEdit, faCircleNotch, faImage, faUpload } from '@fortawesome/free-solid-svg-icons';
import ReactImageMagnify from 'react-image-magnify';


import Utils from '../Utils';

import APIClient from '../../services/APIClient';
import { connect } from 'react-redux'
import config from '../../config';

class InsumoList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      insumos: [],
      insumosFilter: [],
      isDataLoading: true,
      totalSize: 0,
      categoriesLevel1: [],
      categoriesLevel2: [],
      categoriesLevel3: [],
      filterStringInsumo: '',
      selectedInsumoIds: [],
      insumosTypes: [],
      selectedTipoArticulo: [],
      filterTipoArticulo: '',
      selectedCategoriaUno: [],
      filterCategoriaUno: '',
      selectedCategoriaDos: [],
      filterCategoriaDos: '',
      selectedCategoriaTres: [],
      filterCategoriaTres: '',
      showImageMagnify: false,
      insumoSelected: undefined,
      uploadImage:undefined,
    };

    this.loadSupplies = this.loadSupplies.bind(this);
  }

  getPermissions() {
    const { isSuperAdmin, editPermission, deletePermission, isPermissionsLoading } = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }


  componentDidMount() {
    this.loadSupplies();
  }

  async loadSupplies() {
    const { toastManager } = this.props;
    try {
      const insumosRes = await APIClient.get(
        '/insumos?excludeAssocFields=categoryLevel1,categoryLevel2,categoryLevel3,comercializablesFather,insumoFatherObjects,insumosFather&sortField=description&sortDir=asc',
      );
      const categoriesLevel1Res = await APIClient.get('/categorias?filter[category_level_id][eq]=1&filter[active][eq]=1');
      const categoriesLevel2Res = await APIClient.get('/categorias?filter[category_level_id][eq]=2&filter[active][eq]=1');
      const categoriesLevel3Res = await APIClient.get('/categorias?filter[category_level_id][eq]=3&filter[active][eq]=1');
      const insumosTypesRes = await APIClient.get('/insumos-types');
      this.setState({
        insumos: insumosRes.data.data,
        insumosFilter: insumosRes.data.data,
        totalSize: insumosRes.data.data.length,
        categoriesLevel1: categoriesLevel1Res.data.data,
        categoriesLevel2: categoriesLevel2Res.data.data,
        categoriesLevel3: categoriesLevel3Res.data.data,
        insumosTypes: insumosTypesRes.data.data,
        isDataLoading: false
      });
      this.apiParams = 'sortField=id&sortDir=asc&excludeAssocFields=imagenes';
    } catch (err) {
      console.error('Error cargando insumos.', err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  }

  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    const { filterStringInsumo, filterTipoArticulo, filterCategoriaUno, filterCategoriaDos, filterCategoriaTres } = this.state;
    try {
      const { direction, field } = sorting;
      const filterQuery = `${filterStringInsumo}&${filterTipoArticulo}&${filterCategoriaUno}&${filterCategoriaDos}&${filterCategoriaTres}`;
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || ''}&sortDir=${direction || ''}`;
      const insumosRes = await APIClient.get(
        `/insumos?excludeAssocFields=categoryLevel1,categoryLevel2,categoryLevel3,comercializablesFather,insumoFatherObjects,insumosFather&${filterQuery}`,
      );
      this.setState({
        insumos: insumosRes.data.data,
        totalSize: insumosRes.data.data.length,
      });
      window.history.pushState({ page: pagination.page }, '', `?page=${pagination.page}`);
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  handleArticles = (selected) => {
    const article = selected[0] || {};
    const value = article.id;
    this.setState((prevState) => ({
      ...prevState,
      selectedInsumoIds: selected,
      filterStringInsumo: Utils.prepareQueryToFilter('id', 'eq', [value]),
    }));
  };

  handleDataChange = (e) => {
    const { id, value, options, multiple } = e.target;
    let filterName = '';
    let fieldName = '';
    switch (id) {
      case 'selectedTipoArticulo':
        fieldName = 'insumoTypeId';
        filterName = 'filterTipoArticulo';
        break;
      case 'selectedCategoriaUno':
        fieldName = 'categoryLevel1Id';
        filterName = 'filterCategoriaUno';
        break;
      case 'selectedCategoriaDos':
        fieldName = 'categoryLevel2Id';
        filterName = 'filterCategoriaDos';
        break;
      case 'selectedCategoriaTres':
        fieldName = 'categoryLevel3Id';
        filterName = 'filterCategoriaTres';
        break;
      default:
        break;
    }
    if (multiple) {
      const values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);
      this.setState((prevState) => ({
        ...prevState,
        [id]: values,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', values),
      }));
    } else {
      this.setState(async (prevState) => ({
        ...prevState,
        [id]: value,
        [filterName]: Utils.prepareQueryToFilter(fieldName, 'eq', [value]),
      }));
    }
  };

  createSelectAllButtons = (entityName) => (
    <p className="m-0">
      {entityName.substr(0, 1).toUpperCase()}
      {entityName.substr(1)}(
      <button
        id={`select-all-${entityName}`}
        type="submit"
        className="link-button text-primary"
        onClick={() => this.handleSelectAll(entityName)}>
        Limpiar filtro
      </button>
      )
    </p>
  );

  renderSelectedArticlesTypes = () => {
    const { selectedTipoArticulo, insumosTypes } = this.state;
    const selectedTypeArticlesToNumber = selectedTipoArticulo.map((selType) => parseInt(selType));
    const selected = insumosTypes.filter((type) => selectedTypeArticlesToNumber.includes(type.id));
    return selected.length > 0
      ? selected.map((type, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {type.description}
        </Badge>
      ))
      : '';
  };

  renderSelectedCateroriesTypeOne = () => {
    const { selectedCategoriaUno, categoriesLevel1 } = this.state;
    const selectedCategoriaUnoToNumber = selectedCategoriaUno.map((selArt) => parseInt(selArt));
    const selected = categoriesLevel1.filter((cat) => selectedCategoriaUnoToNumber.includes(cat.id));
    return selected.length > 0
      ? selected.map((cat, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {cat.description}
        </Badge>
      ))
      : '';
  };

  renderSelectedCateroriesTypeTwo = () => {
    const { selectedCategoriaDos, categoriesLevel2 } = this.state;
    const selectedCategoriaDosToNumber = selectedCategoriaDos.map((selArt) => parseInt(selArt));
    const selected = categoriesLevel2.filter((cat) => selectedCategoriaDosToNumber.includes(cat.id));
    return selected.length > 0
      ? selected.map((cat, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {cat.description}
        </Badge>
      ))
      : '';
  };

  renderSelectedCateroriesTypeThree = () => {
    const { selectedCategoriaTres, categoriesLevel3 } = this.state;
    const selectedCategoriaTresToNumber = selectedCategoriaTres.map((selArt) => parseInt(selArt));
    const selected = categoriesLevel3.filter((cat) => selectedCategoriaTresToNumber.includes(cat.id));
    return selected.length > 0
      ? selected.map((cat, i) => (
        <Badge variant="primary" className="ml-1" key={i}>
          {cat.description}
        </Badge>
      ))
      : '';
  };

  showRenderImageMagnify = (row) => {
    this.setState({
      showImageMagnify: true,
      insumoSelected: row
    })
  }

  closeRenderImageMagnify = () => {
    this.setState({
      showImageMagnify: false,
      insumoSelected: undefined
    })
  }

  handlerImageUpload = async ()=>{
    const { selectedImage, insumoSelected } = this.state

    const curImgResponse = await APIClient.getImage(selectedImage.filename, { responseType: 'blob' });
    const newImage = {};
    const reader = new FileReader();
    reader.onloadend = async () => {
      const result = reader.result.split(',');
      newImage.fileData = result[1];
      newImage.fileType = result[0].match(/image\/.*g/g)[0];
      newImage.isUploaded = false;
      newImage.posicion = selectedImage.posicion !== 0  ? 0 :selectedImage.posicion + 1 

      await APIClient.post(`/insumos/${insumoSelected.id}/imagenes`, newImage);
    };

    reader.readAsDataURL(curImgResponse.data);
    this.setState({
      showImageMagnify:false,
      insumoSelected:undefined,
    })
  }

  handlerSeLectedImage = (e)=>{
    const { toastManager } = this.props;
    const { insumoSelected } = this.state

    const newFile = e.target.files[0]

    const newImage = {};
    const fileTempUrl = URL.createObjectURL(newFile);
    newImage.filename = fileTempUrl;

    if (newFile && newFile.size > 5000000) {
      toastManager.add('Solo se permiten imágenes de menos de 5 MB.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    insumoSelected.filename = newImage.filename;
    this.setState({
      selectedImage:newImage,
    })
  }


  setImageMagnify = ()=>{
    const { insumoSelected , selectedImage} = this.state;
    
    let pathUrl = `${config.api.backPublicPath}/uploads/insumos/images/${insumoSelected?.id}/${insumoSelected?.imagenes[0]?.filename}`;
    
    if (insumoSelected?.imagenes.length === 0){
      pathUrl =`/images/no_photo.jpg`
    }
    
    if (selectedImage){
      pathUrl = insumoSelected?.filename
    }

    if(!insumoSelected){
      pathUrl = `/images/no_photo.jpg`
    }

    return pathUrl
  }

  renderImageMagnify = () => {
    const { showImageMagnify, insumoSelected } = this.state;

    return (
      <Modal size="md" show={showImageMagnify} onHide={() => this.closeRenderImageMagnify()}>
        <Modal.Header closeButton>
          <Modal.Title>{insumoSelected?.description}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactImageMagnify {...{
            smallImage: {
              alt: insumoSelected?.description,
              isFluidWidth: true,
              src: this.setImageMagnify(),
            },
            largeImage: {
              width: 800,
              height: 1400,
              src: this.setImageMagnify(),
            }
          }} />
          <div>
              <input 
              ref="fileInput"
              type="file"
              lang="es"
              style={{ display: "none" }}
              accept="image/png, image/jpeg"
              onChange={this.handlerSeLectedImage}/>

            <button className="btn btn-outline-info btn-block" onClick={() => this.refs.fileInput.click()}>
            <FontAwesomeIcon
                  icon={faUpload}
                  fixedWidth
                  className='m-1'
                />
            Seleccionar</button>
            <button onClick={this.handlerImageUpload} disabled={!this.state.selectedImage} className="btn btn-primary btn-block">
            Guardar</button>
            
          </div>

        </Modal.Body>

      </Modal>
    );
  };

  renderArticlesAndDescriptionOptions = (choice = {}) => `Codigo ERP: ${choice.erpCode} | Descripcion: ${choice.description}`;

  handleSelectAll = (entityName) => {
    this.setState((prevState) => {
      let {
        selectedInsumoIds,
        filterStringInsumo,
        selectedTipoArticulo,
        filterTipoArticulo,
        selectedCategoriaUno,
        filterCategoriaUno,
        selectedCategoriaDos,
        filterCategoriaDos,
        selectedCategoriaTres,
        filterCategoriaTres,
      } = prevState;
      // map ids from entities into "selected" variables
      switch (entityName) {
        case 'Articulo + Descripción':
          selectedInsumoIds = [];
          filterStringInsumo = '';
          break;
        case 'Tipo de Articulo':
          selectedTipoArticulo = [];
          filterTipoArticulo = '';
          break;
        case 'Categoria 1':
          selectedCategoriaUno = [];
          filterCategoriaUno = '';
          break;
        case 'Categoria 2':
          selectedCategoriaDos = [];
          filterCategoriaDos = '';
          break;
        case 'Categoria 3':
          selectedCategoriaTres = [];
          filterCategoriaTres = '';
          break;
        default:
          break;
      }

      return {
        ...prevState,
        selectedInsumoIds,
        filterStringInsumo,
        selectedTipoArticulo,
        filterTipoArticulo,
        selectedCategoriaUno,
        filterCategoriaUno,
        selectedCategoriaDos,
        filterCategoriaDos,
        selectedCategoriaTres,
        filterCategoriaTres,
      };
    });
  };

  render() {
    const {
      insumos,
      isDataLoading,
      totalSize,
      categoriesLevel1,
      categoriesLevel2,
      categoriesLevel3,
      selectedInsumoIds,
      insumosFilter,
      insumosTypes,
      selectedTipoArticulo,
      selectedCategoriaUno,
      selectedCategoriaDos,
      selectedCategoriaTres,
      isSuperAdmin,
      editPermission,
      isPermissionsLoading,
    } = this.state;

    const articulosRef = React.createRef();

    const columns = [
      {
        dataField: 'id',
        text: 'id',
        hidden: true,
      },
      {
        dataField: 'type.description',
        text: 'Tipo de Articulo',
        sort: true,
      },
      {
        dataField: 'categoryLevel1Id',
        text: 'Categoria N1',
        sort: true,
        formatter: (cellContent, row) => {
          const categoryDescription = categoriesLevel1.filter((category) => category.id === cellContent);
          return categoryDescription[0] ? categoryDescription[0].description : 'Sin categoria asociada';
        },
      },
      {
        dataField: 'categoryLevel2Id',
        text: 'Categoria N2',
        sort: true,
        formatter: (cellContent) => {
          const categoryDescription = categoriesLevel2.filter((category) => category.id === cellContent);
          return categoryDescription[0] ? categoryDescription[0].description : 'Sin categoria asociada';
        },
      },
      {
        dataField: 'categoryLevel3Id',
        text: 'Categoria N3',
        sort: true,
        formatter: (cellContent) => {
          const categoryDescription = categoriesLevel3.filter((category) => category.id === cellContent);
          return categoryDescription[0] ? categoryDescription[0].description : 'Sin categoria asociada';
        },
      },
      {
        dataField: 'erpCode',
        text: 'Código ERP',
        sort: true,
      },
      {
        dataField: 'description',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'active',
        text: 'Estado',
        sort: true,
        formatter: (cellContent) => {
          const estado = cellContent ? 'Activo' : 'Inactivo';
          return estado;
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <>
                  <LinkContainer to={`/supplies/${row.id}`}>
                    <Button size="sm" variant="outline-primary" title="Editar">
                      <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                    </Button>
                  </LinkContainer>

                </>
              ) : (
                <></>
              )}
              <ButtonGroup>
                  <Button size="sm" variant="outline-primary" title={row.imagenes.length === 0 ? "Insumo Sin Imagen" : "Ver Insumo"} onClick={() => this.showRenderImageMagnify(row)}>
                      <FontAwesomeIcon icon={faImage} fixedWidth size="xs" />  
                  </Button>
              </ButtonGroup>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        {this.renderImageMagnify()}
        <h1 className="page-title">Insumos</h1>
        <Card>
          <Card.Header>Filtros de búsqueda</Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <p className="m-0">
                  Articulo + Descripción
                  <button
                    id="clearInput-Articulos"
                    type="submit"
                    className="link-button text-primary"
                    onClick={() => {
                      articulosRef.current.clear();
                      this.setState({ selectedInsumoIds: [], filterStringInsumo: '' });
                    }}>
                    (Limpiar filtro)
                  </button>
                </p>
                <Typeahead
                  defaultSelected={[]}
                  labelKey={(option) => {
                    const label = `Codigo ERP: ${option.erpCode} | Descripción: ${option.description}`;
                    return label;
                  }}
                  id={'selectedInsumoIds'}
                  label="Typeahead"
                  key={'articulos'}
                  style={{ paddingTop: '1.80rem' }}
                  onChange={(selected) => {
                    if (selected.length) {
                      this.handleArticles(selected);
                    }
                  }}
                  ref={articulosRef}
                  selected={selectedInsumoIds}
                  options={insumosFilter}
                  placeholder="Ingrese erp"
                  filterBy={['description', 'erpCode']}
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="selectedTipoArticulo"
                  label={this.createSelectAllButtons('Tipo de Articulo')}
                  value={selectedTipoArticulo}
                  onChange={this.handleDataChange}
                  choices={insumosTypes}
                  choiceIdField="id"
                  choiceLabelField="description"
                  multiple
                />
                {this.renderSelectedArticlesTypes()}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormSelectField
                  id="selectedCategoriaUno"
                  label={this.createSelectAllButtons('Categoria 1')}
                  value={selectedCategoriaUno}
                  onChange={this.handleDataChange}
                  choices={categoriesLevel1}
                  choiceIdField="id"
                  choiceLabelField="description"
                  multiple
                />
                {this.renderSelectedCateroriesTypeOne()}
              </Col>
              <Col md={4}>
                <FormSelectField
                  id="selectedCategoriaDos"
                  label={this.createSelectAllButtons('Categoria 2')}
                  value={selectedCategoriaDos}
                  onChange={this.handleDataChange}
                  choices={categoriesLevel2}
                  choiceIdField="id"
                  choiceLabelField="description"
                  multiple
                />
                {this.renderSelectedCateroriesTypeTwo()}
              </Col>
              <Col md={4}>
                <FormSelectField
                  id="selectedCategoriaTres"
                  label={this.createSelectAllButtons('Categoria 3')}
                  value={selectedCategoriaTres}
                  onChange={this.handleDataChange}
                  choices={categoriesLevel3}
                  choiceIdField="id"
                  choiceLabelField="description"
                  multiple
                />
                {this.renderSelectedCateroriesTypeThree()}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex flex-row justify-content-center">
                  <Button
                    disabled={isDataLoading}
                    className="d-flex py-2 m-1 my-3"
                    variant="primary"
                    onClick={() =>
                      this.onTableUpdate({
                        freeText: '',
                        pagination: { limit: 10, offset: 0 },
                        sorting: { field: 'id', direction: 'ASC' },
                      })
                    }>
                    {!isDataLoading ? (
                      <p className="m-0">Buscar</p>
                    ) : (
                      <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {isPermissionsLoading === false ? (
          <DataTable
            isDataLoading={isDataLoading}
            totalSize={totalSize}
            columns={columns}
            data={insumos}
            onTableUpdate={this.onTableUpdate}
            keyField="id"
            addButton={isSuperAdmin || editPermission ? '/supplies/new' : null}
            defaultSorted={[{ dataField: 'description', order: 'desc' }]}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}
const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(InsumoList));
