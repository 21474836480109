import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormSelectField, FormInputField, FormSwitch } from '../../components';

import APIClient from '../../services/APIClient';

class CategoriaEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: null,
      levels: [],
      existsSuppliesWithThisCategory: false,
    };
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;

    const categoriesRes = await APIClient.get(`/categorias/${id}`);

    const entity = categoriesRes.data.data;

    this.setState({ entity });
    this.loadSuppliesWithThisCategory();
    return entity;
  };

  loadSuppliesWithThisCategory = async () => {
    const { id, entity,  } = this.state;
    let url = '';
    const level = entity.categoryLevel.description;
    switch (level) {
      case 'Categoría Nivel 1':
        url = `/insumos?filter[category_level_1_id][eq]=${id}`;
        break;
      case 'Categoría Nivel 2':
        url = `/insumos?filter[category_level_2_id][eq]=${id}`;
        break;
      case 'Categoría Nivel 3':
        url = `/insumos?filter[category_level_3_id][eq]=${id}`;
        break;
    }
    const insumosWithThisCategoryRes = await APIClient.get(url);
    const existsSuppliesWithThisCategory = insumosWithThisCategoryRes.data.data.length > 0;
    this.setState({ existsSuppliesWithThisCategory,  });
  };

  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/categorias', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/categorias/${id}`, entityToSave);
    }

    toastManager.add(
      `Categoría #${saveResponse.data.data.id} guardada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/categories'),
    );
    return saveResponse.data.data;
  };

  onLoadForm = async () => {
    // get categories
    const categoriesLevelsRes = await APIClient.get(`/categorias-niveles`);

    this.setState({
      levels: categoriesLevelsRes.data.data,
    });
  };

  render() {
    const { entity, isAdding, levels, existsSuppliesWithThisCategory } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Categoría nueva' : `Categoría #${entity && entity.description}`}</h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}>
          <>
            <Row>
              <Col md={6}>
                <FormInputField id="code" label="Codigo" type="text" defaultValue={entity && entity.code} required />
              </Col>
              <Col md={6}>
                <FormInputField id="description" label="Descripción" type="text" defaultValue={entity && entity.description} required />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelectField
                  id="categoryLevelId"
                  label="nivel"
                  defaultValue={entity && entity.categoryLevelId}
                  choices={levels}
                  choiceIdField="id"
                  choiceLabelField="description"
                />
              </Col>
              <Col md={6}>
                <FormSwitch id="active" label={'Activo'} checked={entity ? entity.active : true} labelField="active" />
                {existsSuppliesWithThisCategory ? <span className="bg-warning">Existen insumos con esta categoría asignada</span> : ''}
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(CategoriaEdit);
