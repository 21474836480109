import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import localStorage from '../../services/Security';
import AddingProduct from './AddingProduct';
import ViewProduct from './ViewProduct';
import APIClient from '../../services/APIClient';
import Loading from '../../components/Loading';
import nid from 'nid';

class StocklistIngreso extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;
    this.state = {
      isAdding: typeof id === 'undefined',
      entity: {},
      movementMotives: [],
      providers:[],
      clients:[],
      productStatuses: [],
      productTypes: [],
      selectedPT: { id: 0, description: 'elegir tipo' },
      warehouses: [],
      sections: [],
      locations: [],
      products: [],
      arrayValidateIndex: [],
      movementsToPost: [{ id: '0' }],
      loaded: false,
      waitingQuery: false, 
      userId: localStorage.getSession().user.id,
    };
    this.requiredFields = ['productTypeId', 'units', 'typeId', 'motiveId', 'warehouseId', 'sectionId', 'locationId', 'productStatusId'];

  }

  async componentDidMount() {
    await this.onLoadForm();
  }


  onLoadForm = async () => {
    const { toastManager } = this.props;

    try {
      // get movementMotives
      const movementMotivesRes = await APIClient.get('/stocklist-movement-motives');
      // get movementTypes
      const movementTypesRes = await APIClient.get('/stocklist-movement-types');
      // get productStatuses
      const productStatusesRes = await APIClient.get('/stocklist-product-statuses');
      // get productTypes
      const productTypesRes = await APIClient.get('/stocklist-product-types');
      // get warehouses
      const warehousesRes = await APIClient.get('/warehouses?filter[disabled][eq]=0');
      // get sections
      const sectionsRes = await APIClient.get('/sections?filter[disabled][eq]=0');
      // get locations
      const locationsRes = await APIClient.get('/locations?filter[disabled][eq]=0');
      //get articulos
      const comercializablesRes = await APIClient.get('/articulos');
      // get insumos
      const insumosRes = await APIClient.get('/insumos?filter[active][eq]=1');
      // get proveedores
      const proveedoresRes = await APIClient.get('/proveedores?filter[active][eq]=1');
       // get clientes
      const clientesRes = await APIClient.get('/clientes');
      // get clientes
      const unidadesRes = await APIClient.get('/unidades-medida');
      this.setState({
        movementMotives: movementMotivesRes.data.data,
        movementTypes: movementTypesRes.data.data,
        productStatuses: productStatusesRes.data.data,
        productStatusesCopy: productStatusesRes.data.data,
        productTypes: productTypesRes.data.data,
        warehouses: warehousesRes.data.data,
        sections: sectionsRes.data.data,
        sectionsCopy: sectionsRes.data.data,
        locations: locationsRes.data.data,
        locationsCopy: locationsRes.data.data,
        comercializables: comercializablesRes.data.data,
        insumos: insumosRes.data.data,
        providers : proveedoresRes.data.data,
        clients:clientesRes.data.data,
        unidades:unidadesRes.data.data 
      });
    } catch (err) {
      console.error('Cargando datos para movimiento de stock', err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        loaded: true,
      });
    }
  };

  saveAllMovements = async () => {

    const { toastManager } = this.props;
    let { movementsToPost, productTypes, userId } = this.state;

    try {
      const idComercializable = productTypes.filter(pt => pt.description === 'comercializable')[0].id;
      movementsToPost = movementsToPost.filter((m) => m.id !== '0');
      movementsToPost.forEach(m => delete m.id);
      movementsToPost.forEach(m => {
          if(parseInt(m.productTypeId) === idComercializable) {delete  m.insumoId }
          else { delete m.articuloId }
          m.userId = userId
      });

      this.setState((prevState) => ({
        ...prevState,
        waitingQuery: true,
      }));
      await APIClient.post('/stocklist', movementsToPost);
      this.props.history.push('/stocklist')
      toastManager.add(`Stock guardado correctamente`, {
        appearance: 'success',
        autoDismiss:true

      });

    } catch (err) {
      console.error('Cargando datos de stock', err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    }
  };

  saveMovementInState = (movement) => {
    const { movementsToPost } = this.state;
    movementsToPost.push(movement);
    this.setState((prevState) => ({
      ...prevState,
      movementsToPost,
    }));
  };

  removeMovement = (id) => {
    const { movementsToPost } = this.state;
    const toKeep = movementsToPost.filter((mtp, i) => mtp.id !== id);
    this.setState({
      movementsToPost: toKeep,
    });
  };

  render() {
    const {
      entity,
      isAdding,
      movementMotives,
      providers,
      clients,
      productStatuses,
      productStatusesCopy,
      sectionsCopy,
      locationsCopy,
      productTypes,
      selectedPT,
      products,
      warehouses,
      sections,
      locations,
      movementTypes,
      arrayValidateIndex,
      movementsToPost,
      loaded,
      comercializables,
      insumos,
      waitingQuery,
      unidades,
      editPermission,
      isSuperAdmin
    } = this.state;

    return loaded ? (
      <Fragment>
        <h1 className="page-title">{isAdding ? 'Igresar al stock' : ''}</h1>
        {movementsToPost.map((m, i) => {
          return m.id === '0' ? (
            <AddingProduct
              key={nid()}
              id={m.id}
              entity={entity}
              movement={m}
              movementMotives={movementMotives}
              providers={providers}
              clients={clients}
              udm={unidades}
              movementTypes={movementTypes}
              productStatuses={productStatuses}
              productStatusesCopy={productStatusesCopy}
              productTypes={productTypes}
              products={products}
              warehouses={warehouses}
              sections={sections}
              sectionsCopy={sectionsCopy}
              locations={locations}
              locationsCopy={locationsCopy}
              arrayvalidateindex={arrayValidateIndex}
              saveMovementInState={this.saveMovementInState}
              removeMovement={this.removeMovement}
                  /* permissions */
              isSuperAdmin={isSuperAdmin}
              editPermission={editPermission}
            />
          ) : (
            <ViewProduct
              key={m.id}
              id={m.id}
              movement={m}
              movementMotives={movementMotives}
              providers={providers}
              clients={clients}
              udm={unidades}
              movementTypes={movementTypes}
              productStatuses={productStatuses}
              productStatusesCopy={productStatusesCopy}
              productTypes={productTypes}
              selectedPT={selectedPT}
              warehouses={warehouses}
              sections={sections}
              sectionsCopy={sectionsCopy}
              locations={locations}
              locationsCopy={locationsCopy}
              removeMovement={this.removeMovement}
              comercializables={comercializables}
              insumos={insumos}
            />
          );
        })}
        {movementsToPost.length > 1 ? (
          <Button key="submitBtn" variant="primary" onClick={() => this.saveAllMovements()}>
            {waitingQuery ?   <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />: 'Guardar'}
          </Button>
        ) : (
          ''
        )}
      </Fragment>
    ) : (
      <Loading />
    );
  }
}

export default withToastManager(StocklistIngreso);
