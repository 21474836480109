import React, { Component, Fragment } from 'react';
import { Col, Row, } from 'react-bootstrap';
import APIClient from "../services/APIClient";
import { pendingsMapper } from './MonitorPendientes/Utils';
import './pendingsPrint.css';

const PENDINGS_FOR_PAGE = 84;
const PENDINGS_FOR_COLUMN = 28;

class PendingsPrint extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: '',
      customer: {},
      isAdding: typeof id === "undefined",
      detalles: [],
      entity: {},
      date: '',
      pagesData: [],
    };
  }
  
  //This function buil the actual date
  buildDate = () => {
    const dateNow = new Date();

    let dd = dateNow.getDate();
    let mm =  dateNow.getMonth() + 1;
    let yyyy = dateNow.getFullYear();
    
    this.setState({
      date: `${dd}/${mm}/${yyyy}`
    })
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    try {
      const { entity, customer } = await this.loadData(id);

      //Sort the data by code
      const sortPendings = entity.sort((c, d) => {
        
        let a = c.erpCodigo
        let b = d.erpCodigo
        // force null and undefined to the bottom
        a = a === null || a === undefined ? '' : a
        b = b === null || b === undefined ? '' : b
        // force any string values to lowercase
        a = typeof a === 'string' ? a.toLowerCase() : a
        b = typeof b === 'string' ? b.toLowerCase() : b
        // Return either 1 or -1 to indicate a sort priority
        if (a > b) {
        return 1
        }
        if (a < b) {
        return -1
        }
        // returning 0, undefined or any falsey value will use subsequent sorts or
        // the index as a tiebreaker
        return 0
      })
      const pagesData = this.buildPagination(sortPendings);
      this.setState({ id, customer, pagesData });
      document.title = customer.razonSocial ? `customer - ${customer.razonSocial}` : `customer - backoffice`;

      //initialize date
      this.buildDate();

      return window.print();
      // return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  loadData = async (id) => {
    const pendingsRes = await APIClient.get(`/monitor-pendientes/${id}`);
    const customerRes = await APIClient.get(`/clientes/${id}`);
    const pendings = pendingsMapper(pendingsRes.data.data);
    let entity = pendings;
    const customer = customerRes.data.data;

    return { entity, customer};
  };

  buildPagination = (pendings) => {
    const totalPendings = pendings.length;

    const pages = Math.ceil(totalPendings/PENDINGS_FOR_PAGE);
    const pagesData = [];

    for (let i = 0; i < pages; i += 1) {
      let page = pendings;
      let aux = i + 1;
      page = page.slice(PENDINGS_FOR_PAGE * i, PENDINGS_FOR_PAGE * aux);
      let columns = this.buildColumns(page);
      let obj = {
        pageId: aux,
        columns: columns,
      }
      pagesData.push(obj);
    }

    return pagesData
  }

  buildColumns = (page) => {
    const columns = Math.ceil(page.length/PENDINGS_FOR_COLUMN);
    const pendingsColumns = [];

    for (let i = 0; i < columns; i += 1) {
      let pendingsColumn = page;
      let aux = i + 1;

      pendingsColumn = pendingsColumn.slice(PENDINGS_FOR_COLUMN * i, PENDINGS_FOR_COLUMN * aux);
      let obj = {
        colId: aux,
        pendingsColumn,
      }
      pendingsColumns.push(obj);
    }
  
    return pendingsColumns;
  }

  render() {
    const { customer, pagesData } = this.state;
    return (
      pagesData.map(page =>
          
          <Fragment key={page.pageId}>
            <div className="pendings-print-container">
              <div className="title">
                <h4 className="d-flex">
                  customer:  <span className="ml-1"><b>{customer.razonSocial}</b></span>
                </h4>
              </div>
              <Fragment>
                  <Row>
                    {
                      page.columns.map(column =>
                        <Col md={4} className="pendings-print-colRenderTable" key={column.colId}>
                          <table className="pendings-print-table" border="0">
                            < thead >
                              <tr>
                                <th>Código</th>
                                <th>Cant.</th>
                              </tr>
                            </thead >
                            < tbody >
                            {
                              column.pendingsColumn.map(pending =>
                                <tr key={pending.erpCodigo}>
                                  <td>{pending.erpCodigo}</td>
                                  <td>{pending.noEntregado}</td>
                                </tr>
                              )
                            }
                            </tbody >
                          </table>
                        </Col>
                      )
                    }
                  </Row>
              </Fragment>
              <footer>
                <div className="page-number d-flex justify-content-end mt-1">{`Pendientes de entrega pág ${page.pageId}`}</div>
                <div className="page-number d-flex justify-content-end mt-1">{this.state.date}</div>
              </footer>
            </div>
          </Fragment>
      )
    );
  }
}

export default PendingsPrint;
