import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar, Col, InputGroup, Modal, Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import { connect } from 'react-redux'

class ListaPrecioList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      allSelected: false,
      isDataLoading: true,
      isPriceDataLoading: true,
      isSavingPrices: false,
      lineas: [],
      listaPrecioDetalle: [],
      listasPrecio: [],
      modificador: 0,
      selectedLista: [],
      showPriceModal: false,
      totalSize: 0,
    };

    this.loadListasPrecio = this.loadListasPrecio.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }
  
  componentDidMount() {
    this.loadListasPrecio();
  }

  /**
   * update state prices using modificador after clicking "Aplicar" button
   */
  applyModificador = () => {
    const { allSelected } = this.state;
    this.setState((prevState) => {
      const newDetalles = [...prevState.listaPrecioDetalle];
      let { modificador: updatedModificador } = prevState;
      updatedModificador = updatedModificador / 100 + 1;
      if (allSelected) {
        newDetalles.forEach((det) => {
          det.porcentaje = parseInt(prevState.modificador, 10);
          det.precioFinal = Math.round(det.precio * updatedModificador * 100) / 100;
        });
      } else {
        const selectedDetalles = newDetalles.filter((det) => det.isSelected);
        selectedDetalles.forEach((det) => {
          det.precioFinal = Math.round(det.precio * updatedModificador * 100) / 100;
          det.porcentaje = Math.round(prevState.modificador * 100) / 100;
          const index = newDetalles.findIndex((newDet) => newDet.id === det.id);
          newDetalles.splice(index, 1, det);
        });
      }
      return {
        listaPrecioDetalle: newDetalles,
        isPriceDataLoading: false,
      };
    });
  };

  showModalAndGetLista = async (row) => {
    const { selectedLista } = this.state;
    const { toastManager } = this.props;
    if (selectedLista !== {}) {
      try {
        let newSelectedLista = await APIClient.get(`/listas-precio/${row.id}`);
        const lineas = await APIClient.get('/lineas');
        newSelectedLista = newSelectedLista.data.data;
        const detalles = [...newSelectedLista.detalles];
        detalles.forEach((det) => {
          det.isSelected = false;
          det.porcentaje = 0;
          det.precioFinal = det.precio;
        });
        this.setState({
          listaPrecioDetalle: detalles,
          selectedLista: newSelectedLista,
          showPriceModal: true,
          lineas: lineas.data.data,
        });
      } catch (error) {
        toastManager.add(`Ocurrió un error: "${error.message}"`, {
          appearance: 'error',
        });
      } finally {
        this.setState({ isPriceDataLoading: false });
      }
    }
  };

  onTableRef = (props) => {
    this.tableRef = props;
  };

  /**
   * @param  {string} type - change type
   * @param  {number} page - page number
   * @param  {number} sizePerPage - current page size
   * receives table changes, makes custom API calls and saves results to state.
   */
  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    try {
      const { direction } = sorting;
      let { field } = sorting;
      if (field && field === 'linea') {
        field = field.concat('_id');
      }
      if (field && field === 'marca') {
        field = field.concat('Codigo');
      }
      const articulosRes = await APIClient.get(
        `/articulos?limit=${pagination.limit}&offset=${pagination.offset}&freeText=${freeText && `%${freeText}%`}&sortField=${
          field || ''
        }&sortDir=${direction || ''}`,
      );
      const articulos = articulosRes.data.data;
      this.setState({
        articulos,
        totalSize: articulosRes.data.meta.total,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  handleModalClose = () => {
    this.setState({
      showPriceModal: false,
      listaPrecioDetalle: [],
      selectedLista: [],
      modificador: 0,
    });
  };

  renderChangePricesModal = () => {
    const { isPriceDataLoading, listaPrecioDetalle, modificador, selectedLista, showPriceModal, isSavingPrices } = this.state;
    const columns = [
      {
        dataField: 'articulo.erpCodigo',
        text: 'Código',
        sort: true,
        editable: false,
      },
      {
        dataField: 'articulo.descripcion',
        text: 'Artículo',
        sort: true,
        editable: false,
      },
      {
        dataField: 'articulo.linea.descripcion',
        text: 'Línea',
        sort: true,
        editable: false,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        editable: false,
        classes: 'text-right',
        formatter: (cellContent, row) => cellContent.toString().replace('.', ','),
      },
      {
        dataField: 'porcentaje',
        text: 'Porcentaje',
        sort: true,
        editable: false,
        classes: 'text-right',
        formatter: (cellContent, row) => `${cellContent.toString()}%`,
      },
      {
        dataField: 'precioFinal',
        text: 'Precio Final',
        sort: true,
        editable: false,
        classes: 'text-right',
        formatter: (cellContent, row) => cellContent.toString().replace('.', ','),
      },
    ];

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      clickToSelect: true,
      onSelect: this.onSelectDetalle,
      onSelectAll: (isSelect, rows) => {
        const { lineas } = this.state;
        const { searchText } = this.tableRef.searchContext.props;
        if (isSelect) {
          if (searchText.length > 0) {
            const lineaIds = lineas.filter((linea) => linea.descripcion.toLowerCase().match(searchText.toLowerCase())).map((l) => l.id);
            const filteredDetalles = [];
            listaPrecioDetalle.forEach((det) => {
              if (
                lineaIds.some((linea) => linea === det.articulo.lineaId) ||
                (det.articulo.descripcion && det.articulo.descripcion.toLowerCase().match(searchText.toLowerCase()))
              ) {
                det.isSelected = true;
                filteredDetalles.push(det.id);
              }
            });
            return filteredDetalles;
          }
          this.setState({ allSelected: true });
          return listaPrecioDetalle.map((r) => r.id);
        }
        const newUpdatedDetalles = listaPrecioDetalle.map((det) => {
          det.isSelected = false;
          return det;
        });
        this.setState({ listaPrecioDetalle: newUpdatedDetalles, allSelected: false });
        return [];
      },
    };

    return (
      <Modal size="xl" show={showPriceModal} onHide={this.handleModalClose}>
        <Modal.Header closeButton>
          {selectedLista ? <Modal.Title>{selectedLista.descripcion}</Modal.Title> : <Modal.Title>Lista de Precio</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          <Col md={6}>
            <Form.Group controlId="modificador">
              <Form.Label>Modificar en: </Form.Label>
              <InputGroup>
                <Form.Control
                  as="input"
                  name="modificador"
                  type="number"
                  onChange={this.onModificadorChange}
                  value={modificador}
                  min={-100}
                  max={1000}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text>%</InputGroup.Text>
                  <Button onClick={this.applyModificador} className="ml-3" size="sm" variant="primary">
                    Aplicar
                  </Button>
                </InputGroup.Prepend>
                <InputGroup.Prepend></InputGroup.Prepend>
              </InputGroup>
            </Form.Group>
          </Col>
          {selectedLista ? (
            <DataTable
              isDataLoading={isPriceDataLoading}
              columns={columns}
              data={listaPrecioDetalle}
              keyField="id"
              selectRow={selectRowProps}
              onTableRef={this.onTableRef}
              onTableChange={this.onTableUpdate}
              cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                autoSelectText: true,
                afterSaveCell: this.afterSaveSelectedDetalle,
              })}
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleModalClose} disabled={isSavingPrices}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={this.onSavePrecios} disabled={isSavingPrices}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  onLoadForm = async () => {
    const lineas = await APIClient.get('/lineas');
    this.setState({ lineas: lineas.data.data });
  };

  onModificadorChange = (e) => {
    this.setState({ modificador: e.target.value });
  };

  onSavePrecios = async () => {
    const { selectedLista, listaPrecioDetalle } = this.state;
    const { toastManager } = this.props;

    this.setState({ isSavingPrices: true });

    const updatedDetalles = listaPrecioDetalle.filter((det) => det.porcentaje !== 0);
    const detallesToUpdate = updatedDetalles.map((det) => ({ id: det.id, porcentaje: det.porcentaje }));
    
    try {
      
      if (detallesToUpdate.length > 0) {
        const detallesToUpdateId = updatedDetalles.map((det) => det.articuloId);
        await APIClient.patch(`/solicitudes/check-updated-price/${selectedLista.id}`, { toUpdatePrice: detallesToUpdate.length > 0, updatedItemsId:detallesToUpdateId });
      }
      await APIClient.patch(`/listas-precio/${selectedLista.id}/actualizar-precios`, detallesToUpdate);
      toastManager.add(`Lista de Precios ${selectedLista.id} guardada con éxito`, {
        appearance: 'success',
        autoDismiss: true,
      });
      this.setState({
        listaPrecioDetalle: [],
        selectedLista: [],
        modificador: 0,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error al guardar los cambios: ${error}`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ showPriceModal: false, isSavingPrices: false });
    }
  };

  onSelectDetalle = (row, isSelect) => {
    // const { selectedLista } = this.state;
    this.setState((prevState) => {
      const listaPrecioDetalle = [...prevState.listaPrecioDetalle];
      const detalle = listaPrecioDetalle.find((lpd) => lpd.id === row.id);
      detalle.isSelected = isSelect;
      return { listaPrecioDetalle, ...prevState };
    });
  };

  onRetrieveEntity = async () => {
    const { selectedLista } = this.state;
    const { toastManager } = this.props;
    if (selectedLista !== {}) {
      try {
        let newSelectedLista = await APIClient.get(`/listas-precio/${selectedLista.id}`);
        newSelectedLista = newSelectedLista.data.data;
        const detalles = [...newSelectedLista.detalles];
        detalles.forEach((det) => {
          det.isSelected = false;
        });
        this.setState({ listaPrecioDetalle: detalles, selectedLista: newSelectedLista });
      } catch (error) {
        toastManager.add(`Ocurrió un error: "${error.message}"`, {
          appearance: 'error',
        });
      } finally {
        this.setState({ isDataLoading: false });
      }
    }
  };

  async loadListasPrecio() {
    const { toastManager } = this.props;

    try {
      const listasRes = await APIClient.get('/listas-precio');
      this.setState({
        listasPrecio: listasRes.data.data,
      });
    } catch (err) {
      this.setState({ isDataLoading: false });
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  render() {
    const { listasPrecio, isDataLoading, isSuperAdmin, editPermission, isPermissionsLoading } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
      },
      {
        dataField: 'tipo',
        text: 'Tipo',
        sort: true,
      },
      {
        dataField: 'codigo',
        text: 'Código',
        sort: true,
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'eliminadoFlag',
        text: 'Inactivo',
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { textAlign: 'center' },
        formatter: (cellContent, row) => <>{cellContent && <FontAwesomeIcon icon={faTimes} />}</>,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar className="justify-content-center">
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <>
                  <LinkContainer to={`/listas-precio/${row.id}`}>
                    <Button size="sm" variant="outline-primary" title="Editar">
                      <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                    </Button>
                  </LinkContainer>
                  <Button size="sm" variant="outline-primary" onClick={() => this.showModalAndGetLista(row)} title="Modificar Precios">
                    <FontAwesomeIcon icon={faDollarSign} fixedWidth size="xs" />
                  </Button>
                </>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];
    return (
      <div>
        {this.renderChangePricesModal()}
        <h1 className="page-title">Listas de Precio</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            isDataLoading={isDataLoading}
            columns={columns}
            data={listasPrecio}
            keyField="id"
            addButton={isSuperAdmin || editPermission ? '/listas-precio/nuevo' : null}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}


const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(ListaPrecioList));

