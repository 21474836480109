import React from 'react'
import PropTypes from 'prop-types'

import { Typeahead } from 'react-bootstrap-typeahead'
import { Row, Col } from 'react-bootstrap'
import { FormInputField, FormSelectField, EntityEditForm } from '../../components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withToastManager } from 'react-toast-notifications'
import APIClient from '../../services/APIClient'

import nid from 'nid'

class AddingProduct extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor (props) {
    super(props)
    this.state = {
      entity: props.entity,
      movementMotives: props.movementMotives,
      movementTypes: props.movementTypes,
      providers:props.providers,
      clients:props.clients,
      udm:props.udm,
      productStatuses: props.productStatuses,
      productStatusesCopy: props.productStatusesCopy,
      productTypes: props.productTypes,
      selectedPT: { id: 0, description: 'elegir tipo' },
      products: props.products,
      warehouses: props.warehouses,
      sections: props.sections,
      sectionsCopy: props.sectionsCopy,
      locations: props.locations,
      locationsCopy: props.locationsCopy,
      arrayvalidateindex: props.arrayvalidateindex,
      saveMovementInState: props.saveMovementInState,
      entityToSaveFather: {},
      motiveId:[],
      motiveDescription:[],
      clientId:[],
      insumoSelected:[],
      insumoUdmSelected:[],
      formTrackingState: {
        selectedProductType: 0,
        warehouseId: 0,
        sectionId: 0,
        locationId: 0,
      },
      buttons: [
        {
          text: 'Agregar',
          type: 'submit',
          variant: 'outline-secondary',
          classes: 'mr-1',
          icon: <FontAwesomeIcon icon={faPlus} fixedWidth className="mr-1" />,
        },
      ],
      id: props.id,
      disabled: false,
      movement: props.movement,
    }
    this.requiredFields = ['productTypeId', 'units', 'typeId', 'motiveId', 'warehouseId', 'sectionId', 'locationId', 'productStatusId']

  }

 

  onLoadForm = async () => {
   

    const movementMotiveListIngresoQuery =
      '?filter[description][or]=ingreso por compra&filter[description][or]=ingreso devolución venta&filter[description][or]=ingreso por producción&filter[description][or]=ajuste positivo de stock'
    // get movementMotives
    const movementMotivesRes = await APIClient.get(`/stocklist-movement-motives${movementMotiveListIngresoQuery}`)
    // load default products
    this.loadProducts('comercializable')
    this.setState({
      movementMotives: movementMotivesRes.data.data,
    })
  };udmId

  async loadProducts (productType) {
    const { toastManager } = this.props
    let url
    if (productType === 'insumo') url = '/insumos?filter[active][eq]=1'
    if (productType === 'comercializable') url = '/articulos'
    try {
      const productsRes = await APIClient.get(url)
      this.setState({
        products: productsRes.data.data,
      })
    } catch (err) {
      toastManager.add('Error al cargar datos. Recargar página. Checkear conexión a internet', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  /**
   * Save the item
   */

  onSaveEntity = async (entityToSave) => {
    const { entityToSaveFather, movementTypes, saveMovementInState} = this.state
    // typeID allways ingreso
    entityToSaveFather.typeId = movementTypes.filter((mov) => mov.description === 'ingreso')[0].id
    entityToSaveFather.id = nid()
    try {
      await this.formValidation(entityToSaveFather)
      const movementToSave = entityToSaveFather
      await saveMovementInState(movementToSave)
    } catch (err) {
      const resMessage = err.data ? err.data.data.message : err.message
      console.error(resMessage)
    }
  };

  formValidation (postedFields) {
    const postedFieldKeys = Object.keys(postedFields)
    const arrayvalidateindex = this.requiredFields.filter((requiredField) => postedFieldKeys.indexOf(requiredField) < 0)
    this.setState({
      arrayvalidateindex,
      postedFields,
    })
    if (arrayvalidateindex.length > 0) {
      throw { message: 'faltan campos' }
    }
  }

  saveEntityInfatherState (name, valueToSave, fatherContext) {
    const { entityToSaveFather,insumoUdmSelected } = fatherContext.state

    entityToSaveFather[name] = valueToSave
    entityToSaveFather.udmId = insumoUdmSelected

    fatherContext.setState((prevState) => ({
      entityToSaveFather,

    }))

  }

  checkChildStateFromFather (childState, fatherContext) {
    let { formTrackingState, selectedMotive, productStatuses } = fatherContext.state
    const {
      productTypes,
      selectedPT,
      sections,
      sectionsCopy,
      locations,
      locationsCopy,
      productStatusesCopy,
      movementMotives,
    } = fatherContext.state
    const checkProperty = ['productTypeId', 'warehouseId', 'sectionId', 'locationId', 'motiveId','clientId','providerId']
    const selectedProperties = checkProperty.filter((property) => childState.hasOwnProperty(property))
    // set product type in state and call the api in order to get the apropiate list
    if (selectedProperties.length > 0) {
      if (childState.productTypeId) {
        const newSelectedProductTypeId = parseInt(childState.productTypeId)
        selectedPT.id = formTrackingState.selectedProductType
        if (selectedPT.id !== newSelectedProductTypeId) {
          const productTypeSel = productTypes.find((productType) => productType.id === newSelectedProductTypeId)
          const description = productTypeSel ? productTypeSel.description : selectedPT.description
          selectedPT.description = description || ''
          selectedPT.id = newSelectedProductTypeId
          fatherContext.loadProducts(selectedPT.description)
        }
      }
      const getFilteredStatuses = () =>
        productStatusesCopy.filter((status) => status.description === 'disponible' || status.description === 'reproceso')
      if (childState.motiveId) {
        selectedMotive = movementMotives.filter((movMot) => movMot.id === parseInt(childState.motiveId))[0]
        switch (selectedMotive.description) {
          case 'ingreso por compra':
            productStatuses = getFilteredStatuses()
            break
          case 'ingreso devolución venta':
            productStatuses = getFilteredStatuses()
            break
          default:
            productStatuses = productStatusesCopy
            break
        }
      }

      formTrackingState = {
        selectedProductType: parseInt(childState.productTypeId) || 0,
        warehouseId: parseInt(childState.warehouseId) || 0,
        sectionId: parseInt(childState.sectionId) || 0,
        locationId: parseInt(childState.locationId) || 0,
      }
      const { warehouseId, sectionId } = formTrackingState
      fatherContext.setState((prevState) => ({
        ...prevState,
        ...{
          selectedMotive,
          productStatuses,
          selectedPT,
          formTrackingState,
          warehouseId: warehouseId !== 0 ? warehouseId : prevState.warehouseId,
          sections: warehouseId === 0 ? sections : sectionsCopy.filter((section) => section.warehouseId === warehouseId),
          locations: sectionId === 0 ? locations : locationsCopy.filter((location) => location.sectionId === sectionId),
        },
      }))
    }
  }

  handleArticles = (selected) => {
    const { selectedPT } = this.state
    const insumoUdmSelected = selected[0].unidadMedidaId
    this.setState({insumoUdmSelected})
    const article = selected[0] || {}
    const name = selectedPT.description === 'comercializable' ? 'articuloId' : 'insumoId'
    this.saveEntityInfatherState(name, article.id, this)
  }

  handleMotiveChange= (e)=>{
    const {id, value} = e.target
    const {movementMotives} = this.state
    const motiveDescription = movementMotives.find(elem=>elem.id === Number(value))
    this.setState({
      [id]:value,
      motiveDescription:motiveDescription.description
    }) 
  }

  render () {
    const {
      isAdding,
      movementMotives,
      productStatuses,
      productTypes,
      selectedPT,
      products,
      warehouses,
      sections,
      locations,
      arrayvalidateindex,
      buttons,
      id,
      providers,
      clients,
      motiveDescription,
      udm,
      insumoUdmSelected
    } = this.state

    return (
      <div key={id}>
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={() => {}}
          onSaveEntity={this.onSaveEntity}
          checkChildStateFromFather={this.checkChildStateFromFather}
          saveEntityInfatherState={this.saveEntityInfatherState}
          fatherContext={this}
          addMode={isAdding}
          buttons={buttons}>
          <Row noGutters
          style={{ flexWrap: 'nowrap' }}
          >
            <Col md={2} className="mr-1">
              <FormSelectField
                id="motiveId"
                label="Motivo"
                choices={movementMotives}
                onChange={this.handleMotiveChange}
                choiceIdField="id"
                choiceLabelField="description"
                arrayvalidateindex={arrayvalidateindex}
                size="sm"
              />
            </Col>
            {
            
            motiveDescription === 'ingreso por compra' ?(
            <Col md={2} className="mr-1">
              <FormSelectField
                id="providerId"
                label="Proveedor"
                choices={providers}
                choiceIdField="id"
                choiceLabelField="description"
                arrayvalidateindex={arrayvalidateindex}
                size="sm"
              />
            </Col>

              )
              :
              motiveDescription === 'ingreso devolución venta'?(
                <Col md={2} className="mr-1">
                <FormSelectField
                  id="clientId"
                  label="Cliente"
                  choices={clients}
                  choiceIdField="id"
                  choiceLabelField="razonSocial"
                  arrayvalidateindex={arrayvalidateindex}
                  size="sm"
                />
              </Col>
              )
              :
              <></>
            }
            <Col md={2} className="mr-1 ml-1">
              <FormSelectField
                id="productTypeId"
                label="Tipo"
                choices={productTypes}
                choiceIdField="id"
                choiceLabelField="description"
                arrayvalidateindex={arrayvalidateindex}
                size="sm"
                defaultValue={0}
              />
            </Col>
            <Col md={3}>
              <p style={{ marginBottom: 0, marginBlockStart: 0, marginBlockEnd: 0, marginInlineStart: 0, marginInlineEnd: 0 }}>
                {!selectedPT.description ? `Productos: ${selectedPT.description}` : selectedPT.description}
              </p>
              <Typeahead
                defaultSelected={[]}
                labelKey={(option) => {
                  const label =
                    selectedPT.description === 'comercializable'
                      ? `${option ? option.erpCodigo : ''} - ${option ? option.descripcion : ''}`
                      : `${option ? option.erpCode : ''} - ${option ? option.description : ''}`
                  return label
                }}
                id={selectedPT.description === 'comercializable' ? 'articuloId' : 'insumoId'}
                label="Typeahead"
                key={'articulos'}
                onChange={(selected) => {
                  if (selected.length) {
                    this.handleArticles(selected)
                  }
                }}
                arrayvalidateindex={arrayvalidateindex}
                options={products}
                placeholder="Ingrese erp"
                disabled={selectedPT.description === 'Elegir tipo'}
                filterBy={selectedPT.description === 'comercializable' ? ['descripcion', 'erpCodigo'] : ['description', 'erpCode']}
              />
            </Col>
            <Col className="mr-1 ml-1" md={1}>
              <FormInputField
                id="units"
                type="number"
                label="Unidades"
                defaultValue="0"
                min={1}
                
                step={selectedPT.description === "comercializable" ? '1' : '0.1'}
                onChange={() => {}}
                arrayvalidateindex={arrayvalidateindex}
                size="sm"
              />
            </Col>
                {
                 selectedPT.description === "insumo" ? 
                 (
                <Col className=" ml-1" md={1}>
                  <FormInputField
                    id="udmId"
                    type="string"
                    label="UDM"
                    defaultValue={udm.filter(elem=>elem.id === insumoUdmSelected)[0]?.description || ""}
                    size="sm"
                    disabled
                  />
                </Col>
                 ):<></>
                }
           
          

            <Col className=" ml-1" md={1}>
              <FormSelectField
                id="warehouseId"
                label="Depósito"
                choices={warehouses}
                choiceIdField="id"
                choiceLabelField="description"
                arrayvalidateindex={arrayvalidateindex}
                size="sm"
              />
            </Col>
            <Col className=" ml-1" md={1}>
              <FormSelectField
                id="sectionId"
                label="Sector"
                choices={sections}
                choiceIdField="id"
                choiceLabelField="description"
                arrayvalidateindex={arrayvalidateindex}
                size="sm"
              />
            </Col>
            <Col className=" ml-1" md={1}>
              <FormSelectField
                id="locationId"
                label="Posición"
                choices={locations}
                choiceIdField="id"
                choiceLabelField="description"
                arrayvalidateindex={arrayvalidateindex}
                size="sm"
              />
            </Col>
            <Col md={1} className="mr-1 ml-1">
              <FormSelectField
                id="productStatusId"
                label="Estado"
                choices={productStatuses}
                choiceIdField="id"
                choiceLabelField="description"
                onChange={() => {}}
                arrayvalidateindex={arrayvalidateindex}
                size="sm"
              />
            </Col>
          
            <Col className="ml-1" md={2}>
              <FormInputField
                id="description"
                label="Descripcion"
                size="sm"
              />
            </Col>
          </Row>
        </EntityEditForm>
      </div>
    )
  }
}

export default withToastManager(AddingProduct)
