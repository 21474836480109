import { SET_PERMISSIONS, CLEAR_STORE } from '../actions';
const permissionsUser = (state = {
  permissionsUser: {
            isSuperAdmin: false,
            editPermission: false,
            deletePermission: false,
            isPermissionsLoading: true,
          }, 

}, action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return { ...action.value };
      case CLEAR_STORE:
        return {
          permissionsUser: {
            isSuperAdmin: false,
            editPermission: false,
            deletePermission: false,
            isPermissionsLoading: true,
          }, 
        }
    default:
      return state;
  }
};

export default permissionsUser;



