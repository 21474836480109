/* listOutOfStockDetailsModal */
module.exports.columnsOutOfStock = [
  {
    dataField: 'erpCode',
    text: 'Código ERP',
    sort: true,
    editable: false,
  },

  {
    dataField: 'units',
    text: 'Stock requerido',
    headerStyle: { backgroundColor: '#c12f2f', color: '#f8f9fa' },
    style: { textAlign: 'right' },
    sort: true,
    editable: false,
  },
  {
    dataField: 'resta',
    text: 'Stock Disponible',
    style: { textAlign: 'right' },
    headerStyle: { backgroundColor: '#28a745' },
    sort: true,
    editable: false,
  },
  {
    dataField: 'warehouse',
    text: 'Depósito asignado',
    sort: true,
    editable: false,
  },
];

module.exports.columnsNotExistStock = [
  {
    dataField: 'erpCode',
    text: 'Código ERP',
    sort: true,
    editable: false,
  },
  {
    dataField: 'warehouse.description',
    text: 'Depósito asignado',
    sort: true,
    editable: false,
  },
];

