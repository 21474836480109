import axios from 'axios';
import config from '../config';
import Security from './Security';

const apiClient = {
  delete: async (path) => {
    try {
      const promise = await axios.delete(`${config.api.url}${path}`, {
      // withCredentials: true,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (e.response.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  get: async (path) => {
    try {
      const promise = await axios.get(`${config.api.url}${path}`, {
      // withCredentials: true,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (e.response.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  getImage: async (path, opts) => {
    try {
      const promise = await axios.get(`${path}`, {
      // withCredentials: true,
        ...opts,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (e.response.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  getFile: async (path, opts) => {
    try {
      const promise = await axios.get(`${path}`, {
      // withCredentials: true,
        ...opts,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (e.response.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  post: async (path, data) => {
    try {
      const promise = await axios.post(`${config.api.url}${path}`, data, {
      // withCredentials: true,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (e.response.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  patch: async (path, data) => {
    try {
      const promise = await axios.patch(`${config.api.url}${path}`, data, {
      // withCredentials: true,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (e.response.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  downloadFile: async (url) => {
    return axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    })
  },
};

export default apiClient;
