import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormSelectField, FormInputField, FormCheckField } from '../../components';
import APIClient from '../../services/APIClient';

class ResourceEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { id } = props.match.params;
    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: null,
      productionResourcesTypes: [],
    };
  }

  onLoadForm = async () => {
    const productionResourcesTypesRes = await APIClient.get('/production-resources-types');
    this.setState({
      productionResourcesTypes: productionResourcesTypesRes.data.data,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;
    const expresoRes = await APIClient.get(`/resources/${id}`);
    const entity = expresoRes.data.data;
    this.setState({ entity });
    return entity;
  };

  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;
    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/resources', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/resources/${id}`, entityToSave);
    }
    toastManager.add(
      `Recurso ${saveResponse.data.data.id} guardado con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/resources'),
    );
    return saveResponse.data.data;
  };

  render() {
    const { id, entity, isAdding, productionResourcesTypes } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Recurso de Planta nuevo' : `Recurso de Planta #${id}`}</h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}>
          <>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="description"
                  label="Descripción"
                  placeholder="Inyectora..."
                  type="text"
                  maxLength={20}
                  defaultValue={entity && entity.description}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="availability"
                  label="Disponibilidad (Hs)"
                  type="Number"
                  min={0}
                  max={99999}
                  required
                  defaultValue={entity ? entity.availability : 0}
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="type"
                  label="Tipo"
                  choices={productionResourcesTypes}
                  choiceIdField="id"
                  choiceLabelField="description"
                  type="text"
                  defaultValue={entity && entity.type}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormCheckField id="isActive" label="Activo" defaultChecked={entity ? entity.isActive : true} />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ResourceEdit);
