import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import { connect } from 'react-redux'

class ArticuloList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };
  
  constructor(props) {
    super(props);
    this.apiParams = '';
    this.state = {
      articulos: [],
      isDataLoading: true,
      totalSize: 0,    
    };
    
    this.loadArticulos = this.loadArticulos.bind(this);
  }


  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }
  

  componentDidMount() {
    this.loadArticulos();
  }

  getExportData = async () => {
    const { toastManager } = this.props;
    try {
      const apiResponse = await APIClient.get(`/articulos?${this.apiParams}`);
      return apiResponse.data.data;
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
      return false;
    }
  };

  /**
   * @param  {string} type - change type
   * @param  {number} page - page number
   * @param  {number} sizePerPage - current page size
   * receives table changes, makes custom API calls and saves results to state.
   */
  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    try {
      const { direction } = sorting;
      let { field } = sorting;
      if (field && field === 'linea') {
        field = field.concat('_id');
      }
      if (field && field === 'marca') {
        field = field.concat('Codigo');
      }
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || ''}&sortDir=${
        direction || ''
      }&excludeAssocFields=imagenes`;
      const articulosRes = await APIClient.get(`/articulos?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}`);
      const articulos = articulosRes.data.data;
      this.setState({
        articulos,
        totalSize: articulosRes.data.meta.total,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  async loadArticulos() {
    const { toastManager } = this.props;
    try {
      // get paginated list of articulos
      const articulosRes = await APIClient.get('/articulos?limit=10&offset=0&excludeAssocFields=imagenes');
      this.setState({
        articulos: articulosRes.data.data,
        totalSize: articulosRes.data.meta.total,
        isDataLoading:false,

      });
    } catch (error) {
      console.error('Cargando articulos.', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  }

  render() {
    const { articulos, totalSize, isSuperAdmin, editPermission, isPermissionsLoading } = this.state;

    const columns = [
      {
        dataField: 'erpCodigo',
        text: 'Código',
        sort: true,
      },
      {
        dataField: 'codigoEan13',
        text: 'Ean 13',
        sort: true,
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'marca.descripcion',
        text: 'Marca',
        sort: true,
      },
      {
        dataField: 'linea.descripcion',
        text: 'Línea',
        sort: true,
      },
      {
        dataField: 'byStock',
        text: 'Consumo por Entrega',
        align: 'center',
        sort: true,
        formatter: (cellContent, row) => {
          return UIUtils.getConsumeByStockBadge(row.byStock);
        },
      },
      {
        dataField: 'coleccion.descripcion',
        text: 'Colección',
        csvExport: false,
        // sort: true,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>    
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <LinkContainer to={`/articulos/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Editar">
                    <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Artículos</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            remote={{
              filter: true,
              pagination: true,
              sort: true,
              cellEdit: false,
            }}
            totalSize={totalSize}
            columns={columns}
            data={articulos}
            isDataLoading={isPermissionsLoading}
            onTableUpdate={this.onTableUpdate}
            keyField="id"
            getExportData={this.getExportData}
            addButton={isSuperAdmin || editPermission ? '/articulos/nuevo' : null}
          />
          ) : (
          <Loading />
        )}
      </div>
    );
  }
}
const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(ArticuloList));

