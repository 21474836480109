import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
const LoadingFull = (props) => (

<Modal   size="xs" show={props.showModal} >
<Modal.Header  closeButton>
  <Modal.Title>{props.title}</Modal.Title>
</Modal.Header>
<Modal.Body >
<div className="spinner-grow  text-primary " role="status">
    <span className="sr-only">Loading...</span>
  </div>
  <div className="spinner-grow  text-secondary" role="status">
    <span className="sr-only">Loading...</span>
  </div>
  <div className="spinner-grow  text-success" role="status">
    <span className="sr-only">Loading...</span>
  </div>
  <div className="spinner-grow  text-danger" role="status">
    <span className="sr-only">Loading...</span>
  </div>
  <div className="spinner-grow  text-warning" role="status">
    <span className="sr-only">Loading...</span>
  </div>
  <div className="spinner-grow  text-info" role="status">
    <span className="sr-only">Loading...</span>
  </div>
  <div className="spinner-grow  text-dange" role="status">
    <span className="sr-only">Loading...</span>
  </div>
  <div className="spinner-grow  text-dark" role="status">
    <span className="sr-only">Loading...</span>
  </div>

</Modal.Body>
</Modal>

);
LoadingFull.propTypes = {
  title: PropTypes.string,
  showModal: PropTypes.string.isRequired,
};

LoadingFull.defaultProps = {
  showModal: false,
  title: 'Procesando...',

};

export default LoadingFull;