import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormInputField, FormCheckField, FormSelectField, DataTable } from '../../components';
import APIClient from '../../services/APIClient';

class UsuarioEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      // entity defaults
      entity: {
        isActive: true,
        perfil: {},
      },
      clientes: [],
      vendedores: [],
      roles: [],
      selectedRoles: [],
      deleteRoles: [],
      isDataLoading: true,
    };
  }

  onLoadForm = async () => {
    // get clientes
    const clienteResponse = await APIClient.get('/clientes');
    // get vendedores
    const vendedoresResponse = await APIClient.get('/vendedores');
    // get roles
    const roles = await APIClient.get('/roles');

    this.setState({
      clientes: clienteResponse.data.data,
      vendedores: vendedoresResponse.data.data,
      roles: roles.data.data,
      isDataLoading: false,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get usuario
    const usuarioRes = await APIClient.get(`/usuarios/${id}`);
    const entity = usuarioRes.data.data;
    this.setState({ entity });
    return entity;
  };

  handlerRolesChecks = (e, rol) => {
    const { entity ,selectedRoles , deleteRoles } = this.state;
    const { checked } = e.target;

    let existRol = entity.roles ? entity.roles.some((userRol) => userRol.id === rol.id) : false;
    let newRoles = selectedRoles;
    let newDeleteRoles = deleteRoles;

    if (checked && !existRol) {
      newRoles.push(rol)
    }

    if (!checked && !existRol) {
      newRoles = selectedRoles.filter(selectedRole=>selectedRole.id !== rol.id)
    }

    if (!checked && existRol) {
      deleteRoles.push(rol)
    }
    
    if (checked && existRol) {
      newDeleteRoles = deleteRoles.filter(deleteRole=>deleteRole.id !== rol.id)
    }
    
    this.setState({
        selectedRoles:newRoles,
        deleteRoles:newDeleteRoles,
    });      
      
  }

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, selectedRoles, deleteRoles } = this.state;
    const { history, toastManager } = this.props;
    try {
      entityToSave.roles = selectedRoles;
      entityToSave.rolesToUpdate = deleteRoles;
      let saveResponse = null;
      if (isAdding) {
        saveResponse = await APIClient.post('/usuarios', entityToSave);
      } else {
        const rolesToUpdate = {
          rolesToRemove: deleteRoles,
          rolesToAdd: selectedRoles,
        };
        await APIClient.patch(`/usuarios/roles?userId=${id}`, rolesToUpdate);
        saveResponse = await APIClient.patch(`/usuarios/${id}`, entityToSave);
      }

      history.push('/usuarios');
      if (isAdding) {
        toastManager.add(
          <>
            <p>{`Usuario ${saveResponse.data.data.id} guardado con éxito`}</p>
            <p>
              Se ha generado automáticamente la contraseña:
              <br />
              <strong>{saveResponse.data.data.newPassword}</strong>
            </p>
          </>,
          {
            appearance: 'success',
          },
        );
      } else {
        toastManager.add(`Usuario ${saveResponse.data.data.id} guardado con éxito`, {
          appearance: 'success',
          autoDismiss: true,
        });
      }
      return saveResponse.data.data;
    } catch (error) {
      toastManager.add(`Error al momento de guardar el usuario: ${error.response.data.message}`, {
        appearance: 'error',
      });
    }
  };

  render() {
    const { id, entity, clientes, isAdding, vendedores, isDataLoading, roles } = this.state;

    const columns = [
      
      {
        dataField: '',
        text: '',
        align: 'center',
        editable: false,
        formatter: (cell, row, rowIndex) => {
        let rolChecked = false;
        if(!isAdding) rolChecked = entity.roles.some(rol=>rol.id === row.id)
        return <FormCheckField defaultChecked={rolChecked} id="isActive" onClick={(e) => this.handlerRolesChecks(e,row)} />;
        },
      },
      {
        dataField: 'codigo',
        text: 'codigo',
        sort: true,
      },
      {
        dataField: 'descripcion',
        text: 'descripcion',
        sort: true,
      },
    ];

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Usuario nuevo' : `Usuario #${id}`}</h1>
          <EntityEditForm
            onLoadForm={this.onLoadForm}
            onRetrieveEntity={this.onRetrieveEntity}
            onSaveEntity={this.onSaveEntity}
            addMode={isAdding}>
            <>
              <Row>
                {' '}
                <Col md={6}>
                  <FormCheckField
                    id="isNotifiedNewOrder"
                    label="Recibir notificaciones de Producción"
                    defaultChecked={entity.isNotifiedNewOrder}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormInputField id="firstName" label="Nombre" type="text" defaultValue={entity.firstName} required />
                  <FormInputField id="lastName" label="Apellido" type="text" defaultValue={entity.lastName} required />
                </Col>
                <Col md={6}>
                  <FormInputField id="username" label="Username (login)" type="text" defaultValue={entity.username} required />
                  <FormInputField id="emailAddress" label="E-mail" type="text" defaultValue={entity.emailAddress} required />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormSelectField
                    id="perfil.clienteId"
                    label="Cliente"
                    defaultValue={entity.perfil.clienteId}
                    choices={clientes}
                    choiceIdField="id"
                    choiceLabelField="razonSocial"
                  />
                </Col>
                <Col md={6}>
                  <FormSelectField
                    id="perfil.vendedorId"
                    label="Ejecutivo comercial"
                    defaultValue={entity.perfil.vendedorId}
                    choices={vendedores}
                    choiceIdField="id"
                    choiceLabelField="nombre"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-2">
                  <FormCheckField id="isActive" label="Activo" defaultChecked={entity.isActive} />

                </Col>
                <Col md={12} className="mb-2">
                <FormCheckField id="isSuperAdmin" label="Super Adminsitrador" defaultChecked={entity.isSuperAdmin} />
                </Col>


               <h2>Roles de usuario</h2>
                <DataTable 
                columns={columns} 
                data={roles} 
                isDataLoading={isDataLoading} 
                keyField="id" 
                showSearch={false}
                showExport={false}
                />
              </Row>
            </>
          </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(UsuarioEdit);
