/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import { Button, Card, Col, Row } from 'react-bootstrap';

import { DataTable, FormInputField, FormSelectField, Loading } from '../../components';

import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import { connect } from 'react-redux'

class ProductionReportList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      lines: [],
      reports: [],
      itemsType: [],
      statusOrder: [],
      categories: [],
      isDataLoading: true,
      isDataLoadingReports: false,

      itemStatus: [
        { state: 'Sin Finalizar', isFinalized: false },
        { state: 'Finalizado', isFinalized: true },
      ],
      rangeType: [
        { description: 'Fecha Alta', id: 'createdAt' },
        { description: 'Fecha de finalizacion', id: 'endDate' },
      ],

      curToDate: '',
      curFromDate: '',
      lineSelected: '',
      selectedRange: '',
      orderIdSelected: '',
      categorySelected: '',
      itemsTypeSelected: '',
      itemStatusSelected: '',
      orderStatusSelected: '',
      itemsTypeSelectedDescription: '',
    };

    this.loadReports = this.loadReports.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }

  componentDidMount() {
    this.loadReports();
  }

  updateList = async () => {
    const { toastManager } = this.props;
    const {
      curToDate,
      curFromDate,
      lineSelected,
      selectedRange,
      orderIdSelected,
      categorySelected,
      itemsTypeSelected,
      itemStatusSelected,
      orderStatusSelected,
    } = this.state;

    let reports = [];
    const validatedDate = (selectedRange !== '' && curToDate === '') || (selectedRange !== '' && curFromDate === '');
    if (validatedDate === true) {
      toastManager.add('Complete el rango de fecha Correctamente', {
        appearance: 'warning',
      });
    } else {
      this.setState({ isDataLoadingReports: true });
      const queryParams = `selectedRange=${selectedRange}&productTypeId=${itemsTypeSelected}&lineId=${lineSelected}&categoryId=${categorySelected}&finalizedFather=${itemStatusSelected}&createdAt=${curFromDate}&endDate=${curToDate}&statusId=${orderStatusSelected}&productionOrderId=${orderIdSelected}`;
      const reportsRes = await APIClient.get(`/production-reports?${queryParams}`);
      reports = reportsRes.data.data;
      this.setState({
        reports,
        isDataLoadingReports: false,
      });
    }
  };

  handleChangeValueType = (e) => {
    const { value, id } = e.target;
    const { itemsType } = this.state;
    let valueDescription = '';
    if (value !== '') {
      valueDescription = itemsType.find((elem) => elem.id === Number(value)).description;
    }
    this.setState({
      [id]: value,
      itemsTypeSelectedDescription: valueDescription,
      lineSelected: '',
      categorySelected: '',
    });
  };

  handleChangeValue = (e) => {
    const { value, id } = e.target;
    this.setState({
      [id]: value,
    });
  };

  cleanFilters = () => {
    this.setState({
      curToDate: '',
      curFromDate: '',
      lineSelected: '',
      selectedRange: '',
      orderIdSelected: '',
      categorySelected: '',
      itemsTypeSelected: '',
      itemStatusSelected: '',
      orderStatusSelected: '',
      itemsTypeSelectedDescription: '',
    });
  };

  getExportData = async () => {
    const { reports } = this.state;
    const { toastManager } = this.props;

    try {
      const queryParams = `dataReports=${JSON.stringify(reports)}`;
      const apiResponse = await APIClient.get(`/production-reports/export-csv?${queryParams}`);
      return apiResponse.data.data;
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
      return false;
    }
  };

  async loadReports() {
    const { toastManager } = this.props;
    try {
      const reportsRes = await APIClient.get('/production-reports');
      const itemsTypeRes = await APIClient.get('/stocklist-product-types');
      const linesRes = await APIClient.get('/lineas');
      const categoriesRes = await APIClient.get('/categorias?filter[category_level_id][eq]=1');
      const statusOrderRes = await APIClient.get('/production-orders-detail-status');
      statusOrderRes.data.data.forEach((statu) => {
        const statuOrder = statu;
        if (statuOrder.description === 'Available') {
          statuOrder.description = 'Activa';
        }
        if (statuOrder.description === 'Stopped') {
          statuOrder.description = 'Pausada';
        }
        if (statuOrder.description === 'Canceled') {
          statuOrder.description = 'Cancelada';
        }
        if (statuOrder.description === 'Finished') {
          statuOrder.description = 'Finalizada';
        }
      });
      this.setState({
        reports: reportsRes.data.data,
        itemsType: itemsTypeRes.data.data,
        lines: linesRes.data.data,
        categories: categoriesRes.data.data,
        statusOrder: statusOrderRes.data.data,
        isDataLoading: false,
      });
    } catch (err) {
      this.setState({ isDataLoading: false });
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  render() {
    const {
      lines,
      reports,
      curToDate,
      itemsType,
      rangeType,
      itemStatus,
      categories,
      statusOrder,
      curFromDate,
      selectedRange,
      orderIdSelected,
      itemsTypeSelected,
      itemStatusSelected,
      orderStatusSelected,
      isPermissionsLoading,
      isDataLoadingReports,
      itemsTypeSelectedDescription,
    } = this.state;

    const columns = [
      {
        dataField: 'productionOrderId',
        text: 'N° Orden',
        sort: true,
      },
      {
        dataField: 'productType.description',
        text: 'Tipo',
        sort: true,
      },
      {
        dataField: 'line.descripcion',
        text: 'Linea',
        sort: true,
      },
      {
        dataField: 'category.description',
        text: 'Categoria',
        sort: true,
      },
      {
        dataField: 'description',
        text: 'Descripcion',
        sort: true,
      },
      {
        dataField: 'erpCode',
        text: 'Código ERP',
        sort: true,
      },
      {
        dataField: 'units',
        text: 'Cantidad',
        sort: true,
        align: 'rigth',
      },
      {
        dataField: 'orderFather.createdAt',
        text: 'Fecha de Alta',
        sort: true,
        formatter: (cellContent, row) => {
          const createdAtDate = moment(row.orderFather.createdAt).format('DD/MM/YYYY');
          return createdAtDate;
        },
      },
      {
        dataField: 'orderFather.endDate',
        text: 'Fecha de Finalizacion',
        sort: true,
        formatter: (cellContent, row) => {
          const orderEndDate =
            row.orderFather.status?.description === 'Available' || row.orderFather.status?.description === 'Stopped'
              ? ''
              : moment(row.orderFather.endDate).format('DD/MM/YYYY');
          return orderEndDate;
        },
      },
      {
        dataField: 'finalizedFather',
        text: 'Estado Articulo',
        sort: true,
        formatter: (cellContent, row) => UIUtils.getItemStatusBadge(row.finalizedFather),
      },
      {
        dataField: 'orderFather.status.description',
        text: 'Estado Orden',
        align: 'center',
        sort: true,
        formatter: (cellContent, row) => {
          const statusDescription = row.orderFather.status.description;
          return UIUtils.getOrderStatusBadge(statusDescription);
        },
      },
    ];
    return (
      <div>
        <h1 className="page-title">Reporte de Producción</h1>
        {isPermissionsLoading === false ? (
          <>
            <Card className="my-3">
              <Card.Header>
                <Button variant="warning" onClick={() => this.cleanFilters()} className="float-right">
                  Limpiar Filtros
                </Button>
                Filtros de búsqueda
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <FormInputField
                      placeholder="32.."
                      id="orderIdSelected"
                      label="N° Orden"
                      value={orderIdSelected}
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                  <Col md={8}>
                    <FormSelectField
                      id="orderStatusSelected"
                      label="Estado de la Orden"
                      value={orderStatusSelected}
                      choices={statusOrder}
                      choiceIdField="id"
                      choiceLabelField="description"
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="itemsTypeSelected"
                      label="Tipo de Articulo"
                      choices={itemsType}
                      value={itemsTypeSelected}
                      choiceIdField="id"
                      choiceLabelField="description"
                      onChange={this.handleChangeValueType}
                    />
                  </Col>

                  {itemsTypeSelectedDescription === 'comercializable' ? (
                    <Col md={8}>
                      <FormSelectField
                        id="lineSelected"
                        label="Linea"
                        choices={lines}
                        choiceIdField="id"
                        choiceLabelField="descripcion"
                        onChange={this.handleChangeValue}
                      />
                    </Col>
                  ) : itemsTypeSelectedDescription === 'insumo' ? (
                    <Col md={8}>
                      <FormSelectField
                        id="categorySelected"
                        label="Categoria"
                        choices={categories}
                        choiceIdField="id"
                        choiceLabelField="description"
                        onChange={this.handleChangeValue}
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="selectedRange"
                      label="Rango de fecha"
                      onChange={this.handleChangeValue}
                      value={selectedRange}
                      choices={rangeType}
                      choiceIdField="id"
                      choiceLabelField="description"
                    />
                  </Col>
                  <Col md={4}>
                    <FormInputField
                      id="curFromDate"
                      type="date"
                      label="Fecha desde:"
                      disabled={selectedRange === ''}
                      value={curFromDate}
                      defaultValue={curFromDate !== '' ? curFromDate : null}
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                  <Col md={4}>
                    <FormInputField
                      id="curToDate"
                      type="date"
                      disabled={curFromDate === ''}
                      label="Fecha hasta:"
                      value={curToDate}
                      defaultValue={curToDate !== '' ? curToDate : null}
                      min={curFromDate !== '' ? curFromDate : null}
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="itemStatusSelected"
                      label="Estado del Articulo"
                      choices={itemStatus}
                      value={itemStatusSelected}
                      choiceIdField="isFinalized"
                      choiceLabelField="state"
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Button onClick={this.updateList} className="float-right">
                  {isDataLoadingReports === false ? <span>Filtrar</span> : <span>Cargando...</span>}
                </Button>
              </Card.Footer>
            </Card>
            <DataTable
              wrapperClasses="table-responsive"
              sort={{ dataField: 'orderFather.createdAt', order: 'desc' }}
              isDataLoading={isDataLoadingReports}
              columns={columns}
              data={reports}
              keyField="id"
              getExportData={this.getExportData}
            />
          </>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(ProductionReportList));


