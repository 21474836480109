import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import { connect } from 'react-redux'

class ProveedorList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      isDataLoading: true,
      selectedProveedor: {},
      estados: [
        { active: true, state: 'Activo' },
        { active: false, state: 'Inactivo' },
      ],
    };

    this.loadProveedores = this.loadProveedores.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }
  

  componentDidMount() {
    this.loadProveedores();
  }

  loadProveedores() {
    const { toastManager } = this.props;

    APIClient.get('/proveedores')
      .then((res) => {
        this.setState({
          entities: res.data.data,
          isDataLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  ////////////////////DELETE MODAL
  onConfirmDelete = async () => {
    const { selectedProveedor } = this.state;
    const { toastManager } = this.props;

    try {
      await APIClient.delete(`/proveedores/${selectedProveedor.id}`);
      this.setState((prevState) => {
        const entities = [...prevState.entities];
        const selectedIndex = entities.findIndex((ent) => selectedProveedor.id === ent.id);
        entities.splice(selectedIndex, 1);
        return { entities, selectedProveedor: {}, showConfirmModal: false };
      });
      toastManager.add('Proveedor eliminado con éxito', {
        appearance: 'success',
      });
    } catch (err) {
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
      this.setState({ selectedProveedor: {}, showConfirmModal: false });
    }
  };

  renderDeleteProveedorModal = () => {
    const { showConfirmModal } = this.state;
    const selectedDetalle = {};
    return (
      <Modal size="md" show={showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Proveedor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar este Proveedor?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onConfirmDelete}>
            Eliminar
          </Button>
          <Button variant="secondary" onClick={() => this.setState({ showConfirmModal: false, selectedDetalle })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  showDeleteConfirmModal = (row) => {
    this.setState({
      selectedProveedor: row,
      showConfirmModal: true,
    });
  };
  /////////////////DELETE MODAL END

  render() {
    const { entities, isDataLoading, estados, isSuperAdmin, editPermission, isPermissionsLoading } = this.state;

    const columns = [
      {
        dataField: 'code',
        text: 'Código',
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          const newCode = `00000${cellContent}`.slice(-'00000'.length);
          return newCode;
        },
      },
      {
        dataField: 'description',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'active',
        text: 'Estado',
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          const estadoFound = estados.find((estado) => cellContent === estado.active);
          const estado = estadoFound ? estadoFound.state : row.estado.state;
          return UIUtils.getClienteEstadoBadge(estado);
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <>
                  <LinkContainer to={`/proveedores/${row.id}`}>
                    <Button size="sm" variant="outline-primary" title="Editar">
                      <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                    </Button>
                  </LinkContainer>
                </>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        {this.renderDeleteProveedorModal()}
        <h1 className="page-title">Proveedores</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            columns={columns}
            data={entities}
            isDataLoading={isDataLoading}
            keyField="codigo"
            addButton={isSuperAdmin || editPermission ? '/proveedores/new' : null}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}
const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(ProveedorList));
