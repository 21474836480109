import React, { useEffect, useState } from 'react';
import { Nav, NavItem, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { connect } from 'react-redux';
import { setPermissionsUser } from '../actions/index';
import { withToastManager } from 'react-toast-notifications';

import {
  faClipboardList,
  faDolly,
  faFileAlt,
  faFileInvoiceDollar,
  faList,
  faShoppingCart,
  faSuitcase,
  faTachometerAlt,
  faTruck,
  faUsers,
  faUserTie,
  faExchangeAlt,
  faBoxes,
  faRulerHorizontal,
} from '@fortawesome/free-solid-svg-icons';

import './SideMenu.css';
import projectPackage from '../../package.json';
import Utils from './Utils';
import { Loading } from '../components';
import { sideMenu } from '../linksSideMenu';

library.add(
  faClipboardList,
  faDolly,
  faFileAlt,
  faFileInvoiceDollar,
  faList,
  faShoppingCart,
  faSuitcase,
  faTachometerAlt,
  faTruck,
  faUsers,
  faUserTie,
  faExchangeAlt,
  faBoxes,
  faRulerHorizontal,
);

const SideMenu = (props) => {
  const { setPermissionsUser, toastManager } = props;
  const { history } = props.layoutProps;

  const [permissions, setPermissions] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const getPermissionsActions = async () => {
    const { isSuperAdmin, editPermission, deletePermission, viewPermission, isHome } = await Utils.getPermissionsActions();

    if (viewPermission === false && isSuperAdmin === false && isHome === false) {
      toastManager.add(`No tienes acceso a esa página`, {
        appearance: 'warning',
      });
      history.push('/');
    }
    setPermissionsUser({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading: false,
    });
  };

  const getPermission = async () => {
    const permissionsUser = await Utils.viewsByPermission();
    const isSuperAdmin = permissionsUser.every((permission) => permission.isSuperAdmin);

    setIsSuperAdmin(isSuperAdmin);
    setPermissions(permissionsUser);
  };

  useEffect(() => {
    getPermission();
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    getPermissionsActions();
  }, [history.location.pathname]);

  const accesViews = [];
  const accesModules = [];

  const getAccess = (links) => {
    links.views.forEach((view) => {
      let paths = [];
      let nameModule = '';

      // SubModulo dropdown Case
      if (view.dropdownSubModule) {
        view.dropdownSubModule.forEach((dropdown) => {
          nameModule = dropdown.subModuleName;
          dropdown.dropdownLinks.forEach((link) => {
            paths.push(link.path);
          });
        });
      } else {
        paths = [view.path];
        nameModule = links.moduleName;
      }

      if (permissions.length > 0 && !isSuperAdmin) {
        permissions.forEach((permission) => {
          if (permission.pathView.length > 0 && !permission.isSuperAdmin) {
            const existPermission = paths.find((path) => path === permission.pathView);
            if (existPermission !== undefined) {
              accesModules.push(nameModule);
              paths.forEach((path) => {
                if (path === existPermission) accesViews.push(path);
              });
            }
          } else {
            paths.forEach((path) => {
              accesViews.push(path);
            });
          }
        });
      } else if (isSuperAdmin) {
        accesModules.push(nameModule);
        paths.forEach((path) => {
          accesViews.push(path);
        });
      }
    });
  };

  return (
    <Nav className="col-md-2 d-none d-md-block bg-light sidebar">
      {!isDataLoading ? (
        <>
          <div className="sidebar-sticky">
            {sideMenu.map((links) => {
              const container = links.map((link) => {
                getAccess(link);
                const hasAccessModule = accesModules.some((view) => view === link.moduleName);

                return (
                  <div key={link.moduleName}>
                    {hasAccessModule ? (
                      <>
                        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                          <span>{link.moduleName}</span>
                        </h6>
                        {link.views.map((view) => {
                          return (
                            <NavItem key={view.keyView}>
                              {view.dropdownSubModule ? (
                                view.dropdownSubModule.map((dropdown) => {
                                  const hasAccessSubModule = accesModules.some((subModuleName) => subModuleName === dropdown.subModuleName);
                                  return (
                                    <div key={dropdown.keySubModule}>
                                      {hasAccessSubModule ? (
                                        <Dropdown >
                                          <Dropdown.Toggle variant="muted" id="dropdown-basic">
                                            <FontAwesomeIcon icon={view.icon} fixedWidth className="mr-2" />
                                            {dropdown.subModuleName}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {dropdown.dropdownLinks.map((link) => {
                                              return (
                                                <div key={link.keyView}>
                                                  {accesViews.some((view) => view === link.path) ? (
                                                    <Dropdown.Item >
                                                      <LinkContainer to={`/${link.path}`}>
                                                        <Nav.Link >{link.name}</Nav.Link>
                                                      </LinkContainer>
                                                    </Dropdown.Item>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  );
                                })
                              ) : (
                                <div key={view.keyView}>
                                  {accesViews.some((viewAcces) => viewAcces === view.path) ? (
                                    <LinkContainer to={`/${view.path}`}>
                                      <Nav.Link>
                                        <FontAwesomeIcon icon={view.icon} fixedWidth className="mr-2" />
                                        <span>{view.name}</span>
                                      </Nav.Link>
                                    </LinkContainer>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              )}
                            </NavItem>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              });
              return container;
            })}
            <div className="my-3 px-3">
              <small className="text-muted">{`v${projectPackage.version}`}</small>
            </div>
          </div>
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </Nav>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setPermissionsUser: (value) => dispatch(setPermissionsUser(value)),
});

const mapStateToProps = ({ permimissionsUser }) => ({ permimissionsUser });

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(SideMenu));
