import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Col, Form, InputGroup, Modal, Row,
} from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  DataTable, EntityEditForm, FormAddressInput, FormCheckField, FormInputField, FormSelectField,
} from '../../components';
import APIClient from '../../services/APIClient';

class ClienteEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      selectedLista: {},
      entity: {},
      tipos: [],
      vendedores: [],
      listasPrecio: [],
      provincias: [],
      puntosEntrega: [],
      selectedListaId: null,
      isDataLoading: typeof id === 'undefined',
      showModal: false,
      condicionesPago: [],
      zonas: [],
    };
  }

  getListaPrecioDetailModal = () => {
    // create table for lista precio
    const { isDataLoading, selectedLista, showModal } = this.state;
    const columns = [
      {
        dataField: 'articulo.descripcion',
        text: 'Artículo',
        sort: true,
      },
      {
        dataField: 'talleNombre',
        text: 'Talle',
        sort: true,
      },
      {
        dataField: 'precio',
        text: 'Precio',
        sort: true,
        classes: 'text-right',
      },
    ];

    return (
      <Modal size="lg" show={showModal} onHide={this.handleClose}>
        <Modal.Header closeButton>
          {selectedLista !== undefined && selectedLista !== {} ? (
            <Modal.Title>{selectedLista.descripcion}</Modal.Title>
          ) : (
              <Modal.Title>Lista de Precio</Modal.Title>
            )}
        </Modal.Header>
        <Modal.Body>
          {selectedLista !== undefined && selectedLista !== {} ? (
            <DataTable isDataLoading={isDataLoading} columns={columns} data={selectedLista.detalles || []} keyField="id" />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  saveSelectedListaId = (e) => {
    this.setState({ selectedListaId: e.target.value });
  };

  showModalAndGetArticulos = async () => {
    const { selectedListaId } = this.state;
    // get articulos
    const selectedLista = await APIClient.get(`/listas-precio/${selectedListaId}`);
    this.setState({ selectedLista: selectedLista.data.data, showModal: true });
  };

  onLoadForm = async () => {
    const { clienteId } = this.state.entity;
    // get cliente tipos
    const tiposRes = await APIClient.get('/cliente-tipos');
    // get vendedores
    const vendedoresRes = await APIClient.get('/vendedores');
    // get lista precio
    const listasPrecioRes = await APIClient.get('/listas-precio');
    // get puntos entrega
    //const puntosEntregaRes = await APIClient.get(`/puntos-entrega?filter[clienteId][eq]=${clienteId}`);
    const puntosEntregaRes = await APIClient.get(
      `/clientes/${clienteId}/puntos-entrega`
    );
    const puntosDeEntrega = puntosEntregaRes.data.data.map((pe) => {
      pe.descripcion = `${pe.descripcion} - ${pe.calle} ${pe.numero === '00' ? '' : ' ' + pe.numero} ${!pe.piso ? '' : ' ' + pe.piso} ${!pe.depto ? '' : ' ' + pe.depto}
      ${pe.localidad} ${!pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal}
     ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`
      return pe;
    });
    // get provincias
    const provinciasRes = await APIClient.get('/provincias');
    // get condiciones pago
    const condicionesPago = await APIClient.get('/condiciones-pago');
    // get zonas
    const zonasRes = await APIClient.get('/zonas');

    this.setState({
      tipos: tiposRes.data.data,
      vendedores: vendedoresRes.data.data,
      listasPrecio: listasPrecioRes.data.data,
      provincias: provinciasRes.data.data,
      puntosEntrega: puntosDeEntrega,
      condicionesPago: condicionesPago.data.data,
      zonas: zonasRes.data.data,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;
    // get cliente
    const clienteRes = await APIClient.get(`/clientes/${id}`);
    const puntosEntregaRes = await APIClient.get(
      `/clientes/${id}/puntos-entrega`
    );
    const puntosDeEntrega = puntosEntregaRes.data.data.map((pe) => {
      pe.descripcion = `${pe.descripcion} - ${pe.calle} ${pe.numero === '00' ? '' : ' ' + pe.numero} ${!pe.piso ? '' : ' ' + pe.piso} ${!pe.depto ? '' : ' ' + pe.depto}
      ${pe.localidad} ${!pe.codigoPostal || pe.codigoPostal === '0' ? '' : ' cp: ' + pe.codigoPostal}
     ${!pe.provincia ? '' : ' ' + pe.provincia.descripcion}`
      return pe;
    })
    this.setState({
      entity: clienteRes.data.data,
      selectedListaId: clienteRes.data.data.listaPrecioId,
      puntosEntrega: puntosDeEntrega,
    });
    return clienteRes.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/clientes', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/clientes/${id}`, entityToSave);
    }

    history.push('/clientes');
    toastManager.add(`Cliente ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });

    return saveResponse.data.data;
  };

  render() {
    const {
      id,
      entity,
      tipos,
      vendedores,
      listasPrecio,
      provincias,
      puntosEntrega,
      isAdding,
      selectedListaId,
      condicionesPago,
      zonas
    } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Cliente nuevo' : `Cliente #${id}`}</h1>
        {this.getListaPrecioDetailModal()}

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}>
          <>
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Información Principal</Card.Title>
                <Row>
                  <Col md={6}>
                    <FormInputField id="razonSocial" label="Razón Social" type="text" defaultValue={entity.razonSocial} required />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="documentoNumero" label="CUIT" type="text" defaultValue={entity.documentoNumero} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputField id="nombreFantasia" label="Nombre de Fantasía" type="text" defaultValue={entity.nombreFantasia} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="codigoInterno" label="Código interno" type="text" defaultValue={entity.codigoInterno} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormSelectField
                      id="tipoId"
                      label="Tipo"
                      defaultValue={entity.tipoId}
                      choices={tipos}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                    />
                  </Col>
                  <Col md={3}>
                    <FormInputField
                      id="coeficienteFacturacion"
                      label="Coeficiente Facturación"
                      type="number"
                      min={0}
                      max={1}
                      step="0.05"
                      defaultValue={entity.coeficienteFacturacion}
                    />
                  </Col>
                  <Col>
                    <div className="d-flex mt-3 h-100 align-items-center">
                      <FormCheckField id="eliminadoFlag" label="Inactivo" defaultChecked={entity.eliminadoFlag} />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Información de Contacto</Card.Title>
                <Row>
                  <Col md={6}>
                    <FormInputField id="contacto" label="Contacto Principal" defaultValue={entity.contacto} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="telefono" label="Teléfono Principal" type="text" defaultValue={entity.telefono} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputField id="celular" label="Teléfono móvil" type="text" defaultValue={entity.celular} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="email" label="Email Principal" type="text" defaultValue={entity.email} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputField id="contactoComercial" label="Contacto Comercial" type="text" defaultValue={entity.contactoComercial} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="telefonoComercial" label="Teléfono Comercial" type="tel" defaultValue={entity.telefonoComercial} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="emailComercial" label="Email Comercial" type="text" defaultValue={entity.emailComercial} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputField id="contactoCobranzas" label="Contacto Cobranzas" type="text" defaultValue={entity.contactoCobranzas} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="telefonoCobranzas" label="Teléfono Cobranzas" type="tel" defaultValue={entity.telefonoCobranzas} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="emailCobranzas" label="Email Cobranzas" type="text" defaultValue={entity.emailCobranzas} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormAddressInput provincias={provincias} addMode={isAdding} entity={entity} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormInputField id="longitud" label="Longitud" type="text" defaultValue={entity.longitud} />
                  </Col>
                  <Col md={6}>
                    <FormInputField id="latitud" label="Latitud" type="text" defaultValue={entity.latitud} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Información Comercial</Card.Title>
                <Row>
                  <Col md={6}>
                    <FormSelectField
                      id="vendedorId"
                      label="Vendedor"
                      defaultValue={entity.vendedorId}
                      choices={vendedores}
                      choiceIdField="id"
                      choiceLabelField="nombre"
                    />
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="listaPrecioId">
                      <Form.Label>Lista de Precio</Form.Label>
                      <InputGroup>
                        <Form.Control
                          as="select"
                          onChange={this.saveSelectedListaId}
                          name="listaPrecioId"
                          defaultValue={entity.listaPrecioId}>
                          {isAdding && <option value="">(Seleccione)</option>}
                          {listasPrecio.map((lista) => (
                            <option key={lista.id} value={lista.id}>
                              {lista.descripcion}
                            </option>
                          ))}
                        </Form.Control>
                        {selectedListaId === null || selectedListaId === '' ? null : (
                          <InputGroup.Prepend>
                            <Button onClick={this.showModalAndGetArticulos} size="sm" variant="outline-secondary" title="Detalle">
                              <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="sm" />
                            </Button>
                          </InputGroup.Prepend>
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormSelectField
                      id="puntoEntregaId"
                      label="Punto de Entrega"
                      defaultValue={entity.puntoEntregaId}
                      choices={puntosEntrega}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <FormSelectField
                      id="zonaId"
                      label="Zona"
                      defaultValue={entity.zonaId}
                      choices={zonas}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                    />
                  </Col>
                  <Col md={4}>
                    <FormInputField id="entregaHorario" label="Horario de Entrega" defaultValue={entity.entregaHorario} />
                  </Col>
                  <Col md={2}>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label className="form-label">Días de Entrega</label>
                          <Row className="align-items-center">
                            <Col md={6}>
                              <input
                                id="diasEntrega"
                                name="diasEntrega"
                                type="number"
                                className="form-control text-right"
                                defaultValue={entity.diasEntrega}
                                min={0}
                              />
                            </Col>
                            <Col md={6}>
                              <p className="mb-0">dias.</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <FormSelectField
                      id="condicionPagoId"
                      label="Condición de Pago"
                      defaultValue={entity.condicionPagoId}
                      choices={condicionesPago}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ClienteEdit);
