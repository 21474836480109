import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar, ButtonGroup, Col, Modal,
  Row,
} from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Accordion } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import SectionEdit from './SectionEdit';
import LocationEdit from './LocationEdit';
import APIClient from '../../services/APIClient';

class Section extends React.Component {
  static propTypes = {
   warehouse: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { warehouse } = props;

    this.state = {
      warehouse,
      toggleComponents: true,
      id: 0,
      warehouseId: 0,
      showModal: false,
      locationId: 0,
      currentSectionId: 0
    };
  }


  actualizeToggle = (id, warehouseId) => {
    const { toggleComponents } = this.state;
    this.setState({
      toggleComponents: !toggleComponents,
      id,
      warehouseId,
    })
  }
  actualizeSections = async (sectionId) => {
    const { warehouse } = this.state;
    const { sections } = warehouse;
    const editedOrNewSectionRes = await APIClient.get(`/sections/${sectionId}`);
    const editedOrNewSection = editedOrNewSectionRes.data.data;
    const index = sections.map(s => s.id).indexOf(sectionId);
    if (index >= 0) {

      sections.splice(index, 1, editedOrNewSection);
    } else {
      sections.push(editedOrNewSection);
    }
    warehouse.sections = sections;
    this.setState({
      warehouse,
    })

  }
  sectionHeader = (section, row) => {

    return (
      <Row >
        <Col md={10}>
          {section.description}
        </Col>
        <Col md={2}>
          <a onClick={() => { this.actualizeToggle(section.id, row.id) }} >
            <Button variant={ section.disabled ? "outline-light" : "outline-primary" } size="sm">
              <FontAwesomeIcon icon={faEdit} fixedWidth />
            </Button>
          </a>
        </Col>
      </Row>
    )
  }

  sectionBody = (locations, section, warehouse) => {
    const locationColumns = [
      {
        dataField: 'description',
        text: 'Descripción',
        sort: true,
      },

      {
        dataField: 'disabled',
        text: 'Habilitado',
        sort: true,
        formatter: (cellContent, row) => {
          const view = !cellContent ? 'SI' : 'NO';
          return view;
        }
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {/* <Link to={`/warehouses/${warehouse.id}/sections/${section.id}/locations/${row.id}`} target="_blank"  > */}
              <Button onClick={() => this.showModal(row.id, section.id)} variant={ section.disabled ? "outline-secondary" : "outline-primary" } title="Editar" size="sm" >
                <FontAwesomeIcon icon={faEdit} fixedWidth />
              </Button>
              {/* </Link> */}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <span className="lead mt-1 mb-3"> <strong>{warehouse.description} / {section.description} /</strong></span>
        <div className="mt-3 ml-2">
          <DataTable
            // remote={{
            //   filter: true, pagination: true, sort: true, cellEdit: false,
            // }}
            totalSize={locations.length}
            columns={locationColumns}
            data={locations}
            onTableUpdate={this.onTableUpdate}
            isDataLoading={false}
            keyField="id"
            // addButton={`/warehouses/${warehouse.id}/sections/${section.id}/locations`}
            addButton={() => this.showModal(0, section.id)}
            buttonText={"Agregar posición"}
            showExport={false}
            showSearch={false}

          />
        </div>
      </div>
    )
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };
  showModal = (locationId, currentSectionId) => {
    this.setState({ showModal: true, locationId, currentSectionId });
  }
  modalLocations = () => {
    const { showModal, locationId, warehouse, currentSectionId } = this.state;

    return (
      <Modal size="lg" show={showModal} onHide={this.handleClose}>
        <Modal.Header closeButton>
          {/* {selectedLista !== undefined && selectedLista !== {} ? (
          <Modal.Title>{selectedLista.descripcion}</Modal.Title>
        ) : (
            <Modal.Title>Lista de Precio</Modal.Title>
          )} */}
        </Modal.Header>
        <Modal.Body>
          <LocationEdit
            id={locationId}
            warehouseId={warehouse.id}
            sectionId={currentSectionId}
            closeModal={this.handleClose}
            actualizeSections={this.actualizeSections}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>
    );
  }


  render() {
    const {
      warehouse,
      toggleComponents,
      id
    } = this.state;

    return toggleComponents ?
      (
        <div className="ml-5">
          {this.modalLocations(1, 3)}
          <span className="lead mt-2"> <strong>{warehouse.description} / </strong></span>
          <div className="ml-3 mt-3">
            <a onClick={() => { this.actualizeToggle(0, warehouse.id) }} >
              <Button variant="primary" size="sm">
                <FontAwesomeIcon icon={faPlus} fixedWidth />
             Agregar sector
              </Button>
            </a>
          </div>
          <br />
          <div className="ml-3 mb-3">
            {warehouse.sections.map(section => (
              <Row key={section.id}  >
                <Col md={11} >
                  <Accordion
                    headerClass={ section.disabled ? 'bg-secondary' : '' }
                    eventKey={section.id}
                    data={section}
                    header={this.sectionHeader(section, warehouse)}
                    body={this.sectionBody(section.locations, section, warehouse)}
                    disabled={section.disabled}
                  />
                </Col>
              </Row>
            ))}
          </div>
        </div>

      ) :
      <div className="ml-5">
        <Row>
          <Col md={6}>
            <SectionEdit
              toggleComponent={this.actualizeToggle}
              warehouseId={warehouse.id}
              actualizeState={this.setState}
              actualizeSections={this.actualizeSections}
              id={id}
            />
          </Col>
          <Col md={6}>
            <h3>Sectores en {warehouse.description} </h3>
            <ul>
              {warehouse.sections.map(s => <li key={s.id}>{s.description}</li>)}
            </ul>
          </Col>
        </Row>
      </div>;
  }
}

export default withToastManager(Section);
