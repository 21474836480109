import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import {
  EntityEditForm, FormCheckField, FormInputField, FormSelectField,
} from '../../components';
import APIClient from '../../services/APIClient';

class AutoModeloEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      autoMarcas: [],
      isAdding: typeof id === 'undefined',
      isDataLoading: true,
      entity: {},
    };
  }

  onLoadForm = async () => {
    // get autoMarcas
    const autoMarcasRes = await APIClient.get('/auto-marcas');

    this.setState({
      autoMarcas: autoMarcasRes.data.data,
      isDataLoading: false,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get autoModelo
    const autoModeloRes = await APIClient.get(`/auto-modelos/${id}`);
    const entity = autoModeloRes.data.data;

    this.setState({
      isDataLoading: false,
      entity,
    });
    return autoModeloRes.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      if(!entityToSave.autoMarcaId){
        entityToSave.autoMarcaId = 1;
      }
      saveResponse = await APIClient.post('/auto-modelos', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/auto-modelos/${id}`, entityToSave);
    }

    history.push('/auto-modelos');
    toastManager.add(`Modelo de auto ${saveResponse.data.data.descripcion} guardada con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });

    return saveResponse.data.data;
  };

  render() {
    const {
      id, autoMarcas, entity, isAdding,
    } = this.state;

    const vehiculoTipos = [
      { id: 1, descripcion: 'Automóviles/Transporte' },
      { id: 3, descripcion: 'Camiones/Buses' },
      { id: 4, descripcion: 'Maquinaria' },
    ];

    return (
      <div>
        <h1 className="page-title">
          {isAdding ? 'Modelo de auto nuevo' : `Modelo de auto #${id}`}
        </h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
        >
          <>
            <Row>
              <Col md={6}>
                <FormSelectField
                  id="autoMarcaId"
                  label="Marca"
                  defaultValue={entity.autoMarca ? entity.autoMarca.id : null}
                  choices={autoMarcas}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                  required
                />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="vehiculoTipo"
                  label="Tipo de vehículo"
                  defaultValue={entity.vehiculoTipo}
                  choices={vehiculoTipos}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                  required
                />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="descripcion"
                  label="Descripción"
                  type="text"
                  defaultValue={entity.descripcion}
                  required
                />
              </Col>
              <Col md={6}>
                <FormInputField id="motor" label="Motor" type="text" defaultValue={entity.motor} />
              </Col>
              <Col md={6}>
                <FormInputField
                  id="version"
                  label="Versión"
                  type="text"
                  defaultValue={entity.version}
                />
              </Col>
              <Col md={6}>
                <FormInputField id="año" label="Año" type="text" defaultValue={entity.año} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormCheckField
                  id="activo"
                  label="Activo"
                  defaultChecked={entity.activo ? entity.activo > 0 : true}
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(AutoModeloEdit);
