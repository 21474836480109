import React from 'react';
import { Route } from 'react-router-dom';
import SolicitudList from './SolicitudList';
import SolicitudEdit from './SolicitudEdit';

const routes = () => [
  // <Route path="/solicitudes/nuevo" exact component={SolicitudEdit} key="/solicitudes/nuevo" />,
  <Route path="/solicitudes/:id" component={SolicitudEdit} key="/solicitudes/:id" />,
  <Route path="/solicitudes" exact component={SolicitudList} key="/solicitudes/:id" />,
].map(route => route);

export default routes;
