import React from 'react';
import { Route } from 'react-router-dom';
import SaleReport from './SaleReport';

const routes = () => [
  <Route path="/sale-report" exact component={SaleReport} key="/sale-report" />,
  ,
].map(route => route);

export default routes;
