import React from 'react';
import { Route } from 'react-router-dom';
import ProductionParameterList from './ProductionParameterList';
import ProductionParameterEdit from './ProductionParameterEdit';

const routes = () =>
  [
    <Route path="/production-parameters/new" component={ProductionParameterEdit} key="/production-parameters/new" />,
    <Route path="/production-parameters/:id" component={ProductionParameterEdit} key="/production-parameters/:id" />,
    <Route path="/production-parameters" exact component={ProductionParameterList} key="/production-parameters" />,
  ].map((route) => route);

export default routes;
