import React from 'react';
import { Route } from 'react-router-dom';
import CategoriaList from './CategoriaList';
import CategoriaEdit from './CategoriaEdit';

const routes = () => [
  <Route path="/categories/new" component={CategoriaEdit} key="/categories/new" />,
  <Route path="/categories/:id" component={CategoriaEdit} key="/categories/:id" />,
  <Route path="/categories" exact component={CategoriaList} key="/categories" />,
].map(route => route);

export default routes;