import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import { connect } from 'react-redux'

class DeliveryParameterList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.apiParams = '';
    this.state = {
      deliveryParameterList: [],
      isDataLoading: true,
      totalSize: 0,
    };

    this.loadListDeliveryParameters = this.loadListDeliveryParameters.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }

  componentDidMount() {
    this.loadListDeliveryParameters();
  }

  async loadListDeliveryParameters() {
    const { toastManager } = this.props;
    try {
      const deliveryParameterRes = await APIClient.get('/delivery-parameters');

      this.setState({
        deliveryParameterList: deliveryParameterRes.data.data,
        isDataLoading: false,
      });
    } catch (error) {
      console.error('Cargando Parámetros.', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  render() {
    const { deliveryParameterList, isDataLoading, totalSize, isSuperAdmin, editPermission, isPermissionsLoading } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
      },
      {
        dataField: 'motive.description',
        text: 'Motivo',
        sort: true,
      },
      {
        dataField: 'line.descripcion',
        text: 'Linea',
        sort: true,
      },
      {
        dataField: 'warehouse.description',
        text: 'Depósito',
        sort: true,
      },
      {
        dataField: 'section.description',
        text: 'Sector',
        sort: true,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <LinkContainer to={`/delivery-parameters/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Editar">
                    <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Parametros de Consumo por Entrega</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            totalSize={totalSize}
            columns={columns}
            data={deliveryParameterList}
            showExport={false}
            isDataLoading={isDataLoading}
            keyField="id"
            addButton={isSuperAdmin || editPermission ? '/delivery-parameters/new' : null}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(DeliveryParameterList));


