import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import { connect } from 'react-redux'

class ListaMaterialesList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.apiParams = '';
    this.state = {
      materialsList: [],
      isDataLoading: true,
      totalSize: 0,
      comercializables: [],
      insumos: [],
      estados: [
        { active: true, state: 'Activo' },
        { active: false, state: 'Inactivo' },
      ],
    };

    this.loadListOfMaterials = this.loadListOfMaterials.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }

  componentDidMount() {
    this.loadListOfMaterials();
  }

  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    try {
      const { direction } = sorting;
      let { field } = sorting;
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || ''}&sortDir=${
        direction || ''
      }&excludeAssocFields=imagenes`;
      const warehousesRes = await APIClient.get(`/warehouses?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}`);
      const warehouses = warehousesRes.data.data;

      this.setState({
        warehouses,
        totalSize: warehousesRes.data.meta.total,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  async loadListOfMaterials() {
    const { toastManager } = this.props;
    try {
      const comercializablesRes = await APIClient.get('/articulos');
      const insumosRes = await APIClient.get('/insumos?filter[active][eq]=1');
      const materialsListRes = await APIClient.get('/lista-materiales?limit=10&offset=0');
      this.setState({
        materialsList: materialsListRes.data.data,
        comercializables: comercializablesRes.data.data,
        insumos: insumosRes.data.data,
        isDataLoading: false,
        totalSize: materialsListRes.data.meta.total,
      });
    } catch (error) {
      console.error('Cargando depósitos.', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  render() {
    const {
      materialsList,
      isDataLoading,
      totalSize,
      insumos,
      comercializables,
      estados,
      isSuperAdmin,
      editPermission,
      isPermissionsLoading,
    } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'Descripción',
        sort: true,
        formatter: (cellContent, row) => {
          const articleInfo = row.insumoId
            ? insumos.find((item) => item.id === row.insumoId)
            : comercializables.find((item) => item.id === row.articuloId);
          const description = row.insumoId
            ? `${articleInfo.erpCode} - ${articleInfo.description}`
            : `${articleInfo.erpCodigo} - ${articleInfo.descripcion}`;
          return description;
        },
      },
      {
        dataField: 'isActive',
        text: 'Estado',
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent === null) {
            return '';
          }
          const estadoFound = estados.find((estado) => cellContent === estado.active);
          const estado = estadoFound ? estadoFound.state : row.estado.state;
          return UIUtils.getClienteEstadoBadge(estado);
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <LinkContainer to={`/materials-list/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Editar">
                    <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Lista de Materiales</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            totalSize={totalSize}
            isDataLoading={isDataLoading}
            columns={columns}
            data={materialsList}
            showExport={false}
            keyField="id"
            addButton={isSuperAdmin || editPermission ? '/materials-list/new' : null}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(ListaMaterialesList));
