import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { FormInputField, FormSelectField, EntityEditForm } from '../../components';
import { withToastManager } from 'react-toast-notifications';
import APIClient from '../../services/APIClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

class ViewProduct extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      movementMotives: props.movementMotives,
      productStatuses: props.productStatuses,
      productTypes: props.productTypes,
      products: props.products,
      providers:props.providers,
      clients:props.clients,
      warehouses: props.warehouses,
      sections: props.sections,
      locations: props.locations,
      buttons: [],
      udm:props.udm,
      id: props.id,
      removeMovement: props.removeMovement,
      movement: props.movement,
      comercializables: props.comercializables,
      insumos: props.insumos,
      showConfirmModal:false,
      idRowDelete:undefined,
    };
    this.requiredFields = ['productTypeId', 'units', 'typeId', 'motiveId', 'warehouseId', 'sectionId', 'locationId', 'productStatusId','udmId'];
  }

  componentDidMount() {}

  async loadProducts(productType) {
    const { toastManager } = this.props;
    let url;
    if (productType === 'insumo') url = '/insumos?filter[active][eq]=1';
    if (productType === 'comercializable') url = '/articulos';
    try {
      const productsRes = await APIClient.get(url);
      this.setState({
        products: productsRes.data.data,
      });
    } catch (err) {
      toastManager.add(`Error al cargar datos. Recargar página. Checkear conexión a internet`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }
  /**
   * Save the item
   */

  onSaveEntity = async () => {
  };

  showDeletemModal = (id) => {
    this.setState({
      showConfirmModal:true,
      idRowDelete:id
    });
  };

  closeDeleteConfirmModal = (row) => {
    this.setState({
      idRowDelete:undefined,
      showConfirmModal: false,
    
    });
  };

  renderDeleteModal = () => {
    const { showConfirmModal, idRowDelete, removeMovement } = this.state;
    return (
      <Modal size="md" show={showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Transaccion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar y deshacer esta transacción?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>removeMovement(idRowDelete)}>
            Eliminar
          </Button>
          <Button variant="secondary" onClick={this.closeDeleteConfirmModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  render() {
    const {
      isAdding,
      movementMotives,
      productStatuses,
      productTypes,
      comercializables,
      insumos,
      warehouses,
      sections,
      locations,
      removeMovement,
      id,
      movement,
      buttons,
      providers,
      clients,
      udm
      
    } = this.state;

    const productTypeId = parseInt(movement.productTypeId,10);
    const selectedPT = productTypes.filter(pt => productTypeId === pt.id)[0]
    return (
      <div key={id}>
      {this.renderDeleteModal()}
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={() => {}}
          onSaveEntity={this.onSaveEntity}
          saveEntityInfatherState={this.saveEntityInfatherState}
          fatherContext={this}
          addMode={isAdding}
          buttons={buttons}>
          
             
          <Row noGutters style={{ flexWrap: 'nowrap' }}>
          <Col className="my-auto px-2">
          <Button className="mt-2" size="sm" variant="outline-danger" title="Eliminar" onClick={() => this.showDeletemModal(id)}>
            <FontAwesomeIcon icon={faTrashAlt} fixedWidth size="xs" />
          </Button>
          </Col>
          
          
            <Col md={2} className="mr-1">
              <FormSelectField
                id="motiveId"
                label="Motivo"
                defaultValue={movement.id ? parseInt(movement.motiveId, 10) : 1}
                choices={movementMotives}
                choiceIdField="id"
                choiceLabelField="description"
                size="sm"
                disabled
              />
            </Col>
            {
             movement.motiveId === '13' ?(
            <Col md={2} className="mr-1">
              <FormSelectField
                id="providerId"
                label="Proveedor"
                choices={providers}
                defaultValue={parseInt(movement.providerId)}
                choiceIdField="id"
                choiceLabelField="description"
                size="sm"
                disabled
              />
            </Col>

              )
              :
              movement.motiveId === '10' ?(
                <Col md={2} className="mr-1">
                <FormSelectField
                  id="clientId"
                  label="Cliente"
                  choices={clients}
                  defaultValue={parseInt(movement.clientId)}
                  choiceIdField="id"
                  choiceLabelField="razonSocial"
                  size="sm"
                  disabled
                />
              </Col>
              )
              :
              <></>
            }
            <Col md={2} className="mr-1 ml-1">
              <FormSelectField
                id="productTypeId"
                label="Tipo"
                defaultValue={movement.id ? parseInt(movement.productTypeId, 10) : 1}
                choices={productTypes}
                choiceIdField="id"
                choiceLabelField="description"
                size="sm"
                disabled
              />
            </Col>
       
            <Col className=" ml-1" md={3}>
              <FormSelectField
                id={selectedPT.description === 'comercializable' ? 'articuloId' : 'insumoId'}
                label={!selectedPT.description ? `Productos: ${selectedPT.description}` : selectedPT.description}
                defaultValue={
                  movement.id ? parseInt(selectedPT.description === 'comercializable' ? movement.articuloId : movement.insumoId, 10) : 0
                }
                choices={selectedPT.description === 'comercializable' ? comercializables : insumos}
                choiceIdField="id"
                choiceLabelField={selectedPT.description === 'comercializable' ? 'descripcion' : 'description'}
                size="sm"
                disabled
              />
            </Col>

         
            <Col className="mr-1 ml-1" md={1}>
              <FormInputField
                id="units"
                type="number"
                label="Unidades"
                defaultValue={movement.id ? movement.units : ''}
                min={0}
                step={selectedPT.description === "comercializable" ? '1' : '0.1'}
                size="sm"
                disabled
              />
            </Col>
            {
                 selectedPT.description === "insumo" ? (
            <Col className=" ml-1" md={1}>
                  <FormInputField
                    id="udmId"
                    type="string"
                    label="UDM"
                    defaultValue={movement.id ? udm.filter(elem=>elem.id === movement.udmId)[0]?.description: ''}
                    disabled
                    size="sm"
                    disabled
                  />
                </Col> 
                 ):<></>
              }
            
            <Col className=" ml-1" md={1}>
              <FormSelectField
                id="warehouseId"
                label="Depósito"
                defaultValue={movement.id ? parseInt(movement.warehouseId, 10) : 0}
                choices={warehouses}
                choiceIdField="id"
                choiceLabelField="description"
                size="sm"
                disabled
              />
            </Col>
            <Col className=" ml-1" md={1}>
              <FormSelectField
                id="sectionId"
                label="Sector"
                defaultValue={movement.id ? parseInt(movement.sectionId, 10) : 0}
                choices={sections}
                choiceIdField="id"
                choiceLabelField="description"
                size="sm"
                disabled
              />
            </Col>
            <Col className=" ml-1" md={1}>
              <FormSelectField
                id="locationId"
                label="Posición"
                defaultValue={movement.id ? parseInt(movement.locationId, 10) : 0}
                choices={locations}
                choiceIdField="id"
                choiceLabelField="description"
                size="sm"
                disabled
              />
            </Col>
            <Col md={1} className="mr-1 ml-1">
              <FormSelectField
                id="productStatusId"
                label="Estado"
                defaultValue={movement.id ? parseInt(movement.productStatusId, 10) : 0}
                choices={productStatuses}
                choiceIdField="id"
                choiceLabelField="description"
                onChange={() => {}}
                size="sm"
                disabled
              />
            </Col>
            <Col className=" ml-1" md={2}>
              <FormInputField
                id="description"
                defaultValue={movement.description ? movement.description : ""}
                label="Descripcion"
                size="sm"
                disabled
              />
            </Col>
          </Row>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ViewProduct);
