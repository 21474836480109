import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Container, Form, Image, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import AsyncSelect from 'react-select/async';
import { EntityEditForm, FormCheckField, FormInputField, FormSelectField } from '../../components';
import APIClient from '../../services/APIClient';
import config from '../../config';

class ArticuloEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      colecciones: [],
      entity: {},
      id,
      imagenes: [],
      isAdding: typeof id === 'undefined',
      addedModelos: [],
      autoModelos: [],
      competidores: [],
      compArticulos: [],
      deletedModelos: [],
      formas: [],
      imageToDelete: {},
      isDataLoading: true,
      lineas: [],
      marcas: [],
      posicionChanges: [],
      selectedImage: {},
      tipologias: [],
    };
  }

  /**
   * Get images array, selectedImage and imageToDelete from state and remove matching image from array.
   * If image deleted was default image, add first image as default. Save to state.
   */
  deleteFile = async () => {
    const { toastManager } = this.props;
    const { id, imageToDelete, imagenes: imgs } = this.state;
    let { selectedImage } = this.state;
    try {
      if (imageToDelete.id) {
        // delete image from API.
        await APIClient.delete(`/articulos/${id}/imagenes/${imageToDelete.id}`);
      }
      this.setState((prevState) => {
        const { imagenes } = prevState;
        // get image index
        const imgIndex = imagenes.findIndex((img) => img.posicion === imageToDelete.posicion);
        let posicionChanges = [];
        if (imagenes.length > 1) {
          // update posicion for each consecutive element and push changes into array.
          for (let i = imgIndex + 1; i < imagenes.length; i++) {
            const imagen = imagenes[i];
            imagen.posicion--;
            posicionChanges.push({ id: imagen.id, posicion: imagen.posicion });
          }
          imagenes.splice(imgIndex, 1);
        } else {
          // images array has only one element, no posicion updates are necessary.
          imagenes.pop();
          posicionChanges = [];
        }
        return {
          ...prevState,
          imagenes,
          imageToDelete: {},
          showDeleteImageModal: false,
          posicionChanges,
        };
      });
      toastManager.add('El archivo fue eliminado correctamente.', {
        appearance: 'success',
      });
    } catch (error) {
      console.error(error);
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
    } finally {
      selectedImage = imgs[0];
      this.setState({ selectedImage });
    }
  };

  /**
   * Receives competidores and compArticulos array and returns new JSX elements
   * for each competidor using compArticulos data if there is any.
   * @param  {Object[]} competidores - array de objetos competidor
   * @param  {Object[]} compArticulos - array de objetos competidorArticulos
   * @return  {JSX.Element[]} - Array de elementos JSX.
   */
  generateCompArtElem = () => {
    const { competidores, compArticulos } = this.state;
    const result = [];
    competidores.forEach((competidor) => {
      let newResult = '';
      const compArticulo = compArticulos.find((compArt) => compArt.competidorId === competidor.id);
      if (compArticulo === undefined) {
        newResult = (
          <Col key={competidor.id} sm={4}>
            <FormInputField id={`compArt.${competidor.id}`} type="text" label={competidor.descripcion} />
          </Col>
        );
      } else {
        newResult = (
          <Col key={compArticulo.id} sm={4}>
            <FormInputField
              id={`compArt.${competidor.id}`}
              type="text"
              label={competidor.descripcion}
              defaultValue={compArticulo.descripcion}
            />
          </Col>
        );
      }
      result.push(newResult);
    });
    return result;
  };

  /**
   * Create JSX elements for uploaded and to be uploaded images.
   * Un-uploaded images have a tooltip to indicate they haven't been uploaded yet.
   * @return image JSX elements
   */
  generateImagesElem = () => {
    const { entity, imagenes } = this.state;
    const { id } = entity;
    return (
      <Row>
        {imagenes.map((img, i) => {
          if (img.isUploaded) {
            return (
              <Col key={`img_${img.id}`} md={3}>
                <Container
                  style={{ height: '250px' }}
                  className="d-flex justify-content-center align-items-center img-thumbnail"
                  onClick={() => this.setState((prevState) => ({ ...prevState, selectedImage: img }))}>
                  <Image
                    className="mh-100"
                    id={`img_${i}`}
                    src={`${config.api.publicPath}/${id}/${img.filename}`}
                    alt={`imagen_${i}`}
                    fluid
                  />
                </Container>
              </Col>
            );
          }
          return (
            <OverlayTrigger key={`img_${i}`} overlay={<Tooltip id="tooltip-disabled">Aún no ha sido subida al servidor!</Tooltip>}>
              <Col md={3}>
                <div style={{ height: '250px' }} className="bg-warning d-flex justify-content-center align-items-center img-thumbnail">
                  <Image
                    className="mh-100"
                    disabled
                    style={{ opacity: '0.6' }}
                    id={`img_${i}`}
                    src={`${img.filename}`}
                    onClick={() => this.setState((prevState) => ({ ...prevState, selectedImage: img }))}
                    alt={`imagen_${i}`}
                    fluid
                  />
                </div>
              </Col>
            </OverlayTrigger>
          );
        })}
      </Row>
    );
  };

  /**
   * Create temporary URL for new file and add it into imagenes array.
   */
  handleImageChange = (e) => {
    const { toastManager } = this.props;
    const newFile = e.target.files[0];
    if (newFile) {
      if (newFile.size > 5000000) {
        toastManager.add('Solo se permiten imágenes de menos de 5 MB.', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        this.setState((prevState) => {
          const { imagenes } = prevState;
          const newImage = {};
          const fileTempUrl = URL.createObjectURL(newFile);

          newImage.filename = fileTempUrl;
          if (imagenes.length > 0) {
            newImage.posicion =
              Math.max.apply(
                null,
                imagenes.map((img) => img.posicion),
              ) + 1;
            imagenes.push(newImage);
            return { ...prevState, imagenes };
          }
          newImage.posicion = 0;
          imagenes.push(newImage);
          return { ...prevState, imagenes, selectedImage: newImage };
        });
      }
    }
  };

  /**
   * Receives a string, if its length is greater than 2, calls endpoint using the string
   * and generates option objects according to the response.
   * @param  {string} inputValue - string received by select2 (typed by user).
   * @return  {Object[]} autoModelos array, containing the options to be displayed for the user.
   */
  loadAutoModeloOptions = async (inputValue) => {
    if (inputValue.length > 2) {
      const autoModelosRes = await APIClient.get(`/auto-modelos/find?filter[descripcion]=${inputValue}`);
      const autoModelos = autoModelosRes.data.data.map((modelo) => ({
        id: modelo.id,
        value: `${modelo.descripcion} - ${modelo.motor}`,
        label: `${modelo.autoMarca.descripcion}, ${modelo.descripcion} - ${modelo.motor}`,
        key: modelo.id,
      }));
      return autoModelos;
    }
  };

  setImageAsDefault = () => {
    this.setState((prevState) => {
      const { imagenes, selectedImage } = prevState;
      // get old posicion value
      const { posicion } = selectedImage;
      // find existing default image
      const defaultImage = imagenes[0];
      // if there is and it is not the selected image, then change its posicion to selectedImages old posicion
      if (defaultImage && defaultImage.id !== selectedImage.id) {
        defaultImage.posicion = posicion;
      }
      const image = imagenes.find((img) => selectedImage.id === img.id);
      image.posicion = 1;
      // selectedImage.posicion = 0;
      const posicionChanges = [];
      if (selectedImage.id) {
        posicionChanges.push({ id: selectedImage.id, posicion: selectedImage.posicion });
      }
      posicionChanges.push({ id: defaultImage.id, posicion: defaultImage.posicion });
      imagenes.sort((a, b) => a.posicion - b.posicion);

      return {
        ...prevState,
        imagenes,
        selectedImage: imagenes[0],
        posicionChanges,
      };
    });
  };

  showDeleteImageModal = () => {
    const { showDeleteImageModal } = this.state;
    return (
      <Modal size="md" show={showDeleteImageModal} onHide={() => this.setState({ showDeleteImageModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar acción</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Desea eliminar este archivo?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showDeleteImageModal: false })}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={this.deleteFile}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  onLoadForm = async () => {
    const { id } = this.state;
    // get marcas
    const marcasRes = await APIClient.get('/marcas');
    // get lineas
    const lineasRes = await APIClient.get('/lineas');
    // get colecciones
    const coleccionesRes = await APIClient.get('/colecciones');
    // get tipologias
    const tipologiasRes = await APIClient.get('/tipologias');
    // get autoMarcas
    const autoModelosRes = await APIClient.get('/auto-modelos');
    // get competidores
    const competidoresRes = await APIClient.get('/competidores');
    // get competidorArticulos
    const compArticulosRes = await APIClient.get(`/competidor-articulos?filter[articulo_id][eq]=${id}`);
    // get formas
    const formasRes = await APIClient.get('/formas');

    this.setState({
      autoModelos: autoModelosRes.data.data,
      competidores: competidoresRes.data.data,
      compArticulos: compArticulosRes.data.data,
      formas: formasRes.data.data,
      marcas: marcasRes.data.data,
      lineas: lineasRes.data.data,
      isDataLoading: false,
      colecciones: coleccionesRes.data.data,
      tipologias: tipologiasRes.data.data,
    });
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get articulo
    const articuloRes = await APIClient.get(`/articulos/${id}`);
    const articulo = articuloRes.data.data;
    const { imagenes } = articulo;

    imagenes.forEach((img) => {
      img.isUploaded = true;
    });
    imagenes.sort((a, b) => a.posicion - b.posicion);

    this.setState({
      entity: articulo,
      imagenes,
      selectedImage: imagenes[0],
    });

    return articulo;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, compArticulos, addedModelos, deletedModelos, imagenes, isAdding, posicionChanges } = this.state;
    const { history, toastManager } = this.props;

    if (addedModelos && addedModelos.length > 0) {
      const newModelos = addedModelos.map((modelo) => ({
        articulo_id: Number(id),
        auto_modelo_id: modelo.id,
      }));
      entityToSave.autoModelos = newModelos;
    }

    if (deletedModelos && deletedModelos.length > 0) {
      entityToSave.assocToDelete = { autoModelos: deletedModelos };
    }

    if (entityToSave.compArt) {
      const { compArt } = entityToSave;
      const competidorArticulos = [];
      const competidorIds = Object.keys(entityToSave.compArt);
      competidorIds.forEach((compArtId) => {
        const competidorArt = { competidorId: compArtId, descripcion: compArt[compArtId] };
        const existingCompArt = compArticulos.find((competArt) => competArt.competidorId === Number(compArtId));
        if (existingCompArt !== undefined) {
          competidorArt.id = existingCompArt.id;
        }
        competidorArticulos.push(competidorArt);
      });
      entityToSave.competidorArticulo = competidorArticulos;
      delete entityToSave.compArt;
    }

    if (posicionChanges.length > 0) {
      entityToSave.imagenes = posicionChanges;
    }

    // Retrieve images to upload
    const imgsToUpload = imagenes.filter((img) => !img.isUploaded);
    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/articulos', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/articulos/${id}`, entityToSave);
    }
    if (imgsToUpload.length > 0) {
      const imgPromises = [];
      for (let i = 0; i < imgsToUpload.length; i++) {
        const curImg = imgsToUpload[i];
        // Get image object
        const curImgResponse = await APIClient.getImage(curImg.filename, { responseType: 'blob' });
        const newImage = {};

        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result.split(',');
          newImage.fileData = result[1];
          newImage.fileType = result[0].match(/image\/.*g/g)[0];
          newImage.isUploaded = false;
          newImage.posicion = curImg.posicion;
          const curPromise = APIClient.post(`/articulos/${saveResponse.data.data.id}/imagenes`, newImage);
          imgPromises.push(curPromise);
        };
        reader.onerror = (error) => {
          console.error('Error: ', error);
        };
        reader.readAsDataURL(curImgResponse.data);
      }
      await Promise.all(imgPromises);
    }

    history.push('/articulos');
    toastManager.add(`Artículo ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  prepareToSave = (entityToSave, actionType) => {
    const { posicionChanges } = this.state;
    if (posicionChanges.length > 0) {
      entityToSave.imagenes = posicionChanges;
    }

    return entityToSave;
  };

  /**
   * Receives current autoModelos, action performed by the user and a removed object (if something was removed).
   * Verifies the action performed and adds or deletes accordingly, then saves state.
   * @param  {Object[]} value - currently selected autoModelos.
   * @param  {string} action - user action.
   * @param  {Object} removedValue - if some autoModelo is removed, it receives an object. If not, undefined.
   */
  onAutoModeloSelectChange = (value, { action, removedValue }) => {
    const { addedModelos, autoModelos, deletedModelos } = this.state;
    switch (action) {
      default:
        break;
      case 'remove-value':
        const isModeloNew = addedModelos.find((modelo) => modelo.id === removedValue.id);
        if (isModeloNew !== undefined) {
          const index = addedModelos.findIndex((modelo) => modelo.id === isModeloNew.id);
          addedModelos.splice(index, 1);
        } else {
          deletedModelos.push(removedValue.id);
        }
        break;
      case 'select-option':
        {
          const selectedValue = value[value.length - 1];
          const curModelo = autoModelos.find((modelo) => modelo.id === selectedValue.id);
          addedModelos.push(curModelo);
        }
        break;
    }
    this.setState((prevState) => ({
      entity: {
        ...prevState.entity,
        addedModelos,
        autoModelos: value,
        deletedModelos,
      },
    }));
  };

  render() {
    const {
      autoModelos,
      colecciones,
      competidores,
      entity,
      formas,
      id,
      imagenes,
      isDataLoading,
      isAdding,
      lineas,
      marcas,
      selectedImage,
      tipologias,
    } = this.state;

    const modelOptions = autoModelos.map((modelo) => ({
      id: modelo.id,
      value: modelo.id,
      label: `${modelo.autoMarca.descripcion}, ${modelo.descripcion} - ${modelo.motor}`,
      key: modelo.id,
    }));

    const { atributos } = entity;

    let curModelos = [];
    if (entity.autoModelos) {
      curModelos = entity.autoModelos.map((modelo) => {
        const curModel = modelOptions.find((model) => model.id === modelo.id);
        return curModel;
      });
    }

    return (
      <div>
        {this.showDeleteImageModal()}
        <h1 className="page-title">{isAdding ? 'Artículo nuevo' : `Artículo #${id}`}</h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          prepareToSave={this.prepareToSave}>
          <>
          <Col>
                <FormCheckField id="byStock" label="Consumo por entrega" defaultChecked={entity.byStock} />
          </Col>
            <Row>
              <Col md={6}>
                <FormInputField id="descripcion" label="Descripción" type="text" defaultValue={entity.descripcion} required />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="marcaCodigo"
                  label="Marca"
                  defaultValue={entity.marcaCodigo}
                  choices={marcas}
                  choiceIdField="codigo"
                  choiceLabelField="descripcion"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInputField id="erpCodigo" label="Código ERP" type="text" defaultValue={entity.erpCodigo} />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="lineaId"
                  label="Linea"
                  defaultValue={entity.lineaId}
                  choices={lineas}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputField id="codigoEan13" label="Código EAN13" type="text" defaultValue={entity.codigoEan13} />
              </Col>
              <Col md={6}>
                <FormSelectField
                  id="coleccionCodigo"
                  label="Colección"
                  defaultValue={entity.coleccionCodigo}
                  choices={colecciones}
                  choiceIdField="codigo"
                  choiceLabelField="descripcion"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormSelectField
                  id="tipologia"
                  label="Tipología"
                  type="text"
                  defaultValue={entity.tipologiaId}
                  choices={tipologias}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                />
              </Col>
              <Col md={3}>
                <FormInputField
                  id="factorBulto"
                  type="number"
                  label="Factor Bulto:"
                  defaultValue={entity ? entity.factorBulto : null}
                  min={0}
                  step="0.1"
                />
              </Col>
              <Col md={3}>
                <FormInputField
                  id="factorBulto2"
                  type="number"
                  label="Factor Bulto 2:"
                  min={0}
                  step="0.1"
                  defaultValue={entity ? entity.factorBulto2 : null}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormCheckField id="isPromo" label="Promoción" defaultChecked={entity.isPromo} />
              </Col>
              <Col>
                <FormCheckField id="isNovedad" label="Novedad" defaultChecked={entity.isNovedad} />
              </Col>
              <Col>
                <FormCheckField id="isEliminado" label="Inactivo" defaultChecked={entity.isEliminado} />
              </Col>
              <Col>
                <FormCheckField id="isFavorito" label="Favorito" defaultChecked={entity.isFavorito} />
              </Col>
              <Col>
                <FormCheckField id="isAgotado" label="Agotado" defaultChecked={entity.isAgotado} />
              </Col>
            </Row>

            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Especificaciones</Card.Title>
                <Row>
                  <Col sm={3}>
                    <FormInputField
                      id="atributos.codigoOEM"
                      type="text"
                      label="Código OEM"
                      defaultValue={atributos && atributos.codigoOEM}
                    />
                  </Col>
                  <Col sm={3}>
                    <FormInputField
                      id="atributos.codigoOtros"
                      type="text"
                      label="Código Otros"
                      defaultValue={atributos && atributos.codigoOtros}
                    />
                  </Col>
                  <Col sm={3}>
                    <FormInputField id="atributos.rosca" type="text" label="Rosca" defaultValue={atributos && atributos.rosca} />
                  </Col>
                  <Col sm={3}>
                    <FormInputField
                      id="atributos.diametroExt"
                      type="text"
                      label="Diámetro Ext."
                      defaultValue={atributos && atributos.diametroExt}
                    />
                  </Col>
                  <Col sm={3}>
                    <FormInputField
                      id="atributos.diametroExt2"
                      type="text"
                      label="Diámetro Ext. 2"
                      defaultValue={atributos && atributos.diametroExt2}
                    />
                  </Col>
                  <Col sm={3}>
                    <FormInputField
                      id="atributos.diametroInt"
                      type="text"
                      label="Diámetro Int."
                      defaultValue={atributos && atributos.diametroInt}
                    />
                  </Col>
                  <Col sm={3}>
                    <FormInputField
                      id="atributos.diametroInt2"
                      type="text"
                      label="Diámetro Int. 2"
                      defaultValue={atributos && atributos.diametroInt2}
                    />
                  </Col>
                  <Col sm={3}>
                    <FormInputField
                      id="atributos.entradaSalida"
                      type="text"
                      label="Entrada/Salida"
                      defaultValue={atributos && atributos.entradaSalida}
                    />
                  </Col>
                  <Col sm={3}>
                    <FormInputField id="atributos.largo" type="text" label="Largo" defaultValue={atributos && atributos.largo} />
                  </Col>
                  <Col sm={3}>
                    <FormInputField id="atributos.largo2" type="text" label="Largo 2" defaultValue={atributos && atributos.largo2} />
                  </Col>
                  <Col sm={3}>
                    <FormInputField id="atributos.ancho" type="text" label="Ancho" defaultValue={atributos && atributos.ancho} />
                  </Col>
                  <Col sm={3}>
                    <FormInputField id="atributos.ancho2" type="text" label="Ancho 2" defaultValue={atributos && atributos.ancho2} />
                  </Col>
                  <Col sm={3}>
                    <FormInputField id="atributos.altura" type="text" label="Altura" defaultValue={atributos && atributos.altura} />
                  </Col>
                  <Col sm={3}>
                    <FormInputField id="atributos.altura2" type="text" label="Altura 2" defaultValue={atributos && atributos.altura2} />
                  </Col>
                  <Col sm={3}>
                    <FormSelectField
                      id="atributos.formaId"
                      label="Forma"
                      defaultValue={atributos && atributos.formaId}
                      choices={formas}
                      choiceIdField="id"
                      choiceLabelField="descripcion"
                    />
                  </Col>
                  <Col sm={3}>
                    <FormInputField id="atributos.valvulas" type="text" label="Válvulas" defaultValue={atributos && atributos.valvulas} />
                  </Col>
                  <Col sm={3}>
                    <FormInputField
                      id="atributos.valvulas2"
                      type="text"
                      label="Válvulas 2"
                      defaultValue={atributos && atributos.valvulas2}
                    />
                  </Col>
                  <Col sm={3}>
                    <FormInputField id="atributos.juntas" type="text" label="Juntas" defaultValue={atributos && atributos.juntas} />
                  </Col>
                  <Col sm={3}>
                    <FormInputField id="atributos.detalle" type="text" label="Detalle" defaultValue={atributos && atributos.detalle} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Modelos</Card.Title>
                <Row>
                  <Col className="p-3">
                    <AsyncSelect
                      defaultValue={curModelos || []}
                      isMulti
                      isLoading={isDataLoading}
                      loadOptions={this.loadAutoModeloOptions}
                      onChange={this.onAutoModeloSelectChange}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {competidores && competidores.length > 0 && (
              <Card className="mb-2">
                <Card.Body>
                  <Card.Title>Competidores</Card.Title>
                  {competidores && (
                    <div>
                      <Row>{this.generateCompArtElem()}</Row>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
            {/* <Row> */}
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Imágenes
                </Card.Title>
                {selectedImage && imagenes.length > 0 && (
                  <Row>
                    <Col className="d-flex align-items-center" md={9}>
                      <Container className="mb-2 border border-primary rounded d-flex justify-content-center">
                        <Image
                          id="img_selected"
                          src={
                            selectedImage.isUploaded ? `${config.api.publicPath}/${id}/${selectedImage.filename}` : selectedImage.filename
                          }
                          alt={`imagen_${selectedImage.posicion}`}
                          rounded
                          fluid
                        />
                      </Container>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center" md={3}>
                      <Container className="d-flex flex-column">
                        <Button
                          disabled={selectedImage.posicion === imagenes[0].posicion}
                          className="m-1 bg-primary"
                          onClick={this.setImageAsDefault}>
                          {selectedImage.posicion === imagenes[0].posicion ? 'Predeterminada' : 'Marcar como predet.'}
                        </Button>
                        <Button
                          className="m-1 bg-primary"
                          onClick={() =>
                            this.setState({
                              showDeleteImageModal: true,
                              imageToDelete: { ...selectedImage },
                            })
                          }>
                          Eliminar
                        </Button>
                      </Container>
                    </Col>
                  </Row>
                )}
                {imagenes && this.generateImagesElem()}
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Col md={10}>
                  <Form.Group className="d-flex align-items-center mb-0" controlId="fileUpload">
                    <label className="custom-file-label position-absolute mb-0" htmlFor="customFileLang">
                      Haga click para seleccionar un archivo.
                    </label>
                    <input
                      name="fileUpload"
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={this.handleImageChange}
                    />
                  </Form.Group>
                </Col>
              </Card.Footer>
            </Card>
            {/* </Row> */}
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ArticuloEdit);
