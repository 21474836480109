import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Moment from 'react-moment';

import Layout from './views/Layout';
import Login from './views/Security/Login';
import withAuth from './views/withAuth';
import Remito from './views/Remito';
import PedidoImprimir from './views/PedidoImprimir';
import EtiquetaEnviosImprimir from './views/EtiquetaEnviosImprimir';
import PendingsPrint from './views/PendingsPrint';

import './App.css';

// Moment defaults
Moment.globalFormat = 'YYYY-MM-DD HH:mm';

function App() {
  return (
    <BrowserRouter>
      <ToastProvider autoDismissTimeout="6000" placement="bottom-right">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/remitos/:id" component={withAuth(Remito)} />
          <Route path="/pedidos/:id/imprimir" component={withAuth(PedidoImprimir)} />
          <Route path="/etiqueta-envios/:id/imprimir" component={withAuth(EtiquetaEnviosImprimir)} />
          <Route path="/monitor-pendientes/:id/imprimir" component={withAuth(PendingsPrint)} key="/entregas/:id" />
          <Route path="/" component={withAuth(Layout)} />
        </Switch>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
