import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import RowEgreso from './RowEgreso';
import APIClient from '../../services/APIClient';
import { Loading, FormSelectField } from '../../components';
import localStorage from '../../services/Security';

class StocklistEgreso extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: {},
      movementMotives: [],
      productStatuses: [],
      movementsToPost: [],
      productTypes: [],
      selectedPT: { id: 0, description: 'elegir tipo' },
      warehouses: [],
      sections: [],
      locations: [],
      products: [],
      entityToSaveFather: {},
      formTrackingState: {
        selectedProductType: 0,
        warehouseId: 0,
        sectionId: 0,
        locationId: 0,
      },
      arrayvalidateindex: [],
      ubications: [],
      renderUb: false,
      loaded: false,
      waitingQuery: false,
      motiveId: '',
      productTypeId: '',
      identificators: {},
      displayNoneIndexesList: [],
      indexesErrors: [],
      userId: localStorage.getSession().user.id,
    };
    this.requiredFields = ['productTypeId', 'motiveId'];
  }
  async componentDidMount() {
    await this.onLoadForm();
  }
  onLoadForm = async () => {
    const { toastManager } = this.props;
    try {
      const movementMotiveListIngresoQuery =
        '?filter[description][or]=egreso por devolución a proveedor&filter[description][or]=egreso por venta&filter[description][or]=consumo por producción&filter[description][or]=ajuste negativo de stock';
      // get movementMotives
      const movementMotivesRes = await APIClient.get(`/stocklist-movement-motives${movementMotiveListIngresoQuery}`);
      // get movementTypes
      const movementTypesRes = await APIClient.get('/stocklist-movement-types');
      // get productStatuses
      const productStatusesRes = await APIClient.get('/stocklist-product-statuses');
      // get productTypes
      const productTypesRes = await APIClient.get('/stocklist-product-types');
      // get warehouses
      const warehousesRes = await APIClient.get('/warehouses');
      // get sections
      const sectionsRes = await APIClient.get('/sections');
      // get locations
      const locationsRes = await APIClient.get('/locations');
      //get articulos
      const comercializablesRes = await APIClient.get(`/stocklist/items-filter?productType=comercializable`);
      //get Clientes
      const clientsRes = await APIClient.get(`/clientes`);
      //get providers
      const providersRes = await APIClient.get('/proveedores');
      // get insumos
      const insumosRes = await APIClient.get(`/stocklist/items-filter?productType=insumo`);
      //load default products
      this.loadProducts('comercializable');
      this.setState({
        movementMotives: movementMotivesRes.data.data,
        movementTypes: movementTypesRes.data.data,
        productStatuses: productStatusesRes.data.data,
        productTypes: productTypesRes.data.data,
        warehouses: warehousesRes.data.data,
        sections: sectionsRes.data.data,
        locations: locationsRes.data.data,
        comercializables: comercializablesRes.data.data,
        insumos: insumosRes.data.data,
        clients: clientsRes.data.data,
        providers: providersRes.data.data,
      });
    } catch (err) {
      console.error('Cargando datos para movimiento de stock', err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        loaded: true,
      });
    }
  };

  validateFieldExistence = (movements) => {
    const { movementMotives } = this.state;
    const { toastManager } = this.props;
    const fieldListRequire = [
      'motiveId',
      'productTypeId',
      'typeId',
      'isDelete',
      'locationId',
      'productStatusId',
      'sectionId',
      'typeId',
      'units',
      'warehouseId',
    ];

    const indexesWithErrors = [];

    movements.forEach((mv, index) => {
      if (!mv.isDelete) {
        const motiveDescription = movementMotives.find((mot) => mot.id === Number(mv.motiveId)).description;

        const fieldsCuerrentRequire = Object.keys(mv);

        fieldListRequire.forEach((field) => {
          //requires
          if (!fieldsCuerrentRequire.includes(field)) {
            if (!indexesWithErrors.includes(index)) {
              indexesWithErrors.push(index);
            }
          }
          //requires by motive
          if (motiveDescription === 'egreso por venta' && !fieldsCuerrentRequire.includes('clientId')) {
            if (!indexesWithErrors.includes(index)) {
              indexesWithErrors.push(index);
            }
          }
          if (motiveDescription === 'egreso por devolución a proveedor' && !fieldsCuerrentRequire.includes('providerId')) {
            if (!indexesWithErrors.includes(index)) {
              indexesWithErrors.push(index);
            }
          }
        });
      }
    });

    if (indexesWithErrors.length) {
      toastManager.add(`Transaccion incompleta`, {
        appearance: 'warning',
        autoDismiss: true,
      });
      this.setState({ indexesErrors: indexesWithErrors });
      throw 'error';
    }
  };

  validateDuplicate = (movements) => {
    const { toastManager } = this.props;
    let exitMovements = [];
    movements.forEach((mov) => {
      let exitMov;

      if (mov.insumoId) {
        const { insumoId, isDelete, locationId, productStatusId, sectionId, type, warehouseId } = mov;
        exitMov = {
          insumoId,
          locationId,
          productStatusId,
          sectionId,
          type,
          warehouseId,
          isDelete,
        };
      } else {
        const { articuloId, isDelete, locationId, productStatusId, sectionId, type, warehouseId } = mov;
        exitMov = {
          articuloId,
          locationId,
          productStatusId,
          sectionId,
          type,
          warehouseId,
          isDelete,
        };
      }

      if (exitMovements.includes(JSON.stringify(exitMov))) {
        toastManager.add('Transaccion duplicada', {
          appearance: 'error',
        });
        exitMovements = [];
        throw 'Transaccion duplicada';
      }
      if (!mov.isDelete) {
        exitMovements.push(JSON.stringify(exitMov));
      }
    });
    return false;
  };

  saveMovementInState = (movement) => {
    let { movementsToPost } = this.state;
    movement.isDelete = false;
    movementsToPost = movementsToPost.concat(movement);
    this.setState((prevState) => ({
      ...prevState,
      movementsToPost,
    }));
  };

  async loadProducts(productType) {
    const { toastManager } = this.props;
    let url;
    if (productType === 'insumo') url = '/insumos?filter[active][eq]=1';
    if (productType === 'comercializable') url = '/articulos';
    try {
      const productsRes = await APIClient.get(url);
      this.setState({
        products: productsRes.data.data,
      });
    } catch (err) {
      toastManager.add(`Error al cargar datos. Recargar página. Checkear conexión a internet`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  checkUnist(movements) {
    const { toastManager } = this.props;

    const invalidUnist = movements.some((mov) => mov.units <= 0 || mov.units === '0');
    if (invalidUnist) {
      toastManager.add('Solo unidades positivas', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return false;
    }
    return true;
  }

  saveAllMovements = async () => {
    const { toastManager } = this.props;
    let { movementsToPost, userId } = this.state;
    await this.validateFieldExistence(movementsToPost);
    //this.checkUnist(movementsToPost)

    const isDuplicate = this.validateDuplicate(movementsToPost);
    if (!isDuplicate) {
      try {
        movementsToPost = movementsToPost.filter((m) => m.id !== '0' && m.productTypeId !== 'delete');
        movementsToPost.forEach((m) => {
          m.userId = userId;
          delete m.id;
        });
        this.setState((prevState) => ({
          ...prevState,
          waitingQuery: true,
        }));

        const movementsToPostFilter = movementsToPost.filter((mov) => !mov.isDelete);
        if (this.checkUnist(movementsToPostFilter)) {
          await APIClient.post('/stocklist', movementsToPostFilter);
          this.props.history.push('/stocklist');
          toastManager.add(`Stock consumido correctamente`, {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      } catch (err) {
        console.error('Cargando datos de stock', err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
      } finally {
        this.setState((prevState) => ({
          waitingQuery: false,
        }));
      }
    }
  };

  handleDataChange = (e) => {
    const { id, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  addEgresoRow = () => {
    this.setState((prevState) => {
      const copyEgresos = [...prevState.movementsToPost];
      const movementType = prevState.movementTypes.find((mov) => mov.description === 'egreso');
      copyEgresos.push({
        motiveId: prevState.motiveId,
        productTypeId: Number(prevState.productTypeId),
        typeId: movementType.id,
        isDelete: false,
      });

      return { ...prevState, movementsToPost: copyEgresos };
    });
  };

  editMovementRow = (index, data) => {
    this.setState((prevState) => {
      const copyEgresos = [...prevState.movementsToPost];
      copyEgresos[index].isDelete = false;
      copyEgresos[index] = {
        ...copyEgresos[index],
        ...data,
      };
      return { ...prevState, movementsToPost: copyEgresos };
    });
  };

  resetMovementInState = (index) => {
    this.setState((prevState) => {
      const copyMovements = [...prevState.movementsToPost];
      const itemId = copyMovements[index].hasOwnProperty('articuloId') ? 'articuloId' : 'insumoId';
      copyMovements[index] = {
        productTypeId: copyMovements[index].productTypeId,
        typeId: copyMovements[index].typeId,
        motiveId: copyMovements[index].motiveId,
        [itemId]: copyMovements[index][itemId],
        isDelete: copyMovements[index].isDelete,
      };
      return { ...prevState, movementsToPost: copyMovements };
    });
  };

  handleIdentificators = (index, ubications) => {
    this.setState(
      (prevState) => {
        const copyIdentificators = prevState.identificators;
        const identificatorsKeys = Object.keys(copyIdentificators);
        identificatorsKeys.forEach((idKey) => {
          if (Number(idKey.substr(idKey.length - 1, 1)) === index) {
            delete copyIdentificators[idKey];
          }
        });
        return { ...prevState, identificators: copyIdentificators };
      },
      () => {
        const { identificators } = this.state;
        ubications.forEach((ub) => {
          ub.details.forEach((detail) => {
            const data = detail;
            const identificator = detail.ingresoId + `-${index}`;
            identificators[identificator] = data;
          });
        });
        this.setState({ identificators });
      },
    );
  };

  handleUniqueIdentificator = (index) => {
    this.setState((prevState) => {
      const copyOfMovements = [...prevState.movementsToPost];
      const currentEditedMovement = copyOfMovements[index];
      const itemId = currentEditedMovement.hasOwnProperty('articuloId') ? currentEditedMovement.articuloId : currentEditedMovement.insumoId;

      const currentIdentificator = `${currentEditedMovement.clientId}-${currentEditedMovement.providerId}-${currentEditedMovement.productTypeId}-${itemId}-${currentEditedMovement.warehouseId}-${currentEditedMovement.sectionId}-${currentEditedMovement.locationId}-${currentEditedMovement.productStatusId}`;

      const copyIdentificators = prevState.identificators;
      const identificatorsKeys = Object.keys(copyIdentificators);
      identificatorsKeys.forEach((idKey) => {
        if (Number(idKey.substr(idKey.length - 1, 1)) === index && !idKey.includes(currentIdentificator)) {
          delete copyIdentificators[idKey];
        }
      });
      return { ...prevState, identificators: copyIdentificators };
    });
  };

  handleEditChangesInState = (index, handledField) => {
    const fieldsInOrder = ['clientId', 'providerId', 'warehouseId', 'sectionId', 'locationId', 'productStatusId', 'units'];

    const indexToCut = fieldsInOrder.indexOf(handledField);
    const fieldsToDeleteFromMovement = fieldsInOrder.slice(indexToCut + 1, fieldsInOrder.length);
    this.setState((prevState) => {
      const copyMovements = [...prevState.movementsToPost];
      const current = copyMovements[index];
      fieldsToDeleteFromMovement.forEach((fTD) => {
        if (current.hasOwnProperty(fTD)) {
          delete current[fTD];
        }
      });
      return { ...prevState, movementsToPost: copyMovements };
    });
  };

  validateUnits = (index, data) => {
    const { identificators, movementsToPost, displayNoneIndexesList } = this.state;
    const currentEditedMovement = movementsToPost[index];
    const itemId = currentEditedMovement.hasOwnProperty('articuloId') ? currentEditedMovement.articuloId : currentEditedMovement.insumoId;
    const currentIdentificator = `${currentEditedMovement.productTypeId}-${itemId}-${currentEditedMovement.warehouseId}-${currentEditedMovement.sectionId}-${currentEditedMovement.locationId}-${currentEditedMovement.fifoDate}-${currentEditedMovement.productStatusId}`;
    const identificatorsKeys = Object.keys(identificators);
    identificatorsKeys.forEach((idKey) => {
      if (idKey.includes(currentIdentificator)) {
        if (Number(data.units) > identificators[idKey].resta) {
          alert(`${data.units} cantidad no disponible`);
          throw false;
        }
        // dont't allow two lines with the same identificator
        const idKeyFile = idKey.substr(idKey.length - 1, 1);
        if (Number(idKeyFile) !== index) {
          alert(`existe una línea en la pantalla actual para este artículo - posición física - estado`);
          this.setState((prevState) => {
            const copyMovements = [...prevState.movementsToPost];
            const copyIdentificators = prevState.identificators;
            //reset row
            identificatorsKeys.forEach((idKey2) => {
              if (Number(idKey2.substr(idKey2.length - 1, 1)) === index) {
                delete copyIdentificators[idKey2];
              }
            });
            copyMovements[index] = { productTypeId: 'delete' };
            displayNoneIndexesList.push(index);
            return { ...prevState, movementsToPost: copyMovements, displayNoneIndexesList, identificators: copyIdentificators };
          });
          throw false;
        }
      }
    });
    return true;
  };

  removeMovement = (index) => {
    this.setState((prevState) => {
      const copyEgresos = [...prevState.movementsToPost];
      copyEgresos[index] = {
        ...copyEgresos[index],
        ...{ isDelete: true },
      };
      return { ...prevState, movementsToPost: copyEgresos };
    });
  };

  render() {
    const {
      isAdding,
      movementMotives,
      movementsToPost,
      productTypes,
      productStatuses,
      warehouses,
      sections,
      locations,
      loaded,
      waitingQuery,
      comercializables,
      insumos,
      displayNoneIndexesList,
      indexesErrors,
      clients,
      providers,
      motiveId,
    } = this.state;
    return loaded ? (
      <Fragment>
        <div>
          <h1 className="page-title">{isAdding ? 'Consumir del stock' : ''}</h1>
          <Row className=" align-items-center">
            <Col md={2}>
              <FormSelectField
                id="motiveId"
                label="Motivo"
                choices={movementMotives}
                onChange={this.handleDataChange}
                choiceIdField="id"
                choiceLabelField="description"
                // arrayvalidateindex={arrayvalidateindex}
              />
            </Col>
            <Col md={3}>
              <FormSelectField
                id="productTypeId"
                label="Tipo"
                choices={productTypes}
                onChange={this.handleDataChange}
                choiceIdField="id"
                choiceLabelField="description"
                // arrayvalidateindex={arrayvalidateindex}
              />
            </Col>
            <Col md={3} className="mt-2 d-flex align-items-center">
              <Button onClick={() => this.addEgresoRow()}>Agregar nueva fila</Button>
            </Col>
          </Row>
        </div>
        <div>
          {!!movementsToPost.filter((mov) => mov.isDelete === false).length && (
            <Row className="mt-2" style={{ width: '120%', paddingLeft: '40px' }}>
              <Col style={{ paddingLeft: '0', paddingRight: '15px', maxWidth: '17%', flex: '0 0 17%' }}>
                <h4 style={{ fontSize: '1rem' }}>Seleccione Articulo</h4>
              </Col>
              <Col md={1}>
                <h4 style={{ fontSize: '1rem' }}>Cliente</h4>
              </Col>
              <Col md={1}>
                <h4 style={{ fontSize: '1rem' }}>Proveedor</h4>
              </Col>
              <Col md={1}>
                <h4 style={{ fontSize: '1rem' }}>Depósito</h4>
              </Col>
              <Col style={{ paddingRight: '0px' }}>
                <h4 style={{ fontSize: '1rem' }}>Sector</h4>
              </Col>
              <Col style={{ paddingLeft: '7px', paddingRight: '0px' }}>
                <h4 style={{ fontSize: '1rem' }}>Posición</h4>
              </Col>
              <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <h4 style={{ fontSize: '1rem' }}>Estado</h4>
              </Col>
              <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <h4 style={{ fontSize: '1rem' }}>U. en stock</h4>
              </Col>
              <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <h4 style={{ fontSize: '1rem' }}>U. a mover</h4>
              </Col>
              <Col style={{ paddingLeft: '0px' }}>
                <h4 style={{ fontSize: '1rem' }}>Descripción</h4>
              </Col>
            </Row>
          )}
          {movementsToPost.map((egreso, index) => {
            if (!egreso.isDelete) {
              const productType = productTypes.find((type) => type.id === egreso.productTypeId);
              const productData = {
                type: productType,
                insumos,
                comercializables,
                egreso,
              };
              const aditionalData = {
                productStatuses,
                warehouses,
                sections,
                locations,
              };
              return (
                <RowEgreso
                  key={index}
                  index={index}
                  handleRowData={this.editMovementRow}
                  handleIdentificators={this.handleIdentificators}
                  handleUniqueIdentificator={this.handleUniqueIdentificator}
                  handleEditChangesInState={this.handleEditChangesInState}
                  validateUnits={this.validateUnits}
                  productData={productData}
                  aditionalData={aditionalData}
                  displayNone={displayNoneIndexesList.indexOf(index) >= 0}
                  resetMovementInState={this.resetMovementInState}
                  indexesErrors={indexesErrors}
                  removeMovement={this.removeMovement}
                  clients={clients}
                  providers={providers}
                  motiveDescription={movementMotives.find((mot) => mot.id === Number(motiveId)).description}
                />
              );
            }
          })}
          {movementsToPost.filter((mov) => mov?.isDelete === false).length >= 1 && (
            <Button className="mt-2" key="submitBtn" variant="primary" onClick={() => this.saveAllMovements()}>
              {waitingQuery ? <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" /> : 'Guardar'}
            </Button>
          )}
        </div>
      </Fragment>
    ) : (
      <Loading />
    );
  }
}

export default withToastManager(StocklistEgreso);
