import React from 'react';
import { Route } from 'react-router-dom';
import ListaMaterialesList from './ListaMaterialesList'
import ListaMaterialesEdit from './ListaMaterialesEdit'

const routes = () => [
  <Route path="/materials-list/new" exact component={ListaMaterialesEdit} key="/materials-list/new" />,
  <Route path="/materials-list/:id(\d+)" component={ListaMaterialesEdit} key="/materials-list/:id" />,
  <Route path="/materials-list" exact component={ListaMaterialesList} key="/materials-list" />,
].map(route => route);

export default routes;
