import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Button, ButtonGroup, ButtonToolbar, Card, Modal, ProgressBar, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faEdit, faTimes, faBroom, faTruck, faSync, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, FormInputField, FormSelectField, Loading, FormCheckField } from '../../components';
import APIClient from '../../services/APIClient';
import UIUtils from '../UIUtils';
import PrecioService from '../../services/Precio';
import { connect } from 'react-redux';


class SolicitudList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      entityToCancel: {},
      isDataLoading: true,
      isEntityDataLoading: false,
      showAnularModal: false,
      totalSize: 0,
      limit: 10,
      offset: 0,
      showConfirmRecalculateModal: false,
      requesUpdateIds: [],
      requetsUpdatePriceIds:[],
      loadingRecalculate: false,
      queryParametersDataTable: {},
      /* filtros */
      requestStatus: [],
      clients: [],
      rangeType: [
        { description: 'Desde', id: 'createdAt' },
        { description: 'Hasta', id: 'endDate' },
      ],
      recalculePriceOptions: [
        { description: 'Actualizados', id: false },
        { description: 'Desactualizados', id: true },
      ],
      recalculePriceIdSelected:'',
      clientSelected: '',
      curFromDate: '',
      curToDate: '',
      requestStatusSelected: '',
      disableButtonsFilter: true,
      defaultPropFilter: {
        freeText: '',
        pagination: { limit: 10, offset: 0 },
        sorting: { field: 'fechaConfirmacion', direction: 'desc' },
      },
      isDataLoadingFilter: false

    };

    this.loadSolicitudes = this.loadSolicitudes.bind(this);

  }

  getPermissions() {
    const { isSuperAdmin, editPermission, deletePermission, isPermissionsLoading } = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
    if(prevState.isDataLoadingFilter !== prevState.isDataLoadingFilter){
      this.onTableUpdate(this.state.defaultPropFilter)
    }
  }

  componentDidMount() {
    this.loadSolicitudes();
  }

  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const {
      recalculePriceIdSelected,
      clientSelected,
      curFromDate,
      curToDate,
      requestStatusSelected,
    } = this.state;

    this.setState({ isDataLoadingFilter: true })
    let solicitudesRes;
    const { freeText, pagination, sorting } = queryParameters;
    const { direction, field } = sorting
    const apiParams = `includeCantidad=true&freeText=${freeText && `%${freeText}%`}&sortField=${field || 'createdAt'}&sortDir=${direction || 'desc'}`;
    const queryParams = `toUpdatePrice=${recalculePriceIdSelected}&clienteId=${clientSelected}&codigo=${requestStatusSelected}&curFromDate=${curFromDate}&curToDate=${curToDate}`;

    try {
      solicitudesRes = await APIClient.get(`/solicitudes?limit=${pagination.limit}&offset=${pagination.offset}&${apiParams}&${queryParams}`);
  
      const requetsUpdatePriceIds = solicitudesRes.data.data.filter(request => !request.toUpdatePrice || (request.toUpdatePrice && !request.estadoSolicitudCodigo.match(/SA|SP/))).map(request=>request.id)

      this.setState({
        entities: solicitudesRes.data.data,
        requetsUpdatePriceIds,
        totalSize: solicitudesRes.data.meta.total,
        limit: pagination.limit,
        offset: pagination.offset,
        isDataLoadingFilter: false,
        isDataLoading: false

      })
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  async loadSolicitudes() {
    const { toastManager } = this.props;
    try {
      const clientsRes = await APIClient.get(`/clientes`);
      const requestStatusRes = await APIClient.get(`/request-status`);
      this.setState({
        clients: clientsRes.data.data,
        requestStatus: requestStatusRes.data.data,
        isDataLoading: false
      });
    } catch (error) {
      console.error('Cargando solicitudes', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } 
  }

  limpiarSolicitud = async () => {
    const { entityToClean, defaultPropFilter } = this.state;
    const { toastManager } = this.props;
    this.setState({ disableButtons: true });
    try {
      await APIClient.patch(`/solicitudes/${entityToClean.id}/limpiar`);
      toastManager.add('Se limpió la solicitud correctamente.', {
        appearance: 'success',
      });
    } catch (err) {
      console.error(err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ disableButtons: false, isEntityDataLoading: false, entityToClean: {}, showLimpiarSolicitudModal: false });
      await this.loadSolicitudes();
      await this.onTableUpdate(defaultPropFilter)
    }
  };

  getEntityData = async (row) => {
    const { toastManager } = this.props;
    try {
      const entity = await APIClient.get(`/solicitudes/${row.id}`);
      const entityData = entity.data.data;
      if (
        entityData.estadoSolicitudCodigo.match(/SA|SP/) &&
        entityData.detalles.every((det) => det.solicitudDetalleTalle.entregado === 0)
      ) {
        this.setState({ entityToCancel: entityData, showAnularModal: true });
      } else {
        toastManager.add('No se puede anular esta solicitud porque ya tiene detalles entregados.', {
          appearance: 'warning',
        });
      }
    } catch (error) {
      console.error('Obteniendo información acerca de la solicitud.', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isEntityDataLoading: false });
    }
  };

  handleAnularClick = (row) => {
    this.setState({ isEntityDataLoading: true, showAnularModal: true, entityToCancel: row });
  };

  handleLimpiarClick = (row) => {
    this.setState({ isEntityDataLoading: true, showLimpiarSolicitudModal: true, entityToClean: row });
  };

  handleButtonClick = (row) => {
    this.setState({ isEntityDataLoading: true });
    this.getEntityData(row);
  };

  anularSolicitud = async () => {
    const { entityToCancel, defaultPropFilter } = this.state;
    const { toastManager } = this.props;
    this.setState({ disableButtons: true });
    try {
      await APIClient.patch(`/solicitudes/${entityToCancel.id}`, { estadoSolicitudCodigo: 'SB' });
      toastManager.add('Solicitud anulada correctamente.', {
        appearance: 'success',
      });
      this.setState((prevState) => {
        const { entities } = prevState;
        const cancelledEntity = entities.find((ent) => ent.id === entityToCancel.id);
        cancelledEntity.estadoSolicitudCodigo = 'SB';
        return { entities, disableButtons: false };
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.response.data.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ disableButtons: false, showAnularModal: false, entityToCancel: {} });
      await this.loadSolicitudes();
      await this.onTableUpdate(defaultPropFilter)
    }
  };

  renderAnularPedidoModal = () => {
    const { disableButtons, showAnularModal } = this.state;
    return (
      <Modal size="md" show={showAnularModal} onHide={() => this.setState({ showAnularModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Anular Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea anular este pedido?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={disableButtons} variant="primary" onClick={this.anularSolicitud}>
            {disableButtons ? <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="mr-1" /> : 'Anular'}
          </Button>
          <Button variant="secondary" onClick={() => this.setState({ showAnularModal: false, entityToCancel: {} })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderCleanSolicitudModal = () => {
    const { disableButtons, showLimpiarSolicitudModal } = this.state;
    return (
      <Modal size="md" show={showLimpiarSolicitudModal} onHide={() => this.setState({ showLimpiarSolicitudModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Limpiar Solicitud</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea limpiar esta solicitud?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={disableButtons} variant="primary" onClick={this.limpiarSolicitud}>
            {disableButtons ? <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="mr-1" /> : 'Limpiar'}
          </Button>
          <Button
            disabled={disableButtons}
            variant="secondary"
            onClick={() => this.setState({ showLimpiarSolicitudModal: false, entityToCancel: {} })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  // Entrega a partir de solicitud
  handleRedirect = async (selectedClienteId, solicitudId) => {
    const { toastManager, history } = this.props;
    try {
      const entregaAbierta = await APIClient.get(`/entregas?filter[clienteId][eq]=${selectedClienteId}&filter[estadoEntrega][eq]=EA`);
      if (entregaAbierta.data.data.length === 0) {
        history.push(`/entregas/nuevo/${selectedClienteId}?solicitudId=${solicitudId}`);
      } else {
        history.push(`/entregas/${entregaAbierta.data.data[0].id}?solicitudId=${solicitudId}`);
      }
    } catch (err) {
      toastManager.add('Ocurrió un error al intentar obtener la entrega', {
        appearance: 'error',
      });
    }
  };

  showRecalculatePrices = (ids) => {
    this.setState({ showConfirmRecalculateModal: true , requesUpdateIds:ids});
  };

  handleRecalculatePrices = async () => {
    const { requesUpdateIds } = this.state;
    const { toastManager } = this.props;
    this.setState({ loadingRecalculate: true });

    try {
      await PrecioService.priceUpdatedRequest(requesUpdateIds);

      toastManager.add(

        `${requesUpdateIds.length === 1 ?  `Precios en pedido ${requesUpdateIds[0]} actualizados con éxito` : `Precios en pedidos actualizados con éxito`}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
        () => window.location.reload(),
      );
    } catch (error) {
      console.error(error);
    }
  };

  renderComfirmRecalculateModal = () => {
    const { showConfirmRecalculateModal, loadingRecalculate, requesUpdateIds } = this.state;
    return (
      <Modal
        size="md"
        show={showConfirmRecalculateModal}
        onHide={() => this.setState({ showConfirmRecalculateModal: false, requesUpdateId: '' })}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizacion de precios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            requesUpdateIds?.length === 1 ? (<p>¿La lista de precios de este pedido fue actualizada, deseas actualizar los precios de este pedido?</p>
            ) : ( <p>¿Las listas de precios de estos pedidos fueron actualizadas, deseas actualizar los precios de estos pedidos?</p>)
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={loadingRecalculate} onClick={this.handleRecalculatePrices}>
            {loadingRecalculate ? 'Loading...' : 'Actualizar precios'}
          </Button>
          <Button variant="secondary" onClick={() => this.setState({ showConfirmRecalculateModal: false, requesUpdateId: '' })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleChangeValue = (e) => {
    const { value, id } = e.target;
    this.setState({
      [id]: value,
      disableButtonsFilter: false
    });
  };

  cleanFilters = () => {
    this.setState({
      recalculePriceIdSelected: '',
      clientSelected: '',
      curFromDate: '',
      curToDate: '',
      requestStatusSelected: '',
      disableButtonsFilter: true,
    });
  };

  onSelectRequest= (row,isSelect) => {
    const { requesUpdateIds } = this.state
    
    let requesUpdateIdsUpdated = [];

    if (isSelect) requesUpdateIdsUpdated = [...requesUpdateIds , row.id];

    if (!isSelect) requesUpdateIdsUpdated = requesUpdateIds.filter((requestId) => requestId !== row.id);
    
    this.setState({ requesUpdateIds: requesUpdateIdsUpdated });
  };

  onSelectAllRequest= (isSelect, row) => {
    const { requesUpdateIds, entities } = this.state

    let requesUpdateIdsUpdated = [];
         
    const requetsNotUpdatePriceIds = entities.filter(request => request.toUpdatePrice).map(request=>request.id)
            
    if( isSelect ) requesUpdateIdsUpdated = [...requesUpdateIds, ...requetsNotUpdatePriceIds]

    if( !isSelect )requesUpdateIdsUpdated = requesUpdateIds.filter(requestId=>!requetsNotUpdatePriceIds.includes(requestId))
   
     this.setState({ requesUpdateIds: requesUpdateIdsUpdated }) 

  };

  massiveRecalculation = ()=>{
    const { requesUpdateIds } = this.state
    this.showRecalculatePrices(requesUpdateIds)
  }

  render() {
    const { requetsUpdatePriceIds, requesUpdateIds, defaultPropFilter, isDataLoadingFilter, recalculePriceOptions, requestStatusSelected, curToDate, selectedRange, recalculePriceIdSelected, clientSelected, requestStatus, curFromDate, clients, entities, isDataLoading, totalSize, isSuperAdmin, editPermission, isPermissionsLoading } = this.state;

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      clickToSelect: true,
      nonSelectable: requetsUpdatePriceIds,
      onSelect: this.onSelectRequest,
      onSelectAll: this.onSelectAllRequest
    };

    const columns = [
      {
        dataField: 'toUpdatePrice',
        text: 'Precios',
        sort: true,
        csvFormatter: (cellContent) => {
          let nombre = 'Desactualizado';
          switch (cellContent) {
            case false:
              nombre = 'Actualizado';
              break;
          }
          return nombre;
        },
        formatter: (cellContent, row) => UIUtils.getSolicitudToUpdatePrice(cellContent),
      },
      {
        dataField: 'codigo',
        text: 'Código',
        sort: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'cliente.razonSocial',
        text: 'Cliente',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {cellContent}
            <br />
            <small>{row.cliente && row.cliente.documentoNumero}</small>
          </>
        ),
      },
      {
        dataField: 'cliente.documentoNumero',
        text: 'CUIT',
        sort: false,
        hidden: true,
      },
      {
        dataField: 'createdAt',
        text: 'Fecha Alta',
        sort: true,
        formatter: (cellContent, row) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
      },
      {
        dataField: 'fechaConfirmacion',
        text: 'Fecha Confirmación',
        sort: true,
        formatter: (cellContent, row) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
      },
      {
        dataField: 'estadoSolicitudCodigo',
        text: 'Estado',
        sort: true,
        csvFormatter: (cellContent) => {
          let nombre = '';
          switch (cellContent) {
            case 'SA':
              nombre = 'En Carga';
              break;
            case 'SB':
              nombre = 'Cancelada';
              break;
            case 'SC':
              nombre = 'Cerrada';
              break;
            case 'SE':
              nombre = 'Enviada';
              break;
            case 'SP':
              nombre = 'Confirmada';
              break;
            case 'SF':
              nombre = 'Facturada';
              break;
            default:
              break;
          }
          return nombre;
        },
        formatter: (cellContent, row) => UIUtils.getSolicitudEstadoBadge(cellContent),
      },
      {
        dataField: 'progress',
        isDummyField: true,
        text: 'Progreso',
        formatter: (cellContent, row) => (
          <span>
            <p style={{ textAlign: 'center' }} className="text-align-center mb-0">
              {`${row.entregado}/${row.cantidad}`}
            </p>
            <ProgressBar now={(row.entregado * 100) / row.cantidad} />
          </span>
        ),
        csvFormatter: (cellContent, row) => `${row.entregado}/${row.cantidad}`,
      },
      {
        dataField: 'fechaEntrega',
        text: 'Fecha Comprometida',
        sort: true,
        formatter: (cellContent, row) => {
          if (cellContent !== null) {
            if (row.estadoSolicitudCodigo === 'SP' && moment.utc(cellContent) < moment.utc()) {
              return (
                <Moment className="text-danger font-weight-bold" interval={0}>
                  {cellContent}
                </Moment>
              );
            }
            return <Moment interval={0}>{cellContent}</Moment>;
          }
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar className="d-flex justify-content-center align-items-center">
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <>
                  <LinkContainer to={`/solicitudes/${row.id}`}>
                    <Button size="sm" variant="outline-primary" title="Editar">
                      <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                    </Button>
                  </LinkContainer>

                  <Button
                    size="sm"
                    disabled={!row.estadoSolicitudCodigo.match(/SP/)}
                    variant="outline-primary"
                    onClick={() => this.handleRedirect(row.cliente.id, row.id)}
                    title="Generar-entrega">
                    <FontAwesomeIcon icon={faTruck} fixedWidth size="xs" />
                  </Button>
                  <Button
                    size="sm"
                    disabled={!row.estadoSolicitudCodigo.match(/SP/)}
                    variant="outline-primary"
                    onClick={() => this.handleLimpiarClick(row)}
                    title="Limpiar">
                    <FontAwesomeIcon icon={faBroom} fixedWidth size="xs" />
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => window.open(`/pedidos/${row.id}/imprimir`)}
                    title="Imprimir Pedido">
                    <FontAwesomeIcon icon={faFilePdf} fixedWidth size="xs" />
                  </Button>
                  <Button
                    size="sm"
                    disabled={!row.estadoSolicitudCodigo.match(/SP|SA/)}
                    variant="outline-danger"
                    onClick={() => this.handleAnularClick(row)}
                    title="Anular">
                    <FontAwesomeIcon icon={faTimes} fixedWidth size="xs" />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => window.open(`/pedidos/${row.id}/imprimir`)}
                    title="Imprimir Pedido">
                    <FontAwesomeIcon icon={faFilePdf} fixedWidth size="xs" />
                  </Button>
                </>
              )}
              <Button
                size="sm"
                disabled={!row.toUpdatePrice || (row.toUpdatePrice && !row.estadoSolicitudCodigo.match(/SA|SP/))}
                variant="outline-success"
                onClick={() => this.showRecalculatePrices([row.id])}
                title="Recálculo de precios">
                <FontAwesomeIcon icon={faSync} fixedWidth size="xs" />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
      
    ];

    return (
      <div>
        <h1 className="page-title">Pedidos</h1>
        {this.renderComfirmRecalculateModal()}
        {this.renderAnularPedidoModal()}
        {this.renderCleanSolicitudModal()}
        {isPermissionsLoading === false ?  (
          <>
            <Card className="my-3">
              <Card.Header>
                <Button variant="warning" onClick={async () => this.cleanFilters()} className="float-right">
                  Limpiar Filtros                
                </Button>
                Filtros de búsqueda
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={5}>
                    <FormSelectField
                      id="recalculePriceIdSelected"
                      label="Precios"
                      disabled={isDataLoadingFilter}
                      choices={recalculePriceOptions}
                      choiceIdField="id"
                      choiceLabelField="description"
                      value={recalculePriceIdSelected}
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                  <Col md={5}>
                    <FormSelectField
                      id="clientSelected"
                      label="Clientes"
                      disabled={isDataLoadingFilter}
                      value={clientSelected}
                      choices={clients}
                      choiceIdField="id"
                      choiceLabelField="razonSocial"
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <FormInputField
                      id="curFromDate"
                      type="date"
                      label="Fecha de confirmación - desde:"
                      disabled={selectedRange === '' || isDataLoadingFilter}
                      value={curFromDate}
                      defaultValue={curFromDate !== '' ? curFromDate : null}
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                  <Col md={5}>
                    <FormInputField
                      id="curToDate"
                      type="date"
                      disabled={curFromDate === '' || isDataLoadingFilter}
                      label="Fecha de confirmación - hasta:"
                      value={curToDate}
                      defaultValue={curToDate !== '' ? curToDate : null}
                      min={curFromDate !== '' ? curFromDate : null}
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <FormSelectField
                      id="requestStatusSelected"
                      label="Estado del pedido"
                      choices={requestStatus}
                      value={requestStatusSelected}
                      disabled={isDataLoadingFilter}
                      choiceIdField="codigo"
                      choiceLabelField="descripcion"
                      onChange={this.handleChangeValue}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <div className="d-flex flex-row justify-content-center">
                  <Button
                    disabled={!defaultPropFilter}
                    className="d-flex py-2 mx-1 my-3"
                    variant="primary"
                    onClick={() =>
                      this.onTableUpdate(defaultPropFilter)
                    }>
                    {!isDataLoadingFilter ? (
                      <p className="m-0">Buscar</p>
                    ) : (
                      <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="mr-1" />
                    )}
                  </Button>
                </div>
              </Card.Footer>
            </Card>
            <DataTable
              wrapperClasses={'table-responsive'}
              remote={{
                filter: true,
                pagination: true,
                sort: true,
                cellEdit: false,
              }}
              addButton={()=>this.massiveRecalculation()}
              addButtonIconHidden = { true }
              addButtonIconDisabled = { requesUpdateIds?.length === 0 }
              buttonText= 'Recálculo masivo'
              legendButton='Se realizará el recálculo de todas las solicitudes seleccionadas'
              columns={columns}
              selectRow={selectRowProps}
              data={entities}
              onTableUpdate={this.onTableUpdate}
              isDataLoading={isDataLoading}
              totalSize={totalSize}
              keyField="id"
              exportURL={`/solicitudes/export.csv?${this.apiParams}`}
              defaultSorted={[{ dataField: 'fechaConfirmacion', order: 'desc' }]}
            />
          </>) : (
          <Loading />
        )}
      </div>
    );
  }
}
const mapStateToProps = (permissionsUser) => permissionsUser;

export default connect(mapStateToProps)(withToastManager(SolicitudList));
