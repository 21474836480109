export const pendingsMapper = (pendings) => {
  const undeliveredDetalles = [];
  pendings.forEach((detalle) => {
    if (detalle.solicitado > detalle.entregado) {
      detalle.noEntregado = detalle.solicitado - detalle.entregado;
      undeliveredDetalles.push(detalle);
    }
  });
  return undeliveredDetalles;
}
