import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import { connect } from 'react-redux'

class ProductionParameterList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.apiParams = '';
    this.state = {
      productionParameterList: [],
      lines: [],
      categories: [],
      warehouses: [],
      sections: [],
      locations: [],
      isDataLoading: true,
      totalSize: 0,
    };

    this.loadListProductionParameters = this.loadListProductionParameters.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }

  componentDidMount() {
    this.loadListProductionParameters();
  }

  async loadListProductionParameters() {
    const { toastManager } = this.props;
    try {
      const productionParameterRes = await APIClient.get('/production-parameters');
      const lineRes = await APIClient.get('/lineas');
      const categoryRes = await APIClient.get('/categorias');
      const warehousesRes = await APIClient.get('/warehouses');
      const sectionsRes = await APIClient.get('/sections');
      const locationsRes = await APIClient.get('/locations');
      const motivesRes = await APIClient.get('/stocklist-movement-motives');

      this.setState({
        productionParameterList: productionParameterRes.data.data,
        lines: lineRes.data.data,
        categories: categoryRes.data.data,
        warehouses: warehousesRes.data.data,
        sections: sectionsRes.data.data,
        locations: locationsRes.data.data,
        motives: motivesRes.data.data,
        isDataLoading: false,
      });
    } catch (error) {
      console.error('Cargando Parámetros.', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  render() {
    const {
      productionParameterList,
      isDataLoading,
      categories,
      warehouses,
      totalSize,
      locations,
      sections,
      motives,
      lines,
      isSuperAdmin,
      editPermission,
      isPermissionsLoading,
    } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
      },
      {
        dataField: 'motiveId',
        text: 'Motivo',
        sort: true,
        formatter: (cellContent, row) => {
          const motivesDescription = row.motiveId !== null ? motives.find((elem) => elem.id === row.motiveId).description : 'N/A';
          return motivesDescription;
        },
      },
      {
        dataField: 'productType.description',
        text: 'Tipo',
        sort: true,
      },
      {
        dataField: 'line.descripcion',
        text: 'Linea (Articulo)',
        sort: true,
        formatter: (cellContent, row) => {
          const linesDescription = row.lineId !== null ? lines.find((elem) => elem.id === row.lineId).descripcion : 'N/A';
          return linesDescription;
        },
      },
      {
        dataField: 'category.description',
        text: 'Categoria (Semi-elaborado)',
        formatter: (cellContent, row) => {
          const categoryDescription = row.categoryId !== null ? categories.find((elem) => elem.id === row.categoryId).description : 'N/A';
          return categoryDescription;
        },
        sort: true,
      },
      {
        dataField: 'warehouseId',
        text: 'Depósito',
        formatter: (cellContent, row) => {
          const warehouseDescription =
            row.warehouseId !== null ? warehouses.find((elem) => elem.id === row.warehouseId).description : 'N/A';
          return warehouseDescription;
        },
        sort: true,
      },
      {
        dataField: 'sectionId',
        text: 'Sector',
        formatter: (cellContent, row) => {
          const sectionDescription = row.sectionId !== null ? sections.find((elem) => elem.id === row.sectionId).description : 'N/A';
          return sectionDescription;
        },
        sort: true,
      },
      {
        dataField: 'locationId',
        text: 'Ubicacion',
        formatter: (cellContent, row) => {
          const locationDescription = row.locationId !== null ? locations.find((elem) => elem.id === row.locationId).description : 'N/A';
          return locationDescription;
        },
        sort: true,
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <LinkContainer to={`/production-parameters/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Editar">
                    <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Parametros de Producción</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            totalSize={totalSize}
            columns={columns}
            data={productionParameterList}
            showExport={false}
            isDataLoading={isDataLoading}
            keyField="id"
            addButton={isSuperAdmin || editPermission ? '/production-parameters/new' : null}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}


const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(ProductionParameterList));