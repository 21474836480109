
import { combineReducers } from 'redux';
import permissionsUser from './permissions';
import ingresoStore from './ingresoStore';


const rootReducer = combineReducers({
	ingresoStore,
	permissionsUser ,
})

export default rootReducer;

