import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, ButtonGroup, ButtonToolbar, Alert, Card } from 'react-bootstrap';
import { faCheckCircle, faClipboardList, faPause, faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EntityEditForm, DataTable, FormSelectField, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import CustomModal from '../../components/CustomModal';
import { columnsConsolidated } from './columnsStaticData';
import LoadingFull from '../../components/LoadingFull';

class ProductionOrderAdvance extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { id } = props.match.params;
    this.state = {
      id,
      orderId: [],
      isAdding: typeof id === 'undefined',
      isDataLoading: typeof id !== 'undefined',

      actionSelected: '',
      showloadingFullModal: false,
      showConsolidatedModal: false,
      consolidatedDataLoading: false,
      showActionSatusOrderModal: false,
      showSelectedParametersModal: false,

      entity: [],
      rowFinish: [],
      statusOrder: [],
      messageAction: [],
      consolidatedData: [],
      newFathersComplete: [],

      sections: [],
      locations: [],
      warehouses: [],

      sectionId: null,
      locationId: null,

      columns: {
        columnsConsolidated,
      },
    };
  }

  /* LoadForm */
  onLoadForm = async () => {
    const statusOrderRes = await APIClient.get('/production-orders-detail-status');
    const warehousesRes = await APIClient.get('/warehouses?filter[disabled][eq]=true');
    const sectionsRes = await APIClient.get('/sections?filter[disabled][eq]=true');
    const locationsRes = await APIClient.get('/locations?filter[disabled][eq]=true');

    this.setState({
      statusOrder: statusOrderRes.data.data,
      warehouses: warehousesRes.data.data,
      sections: sectionsRes.data.data,
      locations: locationsRes.data.data,
    });
  };

  /* Modal loadingFull */
  showloadingFullModal = async () => {
    this.setState({
      showloadingFullModal: true,
    });
  };

  loadingFullModal = () => {
    const { showloadingFullModal } = this.state;
    return <LoadingFull showModal={showloadingFullModal} />;
  };

  /* Modal actions status order */

  closeActionSatusOrderModal = () => {
    this.setState({
      showActionSatusOrderModal: false,
      actionSelected: '',
      rowFinish: {},
    });
  };

  showActionSatusOrderModal = (action, row) => {
    this.setState({
      showActionSatusOrderModal: true,
      actionSelected: action,
      rowFinish: row === undefined ? [] : [row],
    });
  };

  generateQuery = async (entityData, urlQuery, orderId, actionMessage) => {
    const response = await APIClient.patch(`/production-orders${urlQuery}${orderId}`, entityData);
    const messageResponse = `Orden ${response.data.data.id} ${actionMessage}`;
    if (response.data.data.newFathersComplete && response.data.data.newFathersComplete.length > 0) {
      await this.showSelectedParametersModal(response, messageResponse);
    }

    return { response, messageResponse };
  };

  actionSatusOrderModal = () => {
    const { actionSelected } = this.state;
    const textAlert = 'Recordá que algunas acciones son irreversibles. ';
    const activeOrdertext = 'Podra avanzar la order';
    const finishArticletext = 'Se consumirán todos los componentes e ingresará el Artículo en estado disponible.';
    const finishOrdertext = 'Se consumirań todos los componentes e ingresarán los Artículos en estado disponible.';
    const pauseOrdertext = 'No se podran hacer avances en los componentes ni en los Artículos hasta volver a activar la orden';
    const cancelOrdertext =
      'Todo los componentes aun no consumidos volverán a estado disponible y se egresaran los artículos aun no finalizados.';
    const actionText =
      actionSelected === 'Finalizar'
        ? finishOrdertext
        : actionSelected === 'Pausar'
        ? pauseOrdertext
        : actionSelected === 'Activar'
        ? activeOrdertext
        : actionSelected === 'Cancelar'
        ? cancelOrdertext
        : finishArticletext;

    const customBody = <p>{`Estas seguro de ${actionSelected} la orden de produccion, esto hara lo siguiente: ${actionText}`}</p>;

    return (
      <CustomModal
        showModal={this.state.showActionSatusOrderModal}
        closeModal={this.closeActionSatusOrderModal}
        save={() => this.onConfirmActionSatusOrder(actionSelected)}
        alert={textAlert}
        textSave="Confirmar"
        title={`${actionSelected} Orden`}
        customBody={customBody}
      />
    );
  };

  onConfirmActionSatusOrder = async (actionSelected) => {
    const { entity, statusOrder, id, rowFinish } = this.state;
    const { history, toastManager } = this.props;
    this.showloadingFullModal();
    if (rowFinish.length > 0) {
      const father = entity.productionOrderDetails.find((ele) => ele.id === rowFinish[0].id);
      father.finalizedFather = true;
      const saveResponse = await APIClient.patch(`/production-orders/advances/finalized-article/${id}`, { entity, rowFinish });
      const message = 'Articulo Finalizado con éxito';
      await this.showSelectedParametersModal(saveResponse, message);
    } else {
      const actionDescription = `${
        actionSelected === 'Finalizar'
          ? 'Finished'
          : actionSelected === 'Pausar'
          ? 'Stopped'
          : actionSelected === 'Activar'
          ? 'Available'
          : 'Canceled'
      }`;
      const statusOrderSelect = statusOrder.find((statu) => statu.description === actionDescription);
      entity.statusId = statusOrderSelect.id;
      let finalMessage = '';
      let message = '';
      let url = '';
      let saveResponseRes = null;
      switch (actionDescription) {
        case 'Finished':
          url = '/advances/finalized-order/';
          message += 'Finalizada con éxito';
          break;

        case 'Canceled':
          url = '/advances/cancel-order/';
          message += 'Cancelada con éxito';
          break;

        default:
          url = '/';
          message += 'Actualizada con éxito';
          break;
      }
      saveResponseRes = await this.generateQuery(entity, url, id, message);
      finalMessage += saveResponseRes.messageResponse;

      if (actionDescription !== 'Finished') {
        toastManager.add(
          finalMessage,
          {
            appearance: 'success',
            autoDismiss: true,
          },
          () => history.push('/production-orders'),
        );
      }
    }
  };

  prepareToSave = () => {
    const { entity } = this.state;
    const entityFilterSelected = entity.productionOrderDetails.filter((father) => father.selectOperation === true);
    const fatherDetailDetail = entityFilterSelected.map((father) => {
      const childsFilter = father.detail.filter((child) => child.operationId === Number(father.selecteOperationId));

      return childsFilter;
    });
    const entityToSave = fatherDetailDetail;
    return entityToSave;
  };

  onRetrieveEntity = async () => {
    const { id } = this.state;
    try {
      const productionOrderRes = await APIClient.get(`/production-orders/${id}`);
      const productionOrders = productionOrderRes.data.data;
      productionOrders.productionOrderDetails.forEach((det) => {
        det.selectOperation = false;
        det.selecteOperationId = '';
      });
      this.setState({
        entity: productionOrders,
        isDataLoading: false,
      });
      return productionOrders;
    } catch (error) {
      console.error(error);
      this.setState({ isDataLoading: false });
    }
  };

  /* SelectedParameters Modal */
  showSelectedParametersModal = async (saveResponse, message) => {
    const { newFathersComplete, id } = saveResponse.data.data;
    this.setState({
      showSelectedParametersModal: true,
      newFathersComplete,
      orderId: id,
      messageAction: message,
    });
  };

  closeSelectedParametersModal = () => {
    const { history, toastManager } = this.props;
    const { orderId } = this.state;
    this.setState({
      showSelectedParametersModal: false,
      newFathersComplete: [],
      messageAction: [],
    });
    toastManager.add(
      `Orden ${orderId} Actualizada con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/production-orders'),
    );
  };

  handleChangeSections = (e, erpCode) => {
    const { value } = e.target;
    const { newFathersComplete } = this.state;
    newFathersComplete.forEach((fat) => {
      const father = fat;
      if (father.erpCode === erpCode) {
        father.newSectionId = parseInt(value);
      }
    });
    this.setState({
      sectionId: value !== '' ? Number(value) : null,
      locationId: null,
    });
  };

  handleChangeLocation = (e, erpCode) => {
    const { value } = e.target;
    const { newFathersComplete } = this.state;
    newFathersComplete.forEach((fat) => {
      const father = fat;
      if (father.erpCode === erpCode) {
        father.newLocationId = parseInt(value);
      }
    });

    this.setState({
      locationId: value !== '' ? Number(value) : null,
    });
  };
  validatedParameters = () => {
    const { newFathersComplete, sectionId, locationId } = this.state;
    const withoutParameters = [];
    newFathersComplete.forEach((elem) => {
      if (elem.newSectionId === '' || elem.newlocationId === '' || sectionId === null || locationId === null) {
        withoutParameters.push(elem);
      }
    });
    return withoutParameters;
  };

  updateParameters = async () => {
    const { newFathersComplete, orderId, messageAction } = this.state;
    const { history, toastManager } = this.props;
    const parametersIncomplete = this.validatedParameters();

    if (parametersIncomplete.length === 0) {
      try {
        await APIClient.patch(`/production-orders/parameters/${orderId}`, newFathersComplete);
        toastManager.add(
          `${messageAction}`,
          {
            appearance: 'success',
            autoDismiss: true,
          },
          () => history.push('/production-orders'),
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      toastManager.add('Asigne Parametros', {
        appearance: 'warning',
        autoDismiss: true,
      });
    }
  };

  listSelectedParametersModal = () => {
    const { newFathersComplete, warehouses, sections, locations, showSelectedParametersModal } = this.state;

    const customBody = newFathersComplete.map((elem) => {
      const newFatherComplete = elem;
      const body = (
        <Card className="my-4">
          <Card.Header>Confirme la unbicacion final del Articulo :{newFatherComplete.erpCode}</Card.Header>
          <Card.Body>
            <Row>
              <Col md={4}>
                <FormSelectField
                  id="warehouseId"
                  label="Depósito"
                  choices={warehouses}
                  disabled
                  required
                  choiceIdField="id"
                  choiceLabelField="description"
                  type="text"
                  defaultValue={newFatherComplete.warehouseId}
                />
              </Col>
              <Col md={4}>
                <FormSelectField
                  id="sectionId"
                  label="Sector"
                  choices={sections.filter((sec) => sec.warehouseId === Number(newFatherComplete.warehouseId))}
                  choiceIdField="id"
                  choiceLabelField="description"
                  onChange={(e) => this.handleChangeSections(e, newFatherComplete.erpCode)}
                  type="text"
                  defaultValue={newFatherComplete.sectionId}
                />
              </Col>
              <Col md={4}>
                <FormSelectField
                  id="locationId"
                  label="Ubicación"
                  choices={
                    newFatherComplete.newSectionId
                      ? locations.filter((loc) => loc.sectionId === Number(newFatherComplete.newSectionId))
                      : locations.filter((loc) => loc.sectionId === Number(newFatherComplete.sectionId))
                  }
                  choiceIdField="id"
                  choiceLabelField="description"
                  onChange={(e) => this.handleChangeLocation(e, newFatherComplete.erpCode)}
                  type="text"
                  defaultValue={newFatherComplete.locationId}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
      return body;
    });
    return (
      <CustomModal
        showModal={showSelectedParametersModal}
        isDataLoading={false}
        closeModal={this.closeSelectedParametersModal}
        title="Selecciones Parametros"
        save={this.updateParameters}
        textSave="Guardar"
        keyFieldDatable="id"
        showExport={false}
        customBody={customBody}
      />
    );
  };

  onSaveEntity = async (entityToSave) => {
    const { id } = this.state;
    const { history, toastManager } = this.props;
    this.showloadingFullModal();
    const saveResponse = await APIClient.patch(`/production-orders/advances/${id}`, entityToSave);
    const message = `Orden ${saveResponse.data.data.id} Actualizada con éxito`;
    if (saveResponse.data.data.newFathersComplete.length > 0) {
      await this.showSelectedParametersModal(saveResponse, message);
    } else {
      toastManager.add(
        message,
        {
          appearance: 'success',
          autoDismiss: true,
        },
        () => history.push('/production-orders'),
      );

      return saveResponse.data.data;
    }
  };

  handleSelectMoment = (event, row) => {
    const { entity } = this.state;
    const { value } = event.target;

    row.selectOperation = !!value;
    row.selecteOperationId = value;
    /* Veer este bug */

    this.setState({
      entity,
    });
  };

  /* Consolidated Modal */
  showConsolidatedModal = async () => {
    this.setState({
      showConsolidatedModal: true,
    });
  };

  closeConsolidatedModal = () => {
    this.setState({
      showConsolidatedModal: false,
      consolidatedData: [],
    });
  };

  listConsolidatedModal = () => {
    const { consolidatedData, columns, showConsolidatedModal } = this.state;
    const { columnsConsolidated } = columns;
    return (
      <CustomModal
        showModal={showConsolidatedModal}
        closeModal={this.closeConsolidatedModal}
        title="Consolidado"
        columns={columnsConsolidated}
        data={consolidatedData}
        keyFieldDatable="id"
      />
    );
  };

  prepareToExportData = (data) => {
    const { entity } = this.state;
    if (data === 'full') {
      const itemsFather = entity.productionOrderDetails;
      this.getExportData(itemsFather);
    } else {
      const itemFather = entity.productionOrderDetails.filter((ele) => ele.id === data);
      this.getExportData(itemFather);
    }
    this.setState({ consolidatedDataLoading: true });
  };

  getExportData = async (itemsFatherData) => {
    const { toastManager } = this.props;
    try {
      const consolidatedDataRes = await APIClient.post('/production-orders/consolidated', itemsFatherData);
      this.setState({
        consolidatedData: consolidatedDataRes.data.data,
        consolidatedDataLoading: false,
      });
      this.showConsolidatedModal();
    } catch (error) {
      this.setState({
        showloadingFullModal: false,
      });
      this.closeConsolidatedModal();
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  render() {
    const { id, entity, isAdding, isDataLoading, consolidatedDataLoading } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
        editable: false,
      },
      {
        dataField: 'erpCode',
        text: 'Codigo ERP',
        headerAlign: 'center',
        editable: false,
      },
      {
        dataField: 'description',
        text: 'Descripcion',
        headerAlign: 'center',
        editable: false,
      },
      {
        dataField: 'units',
        text: 'Cantidad',
        headerAlign: 'center',
        style: { textAlign: 'right' },
        editable: false,
      },
      {
        dataField: 'tipeItem',
        text: 'Tipo de recurso',
        headerAlign: 'center',
        editable: false,
        formatter: (cell, row, rowIndex) => {
          const rowTipeItem = `${row.productType.description === 'comercializable' ? 'Comercializable' : 'Semi-elaborado'}`;
          return rowTipeItem;
        },
      },
      {
        dataField: 'insumo.categoryLevel1.description',
        text: 'Categoria',
        headerAlign: 'center',
        style: { textAlign: 'center' },
        editable: false,
      },
      {
        dataField: 'article.linea.descripcion',
        style: { textAlign: 'center' },
        headerAlign: 'center',
        text: 'Linea',
        editable: false,
      },
      {
        dataField: 'operationDescription',
        text: 'Momento de consumo',
        headerAlign: 'center',
        editable: false,
        formatter: (cellContent, row) => {
          const operations = [];
          row.detail.forEach((detail) => {
            const exist = operations.some((ele) => ele.id === detail.operation.id);
            if (!exist && detail.finalized !== true) {
              operations.push(detail.operation);
            }
          });

          return (
            <>
              {row.finalizedFather ? (
                <Alert variant="success">
                  <p>FINALIZADO</p>
                </Alert>
              ) : (
                <FormSelectField
                  id="operationId"
                  choices={operations}
                  disabled={entity?.status?.description !== 'Available'}
                  onChange={(event) => this.handleSelectMoment(event, row)}
                  choiceIdField="id"
                  choiceLabelField="description"
                />
              )}
            </>
          );
        },
      },
      {
        dataField: 'isSelected',
        text: '',
        align: 'center',
        headerAlign: 'center',
        editable: false,
        formatter: (cell, row) => (
          <span className="d-flex align-items-center justify-content-center ">
            <ButtonToolbar className="mt-4">
              <ButtonGroup>
                {entity?.status?.description !== 'Available' || row.finalizedFather === true ? (
                  <></>
                ) : (
                  <Button size="sm" title="Finalizar Artículo" variant="secondary">
                    <FontAwesomeIcon
                      color="white"
                      icon={faCheckCircle}
                      onClick={() => this.showActionSatusOrderModal('Finalizar el Artículo de', row)}
                      fixedWidth
                      size="xs"
                    />
                  </Button>
                )}
                {consolidatedDataLoading === true ? (
                  <Loading />
                ) : (
                  <Button size="sm" title="Artículo Consolidado" variant="secondary">
                    <FontAwesomeIcon
                      color="white"
                      icon={faClipboardList}
                      onClick={() => this.prepareToExportData(row.id)}
                      fixedWidth
                      size="xs"
                    />
                  </Button>
                )}
              </ButtonGroup>
            </ButtonToolbar>
          </span>
        ),
      },
    ];

    const selectDetalleRow = {
      align: 'center',
      clickToEdit: true,
      clickToSelect: false,
      hideSelectColumn: true,
      mode: 'checkbox',
    };

    const rowClasses = (row) => {
      if (row.addedRow) {
        return 'bg-success text-white font-weight-bold';
      }
      if (row.deletedRow) {
        return 'bg-warning text-white font-weight-bold';
      }
    };

    return (
      <div>
        <h1 className="page-title">{`Seguimiento de Orden de Producción #${id}`}</h1>
        {entity?.status?.description === 'Stopped' ? (
          <Alert variant="warning">
            <Alert.Heading>Orden Pausada</Alert.Heading>
            <p>Recordá que no podra realizar cambio hasta reactivar la orden</p>
          </Alert>
        ) : entity?.status?.description === 'Finished' ? (
          <Alert variant="success">
            <Alert.Heading>Orden Finalizada</Alert.Heading>
            <p>Consulta el detalle de la orden en "Consolidado"</p>
          </Alert>
        ) : entity?.status?.description === 'Canceled' ? (
          <Alert variant="danger">
            <Alert.Heading>Orden Cancelada</Alert.Heading>
            <p>Consulta el detalle de la orden en "Consolidado"</p>
          </Alert>
        ) : (
          <></>
        )}
        {this.actionSatusOrderModal()}
        {this.listConsolidatedModal()}
        {this.listSelectedParametersModal()}
        {this.loadingFullModal()}

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          isEditable={entity?.status?.description === 'Available'}
          prepareToSave={this.prepareToSave}>
          <>
            <Row>
              <Col>
                <DataTable
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                  })}
                  selectRow={selectDetalleRow}
                  columns={columns}
                  data={entity.productionOrderDetails || []}
                  keyField="resourceId"
                  isDataLoading={isDataLoading}
                  rowClasses={rowClasses}
                  showExport={false}
                />
              </Col>
            </Row>

            {entity?.status?.description === 'Finished' || entity?.status?.description === 'Canceled' ? (
              <></>
            ) : (
              <span className="d-flex align-items-center justify-content-end ">
                <ButtonToolbar className="my-4">
                  <ButtonGroup>
                    {entity?.status?.description !== 'Available' ? (
                      <></>
                    ) : (
                      <Button size="xl" className="px-4" title="Finalizar Orden" variant="secondary">
                        <FontAwesomeIcon
                          color="white"
                          icon={faCheckCircle}
                          onClick={() => this.showActionSatusOrderModal('Finalizar')}
                          fixedWidth
                          size="xl"
                        />
                      </Button>
                    )}
                    {entity?.status?.description === 'Available' ? (
                      <Button size="xl" className="px-4" title="Pausar Orden" variant="warning">
                        <FontAwesomeIcon
                          color="white"
                          icon={faPause}
                          onClick={() => this.showActionSatusOrderModal('Pausar')}
                          fixedWidth
                          size="xl"
                        />
                      </Button>
                    ) : (
                      <Button size="xl" className="px-4" title="Activar Orden" variant="success">
                        <FontAwesomeIcon
                          color="white"
                          icon={faPlay}
                          onClick={() => this.showActionSatusOrderModal('Activar')}
                          fixedWidth
                          size="xl"
                        />
                      </Button>
                    )}
                    <Button size="xl" className="px-4" title="Cancelar Orden" variant="danger">
                      <FontAwesomeIcon
                        color="white"
                        icon={faTimes}
                        onClick={() => this.showActionSatusOrderModal('Cancelar')}
                        fixedWidth
                        size="xl"
                      />
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </span>
            )}
            <Row className=" mr-1">
              <Col md={6}>
                {consolidatedDataLoading === true ? (
                  <Loading />
                ) : (
                  <Button className="mr-1 " variant="success" onClick={() => this.prepareToExportData('full')} style={{ fontWeight: 700 }}>
                    <FontAwesomeIcon color="white" className="mx-1" icon={faClipboardList} fixedWidth size="xs" />
                    Consolidado
                  </Button>
                )}
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ProductionOrderAdvance);
