import React from 'react';
import PropTypes from 'prop-types';

import localStorage from '../../services/Security';
import APIClient from '../../services/APIClient';
import { columnsOutOfStock, columnsItems } from './columnsStaticData';
import { EntityEditForm, FormCheckField, DataTable } from '../../components';
import CustomModal from '../../components/CustomModal';
import LoadingFull from '../../components/LoadingFull';

import cellEditFactory from 'react-bootstrap-table2-editor';
import { withToastManager } from 'react-toast-notifications';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

class ProductionOrderNew extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { id } = props.match.params;
    this.state = {
      id,
      statusId: 1,
      isAdding: typeof id === 'undefined',
      userId: localStorage.getSession().user.id,

      isDataLoading: false,
      isCompleteOrder: false,
      isNotValidateStock: true,
      isValidatedLoading: false,

      showAddModal: false,
      showFullLoading: false,
      showDeleteModal: false,
      showOutOfStockDetailsModal: false,

      itemsList: [],
      newDetails: [],
      validateStock: [],
      childsUnified: [],
      itemsListSelected: [],
      selectedDetailRowDel: [],
      productionOrderDetails: [],

      orderValidate: {
        stockAvailable: false,
        hiddenStock: true,
        noIncome: true,
      },
      columns: {
        columnsOutOfStock,
        columnsItems,
      },
    };
  }

  /* Modal loadingFull */
  showloadingFullModal = async () => {
    this.setState({
      showloadingFullModal: true,
    });
  };

  loadingFullModal = () => {
    const { showloadingFullModal } = this.state;
    return <LoadingFull showModal={showloadingFullModal} />;
  };

  /* Out Of Stock Details Modal */
  closeOutOfStockModal = () => {
    this.setState({ showOutOfStockDetailsModal: false });
  };

  showOutOfStockDetailsModal = async () => {
    this.setState({
      isDataLoading: false,
      showOutOfStockDetailsModal: true,
      orderValidate: {
        stockAvailable: false,
        hiddenStock: true,
        noIncome: true,
      },
    });
  };

  listOutOfStockDetailsModal = () => {
    const { childsUnified, showOutOfStockDetailsModal, columns, isDataLoading } = this.state;
    const { columnsOutOfStock } = columns;

    const itemsNotAvailable = childsUnified.filter((child) => child.stockAvailable === false);
    return (
      <CustomModal
        showModal={showOutOfStockDetailsModal}
        isDataLoading={isDataLoading}
        closeModal={this.closeOutOfStockModal}
        title={'Detalle de Stock faltante'}
        showExport={false}
        columns={columnsOutOfStock}
        data={itemsNotAvailable}
        keyFieldDatable={'id'}
      />
    );
  };

  /* Add Items Modal */

  closeAddItemsModal = () => {
    this.setState({ showAddModal: false, newDetails: [] });
  };

  showAddItemsModal = async (itemsSelected) => {
    const { productionOrderDetails } = this.state;
    const filterId = productionOrderDetails.map((detail) => detail.item.id);
    const itemsListRes = await APIClient.get(
      `/production-orders/item-filter?typeItems=${itemsSelected}&filterId=${JSON.stringify(filterId)}`,
    );
    this.setState({
      itemsList: itemsListRes.data.data,
      showAddModal: true,
      isDataLoading: false,
      itemsListSelected: itemsSelected,
    });
  };
  listItemsModal = () => {
    const { itemsList, itemsListSelected, showAddModal, isDataLoading, columns } = this.state;

    const { columnsItems } = columns;

    const selectRowProps = {
      mode: 'checkbox',
      clickToEdit: true,
      hideSelectAll: true,
      onSelect: this.onSelectArticle,
    };
    const titleModal = `${itemsListSelected === 'insumos' ? 'Semi-elaborados' : 'Comercializables'}`;
    const textAlert = `Solo se listarán ${
      itemsListSelected === 'insumos' ? 'Semi-elaborados' : 'Comercializables'
    } con lista de materiales y
    parametros de producción asignados`;
    return (
      <CustomModal
        columns={columnsItems}
        data={itemsList}
        showModal={showAddModal}
        closeModal={this.closeAddItemsModal}
        title={titleModal}
        keyFieldDatable={'id'}
        save={this.saveNewDetailsItem}
        close={this.closeAddItemsModal}
        isDataLoading={isDataLoading}
        selectRowProps={selectRowProps}
        alert={textAlert}
        showExport={false}
        showSearch={true}
      />
    );
  };

  saveNewDetailsItem = () => {
    this.setState((prevState) => ({
      productionOrderDetails: [...prevState.newDetails, ...prevState.productionOrderDetails],
      newDetails: [],
      showAddModal: false,
    }));
  };

  /* Modal delete Items */
  closeDeleteItemModal = () => {
    this.setState({ showDeleteModal: false, selectedDetailRowDel: [] });
  };

  showDeleteItemModal = (row) => {
    this.setState({ showDeleteModal: true, selectedDetailRowDel: row });
  };

  deleteItemModal = () => {
    const customBody = <p>¿Está seguro de que desea eliminar este Item?</p>;
    return (
      <CustomModal
        showModal={this.state.showDeleteModal}
        closeModal={this.closeDeleteItemModal}
        save={this.onConfirmDeleteItem}
        textSave={'Eliminar'}
        title={'Eliminar Item'}
        customBody={customBody}
      />
    );
  };

  onConfirmDeleteItem = async () => {
    const { selectedDetailRowDel, productionOrderDetails } = this.state;
    const productionOrderDetailsFilter = productionOrderDetails.filter((elem) => elem.item.id !== selectedDetailRowDel.item.id);
    this.setState({
      productionOrderDetails: productionOrderDetailsFilter,
      showDeleteModal: false,
    });
  };

  /* stockValidator */

  stockValidator = async () => {
    const { productionOrderDetails, orderValidate } = this.state;
    const { toastManager } = this.props;
    this.setState({ isValidatedLoading: true });

    const stockValidationButton = productionOrderDetails.filter((elem) => parseInt(elem.units) === 0);
    const selectedUnits = stockValidationButton.length > 0;

    if (selectedUnits) {
      toastManager.add(`Las cantidades son necesarias para Validar Materiales`, {
        appearance: 'warning',
        autoDismiss: true,
      });
      this.setState({ isValidatedLoading: false });
    } else {
      const itemsFather = productionOrderDetails.map((elem) => {
        const items = {
          id: elem.articuloId || elem.insumoId,
          unist: elem.units,
          lineId: elem?.lineId || '',
          tipeItem: elem.tipeItem,
          categoryId: elem?.categoryId || '',
          erpCode: elem.item.erpCode,
        };

        return items;
      });

      const queryParams = `itemsFatherText=${JSON.stringify(itemsFather)}`;
      const validateStockRes = await APIClient.get(`/production-orders/validate-stock?${queryParams}`);
      this.setState({
        validateStock: validateStockRes.data.data[0].orderNew,
        childsUnified: validateStockRes.data.data[0].orderNew.childsUnified,
        orderValidate: validateStockRes.data.data[0].orderValidate,
        isValidatedLoading: false,
      });
      this.onSaveUpdatedDetailTable();
    }
    return orderValidate.stockAvailable;
  };

  /* Save Item new */

  afterSaveNewDetail = (row) => {
    this.onSaveNewDetail(row);
  };

  onSaveNewDetail = async (item) => {
    const { itemsListSelected, newDetails, userId } = this.state;
    if (item.isSelected === true) {
      this.setState((prevState) => {
        const newDetails = [...prevState.newDetails];
        const newDetail = {
          item,
          units: 0,
          stock: true,
          insumoId: itemsListSelected === 'insumos' ? item.id : null,
          articuloId: itemsListSelected === 'articulos' ? item.id : null,
          programCheckRow: false,
          addedRow: true,
          tipeItem: itemsListSelected,
          line: item.lineaDescription,
          lineId: item?.lineaId,
          erpCode: item.erpCode,
          category: item.categoryDescription,
          categoryId: item?.categoryLevel1Id,
          description: item.description,
          userId,
        };
        newDetails.push(newDetail);
        return { ...prevState, newDetails };
      });
    } else if (item.isSelected === false) {
      this.setState({
        newDetails: newDetails.filter((elem) => elem.item.id !== item.id),
      });
    }
  };

  /* Save update item */

  afterSaveUpdatedDetail = (oldValue, newValue, row, column) => {
    this.onSaveUpdatedDetail(oldValue, newValue, row, column);
  };

  onSaveUpdatedDetail = async (oldValue, newValue, row, column) => {
    const { productionOrderDetails } = this.state;

    // add properties to the items by entering units
    productionOrderDetails.forEach((elem) => {
      elem.stock = true;
      elem.programCheckRow = false;
    });
    // display validation columns
    this.setState({
      orderValidate: {
        stockAvailable: false,
        hiddenStock: true,
        noIncome: true,
      },
      isNotValidateStock: false,
    });
  };

  // update state when table is modified
  afterSaveUpdatedDetalleTabble = () => {
    this.onSaveUpdatedDetailTable();
  };

  onSaveUpdatedDetailTable = () => {
    const { orderValidate } = this.state;
    const { toastManager } = this.props;

    if (orderValidate.stockAvailable === false && orderValidate.noIncome === false) {
      toastManager.add(`Consulte Detalles de Componentes Faltantes`, {
        appearance: 'warning',
      });
      this.showOutOfStockDetailsModal();
    }
    if (orderValidate.stockAvailable === false && orderValidate.noIncome === true) {
      toastManager.add(`NO TIENE INGRESO EN STOCK`, {
        appearance: 'warning',
      });
      this.showOutOfStockDetailsModal();
    }
  };

  onSelectArticle = (row, isSelect) => {
    const itemsList = [...this.state.itemsList];
    const item = itemsList.find((item) => item.id === row.id);
    item.isSelected = isSelect;
    this.setState({ itemsList });
    this.afterSaveNewDetail(row);
  };

  onSelectItem = (e, row) => {
    const { productionOrderDetails } = this.state;
    const { checked } = e.target;
    const item = productionOrderDetails.find((elem) => elem.item.id === row.item.id);
    item.programCheckRow = checked;
    const someCheck = productionOrderDetails.some((elem) => elem.programCheckRow === true);
    this.setState({
      productionOrderDetails,
      isCompleteOrder: someCheck,
    });
  };

  onChangeCheck = (e) => {
    const { checked } = e.target;
    this.setState({
      statusId: checked === true ? 1 : 2,
    });
  };

  prepareToSave = (entityToSave) => {
    const { productionOrderDetails, statusId } = this.state;
    const items = productionOrderDetails.filter((item) => item.programCheckRow === true);
    entityToSave.productionOrderDetails = items;
    entityToSave.statusId = statusId;
    return entityToSave;
  };

  onSaveEntity = async (entityToSave) => {
    const { history, toastManager } = this.props;
    const stockAvailable = await this.stockValidator();

    if (stockAvailable === true) {
      this.showloadingFullModal();
      try {
        const queryParams = `itemsText=${JSON.stringify(entityToSave)}`;
        const orderNewRes = await APIClient.get(`/production-orders/order-builder?${queryParams}`);
        toastManager.add(
          `Orden de Produccion #${orderNewRes.data.data.id} guardada con éxito`,

          {
            appearance: 'success',
            autoDismiss: true,
          },
          () => history.push('/production-orders'),
        );
      } catch (error) {
        this.setState({
          showloadingFullModal: false,
        });
        toastManager.add(`Ocurrió un error: "${error.message}"`, {
          appearance: 'error',
        });
      }
    } else {
      toastManager.add(`Hubo cambios en el stock, ahora el stock es insuficiente`, {
        appearance: 'warning',
      });
    }
  };

  render() {
    const { id, productionOrderDetails, isValidatedLoading, isCompleteOrder, orderValidate, isAdding, isDataLoading, isNotValidateStock } =
      this.state;

    const columns = [
      {
        dataField: 'item.id',
        text: 'ID',
        sort: true,
        editable: false,
      },
      {
        dataField: 'item.erpCode',
        text: 'Codigo ERP',
        editable: false,
      },
      {
        dataField: 'description',
        text: 'Descripcion',
        editable: false,
      },
      {
        dataField: 'units',
        text: 'Cantidad',
        style: { textAlign: 'right' },
        editable: true,
      },
      {
        dataField: 'tipeItem',
        text: 'Tipo de recurso',
        editable: false,
        formatter: (cell, row, rowIndex) => {
          const rowTipeItem = `${row.tipeItem === 'articulos' ? 'Comercializable' : 'Semi-elaborado'}`;
          return rowTipeItem;
        },
      },
      {
        dataField: 'category',
        text: 'Categoria',
        style: { textAlign: 'center' },
        editable: false,
      },
      {
        dataField: 'line',
        style: { textAlign: 'center' },
        text: 'Linea',
        editable: false,
      },
      {
        dataField: 'stock',
        text: 'Stock',
        align: 'center',
        hidden: orderValidate.hiddenStock,
        editable: false,
        formatter: (cell, row, rowIndex) => {
          const rowValidated = `${row.stock === false ? 'Sin Stock Disponible' : 'Stock Disponible'}`;
          return rowValidated;
        },
      },
      {
        dataField: 'programCheck',
        text: 'Programar Linea',
        align: 'center',
        hidden: orderValidate.hiddenStock,
        editable: false,
        formatter: (cell, row, rowIndex) => {
          return <FormCheckField id="isActive" onClick={(e) => this.onSelectItem(e, row)} />;
        },
      },
      {
        dataField: 'isSelected',
        text: '',
        align: 'center',
        headerAlign: 'center',
        editable: false,
        formatter: (cell, row, rowIndex) => {
          return (
            <span className="d-flex align-items-center justify-content-center">
              <ButtonToolbar>
                <ButtonGroup>
                  <Button size="sm" variant="outline-light" title="Editar">
                    <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.showDeleteItemModal(row)} fixedWidth size="xs" />
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </span>
          );
        },
      },
    ];

    const rowClasses = (row, rowIndex) => {
      if (row.addedRow) {
        return 'bg-success text-white font-weight-bold';
      }
      if (row.deletedRow) {
        return 'bg-warning text-white font-weight-bold';
      }
    };

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Orden de Producción nueva' : `Operacion de Consumo #${id}`}</h1>
        {this.listItemsModal()}
        {this.listOutOfStockDetailsModal()}
        {this.deleteItemModal()}
        {this.loadingFullModal()}
        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
          prepareToSave={this.prepareToSave}
          buttons={[
            {
              text: ' Programar OPs',
              type: 'submit',
              variant: 'primary',
              classes: 'mr-1',
              disabled: isCompleteOrder === false || orderValidate.hiddenStock === true,
            },
          ]}>
          <>
            <Row>
              <Col md={1}>
                <FormCheckField id="statusId" label="Activa" defaultChecked={true} onChange={this.onChangeCheck} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Button variant="primary" className="mr-1" onClick={() => this.showAddItemsModal('articulos')} style={{ fontWeight: 700 }}>
                  + Comercializables{' '}
                </Button>
                <Button id="insumos" variant="primary" onClick={() => this.showAddItemsModal('insumos')} style={{ fontWeight: 700 }}>
                  + Semi-elaborados
                </Button>
              </Col>
            </Row>

            <Row>
              <Col>
                <DataTable
                  cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    autoSelectText: true,
                    clickToSelect: true,
                    afterSaveCell: this.afterSaveUpdatedDetail,
                  })}
                  columns={columns}
                  data={productionOrderDetails || []}
                  keyField="item.id"
                  isDataLoading={isDataLoading}
                  rowClasses={rowClasses}
                  showExport={false}
                />
              </Col>
            </Row>
            <Row className="my-4 mr-1">
              <Col md={6}>
                <Button
                  className="mr-1"
                  variant="success"
                  disabled={isNotValidateStock === false && isValidatedLoading === true}
                  onClick={() => this.stockValidator()}
                  style={{ fontWeight: 700 }}
                >
                  {isNotValidateStock === false && isValidatedLoading === true ? 'Validando...' : 'Validar materiales'}
                </Button>
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(ProductionOrderNew);
