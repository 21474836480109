import React, { useEffect, useState } from 'react';
import { DataTable, Loading } from '../../components';
import { withToastManager } from 'react-toast-notifications';

import APIClient from '../../services/APIClient';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux'

const RolesList = (props: { toastManager: any; history: any ; permissionsUser:any;  }): JSX.Element => {
  const { permissionsUser } = props;

  const [roles, setRoles] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [permissionsView, setPermissionsView] = useState({
    isSuperAdmin: false,
    editPermission: false,
    deletePermission: false,
    isPermissionsLoading: true,
  });

  const getPermissions = ()=>{
    const {isSuperAdmin,editPermission, deletePermission, isPermissionsLoading} = permissionsUser
     setPermissionsView({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    }) 
  }
  
  useEffect(() => {
    getPermissions()
  }, [permissionsUser])

  useEffect(() => {
    loadInitialDate();
  }, []);

  const loadInitialDate = async () => {
    try {
      const roles = await APIClient.get('/roles?excludeAssocFields=permisos');
      setRoles(roles.data.data);
      setIsDataLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      dataField: 'codigo',
      text: 'codigo',
      sort: true,
    },
    {
      dataField: 'descripcion',
      text: 'descripcion',
      sort: true,
    },
    {
      dataField: 'actions',
      isDummyField: true,
      align: 'center',
      text: '',
      formatter: (cellContent: any, row: any) => (
        <ButtonToolbar>
          <ButtonGroup>
            <LinkContainer to={`/roles/${row.id}`}>
              <Button size="sm" variant="outline-primary" title="Editar">
                <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
              </Button>
            </LinkContainer>
          </ButtonGroup>
        </ButtonToolbar>
      ),
    },
  ];
  return (
    <div>
      <h1 className="page-title">Roles de Usuario</h1>
      {isDataLoading === false && permissionsView.isPermissionsLoading === false ? (
        <DataTable
          columns={columns}
          data={roles}
          isDataLoading={isDataLoading}
          keyField="id"
          addButton="/roles/new"
          showSearch={false}
          showExport={false}
        />
      ) : (
        <Loading />
      )}
    </div>
  );
};
const mapStateToProps = (permissionsUser:any) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(RolesList));
