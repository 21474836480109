import React from 'react';
import { Route } from 'react-router-dom';
import OperationsList from './OperationsList';
import OperationsEdit from './OperationsEdit';

const routes = () => [
  <Route path="/operations-uptakes/new" exact component={OperationsEdit} key="/operations-uptakes/new" />,
  <Route path="/operations-uptakes/:id" component={OperationsEdit} key="/operations-uptakes/:id" />,
  <Route path="/operations-uptakes" exact component={OperationsList} key="/operations-uptakes" />,
].map(route => route);

export default routes;
