import React from 'react';
import PropTypes from 'prop-types';
import { faPlus, faMinus, faFileAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Container, Form, Image, Modal, OverlayTrigger, Row, Tooltip, Alert } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormInputField, FormSelectField, FormSwitch } from '../../components';
import APIClient from '../../services/APIClient';
import config from '../../config';

class InsumoEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: {},
      imagenes: [],
      selectedImage: {},
      imageToDelete: {},
      posicionChanges: [],
      categoriesLevel1: [],
      categoriesLevel2: [],
      categoriesLevel3: [],
      unidadesMedida: [],
      insumosTypes: [],
      compraFabrica: [],
      proveedores: [],
      selectedProveedores: [{}],
      entityToSaveFather: { proveedores: [], insumos: [], comercializables: [] },
      insumos: [],
      comercializables: [],
      archivos: [],
      fileToDelete: {},
      singleSelections: [],
      insumosWithStock: [],
      arrayvalidateindex: [],
      checkExistenceParam: 'insumo_id',
    };
    this.requiredFields = [
      'categoryLevel1Id',
      'categoryLevel2Id',
      'categoryLevel3Id',
      'erpCode',
      'description',
      'unidadMedidaId',
      'insumoTypeId',
      'compraOFabricaId',
    ];
  }

  onRetrieveEntity = async () => {
    const { id, entityToSaveFather } = this.state;

    const insumoRes = await APIClient.get(`/insumos/${id}`);
    const entity = insumoRes.data.data;
    const { imagenes, archivos } = entity;
    
    imagenes.forEach((img) => {
      img.isUploaded = true;
    });
    archivos.forEach((file) => {
      file.isUploaded = true;
    });
    const addUnitsToInsumo = function (insumosFather, insumoFatherObjects) {
      insumosFather.forEach((father, i) => {
        const findInsumoFatherObjects = insumoFatherObjects.filter((object) => object.insumoFatherId === father.id)[0];
        entityToSaveFather.insumos[i] = {
          insumo: father,
          units: findInsumoFatherObjects.units,
          unitsInChild: findInsumoFatherObjects.unitsInChild,
        };
      });
    };
    const addUnitsToComercializable = function (comercializablesFather, insumoFatherObjects) {
      comercializablesFather.forEach((father, i) => {
        const findInsumoFatherObjects = insumoFatherObjects.filter((object) => object.articuloFatherId === father.id)[0];
        entityToSaveFather.comercializables[i] = {
          comercializable: father,
          units: findInsumoFatherObjects.units,
          unitsInChild: findInsumoFatherObjects.unitsInChild,
        };
      });
    };
    imagenes.sort((a, b) => a.posicion - b.posicion);
    entityToSaveFather.proveedores = entity.proveedores;
    // entityToSaveFather.comercializables = entity.comercializablesFather;
    addUnitsToInsumo(entity.insumosFather, entity.insumoFatherObjects);
    addUnitsToComercializable(entity.comercializablesFather, entity.insumoFatherObjects);
    this.setState({
      entity,
      imagenes,
      selectedImage: imagenes[0],
      selectedProveedores: entity.proveedores,
      entityToSaveFather,
      archivos,
    });

    return entity;
  };

  onSaveEntity = async (entityToSave) => {
    const { id, isAdding, imagenes, posicionChanges, entityToSaveFather, archivos } = this.state;
    const { history, toastManager } = this.props;

    if (posicionChanges.length > 0) {
      entityToSave.imagenes = posicionChanges;
    }
    entityToSave.proveedores = entityToSaveFather.proveedores;
    entityToSave.insumos = entityToSaveFather.insumos;
    entityToSave.comercializables = entityToSaveFather.comercializables;
    const imgsToUpload = imagenes.filter((img) => !img.isUploaded);
    const filesToUpload = archivos.filter((file) => !file.isUploaded);
    let saveResponse = null;
    if (isAdding) {
      await this.formValidation(entityToSave);
      saveResponse = await APIClient.post('/insumos', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/insumos/${id}?isFromInsumoABM=true`, entityToSave);
    }

    if (imgsToUpload.length > 0) {
      const imgPromises = [];
      for (let i = 0; i < imgsToUpload.length; i++) {
        const curImg = imgsToUpload[i];
        // Get image object
        const curImgResponse = await APIClient.getImage(curImg.filename, { responseType: 'blob' });
        const newImage = {};

        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result.split(',');
          newImage.fileData = result[1];
          newImage.fileType = result[0].match(/image\/.*g/g)[0];
          newImage.isUploaded = false;
          newImage.posicion = curImg.posicion;
          const curPromise = APIClient.post(`/insumos/${saveResponse.data.data.id}/imagenes`, newImage);
          imgPromises.push(curPromise);
        };
        reader.onerror = (error) => {
          console.error('Error: ', error);
        };
        reader.readAsDataURL(curImgResponse.data);
      }
      await Promise.all(imgPromises);
    }

    if (filesToUpload.length > 0) {
      const filesPromises = [];
      for (let i = 0; i < filesToUpload.length; i++) {
        const curFile = filesToUpload[i];
        // Get image object
        const curFileResponse = await APIClient.getFile(curFile.filename, { responseType: 'blob' });
        const newFile = {
          name: curFile.name,
        };

        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result.split(',');
          newFile.fileData = result[1];
          newFile.fileType = result[0].substring(result[0].lastIndexOf(':') + 1, result[0].lastIndexOf(';'));
          newFile.isUploaded = false;
          const curPromise = APIClient.post(`/insumos/${saveResponse.data.data.id}/archivos`, newFile);
          filesPromises.push(curPromise);
        };
        reader.onerror = (error) => {
          console.error('Error: ', error);
        };
        reader.readAsDataURL(curFileResponse.data);
      }
      await Promise.all(filesPromises);
    }
    toastManager.add(
      `Insumo #${saveResponse.data.data.description} guardado con éxito`,
      {
        appearance: 'success',
        autoDismiss: true,
      },
      () => history.push('/supplies'),
    );
    return saveResponse.data.data;
  };

  setImageAsDefault = () => {
    this.setState((prevState) => {
      const { imagenes, selectedImage } = prevState;
      // get old posicion value
      const { posicion } = selectedImage;
      // find existing default image
      const defaultImage = imagenes[0];
      // if there is and it is not the selected image, then change its posicion to selectedImages old posicion
      if (defaultImage && defaultImage.id !== selectedImage.id) {
        defaultImage.posicion = posicion;
      }
      const image = imagenes.find((img) => selectedImage.id === img.id);
      image.posicion = 1;
      // selectedImage.posicion = 0;
      const posicionChanges = [];
      if (selectedImage.id) {
        posicionChanges.push({ id: selectedImage.id, posicion: selectedImage.posicion });
      }
      posicionChanges.push({ id: defaultImage.id, posicion: defaultImage.posicion });
      imagenes.sort((a, b) => a.posicion - b.posicion);

      return {
        ...prevState,
        imagenes,
        selectedImage: imagenes[0],
        posicionChanges,
      };
    });
  };

  generateImagesElem = () => {
    const { entity, imagenes } = this.state;
    const { id } = entity;
    return (
      <Row>
        {imagenes.map((img, i) => {
          if (img.isUploaded) {
            return (
              <Col key={`img_${img.id}`} md={3}>
                <Container
                  style={{ height: '250px' }}
                  className="d-flex justify-content-center align-items-center img-thumbnail"
                  onClick={() => this.setState((prevState) => ({ ...prevState, selectedImage: img }))}>
                  <Image
                    className="mh-100"
                    id={`img_${i}`}
                    src={`${config.api.backPublicPath}/uploads/insumos/images/${id}/${img.filename}`}
                    alt={`imagen_${i}`}
                    fluid
                  />
                </Container>
              </Col>
            );
          }
          return (
            <OverlayTrigger key={`img_${i}`} overlay={<Tooltip id="tooltip-disabled">Aún no ha sido subida al servidor!</Tooltip>}>
              <Col md={3}>
                <div style={{ height: '250px' }} className="bg-warning d-flex justify-content-center align-items-center img-thumbnail">
                  <Image
                    className="mh-100"
                    disabled
                    style={{ opacity: '0.6' }}
                    id={`img_${i}`}
                    src={`${img.filename}`}
                    onClick={() => this.setState((prevState) => ({ ...prevState, selectedImage: img }))}
                    alt={`imagen_${i}`}
                    fluid
                  />
                </div>
              </Col>
            </OverlayTrigger>
          );
        })}
      </Row>
    );
  };

  generateFilesItem = () => {
    const { entity, archivos } = this.state;
    const { id } = entity;
    return (
      <Row>
        {archivos.map((file, i) => {
          if (file.isUploaded) {
            return (
              <Col key={`file_${file.id}`} md={3}>
                <Container style={{ height: '100px' }} className="d-flex align-items-center img-thumbnail">
                  <Button
                    size="sm"
                    variant="outline-primary"
                    title="Descargar"
                    style={{ width: '70%' }}
                    className="d-flex flex-column align-items-center"
                    onClick={() => {
                      APIClient.downloadFile(`${config.api.backPublicPath}/uploads/insumos/files/${id}/${file.filename}`).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', file.name || file.filename);
                        document.body.appendChild(link);
                        link.click();
                      });
                    }}>
                    <FontAwesomeIcon icon={faFileAlt} fixedWidth size="sm" />
                    {file.name}
                  </Button>
                  <Container className="justify-content-end align-items-end text-right">
                    <Button
                      type="submit"
                      size="sm"
                      className="bg-danger"
                      onClick={() =>
                        this.setState({
                          showDeleteFileModal: true,
                          fileToDelete: { ...file },
                        })
                      }>
                      <FontAwesomeIcon icon={faTimes} size="sm" />
                    </Button>
                  </Container>
                </Container>
              </Col>
            );
          }
          return (
            <OverlayTrigger key={`img_${i}`} overlay={<Tooltip id="tooltip-disabled">Aún no ha sido subida al servidor!</Tooltip>}>
              <Col md={3}>
                <Container
                  style={{ height: '100px' }}
                  className="bg-warning d-flex justify-content-center align-items-center img-thumbnail">
                  <Button size="sm" variant="outline-primary" title="Descargar" style={{ width: '70%' }}>
                    <FontAwesomeIcon icon={faFileAlt} fixedWidth size="lg" />
                    {file.name}
                  </Button>
                  <Container className="justify-content-end align-items-end text-right">
                    <Button
                      type="submit"
                      size="sm"
                      className="bg-danger"
                      onClick={() =>
                        this.setState({
                          showDeleteFileModal: true,
                          fileToDelete: { ...file },
                        })
                      }>
                      <FontAwesomeIcon icon={faTimes} size="sm" />
                    </Button>
                  </Container>
                </Container>
              </Col>
            </OverlayTrigger>
          );
        })}
      </Row>
    );
  };

  handleFilesChange = (e) => {
    const { toastManager } = this.props;
    const newFile = e.target.files[0];
    if (newFile) {
      if (newFile.size > 5000000) {
        toastManager.add('Solo se permiten archivos de menos de 5 MB.', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        this.setState((prevState) => {
          const { archivos } = prevState;
          const newFileToUpload = {};
          const fileTempUrl = URL.createObjectURL(newFile);
          newFileToUpload.name = newFile.name;
          newFileToUpload.filename = fileTempUrl;
          archivos.push(newFileToUpload);
          return { ...prevState, archivos };
        });
      }
    }
  };

  handleImageChange = (e) => {
    const { toastManager } = this.props;
    const newFile = e.target.files[0];
    if (newFile) {
      if (newFile.size > 5000000) {
        toastManager.add('Solo se permiten imágenes de menos de 5 MB.', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        this.setState((prevState) => {
          const { imagenes } = prevState;
          const newImage = {};
          const fileTempUrl = URL.createObjectURL(newFile);

          newImage.filename = fileTempUrl;
          if (imagenes.length > 0) {
            newImage.posicion =
              Math.max.apply(
                null,
                imagenes.map((img) => img.posicion),
              ) + 1;
            imagenes.push(newImage);
            return { ...prevState, imagenes };
          }
          newImage.posicion = 0;
          imagenes.push(newImage);
          return { ...prevState, imagenes, selectedImage: newImage };
        });
      }
    }
  };

  showDeleteImageModal = () => {
    const { showDeleteImageModal } = this.state;
    return (
      <Modal size="md" show={showDeleteImageModal} onHide={() => this.setState({ showDeleteImageModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar acción</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Desea eliminar este archivo?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showDeleteImageModal: false })}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={this.deleteImage}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  showDeleteFileModal = () => {
    const { showDeleteFileModal } = this.state;
    return (
      <Modal size="md" show={showDeleteFileModal} onHide={() => this.setState({ showDeleteFileModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar acción</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Desea eliminar este archivo?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({ showDeleteFileModal: false })}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={this.deleteFile}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  deleteImage = async () => {
    const { toastManager } = this.props;
    const { id, imageToDelete, imagenes: imgs } = this.state;
    let { selectedImage } = this.state;
    try {
      if (imageToDelete.id) {
        // delete image from API.
        await APIClient.delete(`/insumos/${id}/imagenes/${imageToDelete.id}`);
      }
      this.setState((prevState) => {
        const { imagenes } = prevState;
        // get image index
        const imgIndex = imagenes.findIndex((img) => img.posicion === imageToDelete.posicion);
        let posicionChanges = [];
        if (imagenes.length > 1) {
          // update posicion for each consecutive element and push changes into array.
          for (let i = imgIndex + 1; i < imagenes.length; i++) {
            const imagen = imagenes[i];
            imagen.posicion--;
            posicionChanges.push({ id: imagen.id, posicion: imagen.posicion });
          }
          imagenes.splice(imgIndex, 1);
        } else {
          // images array has only one element, no posicion updates are necessary.
          imagenes.pop();
          posicionChanges = [];
        }
        return {
          ...prevState,
          imagenes,
          imageToDelete: {},
          showDeleteImageModal: false,
          posicionChanges,
        };
      });
      toastManager.add('El archivo fue eliminado correctamente.', {
        appearance: 'success',
      });
    } catch (error) {
      console.error(error);
      toastManager.add('Ocurrió un error', {
        appearance: 'error',
      });
    } finally {
      selectedImage = imgs[0];
      this.setState({ selectedImage });
    }
  };

  deleteFile = async () => {
    const { toastManager } = this.props;
    const { id, fileToDelete } = this.state;
    try {
      if (fileToDelete.id) {
        await APIClient.delete(`/insumos/${id}/archivos/${fileToDelete.id}`);
      }
      this.setState((prevState) => {
        const { archivos } = prevState;
        const newFiles = archivos.filter((archivo) => archivo.filename !== fileToDelete.filename);

        return {
          ...prevState,
          archivos: newFiles,
          fileToDelete: {},
          showDeleteFileModal: false,
        };
      });
      toastManager.add('El archivo fue eliminado correctamente.', {
        appearance: 'success',
      });
    } catch (error) {
      console.error(error);
      toastManager.add('Ocurrió un error', {
        appearance: 'error',
      });
    }
  };

  prepareToSave = (entityToSave, actionType) => {
    const { posicionChanges, entityToSaveFather } = this.state;
    entityToSave = entityToSaveFather;

    if (posicionChanges.length > 0) {
      entityToSave.imagenes = posicionChanges;
    }
    return entityToSave;
  };

  formValidation(postedFields) {
    const postedFieldKeys = Object.keys(postedFields);
    const arrayvalidateindex = this.requiredFields.filter((requiredField) => postedFieldKeys.indexOf(requiredField) < 0);
    this.setState({
      arrayvalidateindex,
      postedFields,
    });
    if (arrayvalidateindex.length > 0) {
      throw { message: 'faltan campos' };
    }
  }

  /// PROVEEDORES

  renderSelectProveedores = () => {
    const { proveedores, entityToSaveFather } = this.state;
    return entityToSaveFather.proveedores.map((proveedor, i) => (
      <Row key={`pr-${i}`} className="pt-2">
        <Col md={10}>
          <Typeahead
            defaultSelected={proveedor && proveedor.hasOwnProperty('id') ? [proveedor.description] : ['']}
            labelKey="description"
            id={`proveedor_${i}`}
            label="Typeahead"
            key={`proveedor_${i}`}
            onChange={(selected) => this.handleInputSelectChanges(`proveedor_${i}`, selected)}
            options={proveedores}
            placeholder="Ingrese descricpión"
          />
        </Col>
      </Row>
    ));
  };

  addDeleteProveedorSelect = (boolean, deleteIndex) => {
    const { entityToSaveFather } = this.state;
    if (boolean) {
      entityToSaveFather.proveedores.push({});
    } else {
      entityToSaveFather.proveedores.pop();
    }
    this.setState({
      entityToSaveFather,
    });
  };

  setProveedores = (name, valueToSave, entityToSaveFather) => {
    const { proveedores } = this.state;
    const currentElem = proveedores.filter((proveedor) => proveedor.id === parseInt(valueToSave))[0];
    const sum = entityToSaveFather.proveedores;
    for (const i in entityToSaveFather) {
      if (i.includes('proveedor') && i !== 'proveedores') {
        const index = name.charAt(name.length - 1);
        if (parseInt(index) <= sum.length - 1) {
          sum[index] = currentElem;
        } else {
          sum.push(currentElem);
        }
      }
    }
  };

  setInsumos = (name, valueToSave, entityToSaveFather) => {
    const { insumos } = this.state;
    const insumo = { insumo: {}, units: 1, unitsInChild: 1 };
    if (name.includes('insumoUnits')) {
      // add units
      const index = name.charAt(name.length - 1);
      if (entityToSaveFather.insumos.length > 0) {
        entityToSaveFather.insumos[index].units = valueToSave;
      }
    } else if (name.includes('ChildUnits')) {
      const index = name.charAt(name.length - 1);
      if (entityToSaveFather.insumos.length > 0) {
        entityToSaveFather.insumos[index].unitsInChild = valueToSave;
      }
    } else {
      // add entity
      const currentElem = insumos.filter((insumo) => insumo.id === parseInt(valueToSave))[0];
      const sum = entityToSaveFather.insumos;
      for (const i in entityToSaveFather) {
        if (i.includes('insumo_') && i !== 'insumos') {
          const index = name.charAt(name.length - 1);
          if (parseInt(index) <= sum.length - 1) {
            sum[index] = insumo;
            insumo.insumo = currentElem;
          } else {
            insumo.insumo = currentElem;
            sum.push(insumo);
          }
        }
      }
    }
  };

  handleInputSelectChanges = (name, value) => {
    const valueId = value.length ? value[0].id : '';
    this.saveEntityInfatherState(name, valueId, this);
  };

  setComercializables = (name, valueToSave, entityToSaveFather) => {
    const { comercializables } = this.state;
    const comercializable = { comercializable: {}, units: 1, unitsInChild: 1 };
    if (name.includes('comercializableUnits')) {
      // add units
      const index = name.charAt(name.length - 1);
      if (entityToSaveFather.comercializables.length > 0) {
        entityToSaveFather.comercializables[index].units = valueToSave;
      }
    } else if (name.includes('ChildUnits')) {
      const index = name.charAt(name.length - 1);
      if (entityToSaveFather.comercializables.length > 0) {
        entityToSaveFather.comercializables[index].unitsInChild = valueToSave;
      }
    } else {
      const currentElem = comercializables.filter((comercializable) => comercializable.id === parseInt(valueToSave))[0];
      const sum = entityToSaveFather.comercializables;
      for (const i in entityToSaveFather) {
        if (i.includes('comercializable') && i !== 'comercializables') {
          const index = name.charAt(name.length - 1);
          if (parseInt(index) <= sum.length - 1) {
            sum[index] = comercializable;
            comercializable.comercializable = currentElem;
          } else {
            comercializable.comercializable = currentElem;
            sum.push(currentElem);
          }
        }
      }
    }
  };
  /// ////FIN PROVEEDORES

  /// ///BOM
  /// INSUMOS
  renderSelectInsumos = () => {
    const { insumos, entityToSaveFather } = this.state;
    if (insumos.length === 0) {
      return (
        <Col md={4} className="mt-2">
          <Alert className="m-2" variant="primary">
            <p className="font-weight-bold text-sm-rigth m-0" style={{ fontSize: '1rem' }}>
              Sin Listas de Materiales
            </p>
          </Alert>
        </Col>
      );
    }
    return entityToSaveFather.insumos.map((insumo, i) => (
      <Row key={`in-${i}`} className="d-flex align-items-center">
        <Col md={4} className="mt-2">
          <Typeahead
            defaultSelected={insumo.insumo && insumo.insumo.hasOwnProperty('erpCode') ? [insumo.insumo.erpCode] : ['']}
            labelKey="erpCode"
            id={`insumo_${i}`}
            label="Typeahead"
            key={`insumo_${i}`}
            onChange={(selected) => this.handleInputSelectChanges(`insumo_${i}`, selected)}
            options={insumos}
            placeholder="Ingrese erp"
            filterBy={['description']}
          />
        </Col>
        <Col md={2}>
          <p>UDM</p>
          <p className="mt-3" style={{ fontSize: 16 }}>
            {entityToSaveFather.insumos[0].insumo?.unidadMedida?.description}
          </p>
        </Col>
        <Col md={3} className="mb-0">
          <FormInputField
            id={`insumoChildUnits_${i}`}
            type="string"
            label="Unidades en el padre"
            defaultValue={insumo.unitsInChild ? insumo.unitsInChild : '1'}
            onChange={() => {}}
          />
        </Col>
      </Row>
    ));
  };

  addDeleteInsumoSelect = (boolean, deleteIndex) => {
    const { entityToSaveFather } = this.state;
    if (boolean) {
      entityToSaveFather.insumos.push({ insumo: {}, units: 1, unitsInChild: 1 });
    } else {
      entityToSaveFather.insumos.pop();
    }
    this.setState({
      entityToSaveFather,
    });
  };
  /// /FIN INSUMOS
  /// COMERCIALIZABLES

  renderSelectComercializables = () => {
    const { comercializables, entityToSaveFather } = this.state;
    if (comercializables.length === 0) {
      return (
        <Col md={4} className="mt-2">
          <Alert className="m-2" variant="primary">
            <p className="font-weight-bold text-sm-rigth m-0" style={{ fontSize: '1rem' }}>
              Sin Listas de Materiales
            </p>
          </Alert>
        </Col>
      );
    }
    return entityToSaveFather.comercializables.map((comercializable, i) => (
      <Row key={`com-${i}`} className="d-flex align-items-center ">
        <Col md={6} className="mt-3">
          <Typeahead
            defaultSelected={
              comercializable.comercializable && comercializable.comercializable.hasOwnProperty('erpCodigo')
                ? [comercializable.comercializable.erpCodigo]
                : ['']
            }
            labelKey={(option) =>
              `${option ? (comercializable.comercializable ? comercializable.comercializable.erpCodigo : option.erpCodigo) : 'erp'} - ${
                option
                  ? comercializable.comercializable
                    ? comercializable.comercializable.descripcion
                    : option.descripcion
                  : 'descripcion'
              }`
            }
            id={`comercializable${i}`}
            label="Typeahead"
            key={`comercializable${i}`}
            onChange={(selected) => this.handleInputSelectChanges(`comercializable${i}`, selected)}
            options={comercializables}
            placeholder="Ingrese erp"
            filterBy={['descripcion', 'erpCodigo']}
          />
        </Col>
        <Col md={3}>
          <FormInputField
            id={`comercializableChildUnits_${i}`}
            type="number"
            label="Unidades en el padre"
            defaultValue={comercializable.unitsInChild ? comercializable.unitsInChild : '1'}
            onChange={() => {}}
            min={1}
          />
        </Col>
      </Row>
    ));
  };

  addDeleteComercializableSelect = (boolean, deleteIndex) => {
    const { entityToSaveFather } = this.state;
    if (boolean) {
      entityToSaveFather.comercializables.push({ comercializable: {}, units: 1, unitsInChild: 1 });
    } else {
      entityToSaveFather.comercializables.pop();
    }
    this.setState({
      entityToSaveFather,
    });
  };
  /// FIN COMERCIALIZABLES
  /// /FIN BOM

  saveEntityInfatherState(name, valueToSave, fatherContext) {
    const { entityToSaveFather } = fatherContext.state;
    entityToSaveFather[name] = valueToSave;
    if (name.includes('proveedor')) {
      fatherContext.setProveedores(name, valueToSave, entityToSaveFather);
    }
    if (name.includes('insumo')) {
      fatherContext.setInsumos(name, valueToSave, entityToSaveFather);
    }
    if (name.includes('comercializable')) {
      fatherContext.setComercializables(name, valueToSave, entityToSaveFather);
    }

    fatherContext.setState((prevState) => ({
      entityToSaveFather,
    }));
  }

  onLoadForm = async () => {
    const { id, checkExistenceParam } = this.state;
    // get categories
    const categoriesLevel1Res = await APIClient.get('/categorias?filter[category_level_id][eq]=1&filter[active][eq]=1');
    const categoriesLevel2Res = await APIClient.get('/categorias?filter[category_level_id][eq]=2&filter[active][eq]=1');
    const categoriesLevel3Res = await APIClient.get('/categorias?filter[category_level_id][eq]=3&filter[active][eq]=1');
    const unidadesMedidaRes = await APIClient.get('/unidades-medida?filter[active][eq]=1');
    const insumosTypesRes = await APIClient.get('/insumos-types');
    const compraFabricaRes = await APIClient.get('/compra-o-fabrica');
    const proveedoresRes = await APIClient.get('/proveedores?filter[active][eq]=1');
    const itemsFilterRes = await APIClient.get('/insumos/items-filter');
    const { comercializables, insumos } = itemsFilterRes.data.data;
    let insumosWithStockRes;
    if (id) insumosWithStockRes = await APIClient.get(`/monitor-inventario/check-existence/${checkExistenceParam}/${id}`);
    this.setState({
      categoriesLevel1: categoriesLevel1Res.data.data,
      categoriesLevel2: categoriesLevel2Res.data.data,
      categoriesLevel3: categoriesLevel3Res.data.data,
      unidadesMedida: unidadesMedidaRes.data.data,
      insumosTypes: insumosTypesRes.data.data,
      compraFabrica: compraFabricaRes.data.data,
      proveedores: proveedoresRes.data.data,
      insumos,
      comercializables,
      insumosWithStock: id ? insumosWithStockRes.data.data : [],
    });
  };

  render() {
    const {
      id,
      entity,
      isAdding,
      imagenes,
      selectedImage,
      categoriesLevel1,
      categoriesLevel2,
      categoriesLevel3,
      unidadesMedida,
      insumosTypes,
      compraFabrica,
      archivos,
      arrayvalidateindex,
      insumosWithStock,
      entityToSaveFather,
    } = this.state;
    const ableDisable = insumosWithStock.length > 0;

    return (
      <div>
        {this.showDeleteImageModal()}
        {this.showDeleteFileModal()}
        <h1 className="page-title">{isAdding ? 'Insumo nuevo' : `Insumo: ${entity.description ? entity.description : ''}`}</h1>

        <EntityEditForm
          saveEntityInfatherState={this.saveEntityInfatherState}
          fatherContext={this}
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          prepareToSave={this.prepareToSave}
          addMode={isAdding}>
          <>
            <Card className="mb-4">
              <Card.Header>General</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="categoryLevel1Id"
                      label="Categoria Nivel 1"
                      defaultValue={entity.categoryLevel1Id ? entity.categoryLevel1Id : ''}
                      choices={categoriesLevel1}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayvalidateindex={arrayvalidateindex}
                      maxLength={50}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSelectField
                      id="categoryLevel2Id"
                      label="Categoria Nivel 2"
                      defaultValue={entity.categoryLevel2Id ? entity.categoryLevel2Id : ''}
                      choices={categoriesLevel2}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayvalidateindex={arrayvalidateindex}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSelectField
                      id="categoryLevel3Id"
                      label="Categoria Nivel 3"
                      defaultValue={entity.categoryLevel3Id ? entity.categoryLevel3Id : ''}
                      choices={categoriesLevel3}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayvalidateindex={arrayvalidateindex}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormInputField
                      id="erpCode"
                      label="Codigo ERP"
                      type="text"
                      maxLength={15}
                      defaultValue={entity && entity.erpCode}
                      className={arrayvalidateindex.indexOf('erpCode') >= 0 ? 'border border-danger' : ''}
                    />
                  </Col>
                  <Col md={4}>
                    <FormInputField
                      id="eanCode"
                      label="Codigo EAN13"
                      type="number"
                      defaultValue={entity && entity.eanCode}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 13);
                      }}
                    />
                  </Col>
                  <Col md={4}>
                    <FormInputField
                      id="description"
                      label="Descripción"
                      type="text"
                      defaultValue={entity && entity.description}
                      maxLength={50}
                      className={arrayvalidateindex.indexOf('erpCode') >= 0 ? 'border border-danger' : ''}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormInputField
                      id="longDescription"
                      label="Descripción ampliada"
                      type="text"
                      defaultValue={entity && entity.longDescription}
                      maxLength={150}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="unidadMedidaId"
                      label="Unidad de Medida"
                      defaultValue={entity.unidadMedidaId ? entity.unidadMedidaId : ''}
                      choices={unidadesMedida}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayvalidateindex={arrayvalidateindex}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSelectField
                      id="insumoTypeId"
                      label="Tipo de Articulo"
                      defaultValue={entity.insumoTypeId ? entity.insumoTypeId : ''}
                      choices={insumosTypes}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayvalidateindex={arrayvalidateindex}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSwitch
                      id="active"
                      label={'Activo'}
                      checked={Object.keys(entity).length !== 0 ? entity.active : true}
                      labelField="active"
                      disabled={ableDisable}
                    />
                    {ableDisable ? <span className="bg-warning">Este insumo tiene cantidades comprometidas en el stock </span> : ''}
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header>Parametros Fisicos</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={2}>
                    <FormInputField id="pesoKg" label="Peso [kg]" type="number" min={0} step=".01" defaultValue={entity && entity.pesoKg} />
                  </Col>
                  <Col md={2}>
                    <FormInputField
                      id="largoMm"
                      label="Largo [mm]"
                      type="number"
                      min={0}
                      step=".01"
                      defaultValue={entity && entity.largoMm}
                    />
                  </Col>
                  <Col md={2}>
                    <FormInputField
                      id="anchoMm"
                      label="Ancho [mm]"
                      type="number"
                      min={0}
                      step=".01"
                      defaultValue={entity && entity.anchoMm}
                    />
                  </Col>
                  <Col md={2}>
                    <FormInputField
                      id="altoMm"
                      label="Altura [mm]"
                      type="number"
                      min={0}
                      step=".01"
                      defaultValue={entity && entity.altoMm}
                    />
                  </Col>
                  <Col md={2}>
                    <FormInputField
                      id="diametroInternoMm"
                      label="Diametro int [mm]"
                      type="number"
                      min={0}
                      step=".01"
                      defaultValue={entity && entity.diametroInternoMm}
                    />
                  </Col>
                  <Col md={2}>
                    <FormInputField
                      id="diametroExternoMm"
                      label="Diametro ext [mm]"
                      type="number"
                      min={0}
                      step=".01"
                      defaultValue={entity && entity.diametroExternoMm}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Header>Planificación</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={3}>
                    <FormInputField
                      id="minUnidades"
                      label="Stock minimo"
                      type={
                        unidadesMedida.find((elem) => elem.id === Number(entityToSaveFather.unidadMedidaId))?.description === 'Unidades'
                          ? 'number'
                          : 'string'
                      } //verificar si es unidades
                      min={0}
                      defaultValue={entity && entity.minUnidades}
                    />
                  </Col>
                  <Col md={2}>
                    <p>UDM</p>
                    <p className="mt-3" style={{ fontSize: 16 }}>
                      {unidadesMedida.find((elem) => elem.id === Number(entityToSaveFather.unidadMedidaId))?.description}
                    </p>
                  </Col>
                  <Col md={3}>
                    <FormInputField
                      id="maxUnidades"
                      label="Stock maximo"
                      type="string"
                      min={0}
                      defaultValue={entity && entity.maxUnidades}
                    />
                  </Col>
                  <Col md={2}>
                    <FormSwitch id="sePlanifica" label={'Planificado'} checked={entity.sePlanifica} labelField="sePlanifica" />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Header>Producción</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="compraOFabricaId"
                      label="Hacer/ Comprar"
                      defaultValue={entity.compraOFabricaId ? entity.compraOFabricaId : ''}
                      choices={compraFabrica}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayvalidateindex={arrayvalidateindex}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSwitch id="manejaLote" label={'Maneja Lote'} checked={entity.manejaLote} labelField="manejaLote" />
                  </Col>
                </Row>
                <Row>
                  <Col md={{ offset: 9 }}>(Agregar o quitar proveedores)</Col>
                </Row>
                <Row className="pb-5">
                  <Col md={2}>PROVEEDORES</Col>
                  <Col md={8}>{this.renderSelectProveedores()}</Col>
                  <Col md={1}>
                    <Button variant="primary" onClick={() => this.addDeleteProveedorSelect(true)} size="sm">
                      <FontAwesomeIcon icon={faPlus} fixedWidth />
                    </Button>
                  </Col>
                  <Col md={1}>
                    <Button variant="primary" onClick={() => this.addDeleteProveedorSelect(false)} size="sm">
                      <FontAwesomeIcon icon={faMinus} fixedWidth />
                    </Button>
                  </Col>
                </Row>

                {/* LISTA DE MATERIALES */}
                <Row>
                  <Col md={12}>AGREGAR A LISTA DE MATERIALES (EXISTENTES)</Col>
                </Row>
                <Row>
                  <Col md={{ offset: 9 }}>(Agregar o quitar semielaborado padre)</Col>
                </Row>
                <Row>
                  {/* INSUMOS */}
                  <Col md={2}>Seleccionar semielaborado padre</Col>
                  <Col md={8}>{this.renderSelectInsumos()}</Col>
                  <Col md={1}>
                    <Button variant="primary" onClick={() => this.addDeleteInsumoSelect(true)} size="sm">
                      <FontAwesomeIcon icon={faPlus} fixedWidth />
                    </Button>
                  </Col>
                  <Col md={1}>
                    <Button variant="primary" onClick={() => this.addDeleteInsumoSelect(false)} size="sm">
                      <FontAwesomeIcon icon={faMinus} fixedWidth />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ offset: 9 }}>(Agregar o quitar comercializable padre)</Col>
                </Row>
                <Row>
                  {/* COMERCIALIZABLES */}
                  <Col md={2}>Seleccionar comercializable padre</Col>
                  <Col md={8}>{this.renderSelectComercializables()}</Col>
                  <Col md={1}>
                    <Button variant="primary" onClick={() => this.addDeleteComercializableSelect(true)} size="sm">
                      <FontAwesomeIcon icon={faPlus} fixedWidth />
                    </Button>
                  </Col>
                  <Col md={1}>
                    <Button variant="primary" onClick={() => this.addDeleteComercializableSelect(false)} size="sm">
                      <FontAwesomeIcon icon={faMinus} fixedWidth />
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* imagenes */}
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Imágenes</Card.Title>
                {selectedImage && imagenes.length > 0 && (
                  <Row>
                    <Col className="d-flex align-items-center" md={9}>
                      <Container className="mb-2 border border-primary rounded d-flex justify-content-center">
                        <Image
                          id="img_selected"
                          src={
                            selectedImage.isUploaded
                              ? `${config.api.backPublicPath}/uploads/insumos/images/${id}/${selectedImage.filename}`
                              : selectedImage.filename
                          }
                          alt={`imagen_${selectedImage.posicion}`}
                          rounded
                          fluid
                        />
                      </Container>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center" md={3}>
                      <Container className="d-flex flex-column">
                        <Button
                          type="submit"
                          disabled={selectedImage.posicion === imagenes[0].posicion}
                          className="m-1 bg-primary"
                          onClick={this.setImageAsDefault}>
                          {selectedImage.posicion === imagenes[0].posicion ? 'Predeterminada' : 'Marcar como predet.'}
                        </Button>
                        <Button
                          type="submit"
                          className="m-1 bg-primary"
                          onClick={() =>
                            this.setState({
                              showDeleteImageModal: true,
                              imageToDelete: { ...selectedImage },
                            })
                          }>
                          Eliminar
                        </Button>
                      </Container>
                    </Col>
                  </Row>
                )}
                {imagenes && this.generateImagesElem()}
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Col md={10}>
                  <Form.Group className="d-flex align-items-center mb-0" controlId="fileUpload">
                    <label className="custom-file-label position-absolute mb-0" htmlFor="customFileLang">
                      Haga click para seleccionar una imagen.
                    </label>
                    <input
                      name="fileUpload"
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={this.handleImageChange}
                    />
                  </Form.Group>
                </Col>
              </Card.Footer>
            </Card>

            {/* Subida de Archivos */}
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Archivos</Card.Title>
                {archivos && this.generateFilesItem()}
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Col md={10}>
                  <Form.Group className="d-flex align-items-center mb-0" controlId="filesUpload">
                    <label className="custom-file-label position-absolute mb-0" htmlFor="customFileLang">
                      Haga click para seleccionar un archivo.
                    </label>
                    <input
                      name="filesUpload"
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      type="file"
                      accept="application/pdf, text/plain"
                      onChange={this.handleFilesChange}
                    />
                  </Form.Group>
                </Col>
              </Card.Footer>
            </Card>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(InsumoEdit);
