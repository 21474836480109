import React from 'react';
import PropTypes from 'prop-types';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import Section from './Section';
import APIClient from '../../services/APIClient';
import { connect } from 'react-redux'

class WarehouseList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.apiParams = '';
    this.state = {
      warehouses: [],
      isDataLoading: true,
      totalSize: 0,
      warehouseExpanded: [],
    };

   

    this.loadWarehouses = this.loadWarehouses.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }
  

  componentDidMount() {
    this.loadWarehouses();
  }

  /**
   * @param  {string} type - change type
   * @param  {number} page - page number
   * @param  {number} sizePerPage - current page size
   * receives table changes, makes custom API calls and saves results to state.
   */
  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    try {
      const { direction } = sorting;
      let { field } = sorting;
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || ''}&sortDir=${
        direction || ''
      }&excludeAssocFields=imagenes`;
      const warehousesRes = await APIClient.get(`/warehouses?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}`);
      const warehouses = warehousesRes.data.data;

      this.setState({
        warehouses,
        totalSize: warehousesRes.data.meta.total,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  async loadWarehouses() {
    const { toastManager } = this.props;
    try {
      const warehouseRes = await APIClient.get('/warehouses');
      this.setState({
        warehouses: warehouseRes.data.data,
        isDataLoading: false,
        totalSize: warehouseRes.data.meta.total,
      });
    } catch (error) {
      console.error('Cargando depósitos.', error);
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  render() {
    const { warehouses, isDataLoading, totalSize, warehouseExpanded, isSuperAdmin, editPermission, isPermissionsLoading } = this.state;

    const columns = [
      {
        dataField: 'description',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'address',
        text: 'Domicilio',
        sort: true,
      },
      {
        dataField: 'isFactory',
        text: 'Es fábrica',
        sort: true,
        formatter: (cellContent, row) => {
          const view = !cellContent ? 'NO' : 'SI';
          return view;
        },
      },
      {
        dataField: 'disabled',
        text: 'Habilitado',
        sort: true,
        formatter: (cellContent, row) => {
          const view = !cellContent ? 'SI' : 'NO';
          return view;
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <LinkContainer to={`/warehouses/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Editar">
                    <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    const expandRow = {
      showExpandColumn: true,
      expanded: warehouseExpanded,
      renderer: (row) => <Section warehouse={row} />,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return (
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          );
        }
        return (
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        );
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          );
        }
        return (
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        );
      },
    };

    return (
      <div>
        <h1 className="page-title">Depósitos</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            totalSize={totalSize}
            columns={columns}
            data={warehouses}
            showExport={false}
            isDataLoading={isDataLoading}
            keyField="id"
            addButton={isSuperAdmin || editPermission ? '/warehouses/nuevo' : null}
            expandRow={isSuperAdmin || editPermission ? expandRow : false}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(WarehouseList));

