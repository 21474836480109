import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormInputField, FormSelectField } from '../../components';
import APIClient from '../../services/APIClient';

class StocklistEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: {},
      movementMotives: [],
      movementTypes: [],
      productStatuses: [],
      productTypes: [],
      selectedPT: { id: 0, description: 'elegir tipo' },
      warehouses: [],
      sections: [],
      locations: [],
      products: [],
      entityToSaveFather: {},
      formTrackingState: {
        selectedProductType: 0,
        warehouseId: 0,
        sectionId: 0,
        locationId: 0,
      },
      arrayValidateIndex: [],
    };
    this.requiredFields = ['productTypeId', 'units', 'typeId', 'motiveId', 'warehouseId', 'sectionId', 'locationId'];
  }

  onLoadForm = async () => {
    // get movementMotives
    const movementMotivesRes = await APIClient.get('/stocklist-movement-motives');
    // get movementTypes
    const movementTypesRes = await APIClient.get('/stocklist-movement-types');
    // get productStatuses
    const productStatusesRes = await APIClient.get('/stocklist-product-statuses');
    // get productTypes
    const productTypesRes = await APIClient.get('/stocklist-product-types');
    // get warehouses
    const warehousesRes = await APIClient.get('/warehouses');
    // get sections
    const sectionsRes = await APIClient.get('/sections');
    // get locations
    const locationsRes = await APIClient.get('/locations');
    //load default products
    this.loadProducts('comercializable');

    this.setState({
      movementMotives: movementMotivesRes.data.data,
      movementTypes: movementTypesRes.data.data,
      productStatuses: productStatusesRes.data.data,
      productTypes: productTypesRes.data.data,
      warehouses: warehousesRes.data.data,
      sections: sectionsRes.data.data,
      sectionsCopy: sectionsRes.data.data,
      locations: locationsRes.data.data,
      locationsCopy: locationsRes.data.data,
    });
  };

  async loadProducts(productType) {
    const { toastManager } = this.props;
    let url;
    if (productType === 'insumo') url = '/insumos?filter[active][eq]=1';
    if (productType === 'comercializable') url = '/articulos';
    try {
      const productsRes = await APIClient.get(url);
      this.setState({
        products: productsRes.data.data,
      });
    }
    catch (err){
      toastManager.add(`Error al cargar datos. Recargar página. Checkear conexión a internet`, {
        appearance: 'error',
        autoDismiss: true,
      });
    };

  }
  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { isAdding, entityToSaveFather } = this.state;
    const { history, toastManager } = this.props;
    try {
      this.formValidation(entityToSaveFather);
    let saveResponse = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/stocklist', entityToSaveFather);
    } else {
      // saveResponse = await APIClient.patch(`/stocklist/${id}`, entityToSave);
    }

    history.push('/stocklist');
    toastManager.add(`Movimiento ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });

    return saveResponse.data.data;
    } catch (err){
      const resMessage = err.data ? err.data.data.message : err.message;
      toastManager.add(`No se guardaron cambios: ${resMessage}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    };
  };

  formValidation(postedFields) {
    const postedFieldKeys = Object.keys(postedFields);
    const arrayValidateIndex = this.requiredFields.filter((requiredField) => postedFieldKeys.indexOf(requiredField) < 0 );
    this.setState({
      arrayValidateIndex,
      postedFields,
    });
    if(arrayValidateIndex.length > 0){
      throw {message: 'faltan campos'}
    }
  }


  saveEntityInfatherState(name, valueToSave, fatherContext) {
    const {entityToSaveFather} = fatherContext.state;
    entityToSaveFather[name] = valueToSave;
    fatherContext.setState((prevState) => ({
      entityToSaveFather,
    }))
  }
  checkChildStateFromFather(childState, fatherContext) {

    let { formTrackingState, sections, sectionsCopy, locations, locationsCopy, productTypes, selectedPT } = fatherContext.state;
    const checkProperty = ['productTypeId', 'warehouseId', 'sectionId', 'locationId'];
    const selectedProperties = checkProperty.filter((property) => childState.hasOwnProperty(property));
    if (selectedProperties.length > 0) {
      const newSelectedProductTypeId = parseInt(childState.productTypeId);
      selectedPT.id = formTrackingState.selectedProductType;
      if (selectedPT.id !== newSelectedProductTypeId) {
        const productTypeSel = productTypes.find((productType) => productType.id === newSelectedProductTypeId);
        const description = productTypeSel ? productTypeSel.description : selectedPT.description;
        selectedPT.description = description ? description : '';
        selectedPT.id = newSelectedProductTypeId;
        fatherContext.loadProducts(selectedPT.description);
      }

      formTrackingState = {
        selectedProductType: parseInt(childState.productTypeId) || 0,
        warehouseId: parseInt(childState.warehouseId) || 0,
        sectionId: parseInt(childState.sectionId) || 0,
        locationId: parseInt(childState.locationId) || 0,
      };
      const { warehouseId, sectionId } = formTrackingState;
      fatherContext.setState((prevState) => ({
        ...prevState,
        ...{
          selectedPT,
          formTrackingState,
          warehouseId: warehouseId !== 0 ? warehouseId : prevState.warehouseId,
          sections: warehouseId === 0 ? sections : sectionsCopy.filter((section) => section.warehouseId === warehouseId),
          locations: sectionId === 0 ? locations : locationsCopy.filter((location) => location.sectionId === sectionId),
        },
      }));
    }
  }

  render() {
    const {
      entity,
      isAdding,
      movementMotives,
      movementTypes,
      productStatuses,
      productTypes,
      selectedPT,
      products,
      warehouses,
      sections,
      locations,
      arrayValidateIndex,
      
    } = this.state;
    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Nuevo Movimiento de inventario' : ''}</h1>

        <EntityEditForm
          onLoadForm={this.onLoadForm}
          onRetrieveEntity={() => {}}
          onSaveEntity={this.onSaveEntity}
          checkChildStateFromFather={this.checkChildStateFromFather}
          saveEntityInfatherState={this.saveEntityInfatherState}
          fatherContext={this}
          addMode={isAdding}>
          <>

               {/* MOVIMIENTO DE INVENTARIO */}
               <Card className="mb-2">
              <Card.Body>
                <Card.Title>Movimiento de inventario</Card.Title>
                <Row>
                  <Col md={6}>
                    <FormSelectField
                      id="typeId"
                      label="Tipo"
                      defaultValue={entity.typeId}
                      choices={movementTypes}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayValidateIndex={arrayValidateIndex}
                    />
                  </Col>
                  <Col md={6}>
                    <FormSelectField
                      id="motiveId"
                      label="Motivo"
                      defaultValue={entity.motiveId}
                      choices={movementMotives}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayValidateIndex={arrayValidateIndex}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* PRODUCTO */}
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Producto</Card.Title>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="productTypeId"
                      label="Tipo"
                //      defaultValue={productTypes.length > 0 ? productTypes[0].id : 0}
                      choices={productTypes}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayValidateIndex={arrayValidateIndex}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSelectField
                      id="productStatusId"
                      label="Estado"
                     // value={selectedPT.description === 'comercializable' ? productStatuses.filter(sts => sts.description === 'elaborado')[0].id :  entityToSaveFather.productStatusId}
                      choices={productStatuses}
                      choiceIdField="id"
                      choiceLabelField="description"
                      onChange={() => {console.log('HOLIS')}}
                    />
                  </Col>
                  <Col md={3}>
                    <FormInputField id="units" type="number" label="Unidades" defaultValue="0" min={0} step="1"  arrayValidateIndex={arrayValidateIndex}/>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormSelectField
                      id={selectedPT.description === 'comercializable' ? 'articuloId' : 'insumoId'}
                      label={`Productos: ${selectedPT.description}`}
                      //  defaultValue={entity.productTypeId}
                      choices={products}
                      choiceIdField="id"
                      choiceLabelField={selectedPT.description === 'comercializable' ? 'descripcion' : 'description'}
                      arrayValidateIndex={arrayValidateIndex}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* UBICACION EN DEPOSITO */}
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Ubicación en depósito</Card.Title>
                <Row>
                  <Col md={4}>
                    <FormSelectField
                      id="warehouseId"
                      label="Depósito"
                      defaultValue={entity.warehouseId}
                      choices={warehouses}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayValidateIndex={arrayValidateIndex}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSelectField
                      id="sectionId"
                      label="Sector"
                      defaultValue={entity.sectionId}
                      choices={sections}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayValidateIndex={arrayValidateIndex}
                    />
                  </Col>
                  <Col md={4}>
                    <FormSelectField
                      id="locationId"
                      label="Posición"
                      defaultValue={entity.locationId}
                      choices={locations}
                      choiceIdField="id"
                      choiceLabelField="description"
                      arrayValidateIndex={arrayValidateIndex}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(StocklistEdit);
