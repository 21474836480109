import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { EntityEditForm, FormInputField, FormCheckField } from '../../components';
import APIClient from '../../services/APIClient';

class AutoMarcaEdit extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: {},
    };
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get coleccione
    const autoMarcasRes = await APIClient.get(`/auto-marcas/${id}`);

    this.setState({
      isLoading: false,
      entity: autoMarcasRes.data.data,
    });
    return autoMarcasRes.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave) => {
    const { id, isAdding } = this.state;
    const { history, toastManager } = this.props;

    let saveResponse = null;
    if (isAdding) {
      entityToSave.posicion = 0;
      saveResponse = await APIClient.post('/auto-marcas', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/auto-marcas/${id}`, entityToSave);
    }

    history.push('/auto-marcas');
    toastManager.add(`Marca de auto ${saveResponse.data.data.descripcion} guardada con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });

    return saveResponse.data.data;
  };

  render() {
    const { id, entity, isAdding } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Marca de auto nueva' : `Marca de auto #${id}`}</h1>

        <EntityEditForm
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}
        >
          <>
            <Row>
              <Col md={6}>
                <FormInputField
                  id="descripcion"
                  label="Descripción"
                  type="text"
                  defaultValue={entity.descripcion}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormCheckField
                  id="activo"
                  label="Activo"
                  defaultChecked={entity.activo > 0}
                  required
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(AutoMarcaEdit);
