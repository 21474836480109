import React from 'react';
import { Route } from 'react-router-dom';
import ResorceList from './ResourceList';
import ResourceEdit from './ResourceEdit';

const routes = () => [
  <Route path="/resources/new" exact component={ResourceEdit} key="/resources/new" />,
  <Route path="/resources/:id" component={ResourceEdit} key="/resources/:id" />,
  <Route path="/resources" exact component={ResorceList} key="/resources" />,
].map(route => route);

export default routes;
