import React, { Component } from 'react';
import { Card, Tabs, Tab, Col, Row } from 'react-bootstrap';

import Reporte from '../../components/saleReport/Reporte';

export default class SaleReport extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Row className="d-flex align-items-center page-title">
          <Col md={6}>
            <h1>Reporte de ventas</h1>
          </Col>
          <Col md={6} className="d-flex justify-content-center">
            <p className="my-auto text-dark">Este reporte tiene en cuenta las entregas facturadas</p>
          </Col>
        </Row>
        <Card.Header>Filtros</Card.Header>
        <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
          <Tab eventKey="general" title="General">
            <Reporte entity="General" />
          </Tab>
          <Tab eventKey="cliente" title="Cliente">
            <Reporte entity="Clientes" />
          </Tab>
          <Tab eventKey="articulo" title="Articulo">
            <Reporte entity="Articulos" />
          </Tab>
          <Tab eventKey="linea" title="Línea">
            <Reporte entity="Lineas" />
          </Tab>
          <Tab eventKey="provincia" title="Provincia">
            <Reporte entity="Provincias" />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
