import React from 'react';
import { Route } from 'react-router-dom';
import PrecioModificadorList from './PrecioModificadorList';
import PrecioModificadorEdit from './PrecioModificadorEdit';

const routes = () => [
  <Route path="/modificadores-precio/nuevo" exact component={PrecioModificadorEdit} key="/modificadores-precio/nuevo" />,
  <Route path="/modificadores-precio/:id" component={PrecioModificadorEdit} key="/modificadores-precio/:id" />,
  <Route path="/modificadores-precio" exact component={PrecioModificadorList} key="/modificadores-precio" />,
].map(route => route);

export default routes;
