import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable, Loading } from '../../components';
import APIClient from '../../services/APIClient';
import { connect } from 'react-redux'

class ClienteList extends React.Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      clientes: [],
      isDataLoading: true,
      totalSize: 0,
    };


    this.loadClientes = this.loadClientes.bind(this);
  }

  getPermissions(){
    const {isSuperAdmin, editPermission,deletePermission,isPermissionsLoading} = this.props.permissionsUser;
    this.setState({
      isSuperAdmin,
      editPermission,
      deletePermission,
      isPermissionsLoading,
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionsUser !== this.props.permissionsUser) {
      this.getPermissions();
    }
  }
  

  componentDidMount() {
    this.loadClientes();
  }

  onTableUpdate = async (queryParameters) => {
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    try {
      const { direction, field } = sorting;
      this.apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${field || ''}&sortDir=${
        direction || ''
      }&excludeAssocFields=imagenes`;
      const clientesRes = await APIClient.get(`/clientes?limit=${pagination.limit}&offset=${pagination.offset}&${this.apiParams}`);
      this.setState({
        clientes: clientesRes.data.data,
        totalSize: clientesRes.data.meta.total,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    }
  };

  async loadClientes() {
    const { toastManager } = this.props;
    try {
      const clientesRes = await APIClient.get('/clientes?limit=10&offset=0&sortField=id&sortDir=asc');
      this.setState({
        clientes: clientesRes.data.data,
        isDataLoading: false,
        totalSize: clientesRes.data.meta.total,
      });
      this.apiParams = 'sortField=id&sortDir=asc&excludeAssocFields=imagenes';
    } catch (err) {
      console.error('Cargando clientes.', err);
      toastManager.add(`Ocurrió un error: "${err.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  }

  render() {
    const { clientes, isDataLoading, totalSize, isSuperAdmin, editPermission, isPermissionsLoading } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'id',
        hidden: true,
      },
      {
        dataField: 'codigoInterno',
        text: 'Código',
        sort: true,
      },
      {
        dataField: 'razonSocial',
        text: 'Razón Social',
        sort: true,
        formatter: (cellContent, row) => (
          <div>
            {row.razonSocial}
            <br />
            <small title="Nombre de Fantasía">{row.nombreFantasia}</small>
          </div>
        ),
      },
      {
        dataField: 'documentoNumero',
        text: 'CUIT',
        sort: true,
      },
      {
        dataField: 'tipo.descripcion',
        text: 'Tipo',
        sort: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'vendedor.nombre',
        text: 'Vendedor',
        sort: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'email',
        text: 'Contacto',
        sort: true,
        formatter: (cellContent, row) => (
          <div>
            <a href="mailto:{row.email}">{row.email}</a>
            <br />
            <a href="tel:{row.telefono}">{row.telefono}</a>
          </div>
        ),
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'telefono',
        text: 'Teléfono',
        sort: false,
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'celular',
        text: 'Celular',
        sort: false,
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'domicilio',
        isDummyField: true,
        text: 'Domicilio',
        hidden: true,
        csvFormatter: (cellContent, row) =>
          `${row.calle ? row.calle : 'S/C'} ${row.numero ? row.numero : 'S/N'}${row.piso ? ` ${row.piso}` : ''}${
            row.depto ? ` ${row.depto}` : ''
          }, ${row.localidad ? `${row.localidad}, ` : ''}${row.provincia ? row.provincia.descripcion : ''}${
            row.codigoPostal ? `, ${row.codigoPostal}` : ''
          }`,
      },
      {
        dataField: 'coeficienteFacturacion',
        text: 'Coeficiente Facturación',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'diasEntrega',
        text: 'Dias Entrega',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'eliminadoFlag',
        text: 'Eliminado Flag',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'fax',
        text: 'Fax',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'latitud',
        text: 'Latitud',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'longitud',
        text: 'Longitud',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'listaPrecio.descripcion',
        text: 'Lista Precio',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'puntoEntrega.descripcion',
        text: 'Punto Entrega',
        hidden: true,
        csvFormatter: (cellContent) => {
          if (!cellContent) {
            return 'N/A';
          }
          return cellContent;
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar>
            <ButtonGroup>
              {isSuperAdmin || editPermission ? (
                <LinkContainer to={`/clientes/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Editar">
                    <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Clientes</h1>
        {isPermissionsLoading === false ? (
          <DataTable
            remote={{
              filter: true,
              pagination: true,
              sort: true,
              cellEdit: false,
            }}
            totalSize={totalSize}
            isDataLoading={isDataLoading}
            columns={columns}
            data={clientes}
            onTableUpdate={this.onTableUpdate}
            keyField="id"
            addButton={isSuperAdmin || editPermission ? '/clientes/nuevo' : null}
            exportURL={`/clientes/export.csv?${this.apiParams}`}
            defaultSorted={[{ dataField: 'codigo', order: 'desc' }]}
          />
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

const mapStateToProps = (permissionsUser) => (permissionsUser);

export default connect(mapStateToProps)(withToastManager(ClienteList));
